import { createSlice } from '@reduxjs/toolkit';
import { POSTPEND_TERM } from '../../Common/Common.env';
import { RecorderOptions } from './NewFloContainer.types';

const initialState = Object.freeze({});

const newFloReducers = {
	startRecording(
		state: {},
		payload: {
			currentTab: string;
			recordingType: RecorderOptions;
			audio: string;
			video: string;
		}
	) {},
	showPopup(state: {}, payload: any) {},
	showError(state: {}, payload: string) {},
	getDevices(state: {}, payload: RecorderOptions) {},
	setDevices(
		state: {},
		payload: {
			devicesAudio: MediaDeviceInfo[];
			devicesVideo: MediaDeviceInfo[];
		}
	) {},
	prepareForRecording(state: {}, payload: any) {},
	prepareForRecordingToIframe(state: {}, payload: any) {},
	dataAvailable(state: {}, payload: any) {},
	captureSnapshot(state: {}, payload: any) {},
	captureScrollingSnapshot(state: {}, payload: any) {},
	captureScrollingSnapshotNext(state: {}, payload: any) {},
	pauseFloExtension(state: {}, payload: any) {},
	resumeFloExtension(state: {}, payload: any) {},
	baseReadyAction(state: {}, payload: any) {},
	settingAsBaseTab(state: {}, payload: any) {},
};

export const newFloExtToExt = createSlice({
	name: `floik-extension-to-extension-newFlo${POSTPEND_TERM}`,
	initialState,
	// @ts-ignore
	reducers: newFloReducers,
});

export default newFloExtToExt.reducer;

export const startRecording = newFloExtToExt.actions.startRecording;
export const prepareForRecording = newFloExtToExt.actions.prepareForRecording;
export const prepareForRecordingToIframe =
	newFloExtToExt.actions.prepareForRecordingToIframe;
export const dataAvailableExtension = newFloExtToExt.actions.dataAvailable;
export const showPopupExtension = newFloExtToExt.actions.showPopup;
export const showErrorExtension = newFloExtToExt.actions.showError;
export const getDevicesExtension = newFloExtToExt.actions.getDevices;
export const setDevicesExtension = newFloExtToExt.actions.setDevices;
export const captureSnapshot = newFloExtToExt.actions.captureSnapshot;
export const captureScrollingSnapshot = newFloExtToExt.actions.captureScrollingSnapshot;
export const captureScrollingSnapshotNext =
	newFloExtToExt.actions.captureScrollingSnapshotNext;
export const baseReadyAction = newFloExtToExt.actions.baseReadyAction;
export const settingAsBaseTab = newFloExtToExt.actions.settingAsBaseTab;
