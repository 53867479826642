import { get, isUndefined } from 'lodash';
import store from '../store';
import * as constants from './Messages.constants';

const cache: {
	[key: string]: string;
} = {};

function translateGetFromState(key: string): string | undefined {
	if (!isUndefined(get(cache, key))) {
		return get(cache, key);
	}
	cache[key] = get(store?.getState(), `translations.constants['${key}']`) as string;
	return cache[key];
}

export function translate(key: string, defaultValue?: string): string {
	return translateGetFromState(key) ?? get(constants, key) ?? defaultValue;
}

const Translations = new Proxy(constants, {
	get: (target, key: string): string => translate(String(key)),
});

export default Translations;
