import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';

const initialState = Object.freeze({
	global: 0,
	flo: 0,
	manageFlo: 0,
});

type stateType = {
	global: number;
	flo: number;
	manageFlo: number;
	[key: string]: number;
};

const LoaderReducer = {
	showLoader(state: stateType, { payload }: { payload: string }) {
		const type = payload || 'global';
		state[type] = get(state, type, 0) + 1;
	},
	hideLoader(state: stateType, { payload }: { payload: string }) {
		const type = payload || 'global';
		const loaderCount = get(state, type, 0);
		state[type] = loaderCount - 1;
	},
	resetLoader(state: stateType, { payload }: { payload: string }) {
		const type = payload || 'global';
		state[type] = 0;
	},
};

export const Loader = createSlice({
	name: 'loader',
	initialState,
	//@ts-ignore
	reducers: LoaderReducer,
});

export const showLoader = Loader.actions.showLoader;
export const hideLoader = Loader.actions.hideLoader;
export const resetLoader = Loader.actions.resetLoader;

export default Loader.reducer;
