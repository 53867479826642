/*[{
 "id":"63aeb16057d3de5c1748b8fd","floId":"63aeb15f4e59d33390691c4e",
 "resources": [
 {
 "extension":"webm",
 "status":"ready",
 "url":"https://d1bpmbvfmca4i4.cloudfront.net/635f72cfb5b6f0548b25e712/63aeb15f4e59d33390691c4e/63aeb16157d3de5c1748b8fe.webm",
 "resourceType":"ORIGINAL_INPUT",
 "resourceProperties": {"recordTimeInSeconds":10}}],
 "type":"screenShare"
 }]*/
import { scaleLinear } from 'd3-scale';
import {
	cloneDeep,
	concat,
	filter,
	find,
	flatMap,
	get,
	groupBy,
	includes,
	isFinite,
	isNull,
	keyBy,
	map,
	memoize,
	min,
	padStart,
	reduce,
	sortBy,
} from 'lodash';
import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import uniqId from 'uniqid';
import {
	FLO_ELEMENT_CTA_TYPE,
	FLO_ELEMENT_DOCUMENT_BLUR_TYPE,
	FLO_ELEMENT_DOCUMENT_SPOTLIGHT_TYPE,
	FLO_ELEMENT_IMAGE_TYPE,
	FLO_ELEMENT_INTERACTION_CUE_TYPE,
	FLO_ELEMENT_VOICE_OVER_TYPE,
} from '../Pages/Flo/Flo.constants';
import { getEditorElementConfigSelector } from '../Pages/Flo/Flo.selectors';
import {
	elementBeanListType,
	elementVoiceConfigType,
	floTrackType,
	publishElementsDataType,
} from '../Pages/Flo/Flo.types';
import Translations from './Translate.utils';

interface ResourceType {
	extension: string;
	status: string;
	url: string;
	resourceType: string;
	resourceProperties: ResourceProperties;
	hasThumbnails: boolean;
	subtitleUrl: string;
	thumbnailsPath: string;
	cookieDefinitions: unknown;
}

interface ResourceProperties {
	recordTimeInSeconds?: number;
	endTime: string;
	endTimeInMillis: number;
	startTime: string;
	startTimeInMillis: number;
	src?: string;
	voiceId?: string;
	transcribeId?: string;
}

export interface ResourceGroupTrackResponse {
	id: string;
	floId: string;
	resources: ResourceType[];
	type: string;
}

export interface Playlist {
	sources: ResourceProperties[];
}

export function transformResourceGroupToUISourceList(
	data: ResourceGroupTrackResponse[],
	setHeightAndWidthIgnoringType?: boolean
): floTrackType[] {
	// @ts-ignore
	return map(data, (item) => {
		const values = reduce(
			item.resources,
			(acc, resource: ResourceType) => {
				if (includes(resource.extension, 'webm')) {
					acc.url = resource.url;
				}
				if (
					setHeightAndWidthIgnoringType ||
					includes(get(resource, 'resourceProperties.containerType'), 'webm')
				) {
					if (get(resource, 'resourceProperties.height')) {
						acc.height = get(resource, 'resourceProperties.height');
						// acc.height = 586; //get(resource, 'resourceProperties.height');
					}
					if (get(resource, 'resourceProperties.width')) {
						acc.width = get(resource, 'resourceProperties.width');
						// acc.width = 1866; //get(resource, 'resourceProperties.width');
					}
				}
				if (get(resource, 'resourceType') === 'GALLERY_IMAGE_PUBLISHED') {
					acc.galleryImages[get(resource, 'resourceProperties.elementId')] = get(
						resource,
						'url'
					);
				}
				if (get(resource, 'resourceType') === 'PUBLISHED_OUTPUT_VIDEO') {
					if (get(resource, 'resourceProperties.height')) {
						acc.publishedHeight = get(resource, 'resourceProperties.height');
					}
					if (get(resource, 'resourceProperties.width')) {
						acc.publishedWidth = get(resource, 'resourceProperties.width');
					}
				}
				if (includes(resource.extension, 'm3u8')) {
					acc.hlsURL = resource.url;
				}
				if (
					includes(resource.extension, 'mov') &&
					get(resource, 'resourceType') === 'ORIGINAL_INPUT'
				) {
					acc.hlsURL = resource.url;
				}
				if (
					includes(resource.extension, 'mp4') &&
					get(resource, 'resourceType') === 'ORIGINAL_INPUT'
				) {
					acc.hlsURL = resource.url;
				}
				if (includes(resource.resourceType, 'THUMBNAIL')) {
					acc.hasThumbnails = true;
					acc.thumbnailsPath = resource.url;
				}

				if (includes(resource.resourceType, 'TRANSCRIBE')) {
					acc.subtitleUrl = resource.url;
				}

				if (resource.cookieDefinitions) {
					// @ts-ignore
					acc.cookieDefinition = resource.cookieDefinitions;
				}
				if (get(resource, 'resourceProperties.recordTimeInSeconds')) {
					acc.duration = get(resource, 'resourceProperties.recordTimeInSeconds');
				}
				if (get(resource, 'resourceProperties.hasAudio')) {
					acc.hasAudio = get(resource, 'resourceProperties.hasAudio') as boolean;
				}
				return acc;
			},
			{
				galleryImages: {},
				hasAudio: false,
				hlsURL: '',
				url: '',
				duration: 0,
				subtitleUrl: '',
				height: 0,
				width: 0,
				publishedHeight: 0,
				publishedWidth: 0,
				hasThumbnails: false,
				thumbnailsPath: '',
				cookieDefinition: undefined,
			}
		);
		return {
			...values,
			id: item.id,
			type: item.type,
		};
	});
}

export const isValidHttpUrl = (s: string) => {
	let u;
	try {
		u = new URL(s);
	} catch (_) {
		return false;
	}
	return u.protocol.startsWith('http');
};

export const linkify = (t: string) => {
	t = t.replace(/&nbsp;/g, ' ');
	const m = t.match(
		/(?<=\s|^)(https?:\/\/)?[a-zA-Z0-9-:/]+\.[a-zA-Z0-9-].+?(?=[.,;:?!-]?(?:\s|$))/g
	);
	if (!m) return t;
	const a = [];
	m.forEach((x) => {
		const [t1, ...t2] = t.split(x);
		a.push(t1);
		t = t2.join(x);
		const y = (!x.match(/:\/\//) ? 'https://' : '') + x;
		//@ts-ignore
		if (isNaN(x) && isValidHttpUrl(y))
			a.push(
				'<a href="' +
					y +
					'" title="' +
					y +
					'" target="_blank">' +
					y.split('/')[2] +
					'</a>'
			);
		else a.push(x);
	});
	a.push(t);
	return a.join('');
};

export const convertNodesToString = (nodes: ChildNode[]) => {
	if (!nodes) return '';
	//@ts-ignore
	return nodes?.map((node) => node.outerHTML || node.nodeValue).join('');
};

export const convertStringToNodes = (s: string) => {
	const doc = new DOMParser().parseFromString(s || '', 'text/html');
	return Array.from(doc.body.childNodes);
};

export const sanitizeHtml = (str: string) => {
	if (!str) return '';
	let s = (str || '').replace(/<br>/g, '').trim();
	if (s === '<p></p>' || s === '<p><br></p>' || s === '<p><br/></p>') {
		return '';
	}
	const outArr: ChildNode[] = [];
	const nodeArr = convertStringToNodes(s);
	nodeArr.forEach((node) => {
		if (node.hasChildNodes() && node.nodeName !== 'A') {
			const arr = Array.from(node.childNodes);
			// @ts-ignore
			const sanitizedArr = arr.map((child) =>
				// @ts-ignore
				sanitizeHtml(child?.outerHTML || child.nodeValue)
			);
			return outArr.push(...convertStringToNodes(sanitizedArr.join('')));
		}
		if (node.nodeName === '#text') {
			const str = convertNodesToString([node]);
			const link = linkify(str);
			return outArr.push(...convertStringToNodes(link));
		}
		if (node.nodeName === 'A') {
			// @ts-ignore
			node.setAttribute('target', '_blank');
			// @ts-ignore
			const href = node.getAttribute('href');
			// @ts-ignore
			node.setAttribute('title', href);
		}
		return outArr.push(node);
	});
	return convertNodesToString(outArr);
};

const modifyAnchorTags = (nodes: ChildNode[]): ChildNode[] => {
	const modifiedNodes: ChildNode[] = [];
	for (const node of nodes) {
		if (node.nodeType === Node.ELEMENT_NODE && node.nodeName === 'A') {
			// @ts-ignore
			node.setAttribute('target', '_blank');
			modifiedNodes.push(node);
		} else if (node.hasChildNodes()) {
			const modifiedChildren = modifyAnchorTags(Array.from(node.childNodes));
			const newNode = node.cloneNode(false);
			for (const child of modifiedChildren) {
				newNode.appendChild(child);
			}
			// @ts-ignore
			modifiedNodes.push(newNode);
		} else {
			modifiedNodes.push(node);
		}
	}
	return modifiedNodes;
};
export const sanitizeHtmlLinks = (str: string) => {
	if (!str) return '';
	let s = (str || '').trim();
	const n = s.replace(/\n/g, '');
	if (n === '<p></p>' || n === '<p><br></p>' || n === '<p><br/></p>') {
		return '';
	}
	const doc = new DOMParser().parseFromString(s || '', 'text/html');
	// @ts-ignore
	const modifiedNodes = modifyAnchorTags(doc.body.childNodes);
	return convertNodesToString(modifiedNodes);
};

const createSourceForAudio = (audioResources: ResourceType[]) => {
	return map(audioResources, (playlist, index) => {
		return {
			sources: [
				{
					preload: 'auto',
					loop: false,
					audioOnlyMode: true,
					src: playlist.url,
					type: 'audio/mp3',
					startTime: playlist.resourceProperties.startTime,
					startTimeInMillis: playlist.resourceProperties.startTimeInMillis,
					endTime: playlist.resourceProperties.endTime,
					endTimeInMillis: playlist.resourceProperties.endTimeInMillis,
					actualEndTime: min([
						playlist.resourceProperties.endTimeInMillis,
						get(audioResources, `[${index + 1}].resourceProperties.startTimeInMillis`) ||
							Number.MAX_SAFE_INTEGER,
					]),
					transid: playlist.resourceProperties.transcribeId,
					voiceId: playlist.resourceProperties.voiceId,
				},
			],
		};
	});
};

export const getVoiceOverPlaylist = (data: ResourceGroupTrackResponse[]) => {
	return reduce(
		data,
		(acc, item) => {
			acc[item.floId] = acc[item.floId] || [];
			const audioResources = sortBy(
				filter(
					item.resources,
					(resource) => resource.resourceType === 'VOICE_OVER_SNIPPET'
				),
				'resourceProperties.startTimeInMillis'
			);
			const groupedByVoiceId = groupBy(audioResources, 'resourceProperties.voiceId');
			const values = map(groupedByVoiceId, (value, key) => createSourceForAudio(value));
			acc[item.floId] = concat(acc[item.floId], ...values);
			return acc;
		},
		{} as Record<string, Playlist[]>
	);
};

export const formattedTime = (
	timeInMillis: number,
	unit?: string,
	inMulliSeconds?: boolean
) => {
	const startTime = moment.duration(
		// @ts-ignore
		timeInMillis || 0,
		unit || 'milliseconds'
	);
	const totalSeconds = startTime.asSeconds();
	const milliseconds = startTime.milliseconds();
	const startTimeFormated = moment
		.utc(startTime.abs().as('milliseconds'))
		// @ts-ignore
		.format(
			(totalSeconds >= 60 * 60 ? 'HH:' : '') +
				'mm:ss' +
				(inMulliSeconds && milliseconds > 0 ? '.SSS' : '')
		);
	return startTimeFormated;
};

export const timeFormatterFullLength = (time: number, inMulliSeconds?: boolean) => {
	try {
		if (isNaN(time) || isNull(time) || !isFinite(time)) return;
		const dateForFormatting = new Date(time * 1000);
		const formattedString = dateForFormatting.toISOString().substring(11, 23);
		if (inMulliSeconds) return formattedString.replace(/\./g, ':');
		return formattedString.replace(/\..*/, '.000').replace(/\./g, ':');
	} catch (e) {
		console.warn('timeFormatter', time);
		return '00.00';
	}
};

export const formattedTimeToSeconds = (time: string): number => {
	try {
		const index = time.lastIndexOf(':');
		const formatted = `${time.slice(0, index)}.${time.slice(index + 1)}`;
		const seconds = moment.duration(formatted).asSeconds();
		return seconds;
	} catch (e) {
		return 0;
	}
};

export function getRelativeTime(timestamp: string) {
	const now = moment(); // Current time
	const time = moment(timestamp); // Timestamp

	// Calculate the difference in minutes between the current time and the timestamp
	const minutesDiff = now.diff(time, 'minutes');

	if (minutesDiff < 1) {
		return 'Few moments ago';
	} else if (minutesDiff < 60) {
		return `${minutesDiff} minutes ago`;
	} else if (time.isSame(now, 'day')) {
		return 'Today';
	} else if (time.isSame(now.clone().subtract(1, 'day'), 'day')) {
		return 'Yesterday';
	} else {
		return time.format('MMMM DD, YYYY'); // Format the timestamp as 'Month Day'
	}
}

export function openLinkInNewTab(link: string) {
	const node = document.createElement('a');
	node.setAttribute('href', link);
	node.setAttribute('target', '_blank');
	node.click();
}

export const stringToHslColor = (str: string, s: number, l: number) => {
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	const h = hash % 360;
	return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
};

export const getStringWidth = (str: string, font?: string) => {
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');
	let width = 0;
	if (ctx) {
		ctx.font = font || '14px Rubik';
		width = ctx.measureText(str).width;
	}
	return width;
};

export function moveElement(arr, fromIndex, toIndex) {
	// Handle invalid input
	if (fromIndex < 0 || fromIndex >= arr.length || toIndex < 0 || toIndex >= arr.length) {
		return arr;
	}

	// Handle moving the element within the same place
	if (fromIndex === toIndex) {
		return arr;
	}
	const val = cloneDeep(arr);
	// Use splice to remove and insert the element
	const element = val.splice(fromIndex, 1)[0];
	val.splice(toIndex, 0, element);

	return val;
}

export function updateHotspotOrder(list: elementBeanListType[]) {
	const { data } = reduce(
		list,
		(acc, item) => {
			const order = acc.orderCount[item.actualTime] || 0;
			acc.data.push({
				...item,
				order: order + 1,
			});
			acc.orderCount[item.actualTime] = order + 1;
			return acc;
		},
		{
			data: [],
			orderCount: {},
		}
	);
	const values = sortBy(
		data,
		(val) =>
			`${padStart(Math.round(val.actualTime * 1000), 10, '0')}_${padStart(
				val.order,
				3,
				'0'
			)}`
	);
	return map(values, (val, index) => ({ ...val, index }));
}

export function _pointWithInArea(
	px: number,
	py: number,
	x1: number,
	y1: number,
	x2: number,
	y2: number
) {
	return px >= x1 && px <= x2 && py >= y1 && py <= y2;
}

export const pointWithInArea = memoize(
	_pointWithInArea,
	(a, b, c, d, e, f) => `${a}, ${b}, ${c}, ${d}, ${e}, ${f}`
);

export function getDeviceId() {
	let deviceId = localStorage.getItem('deviceId');
	if (!deviceId) {
		deviceId = uniqId();
		localStorage.setItem('deviceId', deviceId);
	}
	return deviceId;
}

export const getBlurElementPoints = ({
	element,
	elementType,
	height,
	width,
	offsetWidth,
	offsetHeight,
	isDocument,
}: {
	element: unknown;
	elementType?: string;
	height: number;
	width: number;
	offsetWidth: number;
	offsetHeight: number;
	isDocument?: boolean;
}) => {
	const elementHeight = get(element, 'canvasRenderConfig.windowCoordinates.height', 0);
	const elementWidth = get(element, 'canvasRenderConfig.windowCoordinates.width', 0);
	let points = {
		left: 0,
		top: 0,
		width: 0,
		height: 0,
	};
	if (isDocument) {
		const canvasX = get(element, 'canvasRenderConfig.canvasCoordinates.x', 0);
		const canvasY = get(element, 'canvasRenderConfig.canvasCoordinates.y', 0);
		const canvasWidth = get(element, 'canvasRenderConfig.canvasCoordinates.width', 0);
		const canvasHeight = get(element, 'canvasRenderConfig.canvasCoordinates.height', 0);
		if (elementHeight > height) {
			const yScale = scaleLinear(
				[0, elementHeight],
				[0, height - (offsetHeight || 0) * 2]
			);
			points.top = yScale(canvasY);
			points.height = yScale(canvasHeight);
		} else {
			points.top = canvasY;
			points.height = canvasHeight;
		}
		if (elementWidth > width) {
			const xScale = scaleLinear([0, elementWidth], [0, width - (offsetWidth || 0) * 2]);
			points.left = xScale(canvasX);
			points.width = xScale(canvasWidth);
		} else {
			points.left = canvasX;
			points.width = canvasWidth;
		}
	} else {
		const yScale = scaleLinear([0, elementHeight], [0, height - (offsetHeight || 0) * 2]);
		const xScale = scaleLinear([0, elementWidth], [0, width - (offsetWidth || 0) * 2]);
		points = {
			left: xScale(get(element, 'canvasRenderConfig.canvasCoordinates.x', 0)),
			top: yScale(get(element, 'canvasRenderConfig.canvasCoordinates.y', 0)),
			width: xScale(get(element, 'canvasRenderConfig.canvasCoordinates.width', 0)),
			height: yScale(get(element, 'canvasRenderConfig.canvasCoordinates.height', 0)),
		};
	}
	return points;
};

export const typingAnimation = (target: HTMLElement, text: string, speed = 50) => {
	let i = 0;
	target.innerHTML = '';

	function typeWriter() {
		if (i < text.length) {
			target.innerHTML += text.charAt(i);
			i++;
			setTimeout(typeWriter, speed);
		}
	}

	typeWriter();
};


const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

export function bytesToSize(bytes: number) {
	if (bytes === 0) return '0 Bytes';

	// @ts-ignore
	const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
	const value = (bytes / Math.pow(1024, i)).toFixed(1); // Round to 1 decimal place
	return `${value} ${sizes[i]}`;
}

export function bytesToMB(bytes: number) {
	try {
		// Convert bytes to megabytes with proper division by 1024
		const MB = bytes / (1024 * 1024);
		// Round to two decimal places for a more user-friendly representation
		return MB;
	} catch {
		return 0;
	}
}


export const useElementVoicePlayer = (voiceConfig: elementVoiceConfigType | undefined) => {
	const { scriptId, voiceId } = voiceConfig || {};
	const [curElementVoiceAudio, setCurElementVoiceAudio] =
		useState<HTMLAudioElement | null>(null);
	const [curVoiceUrl, setCurVoiceUrl] = useState('');

	const voiceOverElement = useSelector((state) => {
		const elements = flatMap(get(state, 'floPage.editorsElements'));
		return find(
			elements,
			(ele: { name: string }) => ele.name === FLO_ELEMENT_VOICE_OVER_TYPE
		);
	});

	const voices = get(voiceOverElement, 'subElements');
	const voicesMap = keyBy(voices, 'id');
	const voiceResourcesMap = useSelector((state) =>
		get(state, `floPage.elementVoiceResourceMap`, '')
	);
	const voiceProperties = get(voiceResourcesMap, `${scriptId}_${voiceId}`);
	const voiceUrl = get(voiceProperties, 'url');

	useEffect(() => {
		if (voiceUrl !== curVoiceUrl) {
			setCurVoiceUrl(voiceUrl);
			if (curElementVoiceAudio) curElementVoiceAudio.pause();
			if (voiceUrl) {
				const audio = new Audio(voiceUrl);
				audio.play();
				setCurElementVoiceAudio(audio);
			} else {
				setCurElementVoiceAudio(null);
			}
		}

	}, [voiceUrl, setCurElementVoiceAudio, curElementVoiceAudio, curVoiceUrl]);
};

export const isActiveElementVoiceOverlapping = (id?: string) => {
	const { HEADS_UP_VOICE_OVER_WARNING_IMAGE, HEADS_UP_VOICE_OVER_WARNING_CTA } =
		Translations;
	const floElements: publishElementsDataType[] = useSelector(
		getEditorElementConfigSelector
	);
	let compareElement;
	let element;
	let message = '';
	const activeEditElement = useSelector((state) =>
		get(state, 'floPage.activeEditElement')
	);
	const prevActiveEditElement = useSelector((state) =>
		get(state, 'floPage.prevActiveEditElement')
	);
	let compareElementProperties;
	if (id) {
		compareElement = find(floElements, ['id', id]);
		compareElementProperties = get(compareElement, 'properties');
		const key = includes(
			[FLO_ELEMENT_CTA_TYPE, FLO_ELEMENT_INTERACTION_CUE_TYPE],
			get(activeEditElement, 'type')
		)
			? 'linkedImageId'
			: 'linkedCtaId';
		element = find(floElements, ['id', get(compareElement, `properties.${key}`)]);
	} else {
		if (
			includes(
				[FLO_ELEMENT_CTA_TYPE, FLO_ELEMENT_INTERACTION_CUE_TYPE],
				get(activeEditElement, 'type')
			)
		) {
			compareElement = find(floElements, ['id', get(activeEditElement, 'element.id')]);
			compareElementProperties = get(activeEditElement, 'element.properties.values');
			element = prevActiveEditElement
				? { properties: get(prevActiveEditElement, 'tempValues') }
				: find(floElements, ['id', get(compareElement, 'properties.linkedImageId')]);
			message = HEADS_UP_VOICE_OVER_WARNING_CTA;
		} else if (
			get(activeEditElement, 'type') === FLO_ELEMENT_IMAGE_TYPE &&
			(!get(activeEditElement, 'tempValues.behaviourType') ||
				get(activeEditElement, 'tempValues.behaviourType') === 'CTA')
		) {
			compareElementProperties = get(activeEditElement, 'tempValues');
			element = find(floElements, ['id', get(compareElementProperties, 'linkedCtaId')]);
			message = HEADS_UP_VOICE_OVER_WARNING_IMAGE;
		}
	}

	const overlap =
		get(element, 'properties.enableVoice') &&
		get(compareElementProperties, 'enableVoice') &&
		get(element, 'properties.voiceConfig.scriptId') &&
		get(compareElementProperties, 'voiceConfig.scriptId');
	return {
		overlap,
		message: overlap ? message : '',
	};
};
