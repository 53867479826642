import { get } from 'lodash';
import { setExtensionRecording } from '../Pages/NewFloContainer/NewFlo.reducers';
import store from '../store';
import {
	setExtensionErrored,
	setInstalledExtension,
} from '../store/reducers/OnBoarding.reducer';

const extensionId =
	process.env.REACT_APP_EXTENSION_ID || 'lhkecgbbmmggmgglgbdpdmjlnmdoahbc';
let isExtensionInstalled = false;
// @ts-ignore
let port: any;

export const SendMessageToExtension = (action: {
	type: string;
	payload?: unknown;
	token?: string;
}) => {
	try {
		// @ts-ignore
		if (chrome?.runtime?.sendMessage) {
			// @ts-ignore
			chrome.runtime.sendMessage(
				extensionId,
				{
					...action,
					payload: {
						// @ts-ignore
						...(action.payload || {}),
						from: 'app',
					},
				},
				undefined,
				// @ts-ignore
				(...args) => {
					const onBoardingState = get(store?.getState(), 'onBoarding');
					if (args.length === 0) {
						if (get(onBoardingState, 'extensionErrored')) {
							store.dispatch(setExtensionErrored(false));
						}
						if (get(onBoardingState, 'extensionInstalled')) {
							store.dispatch(setInstalledExtension(false));
							store.dispatch(setExtensionRecording(false));
						}
					} else {
						if (get(onBoardingState, 'extensionErrored')) {
							store.dispatch(setExtensionErrored(false));
						}
						if (!get(onBoardingState, 'extensionInstalled')) {
							store.dispatch(setInstalledExtension(true));
						}
					}
				}
			);
			isExtensionInstalled = true;
			const onBoardingState = get(store?.getState(), 'onBoarding');
			if (get(onBoardingState, 'extensionErrored')) {
				store.dispatch(setExtensionErrored(false));
			}
		}
	} catch (e) {
		console.warn(e);
		store.dispatch(setExtensionErrored(true));
		store.dispatch(setInstalledExtension(false));
		isExtensionInstalled = false;

		checkConnection();
	}
};

let interval: NodeJS.Timer;

function checkConnection() {
	if (interval) return;
	interval = setInterval(async () => {
		clearInterval(interval);
		if (isExtensionInstalled) {
			clearInterval(interval);
		} else if (!port) {
			try {
				// @ts-ignore
				port = await chrome.runtime.connect(extensionId);
				isExtensionInstalled = true;
				store.dispatch(setInstalledExtension(true));
				await new Promise((resolve) => setTimeout(resolve, 100));
				port.onDisconnect.addListener(() => {
					console.log('on  disconnect app');
					isExtensionInstalled = false;
					// store.dispatch(setInstalledExtension(false));
					checkExtensionUninstalled();
				});
			} catch (e) {
				store.dispatch(setInstalledExtension(false));
				isExtensionInstalled = false;
			}
		} else if (port) {
			try {
				port.postMessage({ type: 'test' });
			} catch (e) {
				port.disconnect();
				port = null;
				store.dispatch(setInstalledExtension(false));
				store.dispatch(setExtensionErrored(false));
				store.dispatch(setExtensionRecording(false));
				isExtensionInstalled = false;
			}
		}
	}, 5000);
}

checkConnection();

export const checkExtensionUninstalled = () => {
	port?.disconnect();
	port = null;
	isExtensionInstalled = false;
	SendMessageToExtension({
		type: 'test',
	});
	checkConnection();
};

export const ExtensionListener = (
	cb: (action: { type: string; payload?: unknown }) => void
) => {
	const onMessage = (event: Event) => {
		const matchingOrigin = [
			'https://app.floik.com',
			'https://us.signoff.ai',
			'http://localhost:3000',
		].includes(get(event, 'origin'));
		if (!matchingOrigin) return;
		// @ts-ignore
		cb(get(event, 'data') as { type: string; payload?: unknown });
	};
	window.addEventListener('message', onMessage, false);
	return () => {
		window.removeEventListener('message', onMessage, false);
	};
};
