import { Button, Grid } from '@mui/material';
import classNames from 'classnames';
import { get, includes, toLower } from 'lodash';
import React, {
	CSSProperties,
	ForwardedRef,
	MouseEventHandler,
	useCallback,
	useEffect,
	useState,
} from 'react';
// import Zoom from '@mui/material/Zoom';
import { IconAlertTriangleFilled as WarningIcon } from '@tabler/icons-react';
import { ReactComponentLike } from 'prop-types';
import { browserName, isChrome } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { FloCreationTemplates } from '../../../../../Common/Common.types';
import { ReactComponent as CUST_DEMO } from '../../../../../Common/images/cust-demo-wo-border.svg';
import { ReactComponent as FilmStrip } from '../../../../../Common/images/FilmStrip.svg';
import { ReactComponent as GIF } from '../../../../../Common/images/gif-wo-border.svg';
import { ReactComponent as GUIDE } from '../../../../../Common/images/guide-wo-bg.svg';
import { ReactComponent as InteractiveDemoSvg } from '../../../../../Common/images/ID-Icon.svg';
import Translations from '../../../../../Common/Translate.utils';
import {
	hideDialogAction,
	showDialogAction,
} from '../../../../../Components/Dialog/Dialog.reducer';
import prentStyles from '../../../NewFloContainer.module.css';
import { AvailableDevices, RecordStates } from '../../../NewFloContainer.types';
import Zoom from '../../../ZoomTemp';
import NetworkSpeed from '../../NetworkSpeed';
import NewFloExtensionError from '../../NewFloExtensionError/NewFloExtensionError';
import PermissionComponent from '../../PermissionComponent';
import { ReactComponent as TickSvg } from './../../../../../Common/images/circle-check.svg';
import MicUrl, { ReactComponent as MicSvg } from './../../../images/Mic.svg';
import cameraAccessError from './images/camera-access.svg';
import permissionError from './images/camera-microphone-access.svg';
import CameraUrl from './images/CameraNoColor.svg';
import chromeUrl, { ReactComponent as ChromeSvg } from './images/Chrome.svg';
import { ReactComponent as FileIcon } from './images/file.svg';
import { ReactComponent as LinkArrow } from './images/link-arrow.svg';
import { ReactComponent as MicRadiationSvg } from './images/MicRaditation.svg';
import micAccessError from './images/microphone-access.svg';
import { ReactComponent as PlayIcon } from './images/play-small.svg';
import { ReactComponent as TakeToExternalSiteSvg } from './images/TaketoExternalSite.svg';
import styles from './TemplateDetails.module.css';

const PERMISSION_MAP = {
	microphone: 'audio_capture',
	camera: 'video_capture',
};

const TEMPLATE_KEYS = {
	step_by_step_guide: 'step_by_step_guide',
};

const PermissionItems = {
	extension: ({ enabled }: { enabled: boolean }) => {
		return (
			<li className={styles.permissionListItem}>
				<div className={styles.permissionItemIcon}>
					<img src={chromeUrl} alt="Chrome" />
				</div>
				<div className={styles.permissionItemDescription}>
					<span className={styles.permissionDescription}>Floik extension</span>
				</div>
				<div
					className={classNames(styles.permissionItemTIck, {
						[styles.enabled]: enabled,
					})}
				>
					<TickSvg />
				</div>
			</li>
		);
	},
	microphone: ({ enabled }: { enabled: boolean }) => (
		<li className={styles.permissionListItem}>
			<div className={styles.permissionItemIcon}>
				<img src={MicUrl} alt="Mic" />
			</div>
			<div className={styles.permissionItemDescription}>
				<span className={styles.permissionDescription}>Microphone</span>
			</div>
			<div
				className={classNames(styles.permissionItemTIck, {
					[styles.enabled]: enabled,
				})}
			>
				<TickSvg />
			</div>
		</li>
	),
	camera: ({ enabled }: { enabled: boolean }) => (
		<li className={styles.permissionListItem}>
			<div className={styles.permissionItemIcon}>
				<img src={CameraUrl} alt="Camera" />
			</div>
			<div className={styles.permissionItemDescription}>
				<span className={styles.permissionDescription}>Camera</span>
			</div>
			<div
				className={classNames(styles.permissionItemTIck, {
					[styles.enabled]: enabled,
				})}
			>
				<TickSvg />
			</div>
		</li>
	),
};

const TemplatePermissionError = ({
	installExtension,
	enableVideoDevice,
	enableAudioDevice,
	isMicOptional,
	onCancel,
	onContinueClick,
}: {
	installExtension: boolean;
	enableVideoDevice: boolean;
	enableAudioDevice: boolean;
	isMicOptional: boolean;
	onContinueClick: MouseEventHandler;
	onCancel: MouseEventHandler;
}) => {
	const {
		NEW_FLO_OPTIONAL_MICROPHONE_CANCEL_BUTTON_TEXT,
		NEW_FLO_OPTIONAL_MICROPHONE_SKIP_BUTTON_TEXT,
	} = Translations;
	return (
		<>
			{installExtension && (
				<section className={styles.installExtension}>
					<div className={styles.installExtensionContainer}>
						<span className={styles.installExtensionHeader}>Install Floik extension</span>
						<span className={styles.installExtensionDescription}>
							Please add Floik extension to your browser to create a flo
						</span>
						<Button
							variant="contained"
							disableFocusRipple
							disableElevation
							disableTouchRipple
							className={styles.installExtensionButton}
							component="a"
							href="https://chrome.google.com/webstore/detail/floik-make-interactive-de/odlncglkkpdcbefpneoelgcopkmgajbh"
							target="_blank"
						>
							<ChromeSvg className={styles.chromeBtn} />
							Visit chrome web store
							<TakeToExternalSiteSvg className={styles.installExtensionButtonIcon} />
						</Button>
					</div>
				</section>
			)}
			{(enableVideoDevice && enableAudioDevice && (
				<section className={styles.templatePermissionError}>
					<h1 className={styles.permissionTitle}>
						Camera and microphones access is required
					</h1>
					<h3 className={styles.permissionDescription}>
						Floik requires access to your camera and microphone. Please provide access to
						start recording.
					</h3>
					<div className={styles.permissionImage}>
						<img src={permissionError} alt="" />
					</div>
				</section>
			)) ||
				(enableVideoDevice && (
					<section className={styles.templatePermissionError}>
						<h1 className={styles.permissionTitle}>Camera access is required</h1>
						<h3 className={styles.permissionDescription}>
							Floik requires access to your camera. Please provide access to start
							recording.
						</h3>
						<div className={styles.permissionImage}>
							<img src={cameraAccessError} alt="" />
						</div>
					</section>
				)) ||
				(enableAudioDevice && (
					<section className={styles.templatePermissionError}>
						<h1 className={styles.permissionTitle}>Microphones access is required</h1>
						<h3 className={styles.permissionDescription}>
							Floik requires access to your microphone. Please provide access to start
							recording.
						</h3>
						<div className={styles.permissionImage}>
							<img src={micAccessError} alt="" />
						</div>
						{isMicOptional && (
							<div className={styles.buttonsContainer}>
								<Button
									autoFocus
									disableFocusRipple
									onClick={onCancel}
									className={styles.cancelButton}
								>
									<span className={styles.cancelButtonText}>
										{NEW_FLO_OPTIONAL_MICROPHONE_CANCEL_BUTTON_TEXT}
									</span>
								</Button>
								<Button
									disableFocusRipple
									variant="contained"
									className={styles.confirmButton}
									onClick={onContinueClick}
									autoFocus
								>
									{NEW_FLO_OPTIONAL_MICROPHONE_SKIP_BUTTON_TEXT}
								</Button>
							</div>
						)}
					</section>
				))}
		</>
	);
};

const TemplateDeviceSelection = React.forwardRef(
	(
		{
			recordState,
			template,
			enableStartButton,
			showVideoOptions,
			showAudioOptions,
			availableDevices,
			devicePermissions,
			videoDevice,
			audioDevice,
			audioDeviceChange,
			videoDeviceChange,
			onStartHandler,
			networkSpeed,
			isMicOptional,
		}: {
			template: FloCreationTemplates;
			recordState: RecordStates;
			enableStartButton: boolean;
			networkSpeed: string;
			showVideoOptions: boolean;
			showAudioOptions: boolean;
			availableDevices: AvailableDevices;
			videoDeviceChange(deviceId?: string): void;
			audioDeviceChange(deviceId?: string): void;
			onStartHandler(): void;
			audioDevice?: string;
			videoDevice?: string;
			devicePermissions: {
				[key: string]: string;
			};
			isMicOptional: boolean;
		},
		videoRef: ForwardedRef<HTMLVideoElement | null>
	) => {
		if (
			(showVideoOptions && get(availableDevices, 'devicesVideo.length', 0) === 0) ||
			(showAudioOptions &&
				get(availableDevices, 'devicesAudio.length', 0) === 0 &&
				!isMicOptional)
		) {
			const helpLinks = {
				macos: Translations.NEW_FLO_DEVICE_ISSUE_MAC_SYSTEM_LINK,
				windows: Translations.NEW_FLO_DEVICE_ISSUE_WINDOWS_SYSTEM_LINK,
				other: Translations.NEW_FLO_DEVICE_ISSUE_LINUX_SYSTEM_LINK,
			};

			const helpLink =
				get(helpLinks, toLower(get(navigator, 'userAgentData.platform'))) ||
				helpLinks.other;
			const [before, after] = Translations.NEW_FLO_DEVICE_ISSUE_MESSAGE.split(
				Translations.NEW_FLO_DEVICE_ISSUE_MESSAGE_LINK_TEXT
			);
			return (
				<div className={styles.deviceSelection}>
					<div className={styles.helpText}>
						{before}{' '}
						<a className={styles.helpLink} target="_blank" href={helpLink}>
							{Translations.NEW_FLO_DEVICE_ISSUE_MESSAGE_LINK_TEXT}
						</a>
						{after}
					</div>
				</div>
			);
		}

		return (
			<section className={styles.deviceSelection}>
				<video
					className={classNames(styles.video, {
						[styles.hideVideo]: !showVideoOptions,
					})}
					id="preview-video"
					ref={videoRef}
					height={9 * 25}
					width={16 * 25}
					muted
					controls={false}
					autoPlay
				></video>

				<div
					className={classNames(styles.micPlayerContainer, {
						[styles.hideMicrophoneContainer]: showVideoOptions || !showAudioOptions,
					})}
				>
					{
						<span className={styles.micSvgWrapper}>
							<MicRadiationSvg />
							<MicSvg className={styles.mic} />
						</span>
					}
				</div>
				{!showVideoOptions && !showAudioOptions && (
					<div
						className={classNames(
							styles.micPlayerContainer,
							styles.permissionHelperContainer
						)}
					>
						<span className={styles.permissionHelperHeading}>
							Click step by step to create a guide
						</span>
						<div className={styles.permissionHelperImage}></div>
					</div>
				)}
				<PermissionComponent
					showVideoOptions={showVideoOptions}
					showAudioOptions={showAudioOptions}
					availableDevices={availableDevices}
					devicePermissions={devicePermissions}
					videoDevice={videoDevice}
					audioDevice={audioDevice}
					audioDeviceChange={audioDeviceChange}
					videoDeviceChange={videoDeviceChange}
					isMicOptional={isMicOptional}
				/>
				<NewFloExtensionError />
				<Button
					variant="contained"
					onClick={onStartHandler}
					className={styles.startButton}
					disableElevation
					disabled={!enableStartButton}
				>
					Next
					{/*Start {!showVideoOptions && !showAudioOptions ? 'capture' : 'recording'}*/}
				</Button>
				<NetworkSpeed networkSpeed={networkSpeed} />
			</section>
		);
	}
);

const TemplateDetails = React.forwardRef(
	(
		{
			recordState,
			template,
			enableStartButton,
			showVideoOptions,
			showAudioOptions,
			availableDevices,
			devicePermissions,
			videoDevice,
			audioDevice,
			audioDeviceChange,
			videoDeviceChange,
			onStartHandler,
			iframeDims,
			onClickCallback,
			networkSpeed,
			onClose,
			setAudioDevice,
		}: {
			iframeDims: CSSProperties;
			template: FloCreationTemplates;
			recordState: RecordStates;
			enableStartButton: boolean;
			showVideoOptions: boolean;
			showAudioOptions: boolean;
			availableDevices: AvailableDevices;
			videoDeviceChange(deviceId?: string): void;
			audioDeviceChange(deviceId?: string): void;
			setAudioDevice(deviceId?: string): void;
			onClickCallback(template: FloCreationTemplates, showDetails?: boolean): void;
			onStartHandler(): void;
			audioDevice?: string;
			networkSpeed: string;
			videoDevice?: string;
			devicePermissions: {
				[key: string]: string;
			};
			onClose: MouseEventHandler;
		},
		videoRef: ForwardedRef<HTMLVideoElement>
	) => {
		const isExtensionInstalled = useSelector((state) =>
			get(state, 'onBoarding.extensionInstalled')
		);
		const dispatch = useDispatch();
		const inputPermissions = get(template, 'inputPermissions', []);
		const optionalInputPermissions = get(template, 'optionalInputPermissions', []);
		let videoDenied = get(devicePermissions, PERMISSION_MAP.camera) !== 'granted';
		const [audioDenied, setAudioDenied] = useState(false);
		const cameraRequired = includes(inputPermissions, 'camera');
		const micRequired = includes(inputPermissions, 'microphone');
		const isMicOptional = includes(optionalInputPermissions, 'microphone');
		const extensionRequired = includes(inputPermissions, 'extension');
		const howToDoGuideLink = get(template, 'howToDoGuideLink', '');
		const howToDoVideoLink = get(template, 'howToDoVideoLink', '');
		const useTemplate = useSelector((state) =>
			get(state, 'newFlo.showTemplateDeviceSelection')
		);
		const exampleFloLinkObj = get(template, 'exampleFlo', '');
		const exampleFloLink = get(exampleFloLinkObj, 'url', '');

		useEffect(() => {
			if (!micRequired) {
				setAudioDenied(false);
			} else {
				let isAudioDenied =
					get(devicePermissions, PERMISSION_MAP.microphone) !== 'granted';
				setAudioDenied(isAudioDenied);
			}
		}, [devicePermissions, micRequired]);

		const onContinueClick = useCallback(() => {
			setAudioDenied(false);
			setAudioDevice('no_audio');
		}, [setAudioDenied, setAudioDevice]);

		if (!cameraRequired) {
			videoDenied = false;
		}

		const extensionNotInstalled = extensionRequired ? !isExtensionInstalled : false;
		const FloIcons = {
			video: FilmStrip,
			document: GUIDE,
			gif: GIF,
			interactive_demo: InteractiveDemoSvg,
			mouse_pointer: CUST_DEMO,
			default: FilmStrip,
		};

		const SVG = (get(FloIcons, template.iconType) ||
			FloIcons.default) as ReactComponentLike;
		const permissionError = videoDenied || audioDenied || extensionNotInstalled;
		const floCreationDiamondFeature = useSelector((state) =>
			get(state, 'routerContainer.diamondFeaturesMap.flo_creation_limit')
		);
		const templateDeviceSelectionIn = !includes(
			[
				'recording',
				'creating',
				'saving',
				'onStopSaving',
				'onDiscardSaving',
				'stopped/save',
			],
			recordState
		);

		if (useTemplate) {
			if (permissionError) {
				return (
					<Zoom
						in={
							!includes(
								[
									'recording',
									'creating',
									'saving',
									'onStopSaving',
									'onDiscardSaving',
									'stopped/save',
								],
								recordState
							)
						}
					>
						<Grid
							container
							className={styles.templatePopup}
							justifyContent="left"
							alignItems="flex-start"
							style={{ width: 'auto' }}
						>
							<TemplatePermissionError
								enableVideoDevice={videoDenied}
								enableAudioDevice={audioDenied}
								installExtension={extensionNotInstalled}
								isMicOptional={isMicOptional}
								onContinueClick={onContinueClick}
								onCancel={onClose}
							/>
						</Grid>
					</Zoom>
				);
			}
			return (
				<Zoom in={templateDeviceSelectionIn}>
					<Grid
						container
						className={styles.templatePopup}
						justifyContent="center"
						alignItems="center"
						style={{ width: 'auto' }}
					>
						<Grid
							item
							classes={{
								root: styles.headerSection,
							}}
							desktop={12}
							tablet={12}
							mobile={12}
						>
							<SVG />

							<span className={styles.headerText}>{get(template, 'name')}</span>
						</Grid>

						<TemplateDeviceSelection
							ref={videoRef}
							networkSpeed={networkSpeed}
							recordState={recordState}
							template={template}
							enableStartButton={enableStartButton}
							showVideoOptions={showVideoOptions}
							showAudioOptions={showAudioOptions}
							availableDevices={availableDevices}
							devicePermissions={devicePermissions}
							videoDevice={videoDevice}
							audioDevice={audioDevice}
							audioDeviceChange={audioDeviceChange}
							videoDeviceChange={videoDeviceChange}
							onStartHandler={onStartHandler}
							isMicOptional={isMicOptional}
						/>
					</Grid>
				</Zoom>
			);
		}

		return (
			<Zoom
				in={
					!includes(
						[
							'recording',
							'creating',
							'saving',
							'onStopSaving',
							'onDiscardSaving',
							'stopped/save',
						],
						recordState
					)
				}
			>
				<Grid
					container
					className={classNames(prentStyles.cardRecordingBody, styles.cardBody)}
					justifyContent="center"
					alignItems="flex-start"
					style={{ width: 'auto' }}
				>
					<Grid
						item
						classes={{
							root: styles.headerSection,
						}}
						desktop={12}
						tablet={12}
						mobile={12}
					>
						<SVG />

						<span className={styles.headerText}>{get(template, 'name')}</span>
					</Grid>
					<Grid
						className={classNames(styles.contentWrapper, [
							{
								[styles.isGuideContainer]:
									template.key === TEMPLATE_KEYS.step_by_step_guide,
							},
						])}
						container
						flexWrap={'nowrap'}
					>
						<Grid
							item
							classes={{
								root: styles.leftSection,
							}}
							desktop={4}
							tablet={4}
							mobile={12}
						>
							<section className={styles.templateContainer}>
								<p className={styles.templateDescription}>{template.description}</p>
							</section>

							<section className={styles.howTo}>
								<p className={styles.templateSubHeading}>How to create?</p>
								<div className={styles.howToWrapper}>
									<a
										href={howToDoVideoLink}
										className={styles.howToElem}
										target="_blank"
										rel="noopener noreferrer"
									>
										<Grid className={styles.iconTextWrapper}>
											<PlayIcon className={styles.howToBtn} />
											<span className={styles.howToElemText}>Watch a video</span>
										</Grid>
										<LinkArrow />
									</a>
									<a
										href={howToDoGuideLink}
										className={styles.howToElem}
										target="_blank"
										rel="noopener noreferrer"
									>
										<Grid className={styles.iconTextWrapper}>
											<FileIcon className={styles.howToBtn} />
											<span className={styles.howToElemText}>Help Guide</span>
										</Grid>
										<LinkArrow />
									</a>
								</div>
								<Button
									variant="contained"
									className={styles.startButton}
									onClick={(event) => {
										if (!isChrome && isExtensionInstalled) {
											dispatch(
												showDialogAction({
													header: (
														<span>
															<WarningIcon className={styles.warningIcon} /> {browserName}{' '}
															is unsupported
														</span>
													),
													showCancel: true,
													showConfirm: true,
													confirmButtonText: 'Cancel',
													confirmButtonType: 'confirm',
													cancelButtonText: (
														<span className={styles.cancelButton}>Continue anyway</span>
													),
													onConfirm: () => {
														dispatch(hideDialogAction(''));
													},
													onCancel: () => {
														onClickCallback(template, true);
													},
													body: `Currently ${browserName} is not fully supported and you might experience some issues during capture. Use Chrome for better experience`,
												})
											);
										} else {
											onClickCallback(template, true);
										}
									}}
									disableElevation
								>
									Use this template
								</Button>
							</section>
						</Grid>
						<Grid
							item
							className={classNames(styles.rightSection, [
								{
									[styles.isGuideIframe]:
										template.key === TEMPLATE_KEYS.step_by_step_guide,
								},
							])}
							desktop={8}
							tablet={8}
							mobile={12}
						>
							<iframe
								style={iframeDims}
								className={styles.templateHowToIframe}
								src={exampleFloLink}
							></iframe>
						</Grid>
					</Grid>
				</Grid>
			</Zoom>
		);
	}
);
export default TemplateDetails;
