import { Button } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';
import classNames from 'classnames';
import { find, get, keyBy, map } from 'lodash';
import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListRowProps } from 'react-virtualized';
import { MESSAGE_TO_ROLE_MAP } from '../../Common/Common.constants';
import Translations from '../../Common/Translate.utils';
import { showDiamondFeaturePopup } from '../../Containers/Routes/Routes.reducers';
import DiamondIcon from '../DiamondIcon/DiamondIcon';
import Dropdown from '../Dropdown/Dropdown';
import InfiniteScrollList from '../InfiniteScrollList/InfiniteScrollList';
import { setErrorToast } from '../Notification/Toasts.reducers';
import styles from './TeamManagementDialog.module.css';
import {
	deleteUserAction,
	getTeamUsersListAction,
	inviteUserAction,
	removeDeletedUser,
	updateUserRoleAction,
} from './TeamManagementDialog.reducer';
import { UserRoleType, UserType } from './TeamManagementDialog.types';

const TeamMemberRow = ({
	data,
	onInvite,
	onRemoveClick,
	style,
	onMemberRoleChange,
	userRoles,
	roleMetaData,
}: {
	data: UserType;
	onInvite: Function;
	onRemoveClick: Function;
	style: CSSProperties;
	onMemberRoleChange(value: string, user: UserType): void;
	userRoles: UserRoleType[];
	roleMetaData: UserRoleType;
}) => {
	const { REMOVED_USER_TEXT } = Translations;
	const isInviting = useSelector(
		(state) => get(state, `loaders['user_invite_${get(data, 'userBean.email')}']`, 0) > 0
	);
	const inviteButtonClick = useCallback(() => {
		onInvite(get(data, 'userBean.email'));
	}, [data, onInvite]);

	const onRemoveButtonClick = useCallback(() => {
		onRemoveClick({
			user: data,
			isCancel: get(data, 'invitePending') && !get(data, 'inviteAccepted'),
		});
	}, [data, onRemoveClick]);

	const onDropdownChange = useCallback(
		(value: string) => {
			onMemberRoleChange(value, data);
		},
		[onMemberRoleChange, data]
	);

	const rowDisabled = get(data, 'deleted') || !get(data, 'canEdit');

	return (
		<div
			className={classNames(styles.row, { [styles.disabled]: rowDisabled })}
			style={style}
		>
			<div className={classNames(styles.column, styles.usernameWrapper)}>
				<div
					className={styles.usernameIcon}
					style={{ background: get(data, 'userIconBgColor') || '#CCBEF9' }}
				>
					{get(data, 'userBean.name')?.charAt(0)}
				</div>
				<div className={styles.username} title={get(data, 'userBean.name' || '-')}>
					{get(data, 'userBean.name') || '-'}
				</div>
			</div>
			<div className={classNames(styles.column, styles.roleColumn)}>
				{get(data, 'roleBean.key') === 'owner' ? (
					<div className={styles.roleOwnerText}>
						{Translations.TEAMSPACE_OWNER_ROLE_TEXT}
					</div>
				) : (
					<div className={styles.dropdownWrapper}>
						<Dropdown
							id={'new_user_role'}
							optionsLabel={'Roles'}
							showRadioButton
							labelKey="label"
							valueKey="id"
							disablePortal={false}
							classes={{
								select: styles.dropdownSelect,
								container: styles.dropdownContainer,
								menuItem: styles.dropdownMenuItem,
								menuItemLabel: styles.menuItemLabel,
								icon: styles.dropdownIcon,
								menuRoot: styles.dropdownMenuRoot,
								menuContainer: styles.dropdownMenuContainer,
								headText: get(data, 'roleBean.id')
									? styles.dropdownHeadTextSelected
									: styles.dropdownHeadText,
								leftIcon: styles.dropdownLeftIcon,
								head: styles.dropdownHead,
							}}
							selected={get(data, 'roleBean.id')}
							showDialogOnChange={get(roleMetaData, 'showWarningOnRoleSet')}
							dialogProps={{
								body: get(Translations, get(roleMetaData, 'warningMessage')),
								id: 'change_user_role',
								variant: 'small',
								header: Translations.TEAMSPACE_ROLE_CHANGE_DIALOG_HEADER,
								showCancel: true,
								showConfirm: true,
								confirmButtonText: 'Confirm',
								confirmButtonType: 'delete',
								cancelButtonText: 'Cancel',
							}}
							// @ts-ignore
							options={userRoles}
							disabled={rowDisabled}
							className={styles.rolesDropdown}
							onChange={onDropdownChange}
							label={Translations.TEAMSPACE_ROLES_DROPDOWN_PLACEHOLDER}
						/>
					</div>
				)}
			</div>
			<div className={classNames(styles.column, styles.emailColumn)}>
				<div className={styles.email} title={get(data, 'userBean.email')}>
					{get(data, 'userBean.email')}
				</div>
			</div>
			<div className={classNames(styles.column, styles.actions)}>
				{!rowDisabled && (
					<>
						{/*{get(data, 'invitePending') && (*/}
						{/*	<span className={styles.actionInfo}>Invited!</span>*/}
						{/*)}*/}
						<Button
							variant="text"
							className={styles.actionRemoveButton}
							onClick={onRemoveButtonClick}
						>
							{get(data, 'invitePending') && !get(data, 'inviteAccepted')
								? Translations.CANCEL_USER_INVITE_BUTTON_TEXT
								: Translations.REMOVE_USER_BUTTON_TEXT}
						</Button>
						{!get(data, 'invitePending') && !get(data, 'inviteAccepted') && (
							<Button
								variant="outlined"
								className={styles.actionButton}
								onClick={inviteButtonClick}
							>
								<span className={styles.actionButtonText}>
									{isInviting
										? Translations.USER_INVITING_TEXT
										: Translations.USER_INVITE_TEXT}
								</span>
							</Button>
						)}
						{get(data, 'invitePending') && (
							<Button
								variant="outlined"
								className={classNames(styles.actionButton, {
									[styles.inviting]: isInviting,
								})}
								onClick={inviteButtonClick}
								disabled={isInviting}
							>
								<span className={styles.actionButtonText}>
									{isInviting
										? Translations.TEAMSPACE_DIALOG_INVITE_AGAIN_INPROGRESS
										: Translations.TEAMSPACE_DIALOG_INVITE_AGAIN}
								</span>
							</Button>
						)}
					</>
				)}
				{get(data, 'deleted') && (
					<>
						<Button
							disableFocusRipple
							disabled
							variant="text"
							className={classNames(styles.actionRemoveButton, styles.disabled)}
						>
							{REMOVED_USER_TEXT}
						</Button>
					</>
				)}
			</div>
		</div>
	);
};

const TeamManagementDialog = () => {
	const dispatch = useDispatch();
	const [newUserRole, setNewUserRole] = useState<string>('');
	const [email, setEmail] = useState('');
	const [isEmailValid, setIsEmailValid] = useState(false);
	const usersList = useSelector((state) => get(state, 'teamManagement.usersList'));
	const paginationInfo = useSelector((state) =>
		get(state, 'teamManagement.paginationInfo')
	);
	const userRoles =
		useSelector((state) => get(state, 'teamManagement.userRoles.data')) || [];
	const [userRolesList, setUserRolesList] = useState<{ [key: string]: any }[]>([]);
	const [userRolesMap, setUserRolesMap] = useState({});

	const currentUserEmail = useSelector((state) =>
		get(state, 'routerContainer.profile.email')
	);

	const collaboratorsDiamondFeature = useSelector((state) =>
		get(state, 'routerContainer.diamondFeaturesMap.teamspace_collaborators')
	);

	const onInviteClick = useCallback(
		(value: string, isNew: boolean, successCb: Function) => {
			dispatch(inviteUserAction({ email: value, isNew, successCb, roleId: newUserRole }));
		},
		[newUserRole]
	);

	const onRemoveClick = useCallback((user: UserType) => {
		dispatch(deleteUserAction(user));
	}, []);

	const resetEmail = useCallback(() => {
		setEmail('');
		setNewUserRole('');
		setIsEmailValid(false);
	}, [setEmail, setIsEmailValid, setNewUserRole]);

	const onInviteButtonClick = useCallback(() => {
		if (get(collaboratorsDiamondFeature, 'diamondEnabled')) {
			dispatch(
				showDiamondFeaturePopup({
					featureInfo: collaboratorsDiamondFeature,
					buttonClicked: 'TEAMSPACE_COLLABORATOR_ADD_CLICK',
				})
			);
			return;
		}
		if (!isEmailValid) return;
		if (currentUserEmail === email) {
			dispatch(
				setErrorToast({
					message: Translations.TEAMSPACE_EMAIL_ERROR_SAME_EMAIL.replace(
						'%EMAIL%',
						email
					),
				})
			);
			resetEmail();
			return;
		}
		const isUserAlreadyInvited = find(usersList, ['userBean.email', email]);
		// if (isUserAlreadyInvited && !isUserAlreadyInvited.deleted) {
		// 	dispatch(
		// 		setErrorToast({
		// 			message: Translations.TEAMSPACE_EMAIL_ERROR_ALREADY_EXIST_EMAIL.replace(
		// 				'%EMAIL%',
		// 				email
		// 			),
		// 		})
		// 	);
		// 	resetEmail();
		// 	return;
		// } else
		if (isUserAlreadyInvited?.deleted) {
			dispatch(removeDeletedUser(email));
		}
		onInviteClick(email, true, resetEmail);
	}, [
		email,
		currentUserEmail,
		resetEmail,
		onInviteClick,
		isEmailValid,
		collaboratorsDiamondFeature,
	]);

	useEffect(() => {
		setUserRolesList(
			map(userRoles, (role) => ({
				...role,
				icon: IconInfoCircle,
				tooltipText: get(Translations, get(MESSAGE_TO_ROLE_MAP, role.key)),
			}))
		);
		setUserRolesMap(keyBy(userRoles, 'id'));
	}, [userRoles, setUserRolesList, setUserRolesMap]);

	useEffect(() => {
		dispatch(getTeamUsersListAction({ pageNo: 1 }));
	}, []);

	const loadMoreUsers = useCallback(
		(params: { startIndex: number; stopIndex: number }) => {
			dispatch(
				getTeamUsersListAction({
					pageNo: Math.ceil(params.startIndex / 50) + 1,
				})
			);
			return new Promise((resolve) => resolve(false));
		},
		[]
	);

	const onInputChange = useCallback(
		(e: React.ChangeEvent) => {
			const inputEl = e.target as HTMLInputElement;
			const value = inputEl.value.trim();
			const emailValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/g.test(value);
			setIsEmailValid(emailValid);
			setEmail(value);
		},
		[setEmail, setIsEmailValid]
	);

	const onNewUserRoleChange = useCallback(
		(value: string) => {
			setNewUserRole(value);
		},
		[setNewUserRole]
	);

	const onMemberRoleChange = useCallback((value: string, user: UserType) => {
		dispatch(
			updateUserRoleAction({
				roleId: value,
				userId: user.id,
			})
		);
	}, []);

	return (
		<div className={styles.wrapper}>
			<h1 className={styles.title}>{Translations.TEAMSPACE_DIALOG_TITLE}</h1>
			<div className={styles.subHeading}>
				{Translations.TEAMSPACE_DIALOG_INVITE_MEMBERS}
			</div>
			<div className={styles.searchWrapper}>
				<div className={styles.searchContainer}>
					<input
						placeholder={Translations.TEAMSPACE_EMAIL_INPUT_PLACEHOLDER}
						className={styles.searchInput}
						onChange={onInputChange}
						value={email}
					/>
				</div>
				<div className={styles.dropdownWrapper}>
					<Dropdown
						id={'new_user_role'}
						optionsLabel={'Roles'}
						showRadioButton
						labelKey="label"
						valueKey="id"
						classes={{
							select: styles.dropdownSelect,
							container: styles.dropdownContainer,
							menuItem: styles.dropdownMenuItem,
							menuItemLabel: styles.menuItemLabel,
							headText: newUserRole
								? styles.dropdownHeadTextSelected
								: styles.dropdownHeadText,
							icon: styles.dropdownIcon,
							menuRoot: styles.dropdownMenuRoot,
							menuContainer: styles.dropdownMenuContainer,
							leftIcon: styles.dropdownLeftIcon,
							head: styles.dropdownHead,
						}}
						selected={newUserRole}
						// @ts-ignore
						options={userRolesList}
						className={styles.rolesDropdown}
						onChange={onNewUserRoleChange}
						label={Translations.TEAMSPACE_ROLES_DROPDOWN_PLACEHOLDER}
					/>
				</div>
				<Button
					variant="contained"
					className={classNames(styles.inviteButton, {
						[styles.disabled]: !isEmailValid || !newUserRole,
					})}
					onClick={onInviteButtonClick}
				>
					{Translations.TEAMSPACE_DIALOG_INVITE_TEXT}
					{get(collaboratorsDiamondFeature, 'diamondEnabled') && (
						<DiamondIcon
							image={get(collaboratorsDiamondFeature, 'diamondImage')}
							className={styles.diamondIcon}
						/>
					)}
				</Button>
			</div>
			<div className={styles.tableWrapper}>
				<InfiniteScrollList
					hasMoreRows={paginationInfo.hasMore}
					rowRenderer={({ index, key, style }: ListRowProps) => {
						const user = usersList[index];
						return (
							<TeamMemberRow
								key={key}
								data={user}
								onInvite={onInviteClick}
								onRemoveClick={onRemoveClick}
								style={style}
								roleMetaData={get(userRolesMap, get(user, 'roleBean.id'))}
								onMemberRoleChange={onMemberRoleChange}
								userRoles={userRolesList}
							/>
						);
					}}
					rowHeight={48}
					loadMoreRows={loadMoreUsers}
					listData={usersList || []}
				/>
			</div>
		</div>
	);
};

export default TeamManagementDialog;
