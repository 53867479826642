import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { errorPageSelector } from '../../Common/Common.selector';
import Translations from '../../Common/Translate.utils';
import styles from './500.module.css';
import { ReactComponent as ServerErrorSvg } from './images/500.svg';

const Page500 = () => {
	const { page500ContentTitle, page500ContentText, page500HomeButton } = Translations;
	//@ts-ignore
	const { error, errorHeader }: { error: string; errorHeader: string } =
		useSelector(errorPageSelector);
	return (
		<Grid container spacing={2} className={styles.container}>
			<Grid item mobile={6} className={styles.imageContainer}>
				<ServerErrorSvg className={styles.image} />
			</Grid>
			<Grid item laptop={5} mobile={6} className={styles.contentContainer}>
				<p
					className={styles.contentTitle}
					dangerouslySetInnerHTML={{ __html: page500ContentTitle }}
				/>
				<p
					className={styles.contentText}
					dangerouslySetInnerHTML={{ __html: page500ContentText }}
				/>
				<Link to="/flos" className={styles.homeButton}>
					{page500HomeButton}
				</Link>
			</Grid>
		</Grid>
	);
};

export default Page500;
