import { createSlice } from '@reduxjs/toolkit';
import { POSTPEND_TERM } from '../../Common/Common.env';
import { AnnotationSnapshot } from '../../Components/Annotations/Annotations.types';

const initialState = Object.freeze({});

const newFloReducers = {
	startRecordingExtension(state: unknown, payload: any) {},
	pauseRecordingExtension(state: unknown, payload: any) {},
	resumeRecordingExtension(state: unknown, payload: any) {},
	resetState(state: unknown, payload: unknown) {},
	useCurrrentTabAsBase(state: unknown, payload: unknown) {},
	setCaptureTab(state: unknown, payload: unknown) {},
	setCaptureWindow(state: unknown, payload: any) {},
	takeMeBacktoTab(state: unknown, payload: unknown) {},
	updateAnnotationComment(state: unknown, payload: AnnotationSnapshot[]) {},
	setNoOfClicks(state: unknown, payload: number) {},
	showCountDown(state: unknown, payload: number) {},
	hideCountDown(state: unknown, payload: unknown) {},
	setCurrentActiveTab(state: unknown, payload: unknown) {},
	checkActiveTabInjected(state: unknown, payload: number) {},
	getExtensionVersion(state: unknown, payload: any) {},
	updateSpeedAndOnline(
		state: unknown,
		payload: {
			online: boolean;
			speed: string;
		}
	) {},
};

// @ts-ignore
export const newFloExtension = createSlice({
	name: `floik-app-to-extension-newFlo${POSTPEND_TERM}`,
	initialState,
	// @ts-ignore
	reducers: newFloReducers,
});

export default newFloExtension.reducer;

export const setCaptureTab = newFloExtension.actions.setCaptureTab;
export const checkActiveTabInjected = newFloExtension.actions.checkActiveTabInjected;
export const setCaptureWindow = newFloExtension.actions.setCaptureWindow;
export const startRecordingExtension = newFloExtension.actions.startRecordingExtension;
export const pauseRecordingExtension = newFloExtension.actions.pauseRecordingExtension;
export const resumeRecordingExtension = newFloExtension.actions.resumeRecordingExtension;
export const resetStateExtension = newFloExtension.actions.resetState;
export const useCurrrentTabAsBase = newFloExtension.actions.useCurrrentTabAsBase;
export const getExtensionVersion = newFloExtension.actions.getExtensionVersion;
export const takeMeBacktoTab = newFloExtension.actions.takeMeBacktoTab;
export const setNoOfClicks = newFloExtension.actions.setNoOfClicks;
export const updateAnnotationCommentExtension =
	newFloExtension.actions.updateAnnotationComment;
export const showCountDown = newFloExtension.actions.showCountDown;
export const hideCountDown = newFloExtension.actions.hideCountDown;
export const setCurrentActiveTab = newFloExtension.actions.setCurrentActiveTab;
export const updateSpeedAndOnline = newFloExtension.actions.updateSpeedAndOnline;
