import { createSlice } from '@reduxjs/toolkit';
import { POSTPEND_TERM } from '../../Common/Common.env';

const initialState = Object.freeze({});

const newFloReducers = {
	pauseRecordingApp(state: {}, payload: unknown) {},
	resumeRecordingApp(state: {}, payload: unknown) {},
	stopRecordingApp(state: {}, payload: unknown) {},
	startRecordingApp(state: {}, payload: unknown) {},
	resetState(state: {}, payload: unknown) {},
	sendCommentAnnotationApp(state: {}, payload: unknown) {},
	multipleRecordingAppError(state: {}, payload: unknown) {},
	setSelectedTemplateApp(state: {}, payload: unknown) {},
	showAppPopupAction(state: {}, payload: unknown) {},
	discardRecordingApp(state: {}, payload: unknown) {},
	discardConfirmRecordingApp(state: {}, payload: unknown) {},
	setExtensionVersionAction(state: {}, payload: unknown) {},
	uploadScreenshot(state: {}, payload: any) {},
	changeCountdownScreenState(state: {}, payload: any) {},
	changeCountdownShowingState(state: {}, payload: any) {},
	getToken(state: {}) {},
	extensionUninstalling(state: {}) {},
	changeCountdownScreenValueState(state: {}, payload: number) {},
};

export const newFloExtToApp = createSlice({
	name: `floik-extension-to-app-newFlo${POSTPEND_TERM}`,
	initialState,
	// @ts-ignore
	reducers: newFloReducers,
});

export default newFloExtToApp.reducer;

export const pauseRecordingApp = newFloExtToApp.actions.pauseRecordingApp;
export const resumeRecordingApp = newFloExtToApp.actions.resumeRecordingApp;
export const stopRecordingApp = newFloExtToApp.actions.stopRecordingApp;
export const startRecordingApp = newFloExtToApp.actions.startRecordingApp;
export const discardRecordingApp = newFloExtToApp.actions.discardRecordingApp;
export const discardConfirmRecordingApp =
	newFloExtToApp.actions.discardConfirmRecordingApp;
export const resetStateExtension = newFloExtToApp.actions.resetState;
export const sendCommentAnnotationApp = newFloExtToApp.actions.sendCommentAnnotationApp;
export const multipleRecordingAppError = newFloExtToApp.actions.multipleRecordingAppError;
export const setSelectedTemplateApp = newFloExtToApp.actions.setSelectedTemplateApp;
export const showAppPopupAction = newFloExtToApp.actions.showAppPopupAction;
export const setExtensionVersionAction = newFloExtToApp.actions.setExtensionVersionAction;
export const uploadScreenshot = newFloExtToApp.actions.uploadScreenshot;
export const getToken = newFloExtToApp.actions.getToken;
export const extensionUninstalling = newFloExtToApp.actions.extensionUninstalling;
export const changeCountdownScreenState =
	newFloExtToApp.actions.changeCountdownScreenState;
export const changeCountdownShowingState =
	newFloExtToApp.actions.changeCountdownShowingState;
export const changeCountdownScreenValueState =
	newFloExtToApp.actions.changeCountdownScreenValueState;
