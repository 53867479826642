import { createSlice } from '@reduxjs/toolkit';
import {
	concat,
	find,
	findIndex,
	get,
	includes,
	isUndefined,
	map,
	max,
	reduce,
	reject,
	set,
} from 'lodash';
import { floOutputMetadataType } from '../Flo/Flo.types';
import { RecentFloDetailType } from '../Home/Home.types';
import {
	FloPagination,
	flosData,
	FlosPayloadType,
	FolderFloActionType,
	FolderFloData,
	FolderResponsePayloadType,
	FolderType,
	userDataType,
} from '../ManageFlos/ManageFlos.types';

interface MySpaceReducerType {
	folders: {
		data: FolderType[];
		pagination: FloPagination;
	};
	sagaReady: boolean;
	flos: {
		[key: string]: FolderFloData;
	};
	recentFlosPagination: FloPagination;
	currentFolderMeta?: FlosPayloadType;
	recentFlos: FlosPayloadType[];
	folderFloAction: FolderFloActionType;
	fileFolderErrors: {
		[key: string]: {
			id: string;
			type: string;
			message: string;
		};
	};
	workspaceFolders: {
		[key: string]: {
			data: FolderType[];
			pagination: FloPagination;
		};
	};
}

const initialState = Object.freeze({
	workspaceFolders: {},
	sagaReady: false,
	folders: {
		data: [],
		pagination: {
			hasMore: false,
			pageNo: 1,
			pageSize: 100,
			totalRecords: 0,
		},
	},
	flos: {},
	recentFlos: [],
	recentFlosPagination: {
		hasMore: false,
		pageNo: 1,
		pageSize: 100,
		totalRecords: 0,
	},
	folderFloAction: {
		floId: '',
		folderId: '',
		type: 'rename',
		isFolder: false,
	},
	fileFolderErrors: {},
	currentFolderMeta: undefined,
});

const mySpaceReducer = {
	getMySpaceFolders(
		state: MySpaceReducerType,
		{
			payload,
		}: {
			payload: { mySpaceId: string; floSpaceId: string; page?: number };
		}
	) {},
	addNewFlo(state: MySpaceReducerType, { payload }: { payload: FlosPayloadType }) {
		const internalFolder = state.folders.data.find((i) => i.internalFolder);
		const folder = payload.folderId
			? state.folders.data.find((i) => i.id === payload.folderId)
			: {};
		const folderId = get(folder, 'id') || get(internalFolder, 'id');
		if (internalFolder && get(state.flos, folderId)) {
			const fromFolderIndex = findIndex(state.folders.data, ['id', folderId]);
			state.flos[folderId].data = concat([payload], state.flos[folderId].data || []);

			if (state.flos[folderId].pagination) {
				state.flos[folderId].pagination.totalRecords += 1;
			} else {
				state.flos[folderId].pagination = {
					hasMore: false,
					pageNo: 1,
					pageSize: 30,
					totalRecords:
						get(state, `folders.data[${fromFolderIndex}].numberOfFlos`, 0) + 1,
				};
			}

			if (fromFolderIndex >= 0 && get(state, `folders.data[${fromFolderIndex}]`)) {
				state.folders.data[fromFolderIndex].numberOfFlos =
					state.folders.data[fromFolderIndex].numberOfFlos + 1;
			}
		}
	},
	setMySpaceFoldersAction(
		state: MySpaceReducerType,
		{
			payload,
		}: {
			payload: {
				pagination: FloPagination;
				data: FolderResponsePayloadType;
			};
		}
	) {
		const { folderMetaData, userData } = payload.data || {};
		const uDataKeyValues = reduce(
			userData,
			(coll, uData) => {
				// @ts-ignore
				coll[String(get(uData || {}, 'userId'))] = uData;
				return coll;
			},
			{}
		);
		const newFolderMetaData = map(folderMetaData, (item): FolderType => {
			const { createdBy, modifiedBy, flos, ...rest } = item;
			// @ts-ignore
			if (flos?.data?.length) {
				state.flos[item.id] = {
					data: map(flos?.data, (flo) => ({
						...flo,
						outputTypes: map(flo.floOutputMetadata, (meta) => meta.outputType),
						createdBy: get(uDataKeyValues, String(flo.createdBy)),
						modifiedBy: get(uDataKeyValues, String(flo.modifiedBy)),
					})),
					pagination: flos?.paginationInfo,
				};
			}
			return {
				...rest,
				modifiedBy: get(uDataKeyValues, String(modifiedBy)),
				createdBy: get(uDataKeyValues, String(createdBy)),
			};
		}) as FolderType[];

		if (payload.pagination.pageNo !== 1) {
			state.folders.data = state.folders.data.concat(newFolderMetaData);
		} else {
			state.folders.data = newFolderMetaData;
		}
		state.folders.pagination = payload.pagination;
	},
	getMySpaceRecentFlos(
		state: MySpaceReducerType,
		{
			payload,
		}: {
			payload: Partial<FloPagination> & {
				workspaceId?: string;
			};
		}
	) {},
	setMySpaceRecentFlos(
		state: MySpaceReducerType,
		{
			payload,
		}: {
			payload: {
				data: {
					floMetaData: RecentFloDetailType[];
					userData: userDataType[];
				};
				pagination: FloPagination;
			};
		}
	) {
		const { floMetaData, userData } = get(payload, 'data') || {};
		const newGloMetaData = map(floMetaData, (item) => {
			const { createdBy, modifiedBy } = item;
			const user = find(userData, { userId: String(createdBy) });
			const userModified = find(userData, { userId: String(modifiedBy) });
			return {
				...item,
				createdBy: user,
				modifiedBy: userModified,
			};
		});

		if (get(payload, 'pagination.pageNo') === 1) {
			state.recentFlos = [];
		}

		// @ts-ignore
		state.recentFlos = state.recentFlos.concat(newGloMetaData);
		state.recentFlosPagination = payload.pagination;
	},
	getMySpaceFolderFlos(
		state: MySpaceReducerType,
		{
			payload,
		}: {
			payload: {
				pageSize?: number;
				folderId?: string;
				floSpaceId?: string;
				filter?: string;
				pageNo?: number;
			};
		}
	) {},
	setMySpaceFolderFlos(
		state: MySpaceReducerType,
		{
			payload,
		}: {
			payload: {
				folderId: string;
				data: flosData;
				pagination: FloPagination;
			};
		}
	) {
		const { floMetaData, userData } = payload.data || {};
		const newFloMetaData = map(floMetaData, (item) => {
			const { createdBy, modifiedBy } = item;
			const user = find(userData, { userId: createdBy });
			const modifiedUser = find(userData, { userId: modifiedBy });
			return {
				...item,
				outputTypes: map(item.floOutputMetadata, (meta) => meta.outputType),
				createdBy: user,
				modifiedBy: modifiedUser,
			};
		});
		state.flos = state.flos || {};
		state.flos[payload.folderId] = state.flos[payload.folderId] || {
			data: [],
		};
		if (payload.pagination.pageNo !== 1) {
			state.flos[payload.folderId].data =
				state.flos[payload.folderId].data.concat(newFloMetaData);
		} else {
			state.flos[payload.folderId].data = newFloMetaData;
		}
		state.flos[payload.folderId].pagination = payload.pagination;
	},
	setMySpaceFolderFloAction(
		state: MySpaceReducerType,
		{ payload }: { payload: FolderFloActionType }
	) {
		const prevId = state.folderFloAction.isFolder
			? state.folderFloAction.folderId
			: state.folderFloAction.floId;
		state.folderFloAction = payload;
		if (includes(['rename', 'addNewFolder'], payload.type)) {
			delete state.fileFolderErrors[prevId];
		}
	},
	setMySpaceFileFolderError(
		state: MySpaceReducerType,
		{
			payload,
		}: {
			payload: {
				id: string;
				type: string;
				message: string;
			};
		}
	) {
		if (!payload.message) {
			delete state.fileFolderErrors[payload.id];
		} else {
			state.fileFolderErrors[payload.id] = payload;
		}
	},
	createMySpaceFolderSuccess(
		state: MySpaceReducerType,
		{
			payload,
		}: {
			payload: FolderType;
		}
	) {
		const [first, ...rest] = state.folders.data;
		state.folders.data = [];
		if (first) state.folders.data.push(first);
		state.folders.data.push(payload);
		if (rest?.length) {
			state.folders.data = concat(state.folders.data, rest);
		}
		state.folders.pagination.totalRecords += 1;
		state.flos[payload.id] = state.flos[payload.id] || {
			data: [],
			pagination: {
				pageNo: 1,
				pageSize: 100,
				totalRecords: 0,
				hasMore: false,
			},
		};
	},
	deleteMySpaceFolderSuccess(
		state: MySpaceReducerType,
		{
			payload,
		}: {
			payload: {
				folderId: string;
				workspaceId: string;
			};
		}
	) {
		state.folders.data = reject(state.folders.data, ['id', payload.folderId]);
		state.folders.pagination.totalRecords -= 1;
		delete state.flos[payload.folderId];
	},
	updateMySpaceFolderName(
		state: MySpaceReducerType,
		{
			payload,
		}: {
			payload: {
				folderId: string;
				name: string;
				actualName: string;
				workspaceId: string;
			};
		}
	) {},
	updateMySpaceFolderNameSuccess(
		state: MySpaceReducerType,
		{
			payload,
		}: {
			payload: {
				folderId: string;
				name: string;
				workspaceId: string;
			};
		}
	) {
		state.folders.data = map(state.folders.data, (folder) =>
			folder.id === payload.folderId
				? {
						...folder,
						name: payload.name,
				  }
				: folder
		);
	},
	createMySpaceFolder(
		state: MySpaceReducerType,
		{
			payload,
		}: {
			payload: {
				name: string;
				workspaceId: string;
			};
		}
	) {},
	deleteMySpaceFolder(
		state: MySpaceReducerType,
		{
			payload,
		}: {
			payload: {
				name: string;
				canEdit: boolean;
				folderId: string;
			};
		}
	) {},
	deleteMySpaceFolderConfirmedAction(
		state: MySpaceReducerType,
		{
			payload,
		}: {
			payload: {
				name: string;
				folderId: string;
				canEdit: boolean;
			};
		}
	) {},
	publishMySpaceFolderAction(
		state: MySpaceReducerType,
		{
			payload,
		}: {
			payload: {
				name: string;
				canEdit: boolean;
				folderId: string;
			};
		}
	) {},
	publishMySpaceFolderSuccessAction(
		state: MySpaceReducerType,
		{
			payload,
		}: {
			payload: {
				name: string;
				canEdit: boolean;
				folderId: string;
			};
		}
	) {
		state.folders.data = map(state.folders.data, (folder) =>
			folder.id === payload.folderId
				? {
						...folder,
						published: true,
				  }
				: folder
		);
	},
	getCurrentFolderMetaData(
		state: MySpaceReducerType,
		{
			payload,
		}: {
			payload: string;
		}
	) {},
	setCurrentFolderMetaData(
		state: MySpaceReducerType,
		{
			payload,
		}: {
			payload: FlosPayloadType;
		}
	) {
		state.currentFolderMeta = payload;
	},
	moveWorkspaceFlo(
		state: MySpaceReducerType,
		{ payload }: { payload: FlosPayloadType }
	) {},
	moveWorkspaceFloSuccess(
		state: MySpaceReducerType,
		{
			payload,
		}: {
			payload: {
				fromWorkspaceId: string;
				toWorkspaceId: string;
				fromFolder: string;
				toFolder: string;
				flo: string;
			};
		}
	) {
		if (state.flos[get(payload, 'fromFolder')]?.data?.length) {
			const selectedFlo = find(state.flos[get(payload, 'fromFolder')].data, [
				'id',
				payload.flo,
			]);
			state.flos[get(payload, 'fromFolder')].data = reject(
				state.flos[get(payload, 'fromFolder')].data,
				['id', get(payload, 'flo')]
			);
			state.flos[get(payload, 'fromFolder')].pagination.totalRecords -= 1;
			state.flos[get(payload, 'toFolder')] = state.flos[get(payload, 'toFolder')] || {};
			state.flos[get(payload, 'toFolder')].data =
				state.flos[get(payload, 'toFolder')].data || [];
			// @ts-ignore
			state.flos[get(payload, 'toFolder')].data = concat(
				[selectedFlo],
				// @ts-ignore
				get(state.flos, `['${get(payload, 'toFolder')}'].data`, [])
			);
			const currentTotalRecords = get(
				state.flos[`${get(payload, 'toFolder')}`],
				`pagination.totalRecords`,
				0
			);
			state.flos[get(payload, 'toFolder')] = set(
				state.flos[get(payload, 'toFolder')],
				'pagination.totalRecords',
				currentTotalRecords + 1
			);
		}
		const recentFlosIndex = findIndex(state.recentFlos, ['id', get(payload, 'flo')]);
		const diffWorkspace = payload.fromWorkspaceId !== payload.toWorkspaceId;
		if (diffWorkspace) {
			state.recentFlos = reject(state.recentFlos, ['id', get(payload, 'flo')]);
		} else if (recentFlosIndex >= 0) {
			state.recentFlos[recentFlosIndex].folderId = get(payload, 'toFolder');
		}
		const fromFolderIndex = findIndex(state.folders.data, [
			'id',
			get(payload, 'fromFolder'),
		]);
		if (fromFolderIndex >= 0 && get(state, `folders.data[${fromFolderIndex}]`)) {
			state.folders.data[fromFolderIndex].numberOfFlos = max([
				state.folders.data[fromFolderIndex].numberOfFlos - 1,
				0,
			]) as number;
		}

		const toFolderIndex = findIndex(state.folders.data, ['id', get(payload, 'toFolder')]);
		if (toFolderIndex >= 0 && get(state, `folders.data[${toFolderIndex}]`)) {
			state.folders.data[toFolderIndex].numberOfFlos += 1;
		}
	},
	moveWorkspaceFloFailed(
		state: MySpaceReducerType,
		{ payload }: { payload: { fromFolder: string; toFolder: string; flo: string } }
	) {
		if (state.flos[get(payload, 'fromFolder')]?.data?.length) {
			const selectedFlo = find(state.flos[get(payload, 'fromFolder')].data, [
				'id',
				payload.flo,
			]);
			state.flos[get(payload, 'fromFolder')].data = reject(
				state.flos[get(payload, 'fromFolder')].data,
				['id', get(payload, 'flo')]
			);
			state.flos[get(payload, 'fromFolder')].pagination.totalRecords -= 1;
			state.flos[get(payload, 'toFolder')] = state.flos[get(payload, 'toFolder')] || {};
			state.flos[get(payload, 'toFolder')].data =
				state.flos[get(payload, 'toFolder')].data || [];
			// @ts-ignore
			state.flos[get(payload, 'toFolder')].data = concat(
				[selectedFlo],
				// @ts-ignore
				get(state.flos, `['${get(payload, 'toFolder')}'].data`, [])
			);
			const currentTotalRecords = get(
				state.flos[`${get(payload, 'toFolder')}`],
				`pagination.totalRecords`,
				0
			);
			state.flos[get(payload, 'toFolder')] = set(
				state.flos[get(payload, 'toFolder')],
				'pagination.totalRecords',
				currentTotalRecords + 1
			);
		}
		const recentFlosIndex = findIndex(state.recentFlos, ['id', get(payload, 'flo')]);
		if (recentFlosIndex >= 0) {
			state.recentFlos[recentFlosIndex].folderId = get(payload, 'toFolder');
		}
		const fromFolderIndex = findIndex(state.folders.data, [
			'id',
			get(payload, 'fromFolder'),
		]);
		if (fromFolderIndex >= 0 && get(state, `folders.data[${fromFolderIndex}]`)) {
			state.folders.data[fromFolderIndex].numberOfFlos = max([
				state.folders.data[fromFolderIndex].numberOfFlos - 1,
				0,
			]) as number;
		}

		const toFolderIndex = findIndex(state.folders.data, ['id', get(payload, 'toFolder')]);
		if (toFolderIndex >= 0 && get(state, `folders.data[${toFolderIndex}]`)) {
			state.folders.data[toFolderIndex].numberOfFlos += 1;
		}
	},
	moveWorkspaceFloSelected(
		state: MySpaceReducerType,
		{ payload }: { payload: { fromFolder: string; toFolder: string; flo: string } }
	) {},
	deleteWorkspaceFlo(
		state: MySpaceReducerType,
		{ payload }: { payload: FlosPayloadType }
	) {},
	deleteWorkspaceFloConfirmed(
		state: MySpaceReducerType,
		{ payload }: { payload: FlosPayloadType }
	) {},
	deleteWorkspaceFloSuccess(
		state: MySpaceReducerType,
		{
			payload,
		}: {
			payload: {
				floId: string;
				folderId?: string;
			};
		}
	) {
		if (payload.folderId) {
			state.flos[payload.folderId] = state.flos[payload.folderId] || {
				data: [],
				pagination: {
					totalRecords: 1,
				},
			};
			state.flos[payload.folderId].data = reject(state.flos[payload.folderId].data, [
				'id',
				payload.floId,
			]);
			state.flos[payload.folderId].pagination.totalRecords -= 1;
			const index = findIndex(state.folders.data, ['id', payload.folderId]);
			if (index >= 0 && get(state, `folders.data[${index}]`)) {
				state.folders.data[index].numberOfFlos = max([
					state.folders.data[index].numberOfFlos - 1,
					0,
				]) as number;
			}
		}
		if (payload.floId && find(state.recentFlos, ['id', payload.floId])) {
			state.recentFlos = reject(state.recentFlos, ['id', payload.floId]);
			state.recentFlosPagination.totalRecords -= 1;
		}
	},
	updateMySpaceFloMeta(
		state: MySpaceReducerType,
		{
			payload,
		}: {
			payload: FlosPayloadType & {
				outputTypeId: string;
				floId: string;
				values: [[string, unknown], [string, unknown]];
			};
		}
	) {},
	updateMySpaceFloMetaSuccess(
		state: MySpaceReducerType,
		{ payload }: { payload: FlosPayloadType }
	) {
		if (!payload.folderId) return;
		state.flos[payload.folderId] = state.flos[payload.folderId] || {};
		if (state.flos[payload.folderId].data?.length) {
			state.flos[payload.folderId].data = map(
				get(state, `flos[${payload.folderId}].data`),
				(item: FlosPayloadType) => {
					if (item.id != payload.id) return item;
					const floOutputMetadata = [...item.floOutputMetadata];
					if (get(payload, 'outputStatus')) {
						floOutputMetadata[0].outputStatus = get(payload, 'outputStatus');
						floOutputMetadata[0].published = get(payload, 'outputStatus') === 'PUBLISHED';
					}
					floOutputMetadata[0].heading =
						get(payload, 'heading') || payload.name || floOutputMetadata[0].heading;
					if (!isUndefined(get(payload, 'description'))) {
						floOutputMetadata[0].description = get(payload, 'description');
					}
					return {
						...item,
						floOutputMetadata,
						duration: item.duration,
					};
				}
			);
		}

		state.recentFlos = map(state.recentFlos, (item: FlosPayloadType) => {
			if (item.id != payload.id) return item;
			const floOutputMetadata = [...item.floOutputMetadata];
			if (get(payload, 'outputStatus')) {
				floOutputMetadata[0].outputStatus = get(payload, 'outputStatus');
				floOutputMetadata[0].published = get(payload, 'outputStatus') === 'PUBLISHED';
			}
			floOutputMetadata[0].heading =
				get(payload, 'heading') || payload.name || floOutputMetadata[0].heading;
			if (!isUndefined(get(payload, 'description'))) {
				floOutputMetadata[0].description = get(payload, 'description');
			}
			return {
				...item,
				floOutputMetadata,
				duration: item.duration,
			};
		});
	},
	onPublishMySpaceFlo(
		state: MySpaceReducerType,
		payload: {
			outputTypeId: string;
			published: boolean;
			force?: boolean;
			initiateProcessing?: boolean;
			floId: string;
		}
	) {},
	setMyspaceFloOutputMetadata(
		state: MySpaceReducerType,
		{
			payload,
		}: {
			payload: {
				meta: floOutputMetadataType[];
				floId: string;
				folderId: string;
			};
		}
	) {
		if (payload.folderId) {
			state.flos[payload.folderId] = state.flos[payload.folderId] || {
				data: [],
				pagination: {
					totalRecords: 0,
				},
			};
			state.flos[payload.folderId].data = map(state.flos[payload.folderId].data, (data) =>
				data.id === payload.floId ? { ...data, floOutputMetadata: payload.meta } : data
			);
			state.flos[payload.folderId].pagination.totalRecords -= 1;
		}
		if (payload.floId && find(state.recentFlos, ['id', payload.floId])) {
			state.recentFlos = map(state.recentFlos, (data) =>
				data?.id === payload.floId
					? {
							...data,
							floOutputMetadata: payload.meta,
					  }
					: data
			);
		}
	},
	getWorkspaceFolders() {},
	setWorkspaceFolders(
		state: MySpaceReducerType,
		{
			payload,
		}: {
			payload: {
				pagination: FloPagination;
				workspaceId: string;
				data: FolderResponsePayloadType;
			};
		}
	) {
		const { folderMetaData, userData } = payload.data;
		const uDataKeyValues = reduce(
			userData,
			(coll, uData) => {
				// @ts-ignore
				coll[String(get(uData || {}, 'userId'))] = uData;
				return coll;
			},
			{}
		);
		const newFolderMetaData = map(folderMetaData, (item): FolderType => {
			const { createdBy, flos, ...rest } = item;
			// @ts-ignore
			// state.flos[item.id] = {
			// 	data: map(flos.data, (flo) => ({
			// 		...flo,
			// 		outputTypes: map(flo.floOutputMetadata, (meta) => meta.outputType),
			// 		createdBy: get(uDataKeyValues, String(flo.createdBy)),
			// 	})),
			// 	pagination: flos.paginationInfo,
			// };
			return {
				...rest,
				createdBy: get(uDataKeyValues, String(createdBy)),
			};
		}) as FolderType[];

		if (payload.pagination.pageNo !== 1) {
			state.workspaceFolders[payload.workspaceId].data =
				state.workspaceFolders[payload.workspaceId].data.concat(newFolderMetaData);
		} else {
			state.workspaceFolders = set(
				state.workspaceFolders,
				`${[payload.workspaceId]}.data`,
				newFolderMetaData
			);
		}
		state.workspaceFolders = set(
			state.workspaceFolders,
			`${[payload.workspaceId]}.pagination`,
			payload.pagination
		);
	},
	leaveMySpacePage(state: MySpaceReducerType) {
		return initialState;
	},
	leaveFolderViewPage(state: MySpaceReducerType) {
		state.currentFolderMeta = undefined;
	},
	clearMySapce(state: MySpaceReducerType) {
		return {
			...initialState,
			sagaReady: state.sagaReady,
		};
	},
	mySpaceSagaReady(state: MySpaceReducerType) {
		state.sagaReady = true;
	},
};

export const mySpace = createSlice({
	name: 'my-space',
	initialState,
	//@ts-ignore
	reducers: mySpaceReducer,
});

export const getMySpaceFolders = mySpace.actions.getMySpaceFolders;
export const setMySpaceFoldersAction = mySpace.actions.setMySpaceFoldersAction;
export const getMySpaceRecentFlos = mySpace.actions.getMySpaceRecentFlos;
export const setMySpaceRecentFlos = mySpace.actions.setMySpaceRecentFlos;
export const getMySpaceFolderFlos = mySpace.actions.getMySpaceFolderFlos;
export const setMySpaceFolderFlos = mySpace.actions.setMySpaceFolderFlos;
export const addNewFloAction = mySpace.actions.addNewFlo;

export const setMySpaceFolderFloAction = mySpace.actions.setMySpaceFolderFloAction;
export const setMySpaceFileFolderError = mySpace.actions.setMySpaceFileFolderError;
export const createMySpaceFolder = mySpace.actions.createMySpaceFolder;

export const createMySpaceFolderSuccess = mySpace.actions.createMySpaceFolderSuccess;
export const deleteMySpaceFolderSuccess = mySpace.actions.deleteMySpaceFolderSuccess;
export const updateMySpaceFolderName = mySpace.actions.updateMySpaceFolderName;
export const updateMySpaceFolderNameSuccess =
	mySpace.actions.updateMySpaceFolderNameSuccess;
export const deleteMySpaceFolderAction = mySpace.actions.deleteMySpaceFolder;
export const publishMySpaceFolderAction = mySpace.actions.publishMySpaceFolderAction;
export const publishMySpaceFolderSuccessAction =
	mySpace.actions.publishMySpaceFolderSuccessAction;
export const deleteMySpaceFolderConfirmedAction =
	mySpace.actions.deleteMySpaceFolderConfirmedAction;
export const getCurrentFolderMetaData = mySpace.actions.getCurrentFolderMetaData;
export const setCurrentFolderMetaData = mySpace.actions.setCurrentFolderMetaData;
export const deleteWorkspaceFlo = mySpace.actions.deleteWorkspaceFlo;
export const deleteWorkspaceFloConfirmed = mySpace.actions.deleteWorkspaceFloConfirmed;
export const deleteWorkspaceFloSuccess = mySpace.actions.deleteWorkspaceFloSuccess;
export const updateMySpaceFloMeta = mySpace.actions.updateMySpaceFloMeta;
export const updateMySpaceFloMetaSuccess = mySpace.actions.updateMySpaceFloMetaSuccess;
export const onPublishMySpaceFlo = mySpace.actions.onPublishMySpaceFlo;
export const setMyspaceFloOutputMetadata = mySpace.actions.setMyspaceFloOutputMetadata;
export const moveWorkspaceFlo = mySpace.actions.moveWorkspaceFlo;
export const moveWorkspaceFloSelected = mySpace.actions.moveWorkspaceFloSelected;
export const moveWorkspaceFloSuccess = mySpace.actions.moveWorkspaceFloSuccess;
export const moveWorkspaceFloFailed = mySpace.actions.moveWorkspaceFloFailed;
export const getWorkspaceFoldersAction = mySpace.actions.getWorkspaceFolders;
export const setWorkspaceFoldersAction = mySpace.actions.setWorkspaceFolders;

export const leaveMySpacePage = mySpace.actions.leaveMySpacePage;
export const leaveFolderViewPage = mySpace.actions.leaveFolderViewPage;
export const clearMySapce = mySpace.actions.clearMySapce;
export const mySpaceSagaReady = mySpace.actions.mySpaceSagaReady;

export default mySpace.reducer;
