export const loginLoadingMessge = 'logging in...';
export const FloMessageMaxLength = 500;
export const FloNameMaxLength = 50;
export const UserNameMaxLength = 22;

export const AudioWaveStroke = '#FFFFFF';
export const AudioWaveFill = '#FFFFFF';
export const AudioWaveFillOpacity = 0.5;
export const AudioWaveStrokeOpacity = 0.5;
export const AudioWaveWidth = 100;
export const AudioWaveHeight = 500;
export const AudioWaveMargin = {
	top: 10,
	right: 30,
	left: 0,
	bottom: 0,
};

export const MIN_CROP_WIDTH = 16;
export const MIN_CROP_HEIGHT = 16;
export const MAX_ADVENTURE_BUTTONS_COUNT = 4;
