import Cookie from 'js-cookie';
import {
	debounce as _debounce,
	forEach,
	get,
	includes,
	last,
	map,
	max,
	min,
	padStart,
	reduce,
	replace,
	sortBy,
} from 'lodash';
import qs from 'qs';
import {
	all,
	call,
	debounce,
	delay,
	ForkEffect,
	put,
	select,
	takeEvery,
	takeLatest,
} from 'redux-saga/effects';
import { API_BASE } from '../../Common/Common.env';
import {
	hideDialogAction,
	showDialogAction,
} from '../../Components/Dialog/Dialog.reducer';
import store from '../../store';
import { pushState } from '../../store/reducers/Routes.reducer';
import API from '../../utils/API';
import { floSpaceSelector } from '../ManageFlos/ManageFlos.selector';
import {
	createNewFloAction,
	createNewTrackAction,
	getCountDownTrackAction,
	getNewFloTracks,
	getTemplateCategories,
	increaseMouseEventCount,
	leaveNewFlosPageAction,
	mergeQueueAction,
	newFloGetTemplates,
	newFloSetTemplates,
	onScreenShareEndedAction,
	resetStateAction,
	saveFloAction,
	sendAnnotationComment,
	sendChunkAction,
	sendMouseEvent,
	sendMouseEventScreenshot,
	setCanShowNewFlo,
	setChunkStats,
	setCountDownTrack,
	setElementId,
	setFloStateAction,
	setHasFailedAttempt,
	setNewFloIdAction,
	setNewFloTracks,
	setNewTrackIdAction,
	setStopSoundTrack,
	setTemplateCategories,
	setUploadError,
	setUploadSpeed,
	stopNewFloStreamsAction,
	uploadFileForCustomFlo,
} from './NewFlo.reducers';
import {
	elementIdSelector,
	hasFailedAttemptSelector,
	newFloDurationSelector,
	newFloIdSelector,
	newFloTracksSelector,
} from './NewFloContainer.selector';
import { optionKeys, RecorderOptions } from './NewFloContainer.types';

import { SagaIterator } from 'redux-saga';
import { AnnotationSnapshot } from '../../Components/Annotations/Annotations.types';
import { SendMessageToExtension } from '../../utils/SendMessageToExtension';
import {
	resetStateExtension,
	setNoOfClicks,
	updateAnnotationCommentExtension,
} from './NewFloExtension.actions';

import Bugsnag from '@bugsnag/js';
import { translate } from 'Common/Translate.utils';
import { scaleLinear } from 'd3-scale';
import videojs from 'video.js';
import 'videojs-contrib-hls';
import 'videojs-contrib-quality-levels';
import 'videojs-hls-quality-selector';
import { FloCreationTemplates } from '../../Common/Common.types';
import {
	bytesToMB,
	bytesToSize,
	transformResourceGroupToUISourceList,
} from '../../Common/Common.utils';
import { setErrorToast } from '../../Components/Notification/Toasts.reducers';
import { hideLoader, resetLoader, showLoader } from '../../store/reducers/Loader.reducer';
import { analyticsLog } from '../../store/reducers/Log.reducer';
import { CancelSagas } from '../../utils/saga.utils';
import { cookieDefinitionType } from '../Flo/Flo.types';
import { getUsageDetails } from '../ManageFlos/ManageFlos.reducers';
import { FloPagination, FlosPayloadType } from '../ManageFlos/ManageFlos.types';
import { addNewFloAction } from '../MySpace/MySpace.reducer';
import { baseReadyAction } from './NewFloExtensionToExtension.actions';

const createFloApi = `${API_BASE}/v1/flo`;
const saveFloApi = `${API_BASE}/v1/flo/:floId/`;
const createTrackApi = `${API_BASE}/v1/flo-resource-manager/flo/:floId/resource-group`;
const multipartTrackApi = `${createTrackApi}/:trackId/input/multipart-upload`;
const multipartPresignedUploadApi = `${createTrackApi}/:trackId/input/multipart-upload/:multipartUploadId`;
const multipartPresignedUploadEtagApi = `${createTrackApi}/:trackId/input/multipart-upload/:multipartUploadId/update`;

const getCountDowntrackURL = `${API_BASE}/v1/global/configuration/application/media/countdown`;

const stopTrackApi = `${createTrackApi}/:trackId/input/multipart-upload/:multipartUploadId/complete`;

let newFloId = '';
let newTrackIds: {
	[key: string]: {
		id: string;
		uploadId: string;
	};
} = {};

type EventsListType = {
	time: number;
	uiId: string;
	type: string;
	clickMeta: {
		additionalMeta: {
			[key: string]: unknown;
		};
		title: string;
		url: string;
	};
	canvasRenderConfig: {
		closestElementBounds: {
			x: number;
			y: number;
			height: number;
			width: number;
		};
		canvasCoordinates: {
			x: number;
			y: number;
			screenTop: number;
			screenLeft: number;
		};
		windowCoordinates: {
			height: number;
			width: number;
		};
	};
};

type ScreenshotListItemType = {
	clickedAt: number;
	uiId: string;
	file: string;
};

let floEnded: boolean = false;
let queue: chunkPayload[] = [];
let screenShareQueue: chunkPayload[] = [];
let videoQueue: chunkPayload[] = [];
let stopped = false;
let eventsList: EventsListType[] = [];
let screenshotList: ScreenshotListItemType[] = [];
let eventsListTimes: number[] = [];
let eventsListIds: {
	[key: string]: boolean;
} = {};

let videoWindowHeight: number = 0;
let videoWindowWidth: number = 0;
let totalSeconds: number = 0;
let discarded: boolean = false;

const Controllers: {
	[key: string]: AbortController | null;
} = {};

export function* onError(e?: ErrorEvent) {
	queue = [];
	stopped = false;
	// @ts-ignore
	window.onbeforeunload = undefined;
	yield put(setFloStateAction('stopped'));
	yield put(
		analyticsLog({
			level: 'error',
			message: `newflo onerror ${e?.toString && e?.toString()}`,
			value: get(e, 'stack'),
		})
	);
	const messageStatus = get(e, 'response.status');
	const redirectOnError = get(e, 'response.data.redirectOnError', false);
	const message =
		get(e, 'response.data.data') || get(e, 'response.data.message') || get(e, 'message');
	if (
		!message ||
		(redirectOnError && [404, 403, 500].includes(messageStatus)) ||
		[412, 402].includes(messageStatus)
	) {
		Bugsnag.notify(`<< onError v2 ${String(e)},
		 responseText: ${get(e, 'request.responseText')} >> url: ${get(e, 'request.responseURL')}
		 >> ${newFloId} >> ${JSON.stringify(newTrackIds || {})}`);
	} else {
		Bugsnag.notify(`newflo onError v2 ${e}`);
		const message = get(e, 'response.data.data') || get(e, 'message');
		yield put(
			showDialogAction({
				header: 'Error',
				variant: 'small',
				body: message || 'Oops! something went wrong, please try again later.',
				showCancel: true,
				cancelButtonText: 'Okay',
			})
		);
		yield put(resetStateAction(''));
	}
}

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export function* createNewTracksSaga({
	payload,
}: {
	payload: {
		floId: string;
		floSpaceId: string;
		type: string;
		original_work: boolean;
		has_audio: boolean;
		has_video: boolean;
		height: string;
		width: string;
		videoDeviceMeta: MediaDeviceInfo | undefined;
		audioDeviceMeta: MediaDeviceInfo | undefined;
		customFlo?: boolean;
		customFile?: unknown;
	};
}) {
	try {
		const templateId: string = yield select(
			(state) => get(state, 'newFlo.template.id', '63b406db38cf62331f71fa51') as string
		);
		const qsElementId = qs.stringify({
			floSpaceId: get(payload, 'floSpaceId'),
			templateId,
			name: 'clicks',
		});
		const controller1 = new AbortController();

		Controllers[`createElement_${get(payload, 'type')}`] = controller1;
		const elemResponse: { data: { id: string } } = yield call(
			new API({
				signal: controller1.signal,
			}).get,
			`${API_BASE}/v1/global/editor-elements/name?${qsElementId}`
		);

		yield put(setElementId(get(elemResponse, 'data.id')));
		let url = createTrackApi.replace(':floId', get(payload, 'floId'));
		const queryParams = qs.stringify({
			tsec: '0',
			original_work: `${payload.original_work}`,
			has_video: `${payload.has_video}`,
			has_audio: `${payload.has_audio}`,
			height: `${payload.height}`,
			width: `${payload.width}`,
			resourceType: get(payload, 'type') === 'camera' ? 'video' : 'screenShare',
			flospaceId: get(payload, 'floSpaceId'),
			container_type: `${isSafari ? 'mp4' : 'webm'}`,
		});
		url = `${url}?${queryParams}`;

		const controller2 = new AbortController();
		Controllers[`devicesMeta_${get(payload, 'type')}`] = controller2;

		// @ts-ignore
		const response: { data: { id: string } } = yield call(
			new API({
				signal: controller2.signal,
			}).post,
			url,
			{
				videoDeviceMeta: payload.videoDeviceMeta,
				audioDeviceMeta: payload.audioDeviceMeta,
			}
		);
		const multipartUrl = multipartTrackApi
			.replace(':floId', get(payload, 'floId'))
			.replace(':trackId', response.data.id);

		const multipartQueryObject = {
			fileExtension: get(payload, 'customFlo')
				? customFileExtension
				: `${isSafari ? 'mp4' : 'webm'}`,
			tsec: '0',
			original_work: `${payload.original_work}`,
			has_video: `${payload.has_video}`,
			has_audio: `${payload.has_audio}`,
			height: `${payload.height}`,
			width: `${payload.width}`,
			resourceType: get(payload, 'type') === 'camera' ? 'video' : 'screenShare',
			flospaceId: get(payload, 'floSpaceId'),
		};
		// @ts-ignore
		const controller3 = new AbortController();
		Controllers[`multiPartReq_${get(payload, 'type')}`] = controller3;
		const multipartResponse: { data: { multipartUploadId: string } } = yield call(
			new API({
				signal: controller3.signal,
			}).put,
			`${multipartUrl}?${qs.stringify(multipartQueryObject)}`,
			{}
		);
		newTrackIds[payload.type] = {
			id: response.data.id,
			uploadId: multipartResponse.data.multipartUploadId,
		};
		yield put(
			setNewTrackIdAction({
				floEnded,
				[payload.type]: response.data.id,
				[`multi_${payload.type}`]: multipartResponse.data.multipartUploadId,
			})
		);
		if (get(payload, 'customFlo')) {
			yield delay(1000);
			yield put(
				sendChunkAction({
					file: get(payload, 'customFile'),
					seq: 1,
					totalSeconds: get(payload, 'duration', 1),
					type: 'screenShare',
					isEnd: false,
				})
			);
			yield put(
				sendChunkAction({
					file: null,
					seq: 2,
					totalSeconds: 0,
					type: 'screenShare',
					isEnd: true,
				})
			);
		}
	} catch (e: any) {
		yield put(
			analyticsLog({
				level: 'error',
				message: `failed to create track: "${newFloId}", stack: ${
					e?.toString && e?.toString()
				}`,
				value: {
					response: get(e, 'request.responseText') || get(e, 'response.data.data'),
					stack: get(e, 'stack'),
				},
			})
		);
		console.warn('error', e);
		yield call(onError);
		yield put(stopNewFloStreamsAction({}));
		yield put(setCanShowNewFlo(false));
	} finally {
		try {
			delete Controllers[`createElement_${get(payload, 'type')}`];
			delete Controllers[`devicesMeta_${get(payload, 'type')}`];
			delete Controllers[`multiPartReq_${get(payload, 'type')}`];
		} catch (e) {
			console.warn(e);
		}
	}
}

export function* onScreenShareEndedSaga() {
	try {
		floEnded = true;
		const keys = Object.keys(Controllers);
		forEach(keys, (key: string) => {
			const controller = Controllers[key];
			if (controller) {
				controller.abort();
				delete Controllers[key];
			}
		});
	} catch (e: any) {
		yield put(
			analyticsLog({
				level: 'error',
				// @ts-ignore
				message: `onScreenShareEndedSaga ${e?.toString()}`,
				value: get(e, 'stack'),
			})
		);
	} finally {
		yield put(setFloStateAction('stopped'));
	}
}

export function* getCountDownTrackSaga() {
	try {
		const response: {
			data: { countdownAbsolutePath: string; stopSoundAbsolutePath: string };
		} = yield call(new API().get, getCountDowntrackURL);
		yield put(setCountDownTrack(response.data.countdownAbsolutePath));
		yield put(
			setStopSoundTrack(
				response.data.stopSoundAbsolutePath || response.data.countdownAbsolutePath
			)
		);
	} catch (e) {
		console.warn('error', e);
		yield call(onError);
	}
}

export function* createNewFloSaga({
	payload,
}: {
	payload: {
		type: RecorderOptions;
		name?: string;
		hasAudio: boolean;
		settings: { [key: string]: MediaTrackSettings };
		customFlo?: boolean;
		duration?: number;
		customFile?: unknown;
		videoHeight?: number;
		videoWidth?: number;
		fileName?: string;
		fileSize?: string;
		fileSizeInBytes?: string;
		errorDialogCb?: { [key: string]: { onCancel?(): void } };
	};
}) {
	try {
		discarded = false;
		eventsList = [];
		eventsListTimes = [];
		screenshotList = [];
		eventsListIds = {};
		videoWindowHeight = 0;
		totalSeconds = 0;
		videoWindowWidth = 0;
		screenShareSeq = 0;
		screenSharePrevSize = 0;
		videoSeq = 0;
		videoPrevSize = 0;
		queue = [];
		screenShareQueue = [];
		floEnded = false;
		videoQueue = [];
		stopped = false;
		screenShareTotalSize = 0;
		screenShareUploadedSize = 0;
		videoTotalSize = 0;
		videoUploadedSize = 0;
		const templateId: string = yield select((state) => {
			if (get(payload, 'customFlo')) {
				return get(state, 'newFlo.cacheTemplates.manual_upload[0].id');
			}
			return (
				(get(state, 'newFlo.template.id') as string) ||
				get(state, 'newFlo.templates[0].id')
			);
		});
		if (!templateId && get(payload, 'customFlo')) {
			throw new Error('Failed to load templates');
		}
		// @ts-ignore
		const floSpaceId = yield select(floSpaceSelector);
		const controller = new AbortController();
		Controllers.createFlo = controller;
		// @ts-ignore
		const response: { data: { id: string } } = yield call(
			new API(
				{
					signal: controller.signal,
				},
				false,
				false,
				true,
				false,
				undefined,
				payload.errorDialogCb
			).post,
			createFloApi,
			{
				floSpaceId,
				name: payload.name,
				templateId,
				fileUpload: get(payload, 'customFlo'),
				duration: get(payload, 'duration'),
				videoHeight: get(payload, 'settings.screenShare.height'),
				videoWidth: get(payload, 'settings.screenShare.width'),
				fileName: get(payload, 'fileName'),
				fileSizeInBytes: get(payload, 'fileSizeInBytes'),
			}
		);

		yield put(setNewFloIdAction(response.data.id));
		newFloId = response.data.id;
		if (
			includes(
				[
					optionKeys.screenOnly,
					optionKeys.screen,
					optionKeys.screenCam,
					optionKeys.screenCamMic,
				],
				payload.type
			)
		) {
			yield put(
				createNewTrackAction({
					floId: response.data.id,
					floSpaceId,
					type: 'screenShare',
					original_work: true,
					customFlo: get(payload, 'customFlo'),
					customFile: get(payload, 'customFile'),
					duration: get(payload, 'duration'),
					has_audio:
						includes([optionKeys.screen], payload.type) && get(payload, 'hasAudio'),
					has_video: true,
					height: get(payload, 'settings.screenShare.height'),
					width: get(payload, 'settings.screenShare.width'),
					audioDeviceMeta:
						optionKeys.screenOnly === payload.type
							? get(payload, 'audioDeviceMeta')
							: null,
				})
			);
		}
		if (
			[
				optionKeys.cameraOnly,
				optionKeys.camera,
				optionKeys.screenCam,
				optionKeys.screenCamMic,
			].includes(payload.type)
		) {
			yield put(
				createNewTrackAction({
					floId: response.data.id,
					floSpaceId,
					type: 'camera',
					original_work: includes(
						[optionKeys.cameraOnly, optionKeys.camera],
						payload.type
					),
					customFlo: get(payload, 'customFlo'),
					has_audio: get(payload, 'hasAudio'),
					has_video: true,
					height: get(payload, 'settings.camera.height'),
					width: get(payload, 'settings.camera.width'),
					videoDeviceMeta: get(payload, 'videoDeviceMeta'),
					audioDeviceMeta: get(payload, 'audioDeviceMeta'),
				})
			);
		}
	} catch (e: any) {
		yield put(
			analyticsLog({
				level: 'error',
				message: `createNewFloSaga ${JSON.stringify(payload)} stack: ${
					e?.toString && e?.toString()
				}`,
				value: get(e, 'stack'),
			})
		);
		yield call(onError, e);
		console.warn('error', e);
	} finally {
		delete Controllers.createFlo;
	}
}

function* stopTrackAPI(floId: string, trackId: string, uploadId: string) {
	let url = stopTrackApi
		.replace(':floId', floId)
		.replace(':trackId', trackId)
		.replace(':multipartUploadId', uploadId);
	// @ts-ignore
	const response: { data: { id: string } } = yield call(new API().put, url, {});
	return response;
}

function* getFloSaga({ payload }: { payload: { floId: string } }): SagaIterator {
	const { floId } = payload;
	const response = yield call(new API().get, `${API_BASE}/v1/flo/${floId}`);
	return response;
}

export function* stopNewFloSaga({
	recorderType,
	payload,
}: {
	recorderType?: 'screenShare' | 'camera';
	payload?: {
		skip?: boolean;
	};
}) {
	SendMessageToExtension(resetStateExtension(''));
	yield put(showLoader('home'));
	try {
		if (payload?.skip) {
			return;
		}
		// @ts-ignore
		const floId = yield select(newFloIdSelector) || newFloId;
		const tracks: { [key: string]: string } = yield select(newFloTracksSelector);
		const duration: number = yield select(newFloDurationSelector);

		if (tracks.camera) {
			if (!recorderType || recorderType === 'camera') {
				yield call(
					// @ts-ignore
					stopTrackAPI,
					floId,
					tracks.camera || get(newTrackIds, 'camera.id', ''),
					tracks.multiCamera || get(newTrackIds, 'camera.uploadId', '')
				);
			}
		}
		if (tracks.screenShare) {
			if (!recorderType || recorderType === 'screenShare') {
				yield call(
					// @ts-ignore
					stopTrackAPI,
					floId,
					tracks.screenShare || get(newTrackIds, 'screenShare.id', ''),
					tracks.multiScreenShare || get(newTrackIds, 'screenShare.uploadId', '')
				);
			}
		}
		const isEmpty = screenShareTotalSize === 0 && videoTotalSize === 0;
		if (floId && !isEmpty) {
			// @ts-ignore
			yield call(saveFloSaga, {
				payload: {
					type: 'save',
					doTrim: false,
					recordingDurationInSec: duration || totalSeconds,
				},
			});
			const floResponse: { data: FlosPayloadType } = yield call(getFloSaga, {
				payload: { floId },
			});
			yield put(addNewFloAction(floResponse.data));
		}
	} catch (e: any) {
		yield put(
			analyticsLog({
				level: 'error',
				message: `failed to stop flo: "${newFloId}" stack: ${
					e?.toString && e?.toString()
				}`,
				value: {
					response: get(e, 'request.responseText') || get(e, 'response.data.data'),
					stack: get(e, 'stack'),
				},
			})
		);
		console.warn('error', get(e, 'request'));
		Bugsnag.notify(`failed to stop new flo v2 ${String(e)},
		 responseText: ${get(e, 'request.responseText')} >> url: ${get(e, 'request.responseURL')}
		 >> payload: ${JSON.stringify(payload || {})} >> ${newFloId} >> ${JSON.stringify(
			newTrackIds || {}
		)}`);
		yield call(onError);
		yield put(resetStateAction({}));
	} finally {
		yield put(hideLoader('home'));
	}
}

export function* sendChunkSaga({
	payload,
}: {
	payload: {
		errorMessage?: number;
		retry?: number;
		file: BlobPart;
		seq: number;
		totalSeconds: number;
		type: 'screenShare' | 'camera';
		isEnd?: boolean;
	};
}) {
	try {
		if (discarded) return;
		if (payload.isEnd) {
			stopped = true;
			return;
		}

		// @ts-ignore
		const floId = yield select(newFloIdSelector) || newFloId;
		// @ts-ignore
		const trackIds = yield select(newFloTracksSelector);

		if (get(payload, 'retry', 0) > 2) {
			yield put(
				analyticsLog({
					level: 'error',
					message: `retry sendChunkSaga payload: ${JSON.stringify(payload)}`,
					value: payload,
				})
			);
			// location.reload();
			Bugsnag.notify({
				name: 'update chunk failed',
				message: `upload failed for v2 floId: ${floId} tracks: ${JSON.stringify(
					trackIds
				)} payload: ${JSON.stringify(payload)},
					newFloId: ${newFloId},
					errorMessage: ${payload.errorMessage}`,
			});
			yield put(stopNewFloStreamsAction({}));
			const message =
				screenShareUploadedSize > 0 || videoUploadedSize > 0
					? 'Your flo will be recovered and shown in flo explorer after sometime'
					: '';
			yield put(
				showDialogAction({
					header: '',
					variant: 'small',
					body: `${
						payload.errorMessage || 'There seems to be a problem with your network'
					}. ${message}`,
					showConfirm: true,
					confirmButtonText: 'Okay',
				})
			);

			return;
		}

		let trackId = trackIds.camera;
		let uploadId = trackIds.multiCamera;
		if (payload.type === 'screenShare') {
			trackId = trackIds.screenShare;
			uploadId = trackIds.multiScreenShare;
		}
		const query = `?seq=${payload.seq}`;
		const multipartUrl = multipartPresignedUploadApi
			.replace(':floId', floId)
			.replace(':trackId', trackId)
			.replace(':multipartUploadId', uploadId);

		const multipartEtagUrl = multipartPresignedUploadEtagApi
			.replace(':floId', floId)
			.replace(':trackId', trackId)
			.replace(':multipartUploadId', uploadId);

		const multipartResponse: {
			data: { uploadId: string; multipartUrl: string };
		} = yield call(new API().put, multipartUrl + query, {} /*{ file: payload.file }*/);
		let startTime = Date.now();
		let startBytes = 0;

		const onUploadProgress = _debounce(
			(progressEvent: { loaded: number; total: number }) => {
				const uploadedBytes = progressEvent.loaded;
				const totalBytes = progressEvent.total;
				const progressPercentage = (uploadedBytes / totalBytes) * 100;
				const currentTime = Date.now();
				const elapsedTime = (currentTime - startTime) / 1000; // Convert to seconds

				// Calculate upload speed in bits per second (bps)
				const uploadSpeedBps = ((uploadedBytes - startBytes) * 8) / elapsedTime;
				startBytes = uploadedBytes;
				startTime = currentTime;
				store.dispatch(
					setUploadSpeed({
						uploadSpeed: uploadSpeedBps.toFixed(2),
						data: {
							[payload.seq]: [uploadedBytes, totalBytes],
						},
					})
				);
			},
			300
		);
		const uploadResponse: unknown = yield call(
			new API(
				{
					headers: {
						'Content-Type': 'video/webm',
					},
				},
				true,
				true
			).put,
			multipartResponse.data.multipartUrl,
			payload.file,
			{
				onUploadProgress,
			}
		);
		totalSeconds = max([totalSeconds, payload.totalSeconds]) as number;

		// @ts-ignore
		yield call(new API().put, multipartEtagUrl, {
			etag: JSON.parse(get(uploadResponse, 'headers.etag')),
			extensionTag: JSON.parse(get(uploadResponse, 'headers.etag')),
			sequence: payload.seq,
			recordTimeInSeconds: payload.totalSeconds,
		});

		if (payload.type === 'screenShare') {
			screenShareUploadedSize += get(payload, 'file.size', 0);
		}

		if (payload.type === 'camera') {
			videoUploadedSize += get(payload, 'file.size', 0);
		}
		yield put(
			setChunkStats({
				screenShareTotalSize,
				screenShareUploadedSize,
				videoTotalSize,
				videoUploadedSize,
			})
		);
	} catch (e: any) {
		let rMessage = '';
		const status = get(e, 'status') || get(e, 'response.status');
		if (status === 401) {
			rMessage = 'Your session has expired';
		}
		yield put(
			setHasFailedAttempt({
				value: true,
				message: rMessage || get(e, 'response.data.data') || get(e, 'message'),
			})
		);
		payload.retry = payload.retry || 0;
		payload.retry += 1;
		const message = get(e, 'response.data.data') || get(e, 'message');
		payload.errorMessage = message;
		queue.push(payload);
		queue = sortBy(queue, (item) => {
			return `${item.isEnd ? 1 : 0}-${padStart(`${item.seq}`, 4, '0')}`;
		});
		yield put(
			analyticsLog({
				level: 'error',
				message: `upload chunk failed floId: "${newFloId}", retry: ${
					payload.retry
				}, chunk_type: "${payload.type}": ${get(payload, 'file.size')}b, last_chunk: ${
					payload.isEnd
				}, stack: ${e?.toString && e?.toString()}`,
				value: get(e, 'stack'),
			})
		);
		Bugsnag.notify({
			name: `upload chunk failed: ${payload.retry}`,
			message: `upload failed for v2 floId: ${newFloId}, attempt: ${
				payload.retry
			}, ${String(e)}`,
		});
		console.error(e);
	}
}

type chunkPayload = {
	file: BlobPart;
	seq: number;
	totalSeconds: number;
	type: 'screenShare' | 'camera';
	isEnd?: boolean;
};

let screenShareSeq = 0;
let customFileExtension = '';
let screenSharePrevSize = 0;
let screenShareTotalSize = 0;
let screenShareUploadedSize = 0;

let videoSeq = 0;
let videoPrevSize = 0;
let videoTotalSize = 0;
let videoUploadedSize = 0;

function mergeBuffer(chunks: BlobPart[]) {
	const blob = new Blob(chunks, {
		type: 'video/webm;',
	});
	return blob;
}

function mergeShareQueue(force?: boolean) {
	let screenShareBuffer: BlobPart[] = [];
	let screenShareBufferLen = 0;
	let canMerge = false;
	let totalSeconds = 0;
	let count = -1;
	screenShareQueue = sortBy(screenShareQueue, (item) => {
		return `${item.isEnd ? 1 : 0}-${padStart(`${item.seq}`, 4, '0')}`;
	});
	if (!force) {
		for (let item of screenShareQueue) {
			count++;
			if (item.file) {
				// @ts-ignore
				if (screenShareBufferLen < 6000000) {
					screenShareBuffer.push(item.file);
					// @ts-ignore
					screenShareBufferLen += item.file.size || 0;
				}
				if (screenShareBufferLen > 6000000) {
					totalSeconds = item.totalSeconds;
					canMerge = true;
					break;
				}
			}
		}
	}
	if (canMerge || force) {
		let chunks = screenShareBuffer;
		if (force) {
			chunks = reduce(
				screenShareQueue,
				(acc: BlobPart[], item) => {
					if (item.file) {
						acc.push(item.file);
					}
					return acc;
				},
				[]
			);
		}
		const file = mergeBuffer(chunks);
		const allow =
			file.size > 0 && (screenSharePrevSize === 0 || screenSharePrevSize > 6000000);
		if (allow) {
			screenSharePrevSize = file.size;
			if (force) {
				screenShareQueue = [];
			} else {
				screenShareQueue = screenShareQueue.slice(count + 1);
			}
			screenShareSeq = screenShareSeq + 1;
			queue.push({
				totalSeconds,
				type: 'screenShare',
				seq: screenShareSeq,
				file,
			});
		}
	}
}

function mergeVideoQueue(force?: boolean) {
	let videoBuffer: BlobPart[] = [];
	let videoBufferLen = 0;
	let canMerge = false;
	let totalSeconds = 0;
	let count = -1;
	videoQueue = sortBy(videoQueue, (item) => {
		return `${item.isEnd ? 1 : 0}-${padStart(`${item.seq}`, 4, '0')}`;
	});
	if (!force) {
		for (let item of videoQueue) {
			count++;
			if (item.file) {
				// @ts-ignore
				if (videoBufferLen < 6000000) {
					videoBuffer.push(item.file);
					// @ts-ignore
					videoBufferLen += item.file.size || 0;
				}
				if (videoBufferLen > 6000000) {
					totalSeconds = item.totalSeconds;
					canMerge = true;
					break;
				}
			}
		}
		// videoQueue.slice(count);
	}
	if (canMerge || force) {
		let chunks = videoBuffer;
		if (force) {
			chunks = reduce(
				videoQueue,
				(acc: BlobPart[], item) => {
					if (item.file) {
						acc.push(item.file);
					}
					return acc;
				},
				[]
			);
		}
		const file = mergeBuffer(chunks);
		const allow = file.size > 0 && (videoPrevSize === 0 || videoPrevSize > 6000000);
		if (allow) {
			videoPrevSize = file.size;
			if (force) {
				videoQueue = [];
			} else {
				videoQueue = videoQueue.slice(count + 1);
			}
			videoSeq = videoSeq + 1;
			queue.push({
				totalSeconds,
				type: 'camera',
				seq: videoSeq,
				file,
			});
		}
	}
}

function* mergeQueue({ payload }: { payload: boolean }): SagaIterator {
	yield call(mergeShareQueue, payload);
	yield call(mergeVideoQueue, payload);
}

function* addToChunkQueue({ payload }: { payload: chunkPayload }) {
	yield put(
		analyticsLog({
			level: 'info',
			message: `add chunk to floId: ${newFloId}, type: ${payload.type}, last_chunk: ${
				payload.isEnd
			}, size: ${get(payload, 'file.size', 0)}, seq: ${payload.seq}`,
		})
	);
	if (payload.type === 'screenShare' && !payload.isEnd) {
		screenShareQueue.push(payload);
		screenShareTotalSize += get(payload, 'file.size', 0) || 0;
	} else if (payload.type === 'camera' && !payload.isEnd) {
		videoQueue.push(payload);
		videoTotalSize += get(payload, 'file.size', 0) || 0;
	}
	yield put(
		setChunkStats({
			screenShareTotalSize,
			screenShareUploadedSize,
			videoTotalSize,
			videoUploadedSize,
		})
	);
	yield delay(550);
	yield put(mergeQueueAction(payload.isEnd));
	if (payload.isEnd && !stopped) {
		yield delay(550);
		queue.push(payload);
		queue = sortBy(queue, (item) => {
			return `${item.isEnd ? 1 : 0}-${padStart(`${item.seq}`, 4, '0')}`;
		});
	}
}

function* UploadSagaRunner() {
	try {
		while (!stopped || queue.length) {
			yield put(mergeQueueAction(false));
			yield delay(1000);
			const hasFailedAttempt: boolean = yield select(hasFailedAttemptSelector);
			if (hasFailedAttempt) continue;
			const [item, item2, ...values] = queue;
			queue = values;
			const calls = [];
			if (item) calls.push(call(sendChunkSaga, { payload: item }));
			if (item2) calls.push(call(sendChunkSaga, { payload: item2 }));
			if (calls.length) yield all(calls);
			if (stopped) yield delay(100);
			if (stopped && queue.length === 0) {
				const isEmpty = screenShareTotalSize === 0 && videoTotalSize === 0;
				yield put(
					stopNewFloStreamsAction({
						skip: isEmpty,
					})
				);
				yield put(setFloStateAction(isEmpty ? 'stopped' : 'stopped/save'));
			}
		}
	} catch (e: any) {
		console.warn(e);
		yield put(
			analyticsLog({
				level: 'error',
				message: `upload chunk failed floId: "${newFloId}" stack: ${
					e?.toString && e?.toString()
				}`,
				value: get(e, 'stack'),
			})
		);
		Bugsnag.notify({
			name: `upload chunk saga failed`,
			message: `upload failed for v2 floId: ${newFloId}, ${String(e)}`,
		});
	}
}

export function* sendMouseEventSaga(
	{
		payload,
	}: {
		payload: EventsListType;
	},
	retry?: number
): SagaIterator {
	try {
		// @ts-ignore
		const floId = yield select(newFloIdSelector) || newFloId;
		const trackIds = yield select(newFloTracksSelector);
		const editorElementId = yield select(elementIdSelector);
		const query = qs.stringify({
			resourceGroupId: trackIds.screenShare || trackIds.camera,
			editorElementId,
		});

		if (!floId) return;

		const annotationResponse: { data: AnnotationSnapshot } = yield call(
			new API().post,
			`${API_BASE}/v1/elements/flo/${floId}?${query}`,
			[
				{
					...payload,
					screenshotClickId: payload.uiId,
				},
			]
		);
		yield put(increaseMouseEventCount({}));
		const noOfClicks = yield select((state) =>
			String(get(state, 'newFlo.noOfClicks', ''))
		);
		yield call(
			SendMessageToExtension,
			setNoOfClicks({
				value: noOfClicks,
			})
		);
	} catch (e: any) {
		let message = '';
		const status = get(e, 'status') || get(e, 'response.status');
		if (status === 401) {
			message = 'Your session has expired';
		}
		yield put(
			analyticsLog({
				level: 'error',
				message: `mouse event failed floId: "${newFloId}" stack: ${
					e?.toString && e?.toString()
				}`,
				value: get(e, 'stack'),
			})
		);
		yield put(
			setHasFailedAttempt({
				value: true,
				message: message || get(e, 'response.data.data') || get(e, 'message'),
			})
		);
		console.warn(e);
		if ((retry || 0) < 2) {
			yield call(sendMouseEventSaga, { payload }, (retry || 0) + 1);
		}
	} finally {
	}
}

export function* sendScreenshotEventSaga(
	{
		payload,
	}: {
		payload: ScreenshotListItemType;
	},
	retry?: number
): SagaIterator {
	try {
		// @ts-ignore
		const floId = yield select(newFloIdSelector) || newFloId;
		const trackIds = yield select(newFloTracksSelector);
		const resourceGroupId = trackIds.screenShare || trackIds.camera;

		if (!floId) return;

		const response: {
			data: string;
		} = yield call(
			new API().put,
			`${API_BASE}/v1/flo-resource-manager/flo/${floId}/resource-group/${resourceGroupId}/input/screenshot-upload`,
			{
				screenshotTimestampInSeconds: payload.clickedAt,
				screenshotClickId: replace(payload.uiId, 'screenshot_', ''),
			}
		);
		const blobRes = yield call(fetch, payload.file);
		// @ts-ignore
		const blob = yield blobRes.blob();

		const uploadResponse: unknown = yield call(
			new API(
				{
					headers: {
						'Content-Type': 'image/jpeg',
					},
				},
				true,
				true
			).put,
			response.data,
			blob
		);
	} catch (e: any) {
		let message = '';
		const status = get(e, 'status') || get(e, 'response.status');
		if (status === 401) {
			message = 'Your session has expired';
		}
		yield put(
			analyticsLog({
				level: 'error',
				message: `upload image failed floId: "${newFloId}" stack: ${
					e?.toString && e?.toString()
				}`,
				value: get(e, 'stack'),
			})
		);
		yield put(
			setHasFailedAttempt({
				value: true,
				message: message || get(e, 'response.data.data') || get(e, 'message'),
			})
		);
		console.warn(e);
		if ((retry || 0) < 2) {
			yield call(sendScreenshotEventSaga, { payload }, (retry || 0) + 1);
		}
	} finally {
	}
}

function* uploadScreenshotSagaRunner() {
	try {
		while (!stopped || screenshotList.length) {
			yield delay(1000);
			const hasFailedAttempt: boolean = yield select(hasFailedAttemptSelector);
			if (hasFailedAttempt) continue;
			const [item, item2, ...values] = screenshotList;
			screenshotList = values;
			const calls = [];
			if (item) calls.push(call(sendScreenshotEventSaga, { payload: item }, 0));
			if (item2) calls.push(call(sendScreenshotEventSaga, { payload: item2 }, 0));
			if (calls.length) yield all(calls);
		}
	} catch (e: any) {
		console.warn(e);
		yield put(
			analyticsLog({
				level: 'error',
				message: `uploadScreenshotSagaRunner failed floId: "${newFloId}" stack: ${
					e?.toString && e?.toString()
				}`,
				value: get(e, 'stack'),
			})
		);
	}
}

function* sentEventSagaRunner() {
	try {
		while (!stopped || eventsList.length) {
			yield delay(1000);
			const hasFailedAttempt: boolean = yield select(hasFailedAttemptSelector);
			if (hasFailedAttempt) continue;
			const [item, item2, ...values] = eventsList;
			eventsList = values;
			const calls = [];
			if (item) calls.push(call(sendMouseEventSaga, { payload: item }, 0));
			if (item2) calls.push(call(sendMouseEventSaga, { payload: item2 }, 0));
			if (calls.length) yield all(calls);
		}
	} catch (e: any) {
		console.warn(e);
		yield put(
			analyticsLog({
				level: 'error',
				message: `sentEventSagaRunner failed floId: "${newFloId}" stack: ${
					e?.toString && e?.toString()
				}`,
				value: get(e, 'stack'),
			})
		);
	}
}

export function* saveFloSaga({
	payload,
}: {
	payload: {
		recordingDurationInSec?: number;
		name?: string;
		type: 'save' | 'discard';
		trimStartAt?: number;
		trimEndAt?: number;
		doTrim: boolean;
		screenShare?: {
			videoHeight: number;
			videoWidth: number;
		};
		video?: {
			videoHeight: number;
			videoWidth: number;
		};
	};
}) {
	if (discarded) return;
	yield put(showLoader('home'));
	yield put(showLoader('flo'));
	try {
		// @ts-ignore
		const floId = yield select(newFloIdSelector) || newFloId;
		// @ts-ignore
		const trackIds = yield select(newFloTracksSelector);
		// @ts-ignore
		const cropConfig = yield select(
			// @ts-ignore
			(state: RootState) => get(state, 'newFlo.postProcessConfigs.crop') as CropStateType
		);
		let cropConfigPayload: Partial<{
			doCrop: boolean;
			width: number;
			height: number;
			xoffset: number;
			yoffset: number;
		}> = {
			doCrop: false,
			width: 0,
			height: 0,
			xoffset: 0,
			yoffset: 0,
		};
		// @ts-ignore
		const floSpaceId = yield select(floSpaceSelector);

		if (payload.type === 'discard') {
			discarded = true;
			screenShareSeq = 0;
			screenSharePrevSize = 0;
			screenShareTotalSize = 0;
			screenShareUploadedSize = 0;

			videoSeq = 0;
			videoPrevSize = 0;
			videoTotalSize = 0;
			videoUploadedSize = 0;
			videoWindowHeight = 0;
			videoWindowWidth = 0;
			queue = [];
			screenShareQueue = [];
			floEnded = false;
			videoQueue = [];
			stopped = false;
			eventsList = [];
			totalSeconds = 0;
			screenshotList = [];
			eventsListTimes = [];
			eventsListIds = {};
		}
		// @ts-ignore
		const dialogId = yield select((state) => get(state, 'dialog.id', ''));
		const customFlo = yield select((state) => get(state, 'newFlo.customFlo', ''));
		if (dialogId === 'new_flo_nw_issue') {
			yield put(hideDialogAction(''));
		}

		if (get(cropConfig, 'enable')) {
			const windowSize = get(cropConfig, 'values.windowSize');
			const videoSize = get(cropConfig, 'videoSize');

			const yScale = scaleLinear(
				[0, Number(get(windowSize, 'height', 0))],
				[0, Number(videoSize.height || 0)]
			);
			const xScale = scaleLinear(
				[0, Number(get(windowSize, 'width', 0))],
				[0, Number(videoSize.width || 0)]
			);
			const x = xScale(Number(get(cropConfig, 'values.x', 0)));
			const y = yScale(Number(get(cropConfig, 'values.y', 0)));
			const x2 = xScale(Number(get(cropConfig, 'values.x2', 0)));
			const y2 = yScale(Number(get(cropConfig, 'values.y2', 0)));
			const xoffset = Math.round(x);
			const yoffset = Math.round(y);
			cropConfigPayload = {
				doCrop: true,
				width: min([Math.round(x2 - x), videoSize.width - xoffset]),
				height: min([Math.round(y2 - y), videoSize.height - yoffset]),
				xoffset,
				yoffset,
			};
		}
		const isSameValue = payload.trimStartAt === payload.trimEndAt;
		const trackDetailsMap: {
			[key: string]: {
				videoHeight: number;
				videoWidth: number;
			};
		} = {};
		if (trackIds.screenShare && get(payload, 'screenShare')) {
			// @ts-ignore
			trackDetailsMap[trackIds.screenShare] = {
				videoHeight:
					videoWindowHeight || (get(payload, 'screenShare.videoHeight') as number),
				videoWidth:
					videoWindowWidth || (get(payload, 'screenShare.videoWidth') as number),
			};
		}
		if (trackIds.video && get(payload, 'video')) {
			// @ts-ignore
			trackDetailsMap[trackIds.video] = get(payload, 'video');
		}
		const response: { data: { id: string } } = yield call(
			new API().put,
			`${saveFloApi.replace(':floId', floId)}${payload.type}`,
			{
				recordingDurationInSec: payload.recordingDurationInSec || totalSeconds,
				floSpaceId,
				name: payload.name,
				trackDetailsMap,
				trackProcessingConfig: {
					floSpaceId,
					trimStartAt: payload.trimStartAt,
					trimEndAt: payload.trimEndAt,
					doTrim: !isSameValue && payload.doTrim,
					cropConfig: cropConfigPayload,
				},
			}
		);
		// @ts-ignore
		window.onbeforeunload = undefined;
		if (payload.type === 'save') {
			yield put(setCanShowNewFlo(false));
			yield put(pushState(`/flos/${response.data.id}`));
			yield put(getUsageDetails({ floSpaceId: floSpaceId }));
		}

		yield put(setFloStateAction('stopped'));
		yield put(resetStateAction({
			visible: customFlo && payload.type === 'discard',
		}));
		queue = [];
		stopped = false;
		newFloId = '';
	} catch (e: any) {
		yield put(
			analyticsLog({
				level: 'error',
				message: `failed to save flo: "${newFloId}" stack: ${
					e?.toString && e?.toString()
				}`,
				value: {
					response: get(e, 'request.responseText'),
					stack: get(e, 'stack'),
				},
			})
		);
		console.warn(e);
		// @ts-ignore
		window.onbeforeunload = undefined;
		yield call(onError);
	} finally {
		yield put(hideLoader('home'));
		yield put(hideLoader('flo'));
	}
}

const transformAnnotationForApi = (annotation: any, comment: string) => ({
	uiId: annotation.uid,
	time: annotation.time,
	commentReq: { content: comment },
	tool: get(annotation, 'state.tool'),
	type: 'annotation',
	uiRenderConfig: get(annotation, 'state'),
});

const transformAnnotationForUi = (
	annotations: AnnotationSnapshot
): AnnotationSnapshot[] => {
	const data = map(annotations, (value) => {
		const uiRenderConfig = get(value, 'uiRenderConfig');
		if (!uiRenderConfig) return;
		uiRenderConfig.id = get(value, 'id');
		uiRenderConfig.commentId = get(value, 'commentId');
		uiRenderConfig.createdBy = get(value, 'createdBy');
		return {
			id: get(value, 'id'),
			uid: get(value, 'uiId') || get(value, 'id'),
			time: get(value, 'time'),
			commentId: get(value, 'commentId'),
			createdBy: get(value, 'createdBy'),
			state: uiRenderConfig,
		};
	});
	// @ts-ignore
	return data as AnnotationSnapshot;
};

export function* addAnnotationComment({
	payload,
}: {
	payload: AnnotationSnapshot;
}): SagaIterator {
	try {
		// @ts-ignore
		const floId = yield select(newFloIdSelector) || newFloId;
		const trackIds = yield select(newFloTracksSelector);
		const annotationResponse: { data: AnnotationSnapshot } = yield call(
			new API().post,
			`${API_BASE}/v1/annotations/flo/${floId}?trackId=${
				trackIds.screenShare || trackIds.camera
			}`,
			transformAnnotationForApi(payload, payload.comment || '')
		);
		const responsePayload = {
			// @ts-ignore
			data: transformAnnotationForUi([annotationResponse.data])[0],
			content: (payload.comment || '').trim(),
			commentId: get(annotationResponse, 'data.commentId'),
			createdBy: get(annotationResponse, 'data.createdBy'),
			id: get(annotationResponse, 'data.id'),
			uiId: get(annotationResponse, 'data.uiId'),
		};
		SendMessageToExtension(updateAnnotationCommentExtension(responsePayload));
	} catch (e: any) {
		console.warn(e);
		yield put(
			analyticsLog({
				level: 'error',
				message: `addAnnotationComment failed floId: "${newFloId}" stack: ${
					e?.toString && e?.toString()
				}`,
				value: get(e, 'stack'),
			})
		);
	} finally {
	}
}

function* getFloTracksSaga({ payload }: { payload: any }): SagaIterator {
	try {
		yield put(showLoader('new flo'));
		const { floId } = payload;
		const response = yield call(
			new API().get,
			`${API_BASE}/v1/flo-resource-manager/flo/${floId}/resource-group`
		);
		const trackData = transformResourceGroupToUISourceList(response?.data) || [];
		yield put(setNewFloTracks(trackData));
		forEach(trackData, (trackItem) => {
			if (trackItem.cookieDefinition) {
				const cookieDefinition = trackItem.cookieDefinition;
				if (cookieDefinition) {
					forEach(cookieDefinition, (item: cookieDefinitionType) => {
						const { domain, key, value } = item || {};
						Cookie.set(key, value, { domain } || '');
					});
				}
			}
		});
	} catch (e: any) {
		console.warn('flo tracks failed');
		yield put(setErrorToast(e));
		yield put(
			analyticsLog({
				level: 'error',
				message: `flo tracks failed floId: "${newFloId}" stack: ${
					e?.toString && e?.toString()
				}`,
				value: get(e, 'stack'),
			})
		);
	} finally {
		yield put(hideLoader('new flo'));
	}
}

export function* addMouseEventScreenshotSaga({
	payload,
}: {
	payload: ScreenshotListItemType;
}) {
	if (!eventsListIds[payload.uiId]) screenshotList.push(payload);
	eventsListIds[payload.uiId] = true;
}

export function* addMouseEventSaga({
	payload,
}: {
	payload: EventsListType;
}): SagaIterator {
	const hasEvent = eventsListTimes.some(
		(item) => payload.time >= item - 0.3 && payload.time <= item + 0.3
	);
	if (hasEvent) return;

	eventsListTimes.push(payload.time);

	if (!eventsListIds[payload.uiId]) eventsList.push(payload);
	eventsListIds[payload.uiId] = true;
}

export function* getTemplateCategoriesSaga() {
	try {
		yield put(showLoader(''));
		const queryObject = {
			pageNumber: 1,
			pageSize: 100,
		};
		const response: { data: FloCreationTemplates[]; paginationInfo: FloPagination } =
			yield call(
				new API(undefined, false, false, true).get,
				`${API_BASE}/v1/global/flo-recording-template/persona?${qs.stringify(
					queryObject
				)}`
			);
		yield put(setTemplateCategories(response.data));
	} catch (e: any) {
		console.warn(e);
		yield put(
			analyticsLog({
				level: 'error',
				message: `getTemplateCategoriesSaga failed floId stack: ${
					e?.toString && e?.toString()
				}`,
				value: get(e, 'stack'),
			})
		);
	} finally {
		yield put(hideLoader(''));
	}
}

export function* uploadFileForCustomFloSaga({
	payload,
}: {
	payload: {
		file: unknown;
		onCancel(): void;
	};
}) {
	try {
		yield put(resetLoader('uploading_file_for_new_flo'));

		const file = get(payload, 'file');
		const fileExt = last((get(payload, 'file.name') || '').split('.'));
		if (!includes(['mp4', 'mov'], fileExt)) {
			yield put(setErrorToast({
				message: translate('FILE_UPLOAD_UNSUPPORTED_FORMAT')
			}))
			throw new Error("upload either .mov or mp4");
		}
		const promise = yield new Promise((resolve, reject) => {
			customFileExtension = 'mp4';
			// const video = document.getElementById('upload-preview');
			const video = document.createElement('video');
			video.id = 'upload-preview';
			video.crossOrigin = 'anonymous'
			// @ts-ignore
			const fileUrl = URL.createObjectURL(file);
			const player =
				videojs(video!, {
					controls: false,
					fluid: false,
					html5: {
						vhs: {
							smoothQualityChange: true,
							withCredentials: process.env.REACT_APP_ENABLE_HLS_CREDENTIALS === 'true',
							overrideNative: true,
						},
					},
					controlBar: {
						remainingTimeDisplay: true,
						durationDisplay: true,
						currentTimeDisplay: true,
						fullscreenToggle: false,
						pictureInPictureToggle: false,
						volumePanel: {
							inline: false,
						},
					},
					userActions: {
						doubleClick: false,
					},
					// sources: [{
					// 	// @ts-ignore
					// 	src: fileUrl,
					// 	// type: get(file, 'type', '') || 'video/mp4',
					// 	withCredentials: false,
					// }]
				});
			const TYPE_MAP = {
				'video/quicktime': 'video/mp4',
			};
			player.src({
				// @ts-ignore
				src: fileUrl,
				type:
					get(TYPE_MAP, get(file, 'type', '')) || get(file, 'type', '') || 'video/mp4',
			});
			const fileName = get(file, 'name');
			const fileSizeInBytes = get(file, 'size');
			const fileSize = bytesToSize(get(file, 'size'));
			customFileExtension = last(get(file, 'name', '').split('.')) || '';

			player.one('loadedmetadata', () => {
				player.currentTime(Number.MAX_SAFE_INTEGER);
				const videoHeight = player.videoHeight();
				const videoWidth = player.videoWidth();
				const audioTracks = player.audioTracks();
				const isAudio = player.isAudio();
				const duration = player.duration();
				if (isFinite(duration)) {
					resolve({
						duration,
						videoHeight,
						videoWidth,
						audioTracks,
						isAudio,
						fileName,
						fileSize,
						fileSizeInBytes,
					});
				}
				player.dispose();
			});
			const onError = (error: unknown) => {
				reject(error);
				player.dispose();
			};
			player.one('error', onError);
			player.one('aderror', onError);
		});
		yield promise;

		const MAX_RESOLUTION = 2048 * 1080;
		const MAX_DURATION = 10 * 60;
		const MAX_SIZE = 300;

		const sizeInMb = bytesToMB(promise.fileSize);
		const resolution = promise.videoHeight * promise.videoWidth;
		const duration = promise.duration;

		if (sizeInMb > MAX_SIZE) {
			yield put(
				setErrorToast({
					message: translate('ERROR_CUSTOM_FILE_UPLOAD_FILE_SIZE'),
				})
			);
			yield put(setUploadError(translate('ERROR_CUSTOM_FILE_UPLOAD_FILE_SIZE')));
			return;
		}
		if (resolution > MAX_RESOLUTION) {
			yield put(
				setErrorToast({
					message: translate('ERROR_CUSTOM_FILE_UPLOAD_RESOLUTION'),
				})
			);
			yield put(setUploadError(translate('ERROR_CUSTOM_FILE_UPLOAD_RESOLUTION')));
			return;
		}
		if (duration > MAX_DURATION) {
			yield put(
				setErrorToast({
					message: translate('ERROR_CUSTOM_FILE_DURATION'),
				})
			);
			yield put(setUploadError(translate('ERROR_CUSTOM_FILE_DURATION')));
			return;
		}

		yield put(
			createNewFloAction({
				type: optionKeys.screen,
				hasAudio: true,
				settings: {
					screenShare: {
						height: promise.videoHeight,
						width: promise.videoWidth,
					},
				},
				customFlo: true,
				duration: promise.duration,
				fileName: promise.fileName,
				fileSize: promise.fileSize,
				fileSizeInBytes: promise.fileSizeInBytes,
				customFile: get(payload, 'file'),
				errorDialogCb: { 412: { onCancel: payload.onCancel } },
			})
		);
	} catch (e) {
		console.error('>>', e);
		// @ts-ignore
		yield put(
			setUploadError(
				e?.target?.player?.error()?.message || translate('ERROR_CUSTOM_FILE_UPLOAD')
			)
		);
	} finally {
		yield put(hideLoader('uploading_file_for_new_flo'));
	}
}

export function* getTemplatesSaga({
	payload,
}: {
	payload?: { id?: string; filter: string; page?: number; initial: true };
}): SagaIterator {
	yield put(showLoader('get_template_saga'));
	try {
		const { initial, filter, page } = payload || {};
		const queryObject: { [key: string]: string | number } = {
			pageNumber: page || 1,
			pageSize: 20,
		};
		if (!get(payload, 'id')) {
			queryObject.filter = `type:${filter || 'user'}`;
		}
		const existingData = yield select((state) =>
			get(state, `newFlo.cacheTemplates[${filter || 'user'}]`, [])
		);
		if (filter === 'upload_video') {
			yield put(
				newFloSetTemplates({
					data: [],
					filter,
				})
			);
			return;
		}
		if (existingData?.length > 0) {
			yield put(
				newFloSetTemplates({
					data: existingData,
					filter,
				})
			);
			return;
		}

		const response: { data: FloCreationTemplates[]; paginationInfo: FloPagination } =
			yield call(
				new API(undefined, false, false, true).get,
				`${API_BASE}/v1/global/flo-recording-template${
					get(payload, 'id') ? '/persona/' + get(payload, 'id', '') : ''
				}?${qs.stringify(queryObject)}`
			);

		if (initial && get(response, 'data.length', 0) === 0) {
			yield put(
				newFloGetTemplates({
					payload: { filter: 'user' },
				})
			);
			return;
		}

		yield put(
			newFloSetTemplates({
				data: response.data,
				filter,
			})
		);
		SendMessageToExtension({
			type: 'set_templates_data',
			payload: response.data,
		});
	} catch (e: any) {
		console.warn(e);
		yield put(
			analyticsLog({
				level: 'error',
				message: `getTemplatesSaga failed stack: ${e?.toString && e?.toString()}`,
				value: get(e, 'stack'),
			})
		);
		if (![401, 403].includes(get(e, 'response.status')))
			yield put(
				setErrorToast({
					type: 'error',
					status: get(e, 'response.status'),
					message: `Failed to get templates`,
					canShow: true,
				})
			);
	} finally {
		yield put(hideLoader('get_template_saga'));
	}
}

export function* newFloRootSagas(): Generator<ForkEffect> {
	const tasks = [
		// @ts-ignore
		yield takeLatest(getCountDownTrackAction.type, getCountDownTrackSaga),
		// @ts-ignore
		yield takeLatest(createNewFloAction.type, createNewFloSaga),
		// @ts-ignore
		yield takeLatest(newFloGetTemplates.type, getTemplatesSaga),
		// @ts-ignore
		yield takeLatest(createNewFloAction.type, UploadSagaRunner),
		// @ts-ignore
		yield takeLatest(createNewFloAction.type, sentEventSagaRunner),
		// @ts-ignore
		yield takeLatest(createNewFloAction.type, uploadScreenshotSagaRunner),
		// @ts-ignore
		yield takeEvery(createNewTrackAction.type, createNewTracksSaga),
		// @ts-ignore
		yield takeLatest(stopNewFloStreamsAction.type, stopNewFloSaga),
		// @ts-ignore
		yield takeLatest(mergeQueueAction.type, mergeQueue),
		// @ts-ignore
		yield takeEvery(sendChunkAction.type, addToChunkQueue),
		// @ts-ignore
		yield takeEvery(saveFloAction.type, saveFloSaga),
		// @ts-ignore
		yield takeLatest(sendAnnotationComment.type, addAnnotationComment),
		// @ts-ignore
		yield takeLatest(getNewFloTracks.type, getFloTracksSaga),
		// @ts-ignore
		yield takeLatest(onScreenShareEndedAction.type, onScreenShareEndedSaga),
		// @ts-ignore
		yield takeLatest(getTemplateCategories.type, getTemplateCategoriesSaga),
		// @ts-ignore
		yield takeLatest(uploadFileForCustomFlo.type, uploadFileForCustomFloSaga),
		// @ts-ignore
		yield debounce(100, sendMouseEvent.type, addMouseEventSaga),
		// @ts-ignore
		yield debounce(100, sendMouseEventScreenshot.type, addMouseEventScreenshotSaga),
	];
	// @ts-ignore
	yield takeEvery(leaveNewFlosPageAction.type, CancelSagas, tasks);
}

export default function runRootSaga() {
	// @ts-ignore
	store.runSaga(newFloRootSagas);
	SendMessageToExtension(baseReadyAction({}));
}
