import { createSlice } from '@reduxjs/toolkit';
import {
	OnboardingReducerState,
	UpdateOnboardingQuestionnaireState,
} from './OnboardQa.types';

const initialState = Object.freeze({
	data: [],
	status: 'loading',
});

const onboardQaReducerObject = {
	getOnboardingQuestionnaire(state: OnboardingReducerState) {},
	updateOnboardingQuestionnaire(
		state: OnboardingReducerState,
		{ payload }: { payload: OnboardingReducerState }
	) {
		state.data = payload.data;
		state.status = payload.status || 'loaded';
	},
	submitOnboardingQuestionnaire(
		state: OnboardingReducerState,
		{ payload }: { payload: UpdateOnboardingQuestionnaireState[] }
	) {},
};

export const onboardingQuestionnaire = createSlice({
	name: 'onboardingQuestionnaire',
	initialState,
	reducers: onboardQaReducerObject,
});

export default onboardingQuestionnaire.reducer;
export const getOnboardingQuestionnaireAction =
	onboardingQuestionnaire.actions.getOnboardingQuestionnaire;
export const updateOnboardingQuestionnaireAction =
	onboardingQuestionnaire.actions.updateOnboardingQuestionnaire;
export const putOnboardingQuestionnaireAction =
	onboardingQuestionnaire.actions.submitOnboardingQuestionnaire;
