import { createSlice } from '@reduxjs/toolkit';

const initialState = Object.freeze({
	previous: location.pathname + location.search,
	current: location.pathname + location.search,
});

interface RouteStateType {
	previous: string;
	current: string;
	errorUrl: string;
}

const RouteReducer = {
	push(state: RouteStateType, { payload }: { payload: string }) {
		state.current = payload;
		state.errorUrl = '';
	},
	update(state: RouteStateType, { payload }: { payload: string }) {
		state.previous = payload;
		state.current = payload;
		state.errorUrl = '';
	},
	pushErrorState(state: RouteStateType, { payload }: { payload: string }) {
		state.errorUrl = payload;
	},
};

export const Routes = createSlice({
	name: 'routes',
	initialState,
	//@ts-ignore
	reducers: RouteReducer,
});

export const updateState = Routes.actions.update;
export const pushState = Routes.actions.push;
export const pushErrorState = Routes.actions.pushErrorState;

export default Routes.reducer;
