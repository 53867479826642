export const ANNOTATION_FCM_EVENT_ADD_ANNOTATION = 'add_annotation';
export const ANNOTATION_FCM_EVENT_ADD_REPLY = 'add_reply';
export const ANNOTATION_FCM_EVENT_DELETE_ANNOTATION = 'delete_annotation';
export const ANNOTATION_FCM_EVENT_DELETE_REPLY = 'delete_reply';
export const ANNOTATION_FCM_EVENT_MARK_DECISION = 'mark_decision';
export const ANNOTATION_FCM_EVENT_MARK_RESOLVED = 'mark_resolved';
export const ANNOTATION_FCM_EVENT_COUNT_COMMENT = 'count_comment';
export const ANNOTATION_FCM_EVENT_COUNT_REPLY = 'count_reply';
export const ANNOTATION_FCM_EVENT_FLO_UPDATED = 'flo_updated';
export const ANNOTATION_FCM_EVENT_FLO_COUNT = 'flo_count';
export const ANNOTATION_FCM_EVENT_FLO_PUBLISH_COUNT = 'flo_published_count';
export const ANNOTATION_FCM_EVENT_FLO_MANUAL_UPLOAD_COUNT = 'flo_video_upload_count';
export const TEAM_USER_REMOVED_EVENT = 'flospace_user_removed';
export const TEAM_USER_ADDED_EVENT = 'flospace_user_join';
export const FLO_DOWNLOAD_EVENT = 'flo_download';
export const ANNOTATION_ONBOARDING_CREATE_FLO_FLAG = 'create-flo';
export const ANNOTATION_ONBOARDING_SHARE_FLO_FLAG = 'share-flo';
export const ANNOTATION_ONBOARDING_INSTALL_EXTENSION_FLAG = 'install-extension';

export const ANNOTATION_FCM_EVENT_PROCESSING_ACTIVITY = 'processing_activity';
export const ANNOTATION_FCM_EVENT_PROCESSING_STATE = 'processing_state';
export const ANNOTATION_FCM_EVENT_FLO_DELETED = 'flo_deleted';
export const ANNOTATION_FCM_EVENT_FLO_DURATION = 'flo_duration';
export const ANNOTATION_FCM_EVENT_EDIT_ANNOTATION = 'edit_annotation';
export const ANNOTATION_FCM_EVENT_EDIT_REPLY = 'edit_reply';

export const ANNOTATION_FCM_EVENT_FLO_PUBLIC_ACCESS = 'flo_toggle_public_access';
export const ANNOTATION_FCM_EVENT_FLO_PRIVATE_ACCESS = 'flo_toggle_private_access';
export const ANNOTATION_FCM_EVENT_FLO_INVITE_ACCESS = 'flo_toggle_invite_access';
export const ANNOTATION_FCM_EVENT_FLO_RENAMED = 'flo_renamed';
export const ANNOTATION_FCM_EVENT_FLO_CLOSED = 'flo_closed';
export const ANNOTATION_FCM_EVENT_FLO_ACTIVE = 'flo_active';
export const EXTENSION_SAVE_TOKEN = 'floik/popup/extension-save-token';
export const DEFAULT_DATE_TIME_VALUE = '2022-04-17T00:00';

export const MESSAGE_TO_ROLE_MAP = {
	admin: 'TEAMSPACE_ADMIN_ROLE_INFO',
	member: 'TEAMSPACE_USER_ROLE_INFO',
	billing: 'TEAMSPACE_BILLING_ROLE_INFO',
};

export const AI_VOICE_REPHRASE_UI_FEATURE_KEY = 'ai_voice_rephrase';