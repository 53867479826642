import React from 'react';
import { Route } from 'react-router-dom';
import FloRoute from '../Flo/Flo.route';
import HomeRoute from '../Home/Home.route';
import InsightsRoute from '../Insights/Insights.route';
import MySpaceRoute from '../MySpace/MySpace.route';

const Lazy = React.lazy(() => import('./ManageFlos'));
const ManageFloRoute = (
	<Route path="/" element={<Lazy />}>
		{FloRoute}
		{InsightsRoute}
		{HomeRoute}
		{MySpaceRoute}
	</Route>
);

export default ManageFloRoute;
