import { createSlice } from '@reduxjs/toolkit';
import { get, map } from 'lodash';
import moment from 'moment/moment';
import { PaginationType } from '../../Common/Common.types';
import { Pagination } from '../TeamManagementDialog/TeamManagementDialog.types';

export type InvoiceType = {
	createdDate: string;
	description: string;
	amount: number;
	currency: string;
	invoiceUrl?: string;
	receiptUrl?: string;
	planName: string;
	formattedCreatedDate?: string;
};

export type InvoicesReducerType = {
	data: InvoiceType[];
	paginationInfo: PaginationType;
};

const invoicesReducers = {
	getInvoices(
		state: InvoicesReducerType,
		{
			payload,
		}: {
			payload: {
				pageNo?: number;
			};
		}
	) {},
	getInvoicesSuccess(
		state: InvoicesReducerType,
		{
			payload,
		}: {
			payload: {
				data: InvoiceType[];
				paginationInfo: Pagination;
			};
		}
	) {
		const transformedData = map(payload.data, (item: InvoiceType) => {
			const createdDate = get(item, 'createdDate');
			return {
				...item,
				formattedCreatedDate: createdDate
					? moment(createdDate).format('MMM DD, YYYY')
					: createdDate,
			};
		});
		if (payload.paginationInfo.pageNo === 1) {
			state.data = transformedData;
		} else {
			state.data = state.data.concat(transformedData);
		}
		state.paginationInfo = payload.paginationInfo;
	},
	downloadInvoice(
		state: InvoicesReducerType,
		{
			payload,
		}: {
			payload: string;
		}
	) {},
};

const initialState = Object.freeze({
	data: [],
	paginationInfo: [],
});

const InvoicesDialogReducer = createSlice({
	name: 'invoicesReducer',
	// @ts-ignore
	reducers: invoicesReducers,
	initialState,
});

export const getInvoicesAction = InvoicesDialogReducer.actions.getInvoices;
export const getInvoicesSuccessAction = InvoicesDialogReducer.actions.getInvoicesSuccess;
export const downloadInvoiceAction = InvoicesDialogReducer.actions.downloadInvoice;

export default InvoicesDialogReducer.reducer;
