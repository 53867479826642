import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
} from '@mui/material';
import { IconPencil } from '@tabler/icons-react';
import classNames from 'classnames';
import { get, includes, isFunction, isString, map, throttle } from 'lodash';
import { useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { sanitizeHtmlLinks } from './../../Common/Common.utils';
import { translate } from './../../Common/Translate.utils';
import Button from './../Button/Button';
import styles from './Dialog.module.css';
import { DialogReducerType, hideDialogAction } from './Dialog.reducer';

const dialogSelector = (state: RootState): DialogReducerType =>
	get(state, 'dialog') as DialogReducerType;

const validProtocols = ['http', 'https', 'ftp', 'ssh', 'telnet', 'sftp', 'file', 'data'];
const pattern = new RegExp('^(' + validProtocols.join('|') + ')://');

const DialogComponent = () => {
	const {
		visible,
		portalContainer,
		buttons,
		onConfirm,
		onCancel,
		header,
		body,
		confirmButtonText,
		confirmButtonType,
		cancelButtonText,
		showConfirm,
		showCancel,
		centerAlignFooter,
		actionsFullWidth,
		headerClassName,
		dialogClassName,
		onClose,
		classes,
		variant,
		showEditIcon,
		onClick,
		onEditClick,
		blurBackdrop,
		positionOnScreen,
	} = useSelector(dialogSelector);
	const dispatch = useDispatch();

	const handleClose = useCallback(
		(eventType: string) => {
			let hideDialog = true;
			if (isFunction(onCancel)) {
				hideDialog = !onCancel(eventType);
			}
			if (hideDialog) {
				dispatch(hideDialogAction(''));
			}
		},
		[dispatch, onCancel]
	);

	const handleConfirm = useCallback(
		throttle(
			() => {
				let hideDialog = true;
				if (isFunction(onConfirm)) {
					hideDialog = !onConfirm(dispatch);
				}
				if (hideDialog) {
					dispatch(hideDialogAction(''));
				}
			},
			1000,
			{ leading: true, trailing: false }
		),
		[dispatch, onConfirm]
	);

	if (!visible) return <span />;
	const disablePortal = !!portalContainer;
	const isBodyString = isString(body || '');
	let useSmall = false;
	if (portalContainer) {
		const { width } = portalContainer.getBoundingClientRect();
		useSmall = width < 500;
	}

	const dialog = (
		<Dialog
			disablePortal={disablePortal}
			open={visible}
			disableAutoFocus
			disableEnforceFocus
			scroll={'paper'}
			classes={{
				container: classNames(styles.dialogContainer, {
					[styles.left]: includes(positionOnScreen, 'left'),
					[styles.top]: includes(positionOnScreen, 'top'),
					[styles.right]: includes(positionOnScreen, 'right'),
					[styles.bottom]: includes(positionOnScreen, 'bottom'),
				}),
				paper: classNames(styles.container, classes?.container || '', {
					[styles.small]: variant === 'small' || useSmall,
					[styles.large]: variant === 'large',
					[styles.hasEditIcon]: showEditIcon,
				}),
				root: classNames(styles.dialogRoot, dialogClassName, {
					[styles.positionRelative]: disablePortal,
					[styles.blurBackdrop]: blurBackdrop,
				}),
			}}
			onClose={() => {
				handleClose('close');
			}}
			componentsProps={{
				backdrop: {
					// @ts-ignore
					classes: {
						root: styles.positionAbsolute,
					},
				},
			}}
		>
			{showEditIcon && (
				<IconButton
					className={styles.editButton}
					aria-label="edit"
					onClick={onEditClick}
					sx={{
						position: 'absolute',
						right: -36,
						top: 0,
					}}
				>
					<IconPencil size={16}></IconPencil>
				</IconButton>
			)}

			<div className={classNames(styles.contentWrapper, classes?.contentWrapper || '')}>
				{header && (
					<DialogTitle
						id="responsive-dialog-title"
						className={classNames(styles.title, headerClassName)}
					>
						{header}
					</DialogTitle>
				)}
				<DialogContent
					className={classNames(styles.dialogContent, classes?.content || '')}
				>
					<div className={classNames(styles.dialogMessage, classes?.dialogMessage || '')}>
						{isBodyString ? (
							<div
								dangerouslySetInnerHTML={{
									__html: sanitizeHtmlLinks((body || '') as string),
								}}
							></div>
						) : (
							body
						)}
					</div>
					{get(buttons, 'length', 0) > 0 && (
						<div
							className={classNames(styles.buttonContainer, {
								[styles.noSubHeading]: !body,
							})}
						>
							{map(buttons, (buttonItem) => {
								let defaultUrl = buttonItem.url;
								const buttonType = buttonItem.type || 'skip-n-continue';
								const useAnchor = buttonType !== 'skip-n-continue';
								const internalLink = buttonType === 'publish-link';
								if (internalLink) {
									defaultUrl = get(buttonItem, 'publishedUrl') || defaultUrl;
								}
								const url = defaultUrl;
								let link = url;
								if (link === '#') {
									link = '';
								}
								if (link && !pattern.test(link)) {
									link = `//${link}`;
								}

								return (
									<Button
										key={`${buttonItem.name}_${get(buttonItem, 'id')}`}
										// @ts-ignore
										component={useAnchor ? 'a' : 'button'}
										href={link}
										autoFocus={false}
										target={'_blank'}
										disableFocusRipple
										disableElevation
										data-is-cta-button="true"
										variant="contained"
										className={styles.userActionButton}
										onClick={() => {
											switch (buttonItem?.type) {
												case 'skip-n-continue':
												default: {
													if (isFunction(onClick)) {
														onClick();
													}
													break;
												}
											}
										}}
									>
										{buttonItem.name}
									</Button>
								);
							})}
						</div>
					)}
				</DialogContent>
				{(showCancel || showConfirm) && (
					<DialogActions
						classes={{
							root: classNames(styles.actions, {
								[styles.center]: centerAlignFooter,
							}),
						}}
					>
						{showCancel && (
							<Button
								autoFocus={false}
								disableFocusRipple
								onClick={() => {
									handleClose('cancelButton');
								}}
								style={{
									width: actionsFullWidth ? (showConfirm ? '50%' : '100%') : 'auto',
								}}
								className={classNames(styles.cancelButton, get(classes, 'cancelButton'))}
							>
								<span className={styles.cancelButtonText}>
									{translate(cancelButtonText, cancelButtonText)}
								</span>
							</Button>
						)}
						{showConfirm && (
							<Button
								disableFocusRipple
								variant="contained"
								className={classNames(
									styles.confirmButton,
									get(classes, 'confirmButton'),
									{
										[styles.deleteButton]: confirmButtonType === 'delete',
									}
								)}
								style={{
									width: actionsFullWidth ? (showCancel ? '50%' : '100%') : 'auto',
								}}
								onClick={handleConfirm}
								autoFocus={false}
							>
								{translate(confirmButtonText, confirmButtonText)}
							</Button>
						)}
					</DialogActions>
				)}
			</div>
		</Dialog>
	);
	if (portalContainer) {
		return createPortal(dialog, portalContainer);
	}
	return dialog;
};

export default DialogComponent;
