import { Paper } from '@mui/material';
import { get } from 'lodash';
import qs from 'qs';
import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import AccountSettingsDialog from '../../Components/AccountSettings/AccountSettings';
import { showAccountSettingsPopup } from '../../Components/AccountSettings/AccountSettings.reducer';
import DialogComponent from '../../Components/Dialog/Dialog';
import ImageGallery from '../../Components/ImageGallery/ImageGallery';
import Loader from '../../Components/Loader/Loader';
import Notification from '../../Components/Notification/Notification';
import { setNotification } from '../../Components/Notification/Toasts.reducers';
import OnBoardingPopup from '../../Components/OnBoarding/OnBoarding.popup';
import Page400 from '../../Pages/400/400';
import Page403 from '../../Pages/403/403';
import Page404 from '../../Pages/404/404';
import Page500 from '../../Pages/500/500';
import { setSseEventSource } from '../../Pages/Flo/Flo.reducers';
import HandleInviteLink from '../../Pages/HandleInviteLink/HandleInviteLInk';
import ManageFlosRoute from '../../Pages/ManageFlos/ManageFlos.route';
import NewFloContainer from '../../Pages/NewFloContainer/NewFloContainer';
import OnboardQaRoute from '../../Pages/OnboardQa/OnboardQa.route';
import { onboardingStatusSelector } from '../../Pages/OnboardQa/OnboardQa.selector';
import { getOnBoardingFlags } from '../../store/reducers/OnBoarding.reducer';
import { getTranslations } from '../../store/reducers/Translation.reducer';
import {
	getFloSpace,
	getProfileDetailsAction,
	getProperFloUrlAction,
} from '../Routes/Routes.reducers';
import styles from './Authenticated.module.css';

const HandleShare = ({ showErrorPage }: { showErrorPage: boolean }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getProperFloUrlAction(location.href));
	}, []);
	if (showErrorPage) return <span></span>;
	return <Loader fullPage />;
};

export const ErrorPages = ({
	showErrorPage,
	errorUrl,
}: {
	showErrorPage?: boolean;
	errorUrl: string;
}) => {
	return (
		<Fragment>
			{showErrorPage && errorUrl.startsWith('/400') && <Page400 />}
			{showErrorPage && errorUrl.startsWith('/404') && <Page404 />}
			{showErrorPage && errorUrl.startsWith('/500') && <Page500 />}
			{showErrorPage && errorUrl.startsWith('/403') && <Page403 />}
		</Fragment>
	);
};

const AuthenticatedRoute = ({
	showErrorPage,
	errorUrl,
}: {
	showErrorPage: boolean;
	errorUrl: string;
}) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const notification = useSelector((state) => get(state, 'toasts'));
	const loader = useSelector((state) => get(state, 'loaders.global') > 0);
	const userId = useSelector((state) => get(state, 'routerContainer.profile.userId'));
	const embededEmailId = useSelector((state) =>
		get(state, 'routerContainer.embededProfile.emailId')
	);
	const floDetails = useSelector((state) => get(state, 'floPage.floDetails'));
	const sseEventSource = useSelector((state) => get(state, 'floPage.sseEventSource'));
	const isOnboardingDone = useSelector(onboardingStatusSelector);
	const { id: floId, floSpaceId } = floDetails;

	useEffect(() => {
		dispatch(getProfileDetailsAction(''));
		dispatch(getFloSpace(''));
		dispatch(getOnBoardingFlags(''));
		dispatch(getTranslations(''));
	}, []);
	useEffect(() => {
		const queryParam = qs.parse(location.search.replace('?', ''));
		if (queryParam?.billing === 'true') {
			dispatch(
				showAccountSettingsPopup({
					visible: true,
					defaultActiveTab: 4,
				})
			);
		}
	}, [location.search]);

	useEffect(() => {
		if (!userId || !floId || sseEventSource) return;
		// dispatch(initiateSSE({ userId }));
		return () => {
			if (sseEventSource) {
				sseEventSource.close();
				dispatch(setSseEventSource(null));
			}
		};
	}, [userId, floId]);

	const onCloseNotification = () => {
		dispatch(
			setNotification({
				type: '',
				message: '',
				canShow: false,
			})
		);
	};

	return (
		<Paper className={styles.container}>
			<Paper className={styles.routeContent}>
				<Routes>
					<Route
						path="/f/:shareId"
						element={<HandleShare showErrorPage={showErrorPage} />}
					/>
					<Route
						path="/fs/:shareId"
						element={<HandleInviteLink showErrorPage={showErrorPage} />}
					/>
					<Route path="/auth" element={<Loader fullPage />} />
					{isOnboardingDone === false ? OnboardQaRoute : ManageFlosRoute}
					<Route path="*" element={<Page404 />} />
				</Routes>
				<ErrorPages showErrorPage={showErrorPage} errorUrl={errorUrl} />
				<AccountSettingsDialog />
				<DialogComponent />
				<ImageGallery />
				<Notification
					type={notification.type}
					message={notification.message}
					canShow={notification.canShow}
					onClose={onCloseNotification}
				/>
				<NewFloContainer />
				{embededEmailId && <div id="tm_userID" data-value={embededEmailId}></div>}
				<OnBoardingPopup />
			</Paper>
			{loader && !showErrorPage && (
				<Loader fullPage wrapperClass={styles.loaderOverlay} />
			)}
		</Paper>
	);
};
export default AuthenticatedRoute;
