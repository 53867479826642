import { get } from 'lodash';
import { call, debounce, ForkEffect, put, select, takeLatest } from 'redux-saga/effects';
import { API_BASE } from '../../Common/Common.env';
import { commonErrorPopup } from '../../Common/Common.saga';
import { showAccountSettingsPopup } from '../../Components/AccountSettings/AccountSettings.reducer';
import { setErrorToast } from '../../Components/Notification/Toasts.reducers';
import {
	hideEditProfilePopup,
	setDefaultAvatarsAction,
	skipConsentAction,
	updateProfileAction,
} from '../../Components/ProfileDialog/ProfileDialog.reducer';
import { getProfileDetailsAction } from '../../Containers/Routes/Routes.reducers';
import { updateSkipConsentAction } from '../../Pages/Flo/Flo.reducers';
import API from '../../utils/API';

function* getAllAvatars() {
	try {
		// @ts-ignore
		const avatarslen = yield select((state): number =>
			get(state, 'profileDialog.defaultAvatars.data.length', 0)
		);
		if (avatarslen > 0) {
			return;
		}
		// @ts-ignore
		const response = yield call(new API().get, `${API_BASE}/v1/global/avatar`);
		yield put(
			setDefaultAvatarsAction({
				data: response.data,
				status: 'loaded',
			})
		);
	} catch (e) {
		console.warn(e);
		yield put(
			setDefaultAvatarsAction({
				data: [],
				status: 'error',
			})
		);
		yield call(commonErrorPopup);
	}
}

function* updateProfileSaga({
	payload,
}: {
	payload: {
		name?: string;
		avatarId?: string;
	};
}) {
	try {
		// @ts-ignore
		const response = yield call(
			new API().put,
			`${API_BASE}/v1/global/user-profile`,
			payload
		);
		yield put(hideEditProfilePopup({}));
		yield put(getProfileDetailsAction({ update: true }));
		yield put(updateSkipConsentAction({}));
	} catch (e) {
		console.warn(e);
		yield put(setErrorToast(e));
	}
}

function* skipConsentSaga({ payload }: { payload: string }) {
	try {
		if (!payload) {
			return;
		}
		// @ts-ignore
		const response = yield call(
			new API().put,
			`${API_BASE}/v1/flo/${payload}/user/consent?is_skipped=true`,
			{}
		);
		yield put(hideEditProfilePopup({}));
		yield put(updateSkipConsentAction({}));
	} catch (e) {
		console.warn(e);
	}
}

function* profileDialogSagas(): Generator<ForkEffect> {
	const tasks = [
		// @ts-ignore
		yield takeLatest(showAccountSettingsPopup.type, getAllAvatars),
		// @ts-ignore
		yield debounce(350, updateProfileAction.type, updateProfileSaga),
		// @ts-ignore
		yield takeLatest(skipConsentAction.type, skipConsentSaga),
	];
}

export default profileDialogSagas;
