import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Tooltip } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { IconSearch } from '@tabler/icons-react';
import classNames from 'classnames';
import { filter, find, get, map, reduce } from 'lodash';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStringWidth } from '../../Common/Common.utils';
import Translations from '../../Common/Translate.utils';
import { generateAspectRatio } from '../../utils/video.utils';
import styles from './Dropdown.module.css';
import { DropdownOptions, DropdownProps } from './Dropdown.types';

const CheckboxIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
	>
		<rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="white" stroke="#C0C0C0" />
	</svg>
);

const RadioIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
	>
		<rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="white" stroke="#C0C0C0" />
	</svg>
);

const RadioIconChecked = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
	>
		<rect width="16" height="16" rx="8" fill="#655BE1" />
		<circle cx="8" cy="8" r="3" fill="white" />
	</svg>
);

const CheckboxCheckedIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
	>
		<rect width="16" height="16" rx="4" fill="#655BE1" />
		<path
			d="M12 5L6.5 10.5L4 8"
			stroke="white"
			stroke-width="1.6666"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

const ImageForSchema = ({
	option,
	videoDims,
}: {
	option?: DropdownOptions;
	videoDims?: { width?: number; height?: number };
}) => {
	let src = '';
	if (get(option, 'constraints.iconImage') === 'based_on_video_size') {
		src = '/images/rectangle.svg';
		if (get(videoDims, 'width', 0) === get(videoDims, 'height')) {
			src = '/images/square.svg';
		} else if (get(videoDims, 'aspectRatio') === '9:16') {
			src = '/images/tiktok.svg';
		}
	} else if (get(option, 'constraints.iconImage')) {
		src = get(option, 'constraints.iconImage', '');
	}

	if (!src) return <></>;

	return (
		<img
			alt={Translations.DROPDOWN_IMAGE_ALT_TEXT}
			src={src}
			className={styles.leftIcon}
		/>
	);
};

const SELECT_ALL_VALUE = 'select_all';

function Dropdown({
	id,
	className,
	selected,
	options,
	valueKey,
	label,
	labelKey,
	onChange,
	disabled,
	classes,
	showSubText,
	placeholder,
	subTextKey = 'subText',
	checkSubTextInheritFrom,
	multiSelect,
	HeadLeftIcon,
	searchable,
	showRadioButton,
	onDropdownClose,
	dropdownWidth,
	showDialogOnChange,
	dialogProps,
	optionsLabel,
	disablePortal = true,
}: DropdownProps) {
	const [selectedOption, setSelectedOption] = React.useState(selected);
	const [filteredOptions, setFilteredOptions] = React.useState<DropdownOptions[]>([]);
	const [searchText, setSearchText] = React.useState('');
	const [isOpen, setIsOpen] = React.useState(false);
	const [selectAllChecked, setSelectAllChecked] = useState(false);
	const dispatch = useDispatch();

	const handleChange = useCallback(
		(event: SelectChangeEvent) => {
			let value = event.target.value;
			// if(multiSelect) {
			// 	const selectAllChecked = value.includes(SELECT_ALL_VALUE);
			// 	if(selectAllChecked) {
			// 		// @ts-ignore
			// 		value = options.map(option => option[valueKey || 'value']);
			// 	}
			// 	setSelectAllChecked(selectAllChecked);
			// }
			// const onConfirm = () => {
			// 	onChange?.(value);
			// 	setSelectedOption(value);
			// 	dispatch(hideDialogAction(''));
			// };
			// if (showDialogOnChange && selectedOption) {
			// 	dispatch(
			// 		showDialogAction({
			// 			...dialogProps,
			// 			showConfirm: true,
			// 			onConfirm,
			// 		})
			// 	);
			// } else {
			// 	onConfirm();
			// }
			onChange?.(value);
			// setSelectedOption(value);
		},
		[
			selectedOption,
			setSelectedOption,
			onChange,
			options,
			setSelectAllChecked,
			showDialogOnChange,
			dialogProps,
		]
	);

	useEffect(() => {
		let newOptions = options;
		if (searchText) {
			newOptions = filter(options, (optionItem) => {
				return optionItem[labelKey || 'label']?.toLowerCase()?.includes(searchText);
			});
		}
		// if(multiSelect) {
		// 	newOptions = [{ [labelKey || 'label']: 'Select All', [valueKey || 'value']: SELECT_ALL_VALUE }, ...newOptions];
		// }
		setFilteredOptions(newOptions);
	}, [options, searchText, setFilteredOptions, multiSelect]);

	const videoDims = useSelector((state) => {
		const floResources = get(state, 'floPage.tracks');
		const dims = reduce(
			floResources,
			(
				acc,
				item
			): {
				[key: string]: {
					height: number;
					width: number;
				};
			} => {
				// @ts-ignore
				acc[item.type] = {
					width: item.width,
					height: item.height,
				};
				return acc;
			},
			{}
		);
		const value = get(dims, 'screenShare') || get(dims, 'video');
		if (!value) return { aspectRatio: '' };
		// @ts-ignore
		return {
			aspectRatio: `(${generateAspectRatio(
				get(value, 'width', 0),
				get(value, 'height', 0)
			)})`,
			width: get(value, 'width', 0),
			height: get(value, 'height', 0),
		};
	});

	useEffect(() => {
		setSelectedOption(selected);
	}, [selected, setSelectedOption]);

	const onSearch = useCallback(
		(event: React.ChangeEvent) => {
			const target = event.target as HTMLInputElement;
			setSearchText(target.value);
		},
		[setSearchText]
	);

	const onOpen = useCallback(() => {
		setIsOpen(true);
	}, [setIsOpen]);

	const onClose = useCallback(() => {
		setIsOpen(false);
		setSearchText('');
		onDropdownClose?.();
	}, [setIsOpen, onDropdownClose, setSearchText]);

	const renderValueCallback = useCallback(() => {
		let headText: string | undefined = '';
		let Icon = null;
		let subText: string | undefined = '';
		let selectedOption;
		let hiddenOptionsCount = 0;
		let showEllipsisForHeadText = true;
		if (multiSelect) {
			Icon = HeadLeftIcon;
			const selectedOptions = filter(options, (option: { [key: string]: string }) =>
				selected?.includes(option[valueKey || 'value'])
			);
			headText = label;
			const dropdownSelect = document.getElementById(id);
			if (dropdownSelect && selectedOptions.length) {
				let tempHeadText = '';
				let newHeadText = '';
				let divWidth = 0;
				let newHeadTextWidth = 0;
				let availableWidth: string | number = window
					.getComputedStyle(dropdownSelect)
					.getPropertyValue('width');
				availableWidth = Number.parseInt(availableWidth) - (Icon ? 24 : 0);
				let index = 0;

				do {
					tempHeadText = `${tempHeadText ? `${tempHeadText}, ` : ''}${get(
						selectedOptions,
						`[${index}].${labelKey || 'label'}`
					)}`;
					divWidth = getStringWidth(tempHeadText);
					if (divWidth >= (availableWidth || 0)) {
						break;
					}
					newHeadTextWidth = divWidth;
					newHeadText = tempHeadText;
					index++;
				} while (index < selectedOptions.length);

				if (index === 0) {
					newHeadText = `${tempHeadText}`;
					hiddenOptionsCount = selectedOptions.length - 1;
				} else if (index < selectedOptions.length) {
					showEllipsisForHeadText = false;
					hiddenOptionsCount = selectedOptions.length - index;
					const spaceforOptionsCount = 8 * hiddenOptionsCount + 20; // 8 for each digit and 20 is padding, margin and + sign;
					if (newHeadTextWidth + spaceforOptionsCount > availableWidth && index !== 0) {
						hiddenOptionsCount += 1;
						newHeadText = map(
							selectedOptions.slice(0, index - 1),
							(item: DropdownOptions) => item[labelKey || 'label']
						).join(', ');
					}
				} else {
					showEllipsisForHeadText = false;
				}
				headText = newHeadText;
			}
		} else {
			selectedOption = find(options, [valueKey || 'value', selected]);
			subText = get(selectedOption, subTextKey);
			if (
				checkSubTextInheritFrom &&
				get(selectedOption, 'constraints.subTextInheritFrom') === 'video-aspect-ratio'
			) {
				subText = videoDims.aspectRatio;
			}
			Icon = HeadLeftIcon || selectedOption?.icon;
			headText = get(selectedOption, labelKey || 'label', '') || label;
		}

		if (Icon) {
			return (
				<span className={classNames(styles.head, classes?.head)}>
					{/*// @ts-ignore*/}
					<Icon className={classNames(styles.leftIcon, classes?.leftIcon)} />
					<span
						title={headText}
						className={classNames({ [styles.headText]: showEllipsisForHeadText })}
					>
						{hiddenOptionsCount ? (
							<div className={styles.overflownHeadTextWrapper}>
								<div className={styles.overflownHeadText}>{headText}</div>
								<div className={styles.hiddenOptionsCount}>+{hiddenOptionsCount}</div>
							</div>
						) : (
							headText
						)}
					</span>
					{showSubText && <span className={styles.subText}> {subText}</span>}
				</span>
			);
		}
		return (
			<span>
				<ImageForSchema option={selectedOption} videoDims={videoDims} />
				<span
					title={headText}
					className={classNames(classes?.headText, {
						[styles.headText]: showEllipsisForHeadText,
					})}
				>
					{hiddenOptionsCount ? (
						<div className={styles.overflownHeadTextWrapper}>
							<div className={styles.overflownHeadText}>{headText}</div>
							<div className={styles.hiddenOptionsCount}>{hiddenOptionsCount}</div>
						</div>
					) : (
						headText
					)}
				</span>
				{showSubText && <span className={styles.subText}> {subText}</span>}
			</span>
		);
	}, [selected, options, label, labelKey, checkSubTextInheritFrom]);
	return (
		<FormControl
			className={classNames(styles.formControl, className)}
			sx={{ m: 1, minWidth: 120 }}
			size="small"
			style={{
				...(dropdownWidth ? { width: dropdownWidth } : {}),
			}}
		>
			{searchable && isOpen && (
				<div className={styles.searchInputWrapper}>
					<IconSearch className={styles.searchIcon} size={16} />
					<input
						placeholder={'Search...'}
						className={styles.searchInput}
						onChange={onSearch}
						value={searchText}
					/>
				</div>
			)}
			<Select
				IconComponent={KeyboardArrowDownIcon}
				notched={false}
				renderValue={renderValueCallback}
				variant="outlined"
				disabled={disabled}
				labelId={id}
				id={id}
				placeholder={placeholder}
				displayEmpty
				multiple={multiSelect}
				onOpen={onOpen}
				onClose={onClose}
				// @ts-ignore
				value={selectedOption}
				label={label}
				onChange={handleChange}
				className={classNames(styles.container, classes?.container, {
					[classes?.openDropdownContainer || '']: isOpen,
				})}
				classes={{
					select: classNames(styles.select, classes?.select),
					icon: classNames(styles.icon, classes?.icon),
				}}
				MenuProps={{
					disablePortal,
					classes: {
						root: classNames(styles.menuRoot, classes?.menuRoot),
						paper: classNames(styles.menuContainer, classes?.menuContainer, {
							[styles.menuContainerWithSearch]: searchable,
						}),
					},
				}}
			>
				{optionsLabel && (
					<MenuItem
						value={optionsLabel}
						className={classNames(
							styles.menuItem,
							classes?.menuItem,
							styles.optionsLabel
						)}
					>
						{optionsLabel}
					</MenuItem>
				)}
				{map(filteredOptions, (option, index: number) => {
					const value = get(option, valueKey || 'key');
					const label = get(option, labelKey || 'key');
					const Icon = get(option, 'icon');
					let subText = get(option, subTextKey, '');
					if (
						checkSubTextInheritFrom &&
						get(option, 'constraints.subTextInheritFrom') === 'video-aspect-ratio'
					) {
						subText = videoDims.aspectRatio;
					}

					return (
						<MenuItem
							className={classNames(styles.menuItem, classes?.menuItem)}
							classes={{
								selected: classNames(styles.selected, classes?.selectedMenuItem),
							}}
							key={value}
							value={value}
							disabled={get(option, 'disabled')}
						>
							<Tooltip
								title={get(option, 'tooltipText')}
								arrow
								classes={{ popper: styles.popper }}
							>
								<>
									<ImageForSchema option={option} videoDims={videoDims} />
									{multiSelect && (
										<Checkbox
											icon={<CheckboxIcon />}
											checkedIcon={<CheckboxCheckedIcon />}
											className={classNames(styles.checkbox, classes?.checkbox)}
											checked={selectedOption?.includes(value)}
										/>
									)}
									{showRadioButton && (
										<Checkbox
											icon={<RadioIcon />}
											checkedIcon={<RadioIconChecked />}
											className={classNames(styles.checkbox, classes?.checkbox)}
											checked={selectedOption === value}
										/>
									)}
									{/*// @ts-ignore*/}
									{Icon && (
										<Tooltip
											title={get(option, 'tooltipText')}
											arrow
											placement="right"
											classes={{ popper: styles.popper }}
										>
											<Icon className={classNames(styles.leftIcon, classes?.leftIcon)} />
										</Tooltip>
									)}
									<div
										className={classNames(styles.menuItemLabel, classes?.menuItemLabel)}
										title={label}
									>
										{label}
									</div>
									{showSubText && <span className={styles.subText}> {subText}</span>}
								</>
							</Tooltip>
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
}

Dropdown.defaultProps = {
	valueKey: 'value',
	labelKey: 'label',
	label: Translations.defaultDropdownSelectText,
	options: [],
};

export default Dropdown;
