import React from 'react';
import { Route } from 'react-router-dom';

export const INSIGHTS_LISTING_PAGE_PATH = '/insights';
export const INSIGHTS_LISTING_PAGE_CRUMBTEXT = 'Flo Insights';
export const INSIGHTS_DETAILS_PAGE_PATH = '/insights/details';

const Lazy = React.lazy(() => import('./Insights'));
const LazyInsightsListingPage = React.lazy(
	() => import('./pages/InsightsListingPage/InsightsListingPage')
);
const LazyInsightsDetailsPage = React.lazy(
	() => import('./pages/InsightsDetails/InsightsDetails')
);

const InsightsRoute = (
	<Route path="/insights" element={<Lazy />}>
		<Route
			path={INSIGHTS_LISTING_PAGE_PATH}
			index
			element={<LazyInsightsListingPage />}
		/>
		<Route path={INSIGHTS_DETAILS_PAGE_PATH} element={<LazyInsightsDetailsPage />} />
	</Route>
);

export default InsightsRoute;
