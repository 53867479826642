import { Snackbar } from '@mui/material';
import classNames from 'classnames';

import { ReactComponent as ErrorSvg } from '../../Common/images/error-circle.svg';
import { ReactComponent as InfoSg } from '../../Common/images/info-circle.svg';
import { ReactComponent as SuccessSvg } from '../../Common/images/success-circle.svg';
import { translate } from '../../Common/Translate.utils';
import styles from './Notification.module.css';
import { NotificationType } from './Notification.types';

const Notification = (props: NotificationType) => {
	const { type, message, onClose, canShow, vertical, horizontal, autoHideDuration } =
		props;
	const isError = type === 'error';
	const isInfo = type === 'info';
	const isWarning = type === 'warning';
	let src = <SuccessSvg className={styles.notificationIcon} />;

	if (isError) src = <ErrorSvg className={styles.notificationIcon} />;
	if (isInfo) src = <InfoSg className={styles.notificationIcon} />;
	if (isWarning)
		src = <InfoSg className={classNames(styles.notificationIcon, styles.warning)} />;

	// if (!canShow) return <span />;
	return (
		<Snackbar
			key={message}
			anchorOrigin={{
				vertical: vertical || 'top',
				horizontal: horizontal || 'center',
			}}
			classes={{
				root: styles.notificationRoot,
			}}
			open={canShow}
			autoHideDuration={3000}
			onClose={onClose}
			TransitionProps={{ onExited: onClose }}
		>
			<div className={styles.notificationContainer}>
				{src}
				<span>{translate(message, message)}</span>
			</div>
		</Snackbar>
	);
};

export default Notification;
