import { createSlice } from '@reduxjs/toolkit';
import { get, set } from 'lodash';
import { ReactNode } from 'react';
import uniqId from 'uniqid';
import { FloPagination } from '../../Pages/ManageFlos/ManageFlos.types';

export type GalleryImgType = {
	cloudFrontUrl: string;
	id: string;
	extension?: string;
	name: string;
	uploadStatus?: string;
	meta?: {
		width: number;
		height: number;
	};
};

export interface ImageGalleryReducerType {
	config: {
		visible: boolean;
		header: ReactNode;
		id?: string;
		confirmButtonText: string;
		dialogClassName?: string;
		variant?: string;
		confirmButtonType?: string;
		cancelButtonText: string;
		onCancel?(): boolean | void;
		onConfirm?(val: GalleryImgType): boolean | void;
		headerClassName?: string;
		classes?: {
			confirmButton?: string;
			cancelButton?: string;
			imagesList?: string;
			container?: string;
		};
		subHeading: string;
		recommendedResolution?: string;
		uploadButtonText: string;
		minDimensions?: {
			width: number;
			height: number;
		};
	};
	imagesList: GalleryImgType[];
	pagination: FloPagination;
	errors: { [key: string]: boolean };
}

const initialState = Object.freeze({
	imagesList: [],
	config: {
		visible: false,
		header: 'Select image',
		id: '',
		onCancel: undefined,
		onConfirm: undefined,
		subHeading: 'GALLERY_SUBHEADING',
		cancelButtonText: 'GALLERY_CANCEL_BUTTON_TEXT',
		confirmButtonText: 'GALLERY_CONFIRM_BUTTON_TEXT',
		uploadButtonText: 'GALLERY_UPLOAD_BUTTON_TEXT',
	},
	pagination: {
		hasMore: false,
		pageNo: 1,
		pageSize: 30,
		totalRecords: 0,
	},
	errors: {},
});

const imageGalleryReducers = {
	showImageGallery(
		state: ImageGalleryReducerType,
		{ payload }: { payload: Partial<ImageGalleryReducerType> }
	) {
		return {
			...initialState,
			// subHeading: Translations.GALLERY_SUBHEADING,
			// cancelButtonText: Translations.GALLERY_CANCEL_BUTTON_TEXT,
			// confirmButtonText: Translations.GALLERY_CONFIRM_BUTTON_TEXT,
			// uploadButtonText: Translations.GALLERY_UPLOAD_BUTTON_TEXT,
			config: {
				...initialState.config,
				...payload,
				visible: true,
			},
		};
	},
	hideImageGallery(state: ImageGalleryReducerType) {
		return {
			...initialState,
			// subHeading: Translations.GALLERY_SUBHEADING,
			// cancelButtonText: Translations.GALLERY_CANCEL_BUTTON_TEXT,
			// confirmButtonText: Translations.GALLERY_CONFIRM_BUTTON_TEXT,
			// uploadButtonText: Translations.GALLERY_UPLOAD_BUTTON_TEXT,
		};
	},
	uploadGalleryImage(state: ImageGalleryReducerType, { payload }: { payload: any }) {
		return set(state, 'imagesList', [
			{ uploadStatus: 'IN_PROGRESS', id: uniqId() },
			...get(state, 'imagesList'),
		]);
	},
	uploadGalleryImageError(state: ImageGalleryReducerType) {
		return set(state, 'imagesList', get(state, 'imagesList').slice(1));
	},
	uploadGalleryImageSuccess(
		state: ImageGalleryReducerType,
		{ payload }: { payload: GalleryImgType }
	): ImageGalleryReducerType {
		state = set(state, 'imagesList', [payload, ...get(state, 'imagesList').slice(1)]);
		state = set(
			state,
			'pagination.totalRecords',
			get(state, 'pagination.totalRecords') + 1
		);
		return state;
	},
	getGalleryImages() {},
	getGalleryImagesSuccess(
		state: ImageGalleryReducerType,
		{
			payload,
		}: { payload: { data: GalleryImgType[]; pageNo?: number; pagination: FloPagination } }
	): ImageGalleryReducerType {
		state = set(state, 'pagination', payload.pagination);
		state = set(state, 'errors.getGalleryImages', false);
		if (payload.pageNo === 1) {
			return set(state, 'imagesList', payload.data);
		}
		return set(state, 'imagesList', [...get(state, 'imagesList'), ...payload.data]);
	},
	getGalleryImagesError(
		state: ImageGalleryReducerType,
		{ payload }: { payload: { pageNo?: number } }
	): ImageGalleryReducerType {
		if (payload.pageNo === 1) {
			state = set(state, 'errors.getGalleryImages', true);
		}
		return state;
	},
};

export const imageGalleryReducer = createSlice({
	name: 'imageGallery',
	initialState,
	// @ts-ignore
	reducers: imageGalleryReducers,
});

export default imageGalleryReducer.reducer;

export const showImageGalleryAction = imageGalleryReducer.actions.showImageGallery;
export const hideImageGalleryAction = imageGalleryReducer.actions.hideImageGallery;
export const uploadGalleryImageAction = imageGalleryReducer.actions.uploadGalleryImage;
export const uploadGalleryImageErrorAction =
	imageGalleryReducer.actions.uploadGalleryImageError;
export const uploadGalleryImageSuccessAction =
	imageGalleryReducer.actions.uploadGalleryImageSuccess;
export const getGalleryImagesSuccessAction =
	imageGalleryReducer.actions.getGalleryImagesSuccess;
export const getGalleryImagesErrorAction =
	imageGalleryReducer.actions.getGalleryImagesError;
export const getGalleryImagesAction = imageGalleryReducer.actions.getGalleryImages;
