import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { get, startsWith, toUpper } from 'lodash';
import { useSelector } from 'react-redux';
import { errorPageSelector } from '../../Common/Common.selector';
import Translations from '../../Common/Translate.utils';
import styles from './400.module.css';

const Page400 = () => {
	//@ts-ignore
	const { error, errorHeader }: { error: string; errorHeader: string } =
		useSelector(errorPageSelector);
	const [page, setPage] = useState('flo');
	useEffect(() => {
		const inFloPage =
			startsWith(location.pathname, '/flo/') || startsWith(location.pathname, '/f/');
		if (inFloPage) setPage('flo');
		const inFloSpacePage =
			startsWith(location.pathname, '/workspace/') ||
			startsWith(location.pathname, '/fs/');
		if (inFloSpacePage) setPage('workspace');
		const inInsightsPage = startsWith(location.pathname, '/insights');
		if (inInsightsPage) setPage('insights');
	}, [setPage]);
	const PAGE_400_CONTENT_TITLE =
		get(Translations, `PAGE_400_CONTENT_TITLE_${toUpper(page)}`) ||
		Translations.PAGE_400_CONTENT_TITLE;
	const PAGE_400_REDIRECT_BTN_TEXT =
		get(Translations, `PAGE_400_REDIRECT_BTN_TEXT_${toUpper(page)}`) ||
		Translations.PAGE_400_REDIRECT_BTN_TEXT;
	return (
		<Grid container spacing={2} className={styles.container}>
			<Grid item laptop={8} mobile={12} className={styles.contentContainer}>
				<p
					className={styles.contentTitle}
					dangerouslySetInnerHTML={{
						__html: errorHeader || error || PAGE_400_CONTENT_TITLE,
					}}
				/>
				<Link to="/flos" className={styles.goToInboxBtn}>
					{PAGE_400_REDIRECT_BTN_TEXT}
				</Link>
			</Grid>
		</Grid>
	);
};

export default Page400;
