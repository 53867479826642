import { Box, Link, Popover, TextField } from '@mui/material';
import classNames from 'classnames';
import { get, map, throttle } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserNameMaxLength } from '../../Common/Common.params';
import { currentProfileSelector } from '../../Common/Common.selector';
import Translations from '../../Common/Translate.utils';
import { RootState } from '../../store';
import Avatar from '../Avatar/Avatar';
import styles from './ProfileDialog.module.css';
import {
	AvatarListItem,
	ProfileDialogReducerType,
	updateProfileAction,
} from './ProfileDialog.reducer';

const dialogSelector = (state: RootState): ProfileDialogReducerType =>
	get(state, 'profileDialog') as ProfileDialogReducerType;

const ProfileDialog = () => {
	const {
		PROFILE_POPUP_AVATAR_TITLE_TEXT,
		PROFILE_POPUP_NAME_TITLE_TEXT,
		PROFILE_POPUP_SAVE_BUTTON_TEXT,
		PROFILE_POPUP_TITLE_TEXT,
		PROFILE_POPUP_INPUT_BOX_PLACEHOLDER_TEXT,
		PROFILE_USER_ROLE_TITLE,
	} = Translations;
	const flospaceInfo = useSelector((state) =>
		get(state, 'routerContainer.currentFloSpace')
	);
	const { defaultAvatars } = useSelector(dialogSelector);
	const avatarList = defaultAvatars.data;
	const avatarListStatus = defaultAvatars.status;
	const profile = useSelector(currentProfileSelector);
	const dispatch = useDispatch();
	const [userName, setUserName] = useState(profile.name);
	const [userProfilePic, setUserProfilePic] = useState<AvatarListItem | undefined>();

	const [showProfileOptions, setShowProfileOptions] = useState(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>();
	const handleOnChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setUserName(event.target.value.replace(/\n/g, '').trim());
		},
		[setUserName]
	);
	useEffect(() => {
		setUserName(profile.name);
	}, [profile.name]);

	const handleConfirm = useCallback(
		throttle(
			(e: React.MouseEvent | React.KeyboardEvent) => {
				e.preventDefault();
				if (userName.length)
					dispatch(
						updateProfileAction({
							name: userName,
							avatarId: userProfilePic?.id,
						})
					);
			},
			1000,
			{ leading: true, trailing: false }
		),
		[userName, userProfilePic]
	);

	const onProfileClickHandler = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			setAnchorEl(event.currentTarget);
			setShowProfileOptions((i) => !i);
		},
		[setAnchorEl, userProfilePic, userName, profile]
	);

	const onCloseProfileSelector = useCallback(() => {
		setAnchorEl(undefined);
		setShowProfileOptions(false);
	}, [setAnchorEl]);

	const onNameChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			const val = (get(event, 'target.value') || '').replace(/\n/g, '').trim();
			setUserName(val);
			if (val !== profile.name)
				dispatch(
					updateProfileAction({
						name: val || profile.name,
						avatarId: userProfilePic?.id,
					})
				);
		},
		[setUserName, profile, userProfilePic]
	);

	return (
		<Box>
			<Box id="responsive-dialog-title" className={styles.title}>
				{PROFILE_POPUP_TITLE_TEXT}
			</Box>
			<Box className={styles.content}>
				<Box sx={{ display: 'flex', mb: 3 }}>
					<Avatar
						onClick={onProfileClickHandler}
						className={styles.avatar}
						classes={{
							profilePicContainer: styles.profilePicContainer,
						}}
						profilePicUrl={
							userProfilePic ? userProfilePic.avatarFileUrl : profile?.profilePicUrl
						}
						avatarColor={profile?.avatarColor}
					/>
					<Link
						component="button"
						sx={{ ml: 1, h: '30px', mt: '4px' }}
						variant="body1"
						underline="none"
						className={styles.editButton}
						onClick={onProfileClickHandler}
					>
						Edit
					</Link>
				</Box>
				<Box className={styles.nameInputContainer}>
					<Box className={styles.nameTitle}>{PROFILE_POPUP_NAME_TITLE_TEXT}</Box>
					<Box className={styles.nameInput}>
						<TextField
							placeholder={PROFILE_POPUP_INPUT_BOX_PLACEHOLDER_TEXT}
							classes={{
								root: styles.input,
							}}
							inputProps={{
								maxLength: UserNameMaxLength,
							}}
							hiddenLabel
							defaultValue={userName}
							fullWidth
							onChange={onNameChange}
							id="filled-hidden-label-small"
							variant="standard"
							size="small"
						/>
					</Box>
				</Box>
				<Box className={styles.nameInputContainer}>
					<Box className={styles.nameTitle}>Email</Box>
					<Box
						classes={{
							root: styles.contentSubHeader,
						}}
					>
						{get(profile, 'is_anonymous') ? '' : get(profile, 'email')}
					</Box>
				</Box>
				<Box className={styles.nameInputContainer}>
					<Box className={styles.nameTitle}>{PROFILE_USER_ROLE_TITLE}</Box>
					<Box className={styles.text}>{get(flospaceInfo, 'userRole.label')}</Box>
				</Box>
				{showProfileOptions && (
					<Popover
						anchorEl={anchorEl}
						classes={{
							paper: styles.popover,
							root: styles.picChooserRoot,
						}}
						anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
						open={Boolean(anchorEl)}
						onClose={onCloseProfileSelector}
					>
						<div className={styles.avatarHeader}>{PROFILE_POPUP_AVATAR_TITLE_TEXT}</div>
						{avatarListStatus === 'error' && <div className="error"></div>}
						{avatarListStatus === 'loaded' && (
							<ul className={styles.menuList}>
								{map(avatarList, (avatar) => (
									<Avatar
										key={avatar.id}
										title={avatar.name}
										name={avatar.name}
										onClick={() => {
											setUserProfilePic(avatar);
											onCloseProfileSelector();
											if (avatar?.id && avatar?.id !== profile?.profilePicUrl)
												dispatch(
													updateProfileAction({
														name: userName || profile.name,
														avatarId: avatar.id,
													})
												);
										}}
										className={classNames(styles.avatarPopup, {
											[styles.active]: avatar.id === userProfilePic?.id,
										})}
										profilePicUrl={avatar.avatarFileUrl}
										avatarColor={profile?.avatarColor}
									/>
								))}
							</ul>
						)}
					</Popover>
				)}
			</Box>
		</Box>
	);
};

export default ProfileDialog;
