import { createSlice } from '@reduxjs/toolkit';
import { find, map, reject } from 'lodash';
import { FloCreationTemplates } from '../../Common/Common.types';
import { userDataType } from '../ManageFlos/ManageFlos.types';
import {
	HomeReducerType,
	RecentFloDetailType,
	RecentPublishedFloDetailType,
} from './Home.types';

const initialState = Object.freeze({
	initialSteps: [],
	templates: [],
	recentFlos: [],
	recentPublishedFlos: [],
});

const homeReducerObject = {
	getTemplates(state: HomeReducerType, { payload }: { payload: unknown }) {},
	setTemplates(state: HomeReducerType, { payload }: { payload: FloCreationTemplates[] }) {
		state.templates = payload;
	},
	getRecentFlos(
		state: HomeReducerType,
		{
			payload,
		}: {
			payload: {
				floSpaceId: string;
			};
		}
	) {},
	setRecentFlos(
		state: HomeReducerType,
		{
			payload,
		}: {
			payload: {
				floMetaData: RecentFloDetailType[];
				userData: userDataType[];
			};
		}
	) {
		const { floMetaData, userData } = payload || {};
		const newGloMetaData = map(floMetaData, (item) => {
			const { createdBy } = item;
			const user = find(userData, { userId: String(createdBy) });
			return {
				...item,
				createdBy: user,
			};
		});

		// @ts-ignore
		state.recentFlos = newGloMetaData;
	},
	deleteRecentFlo(
		state: HomeReducerType,
		{
			payload,
		}: {
			payload: {
				floId: string;
				folderId: string;
			};
		}
	) {
		state.recentFlos = reject(state.recentFlos, ['id', payload.floId]);
	},
	updatedRecentFlos(
		state: HomeReducerType,
		{
			payload,
		}: {
			payload: {
				id: string;
				name: string;
			};
		}
	) {
		state.recentFlos = map(state.recentFlos, (flo) =>
			flo.id === payload.id
				? {
						...flo,
						name: payload.name,
				  }
				: flo
		);
	},
	getRecentPublishedFlos(
		state: HomeReducerType,
		{
			payload,
		}: {
			payload: {
				floSpaceId: string;
			};
		}
	) {},
	setRecentPublishedFlos: function (
		state: HomeReducerType,
		{
			payload,
		}: {
			payload: {
				publishedFloMetaData: RecentPublishedFloDetailType[];
				userData: userDataType[];
			};
		}
	) {
		const { publishedFloMetaData, userData } = payload;
		const newGloMetaData = map(publishedFloMetaData, (item) => {
			if (item.outputType === 'interactive demo') {
				item.publishedUrl += '?skip-video=true';
			}

			const { publishedBy } = item;
			const user = find(userData, { userId: String(publishedBy) });
			return {
				...item,
				createdBy: user,
			};
		});

		// @ts-ignore
		state.recentPublishedFlos = newGloMetaData;
	},
	leaveHomePage(state: HomeReducerType) {},
};

export const homePage = createSlice({
	name: 'home',
	initialState,
	reducers: homeReducerObject,
});

export default homePage.reducer;
export const getTemplates = homePage.actions.getTemplates;
export const setTemplates = homePage.actions.setTemplates;
export const getRecentFlos = homePage.actions.getRecentFlos;
export const setRecentFlos = homePage.actions.setRecentFlos;
export const deleteRecentFlo = homePage.actions.deleteRecentFlo;
export const updatedRecentFlos = homePage.actions.updatedRecentFlos;
export const getRecentPublishedFlos = homePage.actions.getRecentPublishedFlos;
export const setRecentPublishedFlos = homePage.actions.setRecentPublishedFlos;
export const leaveHomePage = homePage.actions.leaveHomePage;
