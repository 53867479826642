import { SagaIterator } from 'redux-saga';
import { call, ForkEffect, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { API_BASE } from '../../Common/Common.env';
import { leaveRoutesAction } from '../../Containers/Routes/Routes.reducers';
import store from '../../store';
import {
	clearOnboarding,
	getOnBoardingFlags,
	hideOnboarding,
	setOnBoardingFlags,
} from '../../store/reducers/OnBoarding.reducer';
import API from '../../utils/API';
import { CancelSagas } from '../../utils/saga.utils';
import { hideLoader, showLoader } from '../../store/reducers/Loader.reducer';

export function* getOnBoardingFlagsSaga({
	payload,
}: {
	payload: { id?: string };
}): SagaIterator {
	try {
		yield put(showLoader('onboarding'));
		const response: { data: { [key: string]: boolean } } = yield call(
			new API().get,
			`${API_BASE}/v1/global/user/onboarding`
		);
		yield put(setOnBoardingFlags(response.data));
	} catch (e) {
		console.warn(e);
	} finally {
		yield put(hideLoader('onboarding'));
	}
}

export function* hideOnboardingSaga({
	payload,
}: {
	payload: { id?: string };
}): SagaIterator {
	try {
		const { id } = payload;
		if (id) {
			yield call(new API().put, `${API_BASE}/v1/global/user/onboarding?featureId=${id}`, {
				id,
			});
		}
	} catch (e) {
		console.warn(e);
	}
}

export function* clearOnboardingSaga(): SagaIterator {
	try {
		yield call(new API().delete, `${API_BASE}/v1/global/user/onboarding`);
	} catch (e) {
		console.warn(e);
	}
}

export function* onBoardingSagas(): Generator<ForkEffect> {
	const tasks = [
		// @ts-ignore
		yield takeEvery(hideOnboarding.type, hideOnboardingSaga),
		// @ts-ignore
		yield takeLatest(getOnBoardingFlags.type, getOnBoardingFlagsSaga),
		// @ts-ignore
		yield takeLatest(clearOnboarding.type, clearOnboardingSaga),
	];
	// @ts-ignore
	yield takeLatest(leaveRoutesAction.type, CancelSagas, tasks);
}

export function runOnboardingSaga() {
	// @ts-ignore
	store.runSaga(onBoardingSagas);
}
