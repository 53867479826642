import { get, reduce } from 'lodash';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API_BASE } from '../../Common/Common.env';
import API from '../../utils/API';
import { getTranslations, setTranslations } from '../reducers/Translation.reducer';

function* getTranslationsSaga(): SagaIterator {
	try {
		const response: {
			data: Array<{
				key: string;
				message: string;
			}>;
		} = yield call(
			new API().get,
			`${API_BASE}/v1/global/message?messageType=CLIENT&pageNumber=1&pageSize=-1`
		);
		yield put(
			setTranslations(
				reduce(
					get(response, 'data', []),
					(
						acc: {
							[key: string]: string;
						},
						item: {
							key: string;
							message: string;
						}
					) => {
						acc[item.key] = item.message;
						return acc;
					},
					{}
				)
			)
		);
	} catch (e) {
		console.warn(e);
	}
}

export default function* translationRootSagas() {
	const tasks = [
		// @ts-ignore
		yield takeLatest(getTranslations.type, getTranslationsSaga),
	];
}
