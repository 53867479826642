import { createSlice } from '@reduxjs/toolkit';
import uniqId from 'uniqid';
import { RoutesReducerState } from '../../Containers/Routes/Routes.types';
import { BrandingDialogState } from './BrandingDialog.types';

const initialState = Object.freeze({
	data: {
		uuid: uniqId(),
		imageUUid: uniqId(),
		customWatermarkConfiguration: {
			customWatermarkFilePath: '',
			customWatermarkDisabled: true,
			defaultWatermarkEnabled: false,
		},
		brandColor: '',
		watermarkPosition: 'bottom-right',
	},
	status: 'loading',
});

const brandingDialogReducers = {
	getBrandingDetails(
		state: RoutesReducerState,
		{ payload }: { payload: { update: boolean } }
	) {},
	updateBrandingDetails(
		state: BrandingDialogState,
		{ payload }: { payload: BrandingDialogState }
	) {
		state.data = {
			...initialState.data,
			...payload.data,
			imageUUid: uniqId(),
		};
		state.status = payload.status || 'loaded';
	},
	updateBrandingColor(
		state: BrandingDialogState,
		{ payload }: { payload: { brandColor: string } }
	) {
		state.data.brandColor = payload.brandColor;
	},
	togglePoweredByFloik(
		state: BrandingDialogState,
		{ payload }: { payload: { hidePoweredBy: boolean } }
	) {},
	toggleShowLogoInHeading(
		state: BrandingDialogState,
		{ payload }: { payload: { hidePoweredBy: boolean } }
	) {},
	toggleShowLogoAsWatermark(
		state: BrandingDialogState,
		{ payload }: { payload: { hidePoweredBy: boolean } }
	) {},
	togglePoweredByFloikSuccess(
		state: BrandingDialogState,
		{ payload }: { payload: BrandingDialogState }
	) {
		state.data = {
			...payload.data,
			imageUUid: uniqId(),
		};
	},
	updateBrandingPosition(
		state: BrandingDialogState,
		{ payload }: { payload: { brandPosition: string; floType: string } }
	) {},
	updateBrandingLogo(
		state: BrandingDialogState,
		{ payload }: { payload: { file: BlobPart } }
	) {},
	removeBrandingLogoAction(
		state: BrandingDialogState,
		{ payload }: { payload: { file: BlobPart } }
	) {},
	removeBrandingLogoSuccessAction(state: BrandingDialogState) {
		state.data.customWatermarkConfiguration.customWatermarkFilePath = '';
		state.data.customWatermarkConfiguration.customWatermarkDisabled = true;
		state.data.customWatermarkConfiguration.defaultWatermarkEnabled = true;
	},
};

export const brandingDialogReducer = createSlice({
	name: 'brandingDialogReducer',
	initialState,
	// @ts-ignore
	reducers: brandingDialogReducers,
});

export default brandingDialogReducer.reducer;

export const getBrandingDetailsAction = brandingDialogReducer.actions.getBrandingDetails;
export const setBrandingDetails = brandingDialogReducer.actions.updateBrandingDetails;
export const setBrandingColorAction = brandingDialogReducer.actions.updateBrandingColor;
export const togglePoweredByFloikAction =
	brandingDialogReducer.actions.togglePoweredByFloik;
export const toggleShowLogoInHeadingAction =
	brandingDialogReducer.actions.toggleShowLogoInHeading;
export const toggleShowLogoAsWatermarkAction =
	brandingDialogReducer.actions.toggleShowLogoAsWatermark;

export const togglePoweredByFloikActionSuccess =
	brandingDialogReducer.actions.togglePoweredByFloikSuccess;

export const setBrandingPositionAction =
	brandingDialogReducer.actions.updateBrandingPosition;
export const setBrandingLogoAction = brandingDialogReducer.actions.updateBrandingLogo;
export const removeBrandingLogoAction =
	brandingDialogReducer.actions.removeBrandingLogoAction;
export const removeBrandingLogoSuccessAction =
	brandingDialogReducer.actions.removeBrandingLogoSuccessAction;
