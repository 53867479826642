import { Dispatch } from '@reduxjs/toolkit';
import { get, map } from 'lodash';
import { SagaIterator } from 'redux-saga';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { API_BASE } from '../../Common/Common.env';
import { stringToHslColor } from '../../Common/Common.utils';
import Translations from '../../Common/Translate.utils';
import { showDialogAction } from '../../Components/Dialog/Dialog.reducer';
import { setErrorToast } from '../../Components/Notification/Toasts.reducers';
import {
	deleteUserAction,
	deleteUserConfirmAction,
	deleteUserSuccessAction,
	getTeamUsersListAction,
	getTeamUsersListActionSuccess,
	getUserRolesAction,
	getUserRolesSuccessAction,
	inviteUserAction,
	inviteUserSuccessAction,
	updateUserRoleAction,
	updateUserRoleSuccessAction,
} from '../../Components/TeamManagementDialog/TeamManagementDialog.reducer';
import { UserType } from '../../Components/TeamManagementDialog/TeamManagementDialog.types';
import API from '../../utils/API';
import { hideLoader, showLoader } from '../reducers/Loader.reducer';

const userItemTransformer = (data: UserType[]) =>
	map(data, (item) => ({
		...item,
		userIconBgColor: get(item, 'userBean.email')
			? stringToHslColor(get(item, 'userBean.email'), 80, 90)
			: '',
	}));

function* getUserRolesSaga(): SagaIterator {
	try {
		yield put(showLoader('teamManagement'));
		const response = yield call(
			new API().get,
			`${API_BASE}/v1/global/permission/roles`,
			{}
		);
		yield put(getUserRolesSuccessAction(response));
	} catch (e) {
		console.warn(e);
		yield put(setErrorToast(e));
	} finally {
		yield put(hideLoader('teamManagement'));
	}
}

function* updateUserRoleSaga({
	payload,
}: {
	payload: { userId: string; roleId: string };
}): SagaIterator {
	try {
		const response = yield call(
			new API().put,
			`${API_BASE}/v1/flospace/user/${payload.userId}/role/${payload.roleId}`,
			{}
		);
		yield put(updateUserRoleSuccessAction(payload));
	} catch (e) {
		console.warn(e);
		yield put(setErrorToast(e));
	}
}

function* getTeamUsersListSaga({
	payload,
}: {
	payload: { pageNo?: number };
}): SagaIterator {
	try {
		yield put(showLoader('teamManagement'));
		const floSpaceId = yield select((state) =>
			get(state, 'routerContainer.currentFloSpace.id')
		);
		if (!floSpaceId) return;
		const response = yield call(
			new API().get,
			`${API_BASE}/v1/flospace/${floSpaceId}/user?pageNumber=${
				payload.pageNo || 1
			}&pageSize=50`,
			{}
		);
		const usersList = userItemTransformer(response.data);
		yield put(getTeamUsersListActionSuccess({ ...response, data: usersList }));
	} catch (e) {
		console.warn(e);
		yield put(setErrorToast(e));
	} finally {
		yield put(hideLoader('teamManagement'));
	}
}

function* deleteUserSaga({
	payload,
}: {
	payload: { user: UserType; isCancel: boolean };
}): SagaIterator {
	try {
		yield put(
			showDialogAction({
				header: payload.isCancel
					? Translations.CANCEL_USER_INVITE_CONFIRM_TITLE
					: Translations.DELETE_USER_CONFIRM_TITLE,
				body: payload.isCancel
					? Translations.CANCEL_USER_INVITE_CONFIRM_TEXT
					: Translations.DELETE_USER_CONFIRM_TEXT,
				showCancel: false,
				showConfirm: true,
				variant: 'small',
				confirmButtonType: 'delete',
				confirmButtonText: payload.isCancel
					? Translations.CANCEL_USER_INVITE_CONFIRM_BUTTON_TEXT
					: Translations.DELETE_USER_CONFIRM_BUTTON_TEXT,
				onConfirm: (dispatch: Dispatch) => {
					dispatch(deleteUserConfirmAction(payload));
				},
			})
		);
	} catch (e) {
		console.warn(e);
		yield put(
			setErrorToast({
				type: 'error',
				status: get(e, 'response.status'),
				message: `Failed to delete user '${get(payload, 'user.userBean.name')}'`,
				canShow: true,
			})
		);
	}
}

function* deleteUserConfirmSaga({
	payload,
}: {
	payload: { user: UserType; isCancel: boolean };
}): SagaIterator {
	try {
		const floSpaceId = yield select((state) =>
			get(state, 'routerContainer.currentFloSpace.id')
		);
		if (!floSpaceId) return;
		const response = yield call(
			new API().delete,
			`${API_BASE}/v1/flospace/${floSpaceId}/user/${payload.user.id}`,
			{}
		);
		yield put(deleteUserSuccessAction(payload));
	} catch (e) {
		console.warn(e);
		yield put(
			setErrorToast(e)
		);
	}
}

function* inviteUserSaga({
	payload,
}: {
	payload: { email: string; isNew: boolean; successCb: Function; roleId: string };
}): SagaIterator {
	try {
		yield put(showLoader('user_invite_' + get(payload, 'email')));
		const floSpaceId = yield select((state) =>
			get(state, 'routerContainer.currentFloSpace.id')
		);
		if (!floSpaceId) return;
		const response = yield call(
			new API().post,
			`${API_BASE}/v1/flospace/${floSpaceId}/user`,
			{ roleId: payload.roleId, emailIds: [payload.email] }
		);
		if (payload.isNew) {
			const usersList = userItemTransformer(response.data);
			yield put(
				inviteUserSuccessAction({ data: usersList, email: get(payload, 'email') })
			);
			if (payload.successCb) {
				payload.successCb(response);
			}
			yield put(hideLoader('user_invite_' + get(payload, 'email')));
		}
	} catch (e) {
		console.warn(e);
		yield put(setErrorToast(e));
	} finally {
		yield delay(3000);
		yield put(hideLoader('user_invite_' + get(payload, 'email')));
	}
}

function* TeamManagementDialog() {
	const tasks = [
		// @ts-ignore
		yield takeLatest(getTeamUsersListAction.type, getTeamUsersListSaga),
		// @ts-ignore
		yield takeLatest(inviteUserAction.type, inviteUserSaga),
		// @ts-ignore
		yield takeLatest(deleteUserAction.type, deleteUserSaga),
		// @ts-ignore
		yield takeLatest(deleteUserConfirmAction.type, deleteUserConfirmSaga),
		// @ts-ignore
		yield takeLatest(getUserRolesAction.type, getUserRolesSaga),
		// @ts-ignore
		yield takeLatest(updateUserRoleAction.type, updateUserRoleSaga),
	];
}

export default TeamManagementDialog;
