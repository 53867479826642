import { Grid } from '@mui/material';
import { get } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Components/Loader/Loader';
import { acceptInvite } from '../../Containers/Routes/Routes.reducers';
import Translations from './../../Common/Translate.utils';
import styles from './HandleInviteLink.module.css';

const HandleInviteLink = ({ showErrorPage }: { showErrorPage: boolean }) => {
	const dispatch = useDispatch();
	const invitation = useSelector((state) => get(state, 'routerContainer.invitation'));
	useEffect(() => {
		dispatch(acceptInvite(location.href));
	}, []);
	if (showErrorPage) return <span></span>;
	if (get(invitation, 'status') === 'success') {
		return (
			<Grid container spacing={2} className={styles.container}>
				<Grid item laptop={8} mobile={12} className={styles.contentContainer}>
					<p className={styles.contentTitle}>{get(invitation, 'message')}</p>
					<a href="/flos" className={styles.goToInboxBtn}>
						{Translations.INVITE_SUCCESS_PAGE_GOTO_FLOS}
					</a>
				</Grid>
			</Grid>
		);
	}

	if (get(invitation, 'status') === 'error') {
		return (
			<Grid container spacing={2} className={styles.container}>
				<Grid item laptop={8} mobile={12} className={styles.contentContainer}>
					<p className={styles.contentTitle}>{get(invitation, 'message')}</p>
				</Grid>
			</Grid>
		);
	}
	return <Loader fullPage />;
};

export default HandleInviteLink;
