import { Box, Button } from '@mui/material';
import { IconMicrophone, IconMicrophoneOff } from '@tabler/icons-react';
import classNames from 'classnames';
import { get } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import Translations from '../../../Common/Translate.utils';
import Dropdown from '../../../Components/Dropdown/Dropdown';
import { ReactComponent as DeniedCamera } from '../images/DeniedCamera.svg';
import { ReactComponent as RecordSvg } from '../images/Record.svg';
import styles from '../NewFloContainer.module.css';
import { AvailableDevices } from '../NewFloContainer.types';

const PermissionComponent = ({
	showVideoOptions,
	availableDevices,
	showAudioOptions,
	audioDeviceChange,
	videoDeviceChange,
	videoDevice,
	audioDevice,
	devicePermissions,
	disableAll,
	isMicOptional,
}: {
	showVideoOptions: boolean;
	showAudioOptions: boolean;
	disableAll?: boolean;
	availableDevices: AvailableDevices;
	videoDeviceChange(deviceId?: string): void;
	audioDeviceChange(deviceId?: string): void;
	audioDevice?: string;
	videoDevice?: string;
	devicePermissions: {
		[key: string]: string;
	};
	isMicOptional: boolean;
}) => {
	const { NEW_FLO_NO_AUDIO_DROPDOWN_LABEL } = Translations;
	const [audioDevicesList, setAudioDevicesList] = useState<
		{ label: string; deviceId: string }[]
	>([]);

	useEffect(() => {
		let newAudioDevices = get(availableDevices, 'devicesAudio');
		if (isMicOptional) {
			const noAudioOption = {
				label: NEW_FLO_NO_AUDIO_DROPDOWN_LABEL,
				deviceId: 'no_audio',
			} as MediaDeviceInfo;
			newAudioDevices = [...(newAudioDevices || []), noAudioOption];
		}
		setAudioDevicesList(newAudioDevices);
	}, [availableDevices.devicesAudio, NEW_FLO_NO_AUDIO_DROPDOWN_LABEL, isMicOptional]);

	const onMicButtonClick = useCallback(() => {
		audioDeviceChange(audioDevice === 'no_audio' ? 'default' : 'no_audio');
	}, [audioDeviceChange, audioDevice]);

	return (
		<div className={styles.deviceSelection}>
			{showVideoOptions && get(devicePermissions, 'video_capture') !== 'denied' && (
				<div
					className={classNames(styles.deviceSelectorRow, {
						[styles.disabledDropdown]: disableAll,
					})}
				>
					<span className={styles.deviceIconWrapper}>
						<RecordSvg className={styles.deviceIcon} />
					</span>
					<Dropdown
						id={'select_camera'}
						label="Select Camera"
						selected={videoDevice}
						labelKey="label"
						valueKey="deviceId"
						disabled={disableAll}
						onChange={videoDeviceChange}
						// @ts-ignore
						options={availableDevices.devicesVideo}
						className={styles.deviceSelectionDropdown}
					/>
				</div>
			)}
			{/*{JSON.stringify(devicePermissions)}*/}
			{get(devicePermissions, 'video_capture') === 'denied' && (
				<Box className={styles.deniedPermissionContainer}>
					<div className={styles.deniedPermissionTitleContainer}>
						<DeniedCamera className={styles.deniedIcon} />
						<span className={styles.deniedPermissionTitle}>
							Grant access to your camera
						</span>
					</div>
					<div
						className={styles.deniedPermissionImage}
						style={{
							backgroundImage: `url(/images/BrowserCamPerm.png)`,
						}}
					></div>
					<div className={styles.deniedPermissionDescription}>
						If you do not see the camera icon in the address bar, you may need to check
						your browser settings or preferences.
						<br />
						In most browsers, you can find the camera permissions settings under the
						"Privacy and Security" or "Site Settings" section.
					</div>
				</Box>
			)}
			{showAudioOptions && (
				<div
					className={classNames(styles.deviceSelectorRow, {
						[styles.disabledDropdown]: disableAll || audioDevicesList.length === 1,
					})}
				>
					<Button
						className={classNames(styles.deviceIconWrapper, {
							[styles.disabled]: audioDevice === 'no_audio',
							[styles.noEvents]: !isMicOptional,
						})}
						onClick={onMicButtonClick}
					>
						{!audioDevice || audioDevice === 'no_audio' ? (
							<IconMicrophoneOff size={20} className={styles.deviceIcon} />
						) : (
							<IconMicrophone size={20} className={styles.deviceIcon} />
						)}
					</Button>
					<Dropdown
						id={'select_microphone'}
						label="Select Microphone"
						selected={audioDevice || (isMicOptional ? 'no_audio' : undefined)}
						labelKey="label"
						valueKey="deviceId"
						disabled={disableAll}
						onChange={audioDeviceChange}
						// @ts-ignore
						options={audioDevicesList}
						className={styles.deviceSelectionDropdown}
					/>
				</div>
			)}
		</div>
	);
};

export default PermissionComponent;
