import {
	IconAntennaBars2,
	IconAntennaBars3,
	IconAntennaBars5,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { get } from 'lodash';
import styles from '../NewFloContainer.module.css';

const networkTextMap = {
	fair: 'Fair connection',
	weak: 'Weak connection',
	vweak: 'Very weak connection',
};

const NetworkIconMap = {
	fair: <IconAntennaBars5 size={16} />,
	weak: <IconAntennaBars3 size={16} />,
	vweak: <IconAntennaBars2 size={16} />,
};

const NetworkSpeed = ({ networkSpeed }: { networkSpeed: string }) => {
	if (!networkSpeed) return <></>;
	const NWIcon = get(NetworkIconMap, networkSpeed);
	return (
		<div className={classNames(styles.networkSpeed, styles[networkSpeed])}>
			<span>{NWIcon}</span>
			{get(networkTextMap, networkSpeed)}
		</div>
	);
};

export default NetworkSpeed;
