import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { ReactNode } from 'react';

export interface DialogReducerType {
	visible: boolean;
	showEditIcon?: boolean;
	header: ReactNode;
	body: ReactNode;
	id?: string;
	confirmButtonText: string;
	dialogClassName?: string;
	variant?: string;
	confirmButtonType?: string;
	cancelButtonText: string;
	showConfirm: boolean;
	sending: boolean;
	centerAlignFooter: boolean;
	portalContainer?: HTMLElement | null;
	buttons: {
		name?: string;
		selectedFloId?: string;
		selectedFloName?: string;
		publishedUrl?: string;
		url?: string;
		type?: string;
	}[];
	actionsFullWidth: boolean;
	collabAdding: boolean;
	showCancel: boolean;
	onCancel?(eventType: string): boolean | void;
	onClick?(): void;
	onClose?(): void;
	onEditClick?(): void;
	onConfirm?(dispatch: Dispatch): boolean | void;
	headerClassName?: string;
	classes?: {
		confirmButton?: string;
		cancelButton?: string;
		dialogMessage?: string;
		container?: string;
		contentWrapper?: string;
		content?: string;
	};
	blurBackdrop: boolean;
}

const initialState = Object.freeze({
	visible: false,
	header: 'header',
	body: 'body',
	showConfirm: false,
	portalContainer: null,
	id: '',
	buttons: [],
	showCancel: false,
	sending: false,
	centerAlignFooter: false,
	collabAdding: false,
	onCancel: undefined,
	onConfirm: undefined,
	cancelButtonText: 'Cancel',
	confirmButtonText: 'Confirm',
	actionsFullWidth: false,
	blurBackdrop: false,
});

const dialogReducers = {
	showDialog(
		state: DialogReducerType,
		{ payload }: { payload: Partial<DialogReducerType | any> }
	) {
		return {
			...initialState,
			...payload,
			visible: true,
		};
	},
	hideDialog(state: DialogReducerType, { payload }: { payload: string }) {
		if (payload && state.id !== payload) {
			return state;
		}
		return initialState;
	},
	setDialogState(state: DialogReducerType, { payload }: { payload: boolean }) {
		state.sending = payload;
	},
	setDialogStateForCollaborator(
		state: DialogReducerType,
		{ payload }: { payload: boolean }
	) {
		state.collabAdding = payload;
	},
};

export const dialogReducer = createSlice({
	name: 'dialogReducer',
	initialState,
	// @ts-ignore
	reducers: dialogReducers,
});

export default dialogReducer.reducer;

export const showDialogAction = dialogReducer.actions.showDialog;
export const hideDialogAction = dialogReducer.actions.hideDialog;
export const setDialogStateAction = dialogReducer.actions.setDialogState;
export const setDialogStateActionForCollaborator =
	dialogReducer.actions.setDialogStateForCollaborator;
