import { Player } from '@lottiefiles/react-lottie-player';
import { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import infinity from '../../animation/infinity.json';
import styles from './Loader.module.css';

const Loader = ({
	className,
	wrapperClass,
	height = '100px',
	width = '100px',
	fullPage = false,
	nodelay = false,
	wrapperWidth,
	wrapperHeight,
}: {
	className?: string;
	wrapperClass?: string;
	height?: string;
	width?: string;
	fullPage?: boolean;
	nodelay?: boolean;
	wrapperWidth?: number | string;
	wrapperHeight?: number | string;
}) => {
	const [showLoader, setShowLoader] = useState(false);
	const [mounted, setMounted] = useState(true);
	const timeoutCallback = useCallback(() => {
		setShowLoader(mounted);
	}, [showLoader, mounted, setShowLoader]);
	useEffect(() => {
		let timeout: string | number | NodeJS.Timeout | undefined;
		if (nodelay) {
			timeoutCallback();
		} else {
			timeout = setTimeout(timeoutCallback, 3000);
		}
		return () => {
			if (timeout) clearTimeout(timeout);
			setMounted(false);
		};
	}, [setShowLoader, nodelay]);
	if (!showLoader) return <></>;
	const content = (
		<Player
			data-testid="player"
			autoplay
			loop
			src={infinity}
			style={{ height, width }}
			className={classNames(
				{ [styles.infinity]: !fullPage },
				{ [styles.fullPageInfinity]: fullPage },
				className
			)}
		/>
	);
	let actualContent = content;
	if (fullPage)
		actualContent = (
			<div data-testid="fullPage" className={styles.loaderOverlay}>
				<div className={styles.loaderContainer}>{content}</div>
			</div>
		);

	if (wrapperClass)
		return (
			<div
				data-testid="wrapper"
				style={{
					width: wrapperWidth || 'auto',
					height: wrapperHeight || 'auto',
				}}
				className={wrapperClass}
			>
				{actualContent}
			</div>
		);
	return actualContent;
};

export default Loader;
