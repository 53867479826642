import { throttle } from 'lodash';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import '../../styles.css';
import Routes from '../Routes/Routes';

const MobileBanner = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const [height, setHeight] = useState(window.innerHeight);

	const resizeHandler = useCallback(() => {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
	}, [setWidth, setHeight]);

	useEffect(() => {
		const throttledHandler = throttle(resizeHandler, 300, {
			trailing: true,
		});
		window.addEventListener('resize', throttledHandler);
		resizeHandler();
		return () => {
			window.removeEventListener('resize', throttledHandler);
		};
	}, [resizeHandler]);
	return (
		<div className="mobileBanner">
			<p>
				Oops! It seems like your window size is too small ({width}px,{height}px) to
				display Floik App properly.
			</p>
			<p>
				Please ensure your window has a minimum width of 767 pixels and a minimum height
				of 577 pixels to load Floik Application.
			</p>
			<p>
				Tip: You can increase your screen resolution in your OS settings or make the
				window larger for an optimal experience.
			</p>
		</div>
	);
};

export default function App() {
	return (
		<div className="floik-container">
			<Router>
				<Suspense>
					<Routes key="routes" />
				</Suspense>
			</Router>
			<MobileBanner />
			<div id="portal-root"></div>
		</div>
	);
}
