import { createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { WorkspaceMetaData } from '../../Common/Common.types';
import { DialogReducerType } from '../../Components/Dialog/Dialog.reducer';
import {
	DiamondFeatureType,
	FloSpaceItem,
	getFloSpaceType,
	Profile,
	RoutesReducerState,
} from './Routes.types';

const initialState = Object.freeze({
	isLoading: false,
	isAuthenticated: false,
	anonymous: false,
	floikCode: '',
	theme: 'light',
	authToken: '',
	refreshToken: '',
	currentFloSpace: null,
	profile: null,
	embededProfile: null,
	brandImage: '',
	folders: {},
	invitation: {
		message: '',
		status: '',
	},
	swVersion: 0,
	diamondFeaturesMap: {},
});

const routesReducers = {
	getProfileDetails(
		state: RoutesReducerState,
		{ payload }: { payload: { update: boolean } }
	) {},
	setProfileDetails(state: RoutesReducerState, { payload }: { payload: Profile }) {
		state.profile = payload;
	},
	setEmbededProfileDetails(state: RoutesReducerState, { payload }: { payload: Profile }) {
		state.embededProfile = payload;
	},
	getFloSpace(state: RoutesReducerState, { payload }: { payload: getFloSpaceType }) {},
	getAuth(state: RoutesReducerState) {
		state.isLoading = true;
	},
	logoutAllDevices(state: RoutesReducerState) {
		state.isLoading = true;
	},
	setAnonymous(state: RoutesReducerState, { payload }: { payload: boolean }) {
		state.anonymous = payload;
	},
	setAuth(state: RoutesReducerState, { payload }: { payload: boolean }) {
		state.isLoading = false;
		state.isAuthenticated = payload;
	},
	setCurrentFloSpace(state: RoutesReducerState, { payload }: { payload: FloSpaceItem }) {
		state.currentFloSpace = payload;
	},
	onAuthSuccess(
		state: RoutesReducerState,
		{
			payload,
		}: {
			payload: Omit<RoutesReducerState, 'isLoading' | 'isAuthenticated'>;
		}
	) {
		state.isLoading = false;
		state.isAuthenticated = true;
		state.userName = payload.userName;
		state.userId = payload.userId;
		state.authToken = payload.authToken;
		state.refreshToken = payload.refreshToken;
	},
	checkIsLoggedIn(state: RoutesReducerState) {
		// return state;
	},
	leaveRoutes(state: RoutesReducerState) {
		// return initialState;
	},
	createAnonymousUser(state: RoutesReducerState) {
		state.isLoading = true;
	},
	logout(state: RoutesReducerState) {},
	refreshToken(state: RoutesReducerState) {},
	getProperFloUrl(state: RoutesReducerState, { payload }: { payload: string }) {},
	updateTheme(state: RoutesReducerState, { payload }: { payload: string }) {},
	updateThemeSuccess(state: RoutesReducerState, { payload }: { payload: string }) {
		state.theme = payload;
	},
	setBrandImage(
		state: RoutesReducerState,
		{
			payload,
		}: {
			payload: {
				url: string;
				position: string;
			};
		}
	) {
		state.brandImage = payload.url;
		state.brandPosition = payload.position;
	},
	setWorkspaces(
		state: RoutesReducerState,
		{
			payload,
		}: {
			payload: WorkspaceMetaData[];
		}
	) {
		state.workspaces = payload;
	},
	acceptInvite() {},
	setInvitationStatus(
		state: RoutesReducerState,
		{
			payload,
		}: {
			payload: {
				message: string;
				status: 'success' | 'error';
			};
		}
	) {
		state.invitation = payload;
	},
	setSwVersion(
		state: RoutesReducerState,
		{
			payload,
		}: {
			payload: number;
		}
	) {
		state.swVersion = payload;
	},
	getDiamondFeatureInfo(
		state: RoutesReducerState,
		{
			payload,
		}: {
			payload: { floSpaceId: string };
		}
	) {},
	showDiamondFeaturePopup(
		state: RoutesReducerState,
		{
			payload,
		}: {
			payload: {
				featureInfo: DiamondFeatureType;
				dialogParams?: Partial<DialogReducerType>;
				buttonClicked?: string;
			};
		}
	) {},
	setDiamondFeatureInfo(
		state: RoutesReducerState,
		{
			payload,
		}: {
			payload: { [key: string]: DiamondFeatureType };
		}
	) {
		state = set(state, 'diamondFeaturesMap', payload);
	},
};

export const routesContainer = createSlice({
	name: 'routesContainer',
	initialState,
	// @ts-ignore
	reducers: routesReducers,
});

export const getFloSpace = routesContainer.actions.getFloSpace;
export const getAuthAction = routesContainer.actions.getAuth;
export const logoutAllDevices = routesContainer.actions.logoutAllDevices;
export const getProfileDetailsAction = routesContainer.actions.getProfileDetails;
export const setProfileDetailsAction = routesContainer.actions.setProfileDetails;
export const setEmbededProfileDetailsAction =
	routesContainer.actions.setEmbededProfileDetails;
export const setAuthAction = routesContainer.actions.setAuth;
export const logoutAction = routesContainer.actions.logout;
export const setCurrentFloSpaceAction = routesContainer.actions.setCurrentFloSpace;
export const setWorkspaces = routesContainer.actions.setWorkspaces;
export const checkIsLoggedInAction = routesContainer.actions.checkIsLoggedIn;
export const onAuthSuccessAction = routesContainer.actions.onAuthSuccess;
export const createAnonymousUserAction = routesContainer.actions.createAnonymousUser;
export const setAnonymousAction = routesContainer.actions.setAnonymous;
export const leaveRoutesAction = routesContainer.actions.leaveRoutes;
export const getProperFloUrlAction = routesContainer.actions.getProperFloUrl;
export const acceptInvite = routesContainer.actions.acceptInvite;
export const refreshTokenAction = routesContainer.actions.refreshToken;
export const updateThemeAction = routesContainer.actions.updateTheme;
export const updateThemeSuccessAction = routesContainer.actions.updateThemeSuccess;
export const setBrandImage = routesContainer.actions.setBrandImage;
export const setInvitationStatus = routesContainer.actions.setInvitationStatus;
export const getDiamondFeatureInfo = routesContainer.actions.getDiamondFeatureInfo;
export const setDiamondFeatureInfo = routesContainer.actions.setDiamondFeatureInfo;
export const showDiamondFeaturePopup = routesContainer.actions.showDiamondFeaturePopup;

export const setSwVersion = routesContainer.actions.setSwVersion;
export default routesContainer.reducer;
