import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { errorPageSelector } from '../../Common/Common.selector';
import { translate } from '../../Common/Translate.utils';
import styles from './404.module.css';
import { ReactComponent as NotFoundSvg } from './images/404.svg';

const Page404 = () => {
	//@ts-ignore
	const { error, errorHeader }: { error: string; errorHeader: string } =
		useSelector(errorPageSelector);

	return (
		<Grid container spacing={2} className={styles.container}>
			<Grid item mobile={6} className={styles.imageContainer}>
				<NotFoundSvg className={styles.image} />
			</Grid>
			<Grid item laptop={5} mobile={6} className={styles.contentContainer}>
				<p
					className={styles.contentTitle}
					dangerouslySetInnerHTML={{
						__html: errorHeader || translate('page404ContentTitle'),
					}}
				/>
				<p
					className={styles.contentText}
					dangerouslySetInnerHTML={{
						__html: error || translate('page404ContentText'),
					}}
				/>
				<Link to="/flos" className={styles.homeButton}>
					{translate('page404HomeButton')}
				</Link>
			</Grid>
		</Grid>
	);
};

export default Page404;
