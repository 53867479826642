import { SagaIterator } from 'redux-saga';
import { call, takeEvery } from 'redux-saga/effects';
import { API_BASE } from '../../Common/Common.env';
import API from '../../utils/API';
import { analyticsLog } from '../reducers/Log.reducer';

function* analyticsLogSaga({
	payload,
}: {
	payload: {
		level?: string;
		message?: string;
		value?: unknown;
		eventType?: string;
	};
}): SagaIterator {
	try {
		yield call(new API().post, `${API_BASE}/v1/global/client/log`, {
			eventType: payload.eventType || 'app',
			level: payload.level || 'info',
			timeStamp: new Date(),
			message: payload.message + ` ${JSON.stringify(payload.value || '')}`,
			payload: payload.value,
		});
		if (payload.message === 'cache-stats') {
			if ('serviceWorker' in navigator) {
				// @ts-ignore
				navigator?.serviceWorker?.controller.postMessage({
					type: 'clearCacheStats',
				});
			}
		}
	} catch (e) {
		console.error(e);
	}
}

export default function* AnalyticsLogSagas() {
	const tasks = [
		// @ts-ignore
		yield takeEvery(analyticsLog.type, analyticsLogSaga),
	];
}
