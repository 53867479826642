import {
	debounce,
	find,
	forEach,
	get,
	includes,
	isUndefined,
	map,
	max,
	omit,
	pick,
	reduce,
	set,
	throttle,
} from 'lodash';
import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { analyticsLog } from '../../../store/reducers/Log.reducer';

import { Dispatch } from '@reduxjs/toolkit';
import Cookie from 'js-cookie';
import moment from 'moment';
import { EXTENSION_SAVE_TOKEN } from '../../../Common/Common.constants';
import { POSTPEND_TERM } from '../../../Common/Common.env';
import {
	ExtensionVersionSelector,
	isExtensionInstalledSelector,
} from '../../../Common/Common.selector';
import { FloCreationTemplates } from '../../../Common/Common.types';
import Translations, { translate } from '../../../Common/Translate.utils';
import {
	hideDialogAction,
	showDialogAction,
} from '../../../Components/Dialog/Dialog.reducer';
import { setErrorToast } from '../../../Components/Notification/Toasts.reducers';
import { setExtensionVersion } from '../../../store/reducers/OnBoarding.reducer';
import {
	checkExtensionUninstalled,
	ExtensionListener,
	SendMessageToExtension,
} from '../../../utils/SendMessageToExtension';
import { calculateAspectRatioFit } from '../../../utils/video.utils';
import { getDuration } from '../components/RecordingScreen';
import {
	createNewFloAction,
	getCountDownTrackAction,
	getTemplateCategories,
	leaveNewFlosPageAction,
	newFloGetTemplates,
	onScreenShareEndedAction,
	resetStateAction,
	saveFloAction,
	sendAnnotationComment,
	sendChunkAction,
	sendMouseEvent,
	sendMouseEventScreenshot,
	setCanShowNewFlo,
	setExtensionRecording,
	setFloStateAction,
	setHasFailedAttempt,
	setNewFloDuration,
	setNewFloStreamsAction,
	setSelectedTemplate,
	stopNewFloStreamsAction,
} from '../NewFlo.reducers';
import runRootSaga from '../NewFlo.sagas';
import {
	canDeleteEditFlo,
	countdownTrackSelector,
	formattedTimeRestrictionSelector,
	hasFailedAttemptMessageSelector,
	hasFailedAttemptSelector,
	isExtensionErrored,
	isOnlineSelector,
	newFloIdSelector,
	newFloNameSelector,
	recordStateSelector,
	stopSoundTrackSelector,
} from '../NewFloContainer.selector';
import { AvailableDevices, optionKeys, RecorderOptions } from '../NewFloContainer.types';
import {
	getExtensionVersion,
	pauseRecordingExtension,
	resetStateExtension,
	resumeRecordingExtension,
	setCaptureTab,
	showCountDown,
	startRecordingExtension,
	takeMeBacktoTab,
	useCurrrentTabAsBase,
} from '../NewFloExtension.actions';
import {
	changeCountdownScreenState,
	changeCountdownScreenValueState,
	changeCountdownShowingState,
	discardConfirmRecordingApp,
	discardRecordingApp,
	extensionUninstalling,
	getToken,
	multipleRecordingAppError,
	pauseRecordingApp,
	resumeRecordingApp,
	sendCommentAnnotationApp,
	setExtensionVersionAction,
	setSelectedTemplateApp,
	showAppPopupAction,
	startRecordingApp,
	stopRecordingApp,
} from '../NewFloExtensionToApp.actions';
import {
	baseReadyAction,
	getDevicesExtension,
	prepareForRecording,
	setDevicesExtension,
	settingAsBaseTab,
} from '../NewFloExtensionToExtension.actions';
import styles from './../NewFloContainer.module.css';
import useStartNewfloEffect from './NewFloStartEffect.hook';

function usePrevious<T>(value: T): T | null {
	const ref = useRef<T>(null);
	useEffect(() => {
		// @ts-ignore
		ref.current = value;
	}, [ref, value]);
	return ref.current;
}

const defaultAudio = new Audio(location.origin + '/count-down-start.mp3');
defaultAudio.muted = false;

const defaultStopAudio = new Audio(location.origin + '/end-tone.mp4');
defaultStopAudio.muted = false;

const getVideoFromExtension = () =>
	document
		.querySelector(`#floik-annotations${POSTPEND_TERM}`)
		?.shadowRoot?.querySelector(`#floik-video${POSTPEND_TERM}`) as HTMLVideoElement;

export async function invokeScreenShare(
	selectedOption: RecorderOptions,
	devices: {
		videoDevice?: string;
		audioDevice?: string;
	}
) {
	const constraints = {
		video: {
			logicalSurface: true,
			frameRate: {
				// min: 24,
				ideal: 24,
				max: 25,
			},
			resizeMode: 'none',
			aspectRatio: {
				ideal: 16 / 9,
			},
		},
		audio: false,
	};
	return await navigator.mediaDevices.getDisplayMedia(constraints).then((stream) => {
		const videoTrack = stream.getVideoTracks()[0];
		const settings = videoTrack.getSettings();
		const screenType = get(settings, 'displaySurface');
		SendMessageToExtension(setCaptureTab(''));
		// @ts-ignore
		videoTrack.oncapturehandlechange = (event) => {
			// if (screenType === 'browser') {
			SendMessageToExtension(setCaptureTab(''));
			// }
		};
		if (screenType === 'browser') {
			SendMessageToExtension(setCaptureTab(''));
		}

		return stream;
	});
}

export async function invokeCamera(
	selectedOption: RecorderOptions,
	devices: {
		videoDevice?: string;
		audioDevice?: string;
	}
) {
	const useMic = includes(
		[optionKeys.camera, optionKeys.screen, optionKeys.screenCamMic],
		selectedOption
	);
	return await navigator.mediaDevices
		.getUserMedia({
			video: {
				deviceId: devices.videoDevice,
			},
			audio: useMic
				? {
						channelCount: 2,
						deviceId: devices.audioDevice,
						echoCancellation: true,
						noiseSuppression: true,
				  }
				: false,
		})
		.then((stream) => {
			return stream;
		});
}

export async function invokeAudio(
	selectedOption: RecorderOptions,
	devices: {
		videoDevice?: string;
		audioDevice?: string;
	}
) {
	return await navigator.mediaDevices
		.getUserMedia({
			video: false,
			audio: {
				channelCount: 2,
				deviceId: devices.audioDevice,
				echoCancellation: true,
				noiseSuppression: true,
			},
		})
		.then((stream) => {
			return stream;
		});
}

async function getDevices(
	target: RecorderOptions,
	onPermissionChange: (change: PermissionStatus) => void,
	inputDevices?: {
		[key: string]: string | undefined;
	}
): Promise<AvailableDevices> {
	const useCamera = includes(
		[
			optionKeys.cameraOnly,
			optionKeys.camera,
			optionKeys.screenCam,
			optionKeys.screenCamMic,
		],
		target
	);
	const useMic = includes(
		[optionKeys.camera, optionKeys.screen, optionKeys.screenCamMic],
		target
	);
	const permissions = [];

	if (useMic) {
		permissions.push(
			navigator.permissions.query({
				// @ts-ignore
				name: 'microphone',
			})
		);
	}
	if (useCamera) {
		permissions.push(
			navigator.permissions.query({
				// @ts-ignore
				name: 'camera',
			})
		);
	}
	let perms = {};
	if (permissions?.length) {
		perms = await Promise.all(permissions).then(([result, result2]) => {
			const values = {
				[get(result, 'name')]: result.state,
				...(get(result2, 'name')
					? { [get(result2, 'name')]: get(result2, 'state') }
					: {}),
			};
			if (result.state === 'prompt' || get(result2, 'state') === 'prompt') {
				SendMessageToExtension({
					type: 'take_me_to_base',
				});
				result.addEventListener('change', (res) => {
					// @ts-ignore
					if (
						result.state === 'granted' &&
						(useCamera ? get(result2, 'state') === 'granted' : true)
					) {
						SendMessageToExtension(takeMeBacktoTab({}));
					}
					// @ts-ignore
					perms[get(result, 'name', '')] = result.state;
				});
				if (result2)
					result2.addEventListener('change', (res) => {
						// @ts-ignore
						if (get(result2, 'state') === 'granted' && result.state === 'granted') {
							SendMessageToExtension(takeMeBacktoTab({}));
						}
						// @ts-ignore
						perms[get(result2, 'name', '')] = get(result2, 'state');
					});
			}
			return values;
		});
	}
	try {
		if (useCamera || useMic) {
			const streams = await navigator.mediaDevices.getUserMedia({
				video:
					useCamera && get(inputDevices, 'videoDevice')
						? { deviceId: inputDevices?.videoDevice }
						: useCamera,
				audio:
					useMic && get(inputDevices, 'audioDevice')
						? { deviceId: inputDevices?.audioDevice }
						: useMic,
			});
			const devices = await navigator.mediaDevices.enumerateDevices();
			const allDevices = devices.reduce(
				(scope: AvailableDevices, device: MediaDeviceInfo) => {
					if (device.deviceId && device.kind == 'audioinput') {
						// @ts-ignore
						scope.devicesAudio.push(device);
					} else if (device.deviceId && device.kind == 'videoinput') {
						// @ts-ignore
						scope.devicesVideo.push(device);
					}
					return scope;
				},
				{
					perms,
					devicesAudio: [],
					devicesVideo: [],
					previewStreams: streams,
					inputDevices,
				}
			);
			return allDevices;
		}
		return {
			perms,
			devicesAudio: [],
			devicesVideo: [],
			previewStreams: undefined,
			inputDevices,
		};
	} catch (e) {
		return {
			perms,
			devicesAudio: [],
			devicesVideo: [],
			previewStreams: undefined,
			inputDevices,
		};
	}
}

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const typesInSafari = ['video/mp4;codecs:h264'];

const typesInChrome = ['video/webm;codecs=vp9,opus', 'video/webm;codecs=vp8,opus'];

const getSupportedMimeType = () => {
	let types = typesInChrome;
	if (isSafari) {
		types = typesInSafari;
	}
	for (let i of types) {
		if (MediaRecorder.isTypeSupported(i)) return i;
	}
	return 'video/webm';
};

const MediaRecorderOptions = {
	mimeType: getSupportedMimeType(),
	frameRate: 24,
};

async function invokeMedia(
	selectedOption: RecorderOptions,
	devices: {
		videoDevice?: string;
		audioDevice?: string;
	}
) {
	const selectedMedia = [];
	if (
		[
			optionKeys.screenOnly,
			optionKeys.screen,
			optionKeys.screenCam,
			optionKeys.screenCamMic,
		].includes(selectedOption)
	) {
		selectedMedia.push(invokeScreenShare(selectedOption, devices));
		if (optionKeys.screen === selectedOption && devices.audioDevice !== 'no_audio')
			selectedMedia.push(invokeAudio(selectedOption, devices));
	}
	if (
		[
			optionKeys.cameraOnly,
			optionKeys.camera,
			optionKeys.screenCam,
			optionKeys.screenCamMic,
		].includes(selectedOption)
	) {
		selectedMedia.push(invokeCamera(selectedOption, devices));
	}
	const streams = await Promise.all(selectedMedia);
	return { streams, selectedOption };
}

let videoEnd = false;
let screenEnd = false;

const recordChunkInterval = 10000;

let chunksVideo: BlobPart[] = [];
let chunksVideoSize = 0;
let chunksShare: BlobPart[] = [];
let chunksShareSize = 0;
let elapsedStartTime: moment.Moment | undefined | null = undefined;
let trueStartTime: moment.Moment | undefined | null = undefined;
let elapsedEndTime: moment.Moment | undefined | null = undefined;
let elapsedDuration = 0;
let newfloPausedDuration = 0;

const useNewFloEffect = ({ activeTemplateFilter }: { activeTemplateFilter?: string }) => {
	const {
		MULTIPLE_FLO_RECORDING_TXT,
		MULTIPLE_FLO_RECORDING_CONFIRM_BTN_TXT,
		MULTIPLE_FLO_RECORDING_CANCEL_BTN_TXT,
	} = Translations;
	const recordState = useSelector(recordStateSelector);
	const countdownTrack = useSelector(countdownTrackSelector);
	const stopSoundTrack = useSelector(stopSoundTrackSelector);
	const prevRecordState = usePrevious(recordState);
	const formatMaxTime = useSelector(formattedTimeRestrictionSelector);

	const NEW_FLO_PERMISSIONS_POP_UP_SUB_TITLE_TEXT = translate(
		'NEW_FLO_PERMISSIONS_POP_UP_SUB_TITLE_TEXT'
	);
	const NEW_FLO_PERMISSIONS_POP_UP_TITLE_TEXT = translate(
		'NEW_FLO_PERMISSIONS_POP_UP_TITLE_TEXT'
	);
	const NEW_FLO_DISCARD_POP_UP_TITLE_TEXT = translate(
		'NEW_FLO_DISCARD_POP_UP_TITLE_TEXT'
	);
	const NEW_FLO_DISCARD_POP_UP_SUB_TITLE_TEXT = translate(
		'NEW_FLO_DISCARD_POP_UP_SUB_TITLE_TEXT'
	);
	const NEW_FLO_DISCARD_POP_UP_BTN_TYPE = translate('NEW_FLO_DISCARD_POP_UP_BTN_TYPE');
	const NEW_FLO_DISCARD_POP_UP_BTN_TEXT = translate('NEW_FLO_DISCARD_POP_UP_BTN_TEXT');
	const NEW_FLO_COUNTDOWN_TITLE = translate('NEW_FLO_COUNTDOWN_TITLE');
	const NEW_FLO_COUNTDOWN_SUB_TITLE = translate('NEW_FLO_COUNTDOWN_SUB_TITLE');
	const NEW_FLO_PERMISSIONS_ERROR_POP_UP_TITLE_TEXT = translate(
		'NEW_FLO_PERMISSIONS_ERROR_POP_UP_TITLE_TEXT'
	);
	const NEW_FLO_PERMISSIONS_ERROR_POP_UP_SUB_TITLE_TEXT = translate(
		'NEW_FLO_PERMISSIONS_ERROR_POP_UP_SUB_TITLE_TEXT'
	);
	const EDIT_FLO_DISCARD_POP_UP_TITLE_TEXT = translate(
		'EDIT_FLO_DISCARD_POP_UP_TITLE_TEXT'
	);
	const EDIT_FLO_DISCARD_POP_UP_SUB_TITLE_TEXT = translate(
		'EDIT_FLO_DISCARD_POP_UP_SUB_TITLE_TEXT'
	);
	const EDIT_FLO_DISCARD_POP_UP_BTN_TYPE = translate('EDIT_FLO_DISCARD_POP_UP_BTN_TYPE');
	const EDIT_FLO_DISCARD_POP_UP_BTN_TEXT = translate('EDIT_FLO_DISCARD_POP_UP_BTN_TEXT');
	const NEW_FLO_RESUME_COUNTDOWN_TITLE = translate('NEW_FLO_RESUME_COUNTDOWN_TITLE');

	const canShowNewFlo = useSelector((state: RootState) =>
		get(state, 'newFlo.canShowNewFlo')
	);
	const previewVideoRef = useRef<HTMLVideoElement>(null);
	const isExtensionInstalled = useSelector(isExtensionInstalledSelector);
	const extensionErrored = useSelector(isExtensionErrored);
	const extensionVersion = useSelector(ExtensionVersionSelector);
	const hasFailedAttempts = useSelector(hasFailedAttemptSelector);
	const hasFailedAttemptMessage = useSelector(hasFailedAttemptMessageSelector);
	const isOnline = useSelector(isOnlineSelector);
	const canDelete = useSelector(canDeleteEditFlo);
	const floId = useSelector(newFloIdSelector);
	const newFloName = useSelector(newFloNameSelector);
	const selectedTemplate = useSelector((state) => get(state, 'newFlo.template'));
	const clicksCount = useSelector((state) => get(state, 'newFlo.noOfClicks', 0));
	const isExtensionRecording = useSelector((state) =>
		get(state, 'newFlo.extensionRecordingInProgress', false)
	);
	const [popupDims, setPopupDims] = useState<CSSProperties>({});
	const [iframeDims, setIframeDims] = useState<CSSProperties>({});
	const exampleFloLinkObj = get(selectedTemplate, 'exampleFlo', '');
	const exampleFloLinkWidth = get(exampleFloLinkObj, 'width', 0);
	const exampleFloLinkHeight = get(exampleFloLinkObj, 'height', 0);
	const showTemplateDeviceSelection = useSelector((state) =>
		get(state, 'newFlo.showTemplateDeviceSelection')
	);
	const [screenShareStream, setScreenShareStream] = useState<MediaStream | undefined>();
	const [active, setActive] = useState<RecorderOptions | undefined>();
	const [floName, setFloName] = useState(newFloName || '');
	const [durationTime, setDuration] = useState(0);

	const [countdownType, setCountdownType] = useState('');
	const [hasAudioStarted, setStartAudioStarted] = useState(false);
	const [hasResumeAudioStarted, setResumeAudioStarted] = useState(false);
	const [extensionCountdownValue, setExtensionCountdownValue] = useState(3);

	const [totalPauseDuration, setTotalPauseDurationInMillis] = useState(0);
	const [pauseStartTime, setPauseStartTime] = useState();
	const [startTime, setStartTime] = useState();
	const [actualStartTime, setActualStartTime] = useState();
	const [endTime, setEndTime] = useState();
	const videoRef = useRef<HTMLVideoElement>(null);
	const [hasAudioCompleted, setHasAudioCompleted] = useState<boolean>(false);
	const [hasCountdownClosed, setHasCountdownClosed] = useState<boolean>(false);
	const [hasCountdownOpened, setHasCountdownOpened] = useState<boolean>(false);
	const [settings, setSettings] = useState<{ [key: string]: MediaTrackSettings }>({});
	const [recorders, setRecorders] = useState<{
		videoRecorder: MediaRecorder | null;
		screenRecorder: MediaRecorder | null;
	}>({
		videoRecorder: null,
		screenRecorder: null,
	});
	const [audioDevice, setAudioDevice] = useState<string | undefined>();
	const [audioDeviceMeta, setAudioDeviceMeta] = useState<MediaDeviceInfo | undefined>();
	const [audio, setCountDownAudio] = useState(defaultAudio);
	const [resumeAudio, setResumeCountDownAudio] = useState(defaultAudio);
	const [stopSound, setStopSoundAudio] = useState(defaultStopAudio);
	const [devicePermissions, setDevicePermissions] = useState<{
		[key: string]: string;
	}>({});
	const [playStartAudio, setPlayStartAudio] = useState<boolean>(false);
	const [playResumeAudio, setPlayResumeAudio] = useState<boolean>(false);
	const [isPrepareScreen, setIsPrepareScreen] = useState<boolean>(false);
	const [videoDevice, setVideoDevice] = useState<string | undefined>();
	const [previewStreams, setPreviewStreams] = useState<MediaStream | null | undefined>(
		null
	);
	const [videoDeviceMeta, setVideoDeviceMeta] = useState<MediaDeviceInfo | undefined>();
	const [availableDevices, setAvailableDevices] = useState<
		Omit<AvailableDevices, 'perms'>
	>({
		devicesAudio: [],
		devicesVideo: [],
	});

	const profile = useSelector((state: RootState) =>
		get(state, 'routerContainer.profile')
	);
	const [isRecording, setIsRecording] = useState(false);
	const [isPaused, setIsPaused] = useState(false);
	const [countDown, setCountDown] = useState<number>(3);
	const [canShowCountDown, setCanShowCountDown] = useState<boolean>(false);

	const cardStyle = {
		display: !canShowCountDown ? 'flex' : 'none',
	};
	const templates: FloCreationTemplates[] = useSelector((state) =>
		get(state, 'newFlo.templates')
	);
	const customFlo = useSelector((state) =>
		get(state, 'newFlo.customFlo')
	);
	const canShowVideo = recordState === 'recording' && countDown < 1;
	const canShowClose = !includes(
		[
			'recording',
			'creating',
			'saving',
			'onStopSaving',
			'onDiscardSaving',
			'stopped/save',
		],
		recordState
	) || !customFlo;
	const recordingSingleColumn = recordState === 'recording' && clicksCount === 0;
	const prevCanShowNewFlo = usePrevious(canShowNewFlo);

	const template: FloCreationTemplates[] = useSelector((state) =>
		get(state, 'newFlo.template')
	);
	const dialogId: string = useSelector((state) => get(state, 'dialog.id'));
	const validExtension = isExtensionInstalled && extensionVersion >= 0.0105;
	const dispatch = useDispatch();

	const handleOnDevices = useCallback(
		(devices: AvailableDevices) => {
			setPreviewStreams(devices.previewStreams);
			setDevicePermissions(devices.perms || {});
			setAvailableDevices(devices);
			const isMicOptional = includes(
				selectedTemplate?.optionalInputPermissions,
				'microphone'
			);
			let audioDevice =
				get(devices, 'inputDevices.audioDevice') ||
				get(devices.devicesAudio, '0.deviceId');
			if (!audioDevice && isMicOptional) {
				audioDevice = 'no_audio';
			}
			setAudioDevice(audioDevice);
			const devicesAudio = find(devices.devicesAudio, {
				deviceId: get(devices, 'inputDevices.audioDevice'),
			});
			setAudioDeviceMeta(devicesAudio || get(devices.devicesAudio, '0'));
			setVideoDevice(
				get(devices, 'inputDevices.videoDevice') ||
					get(devices.devicesVideo, '0.deviceId')
			);
			const devicesVideo = find(devices.devicesVideo, {
				deviceId: get(devices, 'inputDevices.videoDevice'),
			});
			setVideoDeviceMeta(devicesVideo || get(devices.devicesVideo, '0'));
		},
		[
			setPreviewStreams,
			setDevicePermissions,
			setAvailableDevices,
			setAudioDevice,
			setVideoDevice,
			setAudioDeviceMeta,
			setVideoDeviceMeta,
			selectedTemplate,
		]
	);

	const onDataAvailable = useCallback(
		(e: BlobEvent, recorderType: 'screenShare' | 'camera') => {
			const end: moment.Moment = elapsedEndTime || moment();
			const diff = elapsedStartTime ? end.diff(elapsedStartTime) : 0;
			const duration = moment.duration(elapsedDuration + diff);
			const totalSeconds = duration.asSeconds();

			if (recorderType === 'screenShare') {
				chunksShare.push(e.data);
				chunksShareSize += e.data.size;
				if (chunksShareSize < 6000000 && !screenEnd) return;
			}
			if (recorderType === 'camera') {
				chunksVideo.push(e.data);
				chunksVideoSize += e.data.size;
				if (chunksVideoSize < 6000000 && !videoEnd) return;
			}
			let seq = 0;
			const chunks = recorderType === 'screenShare' ? chunksShare : chunksVideo;
			if (recorderType === 'screenShare') {
				chunksShare = [];
			}
			if (recorderType === 'camera') {
				chunksVideo = [];
			}
			const blob = new Blob(chunks, {
				type: 'video/webm;',
			});

			// @ts-ignore
			dispatch(
				sendChunkAction({
					// @ts-ignore
					file: blob,
					seq,
					totalSeconds,
					type: recorderType,
				})
			);
		},
		[dispatch, startTime, durationTime, endTime]
	);

	const stopRecordings = useCallback(() => {
		// @ts-ignore
		if (recorders?.videoRecorder?.stop) {
			if (recorders?.videoRecorder?.stream?.active) {
				forEach(recorders?.videoRecorder?.stream.getTracks(), (track) => track.stop());
				recorders.videoRecorder?.stop();
			}
			videoEnd = true;
		}
		// @ts-ignore
		if (recorders?.screenRecorder?.stop) {
			if (recorders?.screenRecorder?.stream?.active) {
				forEach(recorders?.screenRecorder?.stream.getTracks(), (track) => track.stop());
				recorders.screenRecorder?.stop();
			}
			screenEnd = true;
		}
	}, [recorders.videoRecorder, recorders.screenRecorder]);

	const onStopHandler = useCallback(
		throttle(
			() => {
				const formatType = get(
					selectedTemplate,
					`defaultOutputTypeConfigurations.formatType.name`
				);

				if (previewStreams && previewStreams.active) {
					try {
						forEach(previewStreams.getTracks(), (track) => track.stop());
					} catch (e) {
						console.warn(e);
					}
				}
				elapsedEndTime = moment();
				stopRecordings();
				dispatch(setFloStateAction(!isRecording ? 'stopped' : 'onStopSaving'));
				dispatch(stopNewFloStreamsAction({ skip: true }));
				elapsedEndTime = moment();
				dispatch(
					setNewFloDuration(
						getDuration({
							startTime: startTime,
							endTime: elapsedEndTime,
							duration: durationTime,
						})
					)
				);
				if (formatType === 'document' && clicksCount === 0) {
					if (isRecording)
						dispatch(
							setErrorToast({
								message: 'There were no clicks found, failed to save.',
							})
						);
					if (canDelete && isRecording) {
						dispatch(
							saveFloAction({
								name: floName,
								type: 'discard',
							})
						);
					}
				}
				if (!isRecording) {
					setCanShowCountDown(false);
					dispatch(resetStateAction({}));
				}
				if (stopSound) {
					stopSound.volume = 1;
					stopSound.play();
				}
				if (validExtension) SendMessageToExtension(resetStateExtension(''));
			},
			1000,
			{ leading: true, trailing: false }
		),
		[
			validExtension,
			isRecording,
			startTime,
			durationTime,
			clicksCount,
			selectedTemplate,
			previewStreams,
			stopSound,
			recorders.videoRecorder,
			recorders.screenRecorder,
			stopRecordings,
		]
	);

	const onScreenShareEnded = useCallback(
		(event: unknown) => {
			screenEnd = true;
			videoEnd = true;
			dispatch(onScreenShareEndedAction(''));
			onStopHandler();
			if (validExtension) {
				SendMessageToExtension({
					type: 'take_me_to_base',
				});
				SendMessageToExtension({
					type: 'multiple_commands',
					payload: [
						{
							type: 'take_me_to_base',
						},
						resetStateExtension({}),
					],
				});
			}
		},
		[
			validExtension,
			stopSound,
			setCountDown,
			onStopHandler,
			startTime,
			endTime,
			durationTime,
			stopRecordings,
		]
	);

	const invokeMediaCallback = useCallback(
		(data: { streams: MediaStream[]; selectedOption: RecorderOptions }) => {
			dispatch(setNewFloStreamsAction(data.streams));
			const settingsData: {
				[key: string]: MediaTrackSettings;
			} = {};
			const rec: {
				videoRecorder: MediaRecorder | null;
				screenRecorder: MediaRecorder | null;
			} = {
				videoRecorder: null,
				screenRecorder: null,
			};
			if (
				data.selectedOption &&
				[
					optionKeys.screenOnly,
					optionKeys.screen,
					optionKeys.screenCam,
					optionKeys.screenCamMic,
				].includes(data.selectedOption)
			) {
				const screenRecorder = new MediaRecorder(data.streams[0], MediaRecorderOptions);
				if (data.selectedOption === optionKeys.screen) {
					const tracks = data.streams[1]?.getAudioTracks() || [];
					for (let track of tracks) {
						data.streams[0].addTrack(track);
					}
				}
				screenRecorder.ondataavailable = (e: BlobEvent) => {
					onDataAvailable(e, 'screenShare');
				};
				screenRecorder.onstop = () => {
					setTimeout(() => {
						dispatch(
							sendChunkAction({
								isEnd: true,
								seq: -1,
							})
						);
					}, 1000);
				};
				settingsData.screenShare = data.streams[0].getVideoTracks()[0].getSettings();
				rec.screenRecorder = screenRecorder;
			}
			if (
				data.selectedOption &&
				[
					optionKeys.cameraOnly,
					optionKeys.camera,
					optionKeys.screenCam,
					optionKeys.screenCamMic,
				].includes(data.selectedOption)
			) {
				const stream =
					data.streams[
						includes([optionKeys.cameraOnly, optionKeys.camera], data.selectedOption)
							? 0
							: 1
					];
				if (videoRef?.current) {
					videoRef.current.srcObject = stream;
				}

				const videoRecorder = new MediaRecorder(stream, MediaRecorderOptions);
				videoRecorder.ondataavailable = (e: BlobEvent) => {
					onDataAvailable(e, 'camera');
				};
				videoRecorder.onstop = () => {
					setTimeout(() => {
						dispatch(
							sendChunkAction({
								isEnd: true,
								seq: -1,
							})
						);
					}, 1000);
				};
				settingsData.camera = stream.getVideoTracks()[0].getSettings();
				rec.videoRecorder = videoRecorder;
			}
			setScreenShareStream(data.streams[0]);
			setRecorders(rec);
			setSettings(settingsData);
			return settingsData;
		},
		[
			setScreenShareStream,
			setRecorders,
			onDataAvailable,
			setSettings,
			dispatch,
			startTime,
			active,
			onScreenShareEnded,
		]
	);

	const onPermissionChange = useCallback(
		(change: PermissionStatus) => {
			setDevicePermissions((state) => ({
				...state,
				[get(change, 'name')]: get(change, 'state'),
			}));
		},
		[setDevicePermissions]
	);

	const onClickHandler = useCallback(
		throttle(
			(
				event: null | React.MouseEvent<HTMLDivElement>,
				target: RecorderOptions,
				devices?: {
					[key: string]: string | undefined;
				}
			): void => {
				setActive(target);
				dispatch(stopNewFloStreamsAction({}));
				if (previewStreams)
					forEach(previewStreams.getTracks(), (track) => {
						try {
							if (track.enabled) track.stop();
						} catch (e) {
							console.warn(e);
						}
					});
				getDevices(target, onPermissionChange, devices)
					.then(handleOnDevices)
					.catch((e) => {
						console.warn(e);
						dispatch(
							showDialogAction({
								variant: 'small',
								dialogClassName: styles.dialogRootMessage,
								header: NEW_FLO_PERMISSIONS_ERROR_POP_UP_TITLE_TEXT,
								body: NEW_FLO_PERMISSIONS_ERROR_POP_UP_SUB_TITLE_TEXT,
								showCancel: true,
								cancelButtonText: 'Okay',
							})
						);
					});
			},
			1000,
			{ leading: true, trailing: false }
		),
		[
			previewStreams,
			onPermissionChange,
			floName,
			active,
			dispatch,
			setActive,
			handleOnDevices,
		]
	);

	const onBackHandler = useCallback(() => {
		dispatch(setSelectedTemplate({}));
	}, []);

	const onStartHandler = useCallback(
		debounce(
			(
				newSelectedTarget?: RecorderOptions,
				audioDeviceOverwrite?: string,
				videoDeviceOverwrite?: string
			) => {
				const selected = active || newSelectedTarget;
				if (!navigator.onLine) {
					dispatch(
						showDialogAction({
							variant: 'small',
							header: '',
							dialogClassName: styles.dialogRootMessage,
							body: 'You seem to be offline, try again later.',
							showConfirm: true,
							confirmButtonText: 'Okay',
						})
					);
					return;
				}
				if (selected && recordState === 'stopped') {
					if (isExtensionInstalled && isExtensionRecording) {
						dispatch(
							showDialogAction({
								variant: 'small',
								id: 'multiple_flo_with_use_here',
								header: '',
								dialogClassName: styles.dialogRootMessage,
								body: MULTIPLE_FLO_RECORDING_TXT,
								showConfirm: true,
								showCancel: true,
								cancelButtonText: MULTIPLE_FLO_RECORDING_CANCEL_BTN_TXT,
								confirmButtonText: MULTIPLE_FLO_RECORDING_CONFIRM_BTN_TXT,
								onConfirm(dispatch: Dispatch) {
									SendMessageToExtension(useCurrrentTabAsBase({}));
									dispatch(hideDialogAction(''));
									dispatch(setExtensionRecording(false));
								},
							})
						);
						return;
					}

					if (isExtensionInstalled && !validExtension) {
						if (dialogId !== 'install_extension') {
							dispatch(
								showDialogAction({
									variant: 'small',
									id: 'install_extension',
									dialogClassName: styles.dialogRootMessage,
									header: '',
									body:
										extensionVersion === 0
											? 'There seems to be some issue with your extension, check permissions given for extension.'
											: 'Please install latest version of floik extension to continue.',
									showCancel: true,
									cancelButtonText: 'Okay',
								})
							);
						}
						return;
					}

					videoEnd = false;
					screenEnd = false;
					chunksVideo = [];
					chunksVideoSize = 0;
					chunksShare = [];
					chunksShareSize = 0;
					newfloPausedDuration = 0;
					let shareType = '';
					invokeMedia(selected, {
						videoDevice: videoDeviceOverwrite || videoDevice,
						audioDevice: audioDeviceOverwrite || audioDevice,
					})
						// @ts-ignore
						.then(invokeMediaCallback)
						.then((settings: { [key: string]: MediaTrackSettings }) => {
							const hasMicrophone = includes(
								selectedTemplate?.inputPermissions,
								'microphone'
							);
							const hasAudio = hasMicrophone && audioDevice !== 'no_audio';
							dispatch(
								createNewFloAction({
									type: selected,
									name: floName,
									settings,
									videoDeviceMeta,
									audioDeviceMeta,
									hasAudio,
								})
							);
							shareType = get(settings, 'screenShare.displaySurface') as string;
						})
						.catch((error) => {
							console.warn('error', error);
							dispatch(
								showDialogAction({
									variant: 'small',
									dialogClassName: styles.dialogRootMessage,
									header: NEW_FLO_PERMISSIONS_POP_UP_TITLE_TEXT,
									body: NEW_FLO_PERMISSIONS_POP_UP_SUB_TITLE_TEXT,
								})
							);
						})
						.finally(() => {
							if (shareType !== 'browser') {
								SendMessageToExtension(takeMeBacktoTab({}));
							}
						});
				} else {
					dispatch(
						showDialogAction({
							variant: 'small',
							dialogClassName: styles.dialogRootMessage,
							header: 'Editing',
							body: 'Editing flo is in progress',
							showConfirm: true,
							confirmButtonText: 'Okay',
						})
					);
				}
			},
			500
		),
		[
			isExtensionRecording,
			isExtensionInstalled,
			validExtension,
			dialogId,
			selectedTemplate,
			videoDeviceMeta,
			audioDeviceMeta,
			audioDevice,
			videoDevice,
			active,
			floName,
			recordState,
			invokeMediaCallback,
		]
	);

	const getProfile = useCallback(() => {
		return {
			name: get(profile, 'name'),
			userId: get(profile, 'userId'),
			profilePicUrl: get(profile, 'userProfile.profilePicUrl'),
			avatarColor: get(profile, 'userProfile.profilePreference.avatarColor'),
			profilePreference: {
				avatarColor: get(profile, 'userProfile.profilePreference.avatarColor'),
			},
			email: get(profile, 'profile.email'),
			is_anonymous: get(profile, 'profile.anonymous'),
		};
	}, [profile]);
	const onBeforeStartEnd = useCallback(() => {
		setCanShowCountDown(false);
		dispatch(setFloStateAction('stopped'));
		dispatch(stopNewFloStreamsAction({ skip: true }));
		dispatch(resetStateAction({}));
		SendMessageToExtension(resetStateExtension(''));
	}, [setCanShowCountDown]);

	const startMediaRecording = useCallback(() => {
		if (screenEnd) {
			return;
		}
		try {
			if (recorders.screenRecorder || recorders.videoRecorder) {
				setIsPrepareScreen(false);
				if (validExtension)
					SendMessageToExtension(
						startRecordingExtension({
							formatMaxTime,
							prepareScreen: false,
							canPreviewVideo: includes(get(template, 'inputPermissions'), 'camera'),
							canCaptureClicks: includes(
								get(template, 'inputPermissions'),
								'screenshare'
							),
							apiCallInProgress: false,
							template: pick(template, ['name', 'illustrationImage', 'iconType']),
							outputType: get(
								template,
								'defaultOutputTypeConfigurations.formatType.name'
							),
							currentUser: getProfile(),
							screenType: get(settings, 'screenShare.displaySurface'),
							startTime: moment().format(),
							enableAnnotation: !!recorders?.screenRecorder,
						})
					);
			}
			setIsRecording(true);
			try {
				let start = 0;
				let end = 0;
				if (recorders.videoRecorder) {
					recorders.videoRecorder.onstart = () => {
						const t3 = performance.now();
						dispatch(
							analyticsLog({
								level: 'INFO',
								message: `
								newflo video startTime - endTime performace for mediastartTime ${
									end - start
								}ms, video startTime - startEventEventReceivedTime performace ${
									t3 - start
								}ms, endTime - startEventEventReceivedTime performace ${t3 - end}ms`,
								value: {
									end,
									start,
									startEventTimeRegistered: t3,
								},
							})
						);
						const previewVideo = videoRef.current;
						if (previewVideo) {
							// @ts-ignore
							previewVideo.srcObject = recorders.videoRecorder.stream;
						}
					};
				}
				if (recorders.screenRecorder)
					recorders.screenRecorder.onstart = () => {
						const t3 = performance.now();
						dispatch(
							analyticsLog({
								level: 'INFO',
								message: `newflo screenshare startTime - endTime performace for mediastartTime ${
									end - start
								}ms,  startTime - startEventEventReceivedTime performace ${
									t3 - start
								}ms, endTime - startEventEventReceivedTime performace ${t3 - end}ms`,
								value: {
									end,
									start,
									startEventTimeRegistered: t3,
								},
							})
						);
					};
				elapsedStartTime = moment();
				trueStartTime = elapsedStartTime;
				// @ts-ignore
				setStartTime(elapsedStartTime);
				// @ts-ignore
				setActualStartTime(elapsedStartTime);

				start = performance.now();

				if (recorders?.videoRecorder?.start) {
					recorders.videoRecorder.start(recordChunkInterval);
				}
				if (recorders?.screenRecorder?.start) {
					recorders.screenRecorder.start(recordChunkInterval);
				}
				end = performance.now();
				setCanShowCountDown(false);
			} catch (e) {
				dispatch(
					analyticsLog({
						level: 'error',
						message: get(e, 'message'),
						value: get(e, 'stack'),
					})
				);
				onBeforeStartEnd();
				dispatch(
					showDialogAction({
						variant: 'small',
						header: 'Error',
						dialogClassName: styles.dialogRootMessage,
						body: 'Oops! something went wrong, please try again later.',
						showCancel: true,
						cancelButtonText: 'Okay',
					})
				);
			}
		} catch (e) {
			console.warn(e);
			onBeforeStartEnd();
		}
	}, [
		setCanShowCountDown,
		validExtension,
		setIsPrepareScreen,
		onBeforeStartEnd,
		setIsRecording,
		videoRef,
		template,
		setActualStartTime,
		setStartTime,
		settings,
		recorders,
		getProfile,
	]);

	const onStartAudioEnded = useCallback(() => {
		// SendMessageToExtension(hideCountDown({}));
		// setCanShowCountDown(false);
		// setHasAudioCompleted(true);
	}, [setHasAudioCompleted, setCanShowCountDown]);

	const onStartTimeout = useCallback(() => {
		if (isExtensionInstalled && !isRecording) {
			dispatch(
				analyticsLog({
					level: 'warn',
					message: `failed to start flo: "${floId}", since count didnt show up`,
				})
			);
			dispatch(
				showDialogAction({
					variant: 'small',
					id: 'start_warning',
					header: 'Warning',
					dialogClassName: styles.dialogRootMessage,
					body: 'Clicks might not get captured do you still want to continue',
					showCancel: true,
					showConfirm: true,
					cancelButtonText: 'Cancel',
					confirmButtonText: 'Okay',
					onCancel() {
						onBeforeStartEnd();
						dispatch(hideDialogAction(''));
						dispatch(resetStateAction({}));
					},
					onConfirm() {
						if (!isRecording) {
							// setCanShowCountDown(true);
							// setIsPrepareScreen(false);
							// audio.play();
							setPlayStartAudio(true);
							if (validExtension) {
								SendMessageToExtension(showCountDown({ value: 3 }));
							}
						}
						dispatch(hideDialogAction(''));
					},
				})
			);
		}
	}, [
		setCanShowCountDown,
		setIsPrepareScreen,
		audio,
		floId,
		isRecording,
		onBeforeStartEnd,
		hasCountdownOpened,
		isExtensionInstalled,
	]);

	const startRecording = useCallback(() => {
		audio.onended = onStartAudioEnded;
		audio.currentTime = 0;
		audio.volume = 1;
		audio.muted = false;
		if (!screenEnd) {
			setPlayStartAudio(true);
			if (validExtension) {
				SendMessageToExtension(showCountDown({ value: 3 }));
			}
		} else {
			setPlayStartAudio(false);
			onBeforeStartEnd();
		}
	}, [
		validExtension,
		setPlayStartAudio,
		onBeforeStartEnd,
		onStartAudioEnded,
		setIsPrepareScreen,
		setIsRecording,
		setCanShowCountDown,
		setCountDown,
		audio,
		videoRef,
		template,
		setActualStartTime,
		setStartTime,
		settings,
		recorders.screenRecorder,
		recorders.videoRecorder,
		getProfile,
	]);

	const onResumeAudioEnded = useCallback(() => {
		// SendMessageToExtension(hideCountDown({}));
		// setCanShowCountDown(false);
		// setHasAudioCompleted(true);
	}, [
		setHasAudioCompleted,
		setPlayResumeAudio,
		recorders?.screenRecorder,
		recorders?.videoRecorder,
		setPauseStartTime,
		setTotalPauseDurationInMillis,
		setStartTime,
		pauseStartTime,
		setIsPaused,
		videoRef,
	]);

	const resumeAudioCountdownUpdate = useCallback(() => {}, [
		validExtension,
		resumeAudio,
		countDown,
		setCountDown,
	]);

	const onDiscardConfirm = useCallback(() => {
		if (previewStreams && previewStreams.active) {
			try {
				forEach(previewStreams.getTracks(), (track) => track.stop());
			} catch (e) {
				console.warn(e);
			}
		}

		if (canDelete) {
			dispatch(setCanShowNewFlo(false));
			dispatch(
				saveFloAction({
					name: floName,
					type: 'discard',
				})
			);
		} else {
			dispatch(setCanShowNewFlo(false));
			dispatch(resetStateAction(''));
		}
		dispatch(stopNewFloStreamsAction({ skip: true }));
		stopRecordings();
		SendMessageToExtension({
			type: 'take_me_to_base',
		});
		SendMessageToExtension(resetStateExtension({}));
		setResetState();
	}, [
		previewStreams,
		stopRecordings,
		canDelete,
		floName,
		dispatch,
		setActive,
		setFloName,
		setDuration,
		setStartTime,
		setEndTime,
		setRecorders,
		setAudioDevice,
		setVideoDevice,
		setAvailableDevices,
		setCountDown,
	]);

	const onErroredResumeConfirm = useCallback(() => {
		if (navigator.onLine) {
			if (hasFailedAttempts) {
				dispatch(
					setHasFailedAttempt({
						value: false,
						message: '',
					})
				);
			}
			onPauseHandler(true);
		} else {
			dispatch(
				setErrorToast({
					type: 'error',
					message: `You seem to be still offline`,
					canShow: true,
				})
			);
		}
	}, [hasFailedAttempts]);

	const displayOfflineMessage = useCallback(() => {
		if (!isOnline) {
			SendMessageToExtension({
				type: 'take_me_to_base',
			});
			dispatch(
				showDialogAction({
					variant: 'small',
					id: 'new_flo_nw_issue',
					header: '',
					body: 'You seem to be offline.',
					showCancel: true,
					showConfirm: true,
					dialogClassName: styles.dialogRootMessage,
					confirmButtonText: 'Check now',
					cancelButtonText: 'Discard flo',
					confirmButtonType: 'delete',
					onConfirm: onErroredResumeConfirm,
					onClose: () => {
						dispatch(hideDialogAction(''));
					},
					onCancel: () => {
						dispatch(hideDialogAction(''));
						onDiscardConfirm();
					},
				})
			);
		}
	}, [isOnline, onDiscardConfirm, onErroredResumeConfirm]);

	const displayFailedAttemptMessage = useCallback(() => {
		SendMessageToExtension({
			type: 'take_me_to_base',
		});
		dispatch(
			showDialogAction({
				id: 'new_flo_nw_issue',
				header: '',
				variant: 'small',
				body:
					hasFailedAttemptMessage ||
					'There seems to be some issue with your network connection. please check your connection',
				showCancel: true,
				showConfirm: true,
				dialogClassName: styles.dialogRootMessage,
				confirmButtonText: 'Try again',
				confirmButtonType: 'delete',
				cancelButtonText: 'Discard flo',
				onConfirm: onErroredResumeConfirm,
				onClose: () => {
					dispatch(hideDialogAction(''));
				},
				onCancel: () => {
					dispatch(hideDialogAction(''));
					onDiscardConfirm();
				},
			})
		);
	}, [onDiscardConfirm, onErroredResumeConfirm, hasFailedAttemptMessage]);

	const onPauseHandler = useCallback(
		throttle(
			(value?: boolean) => {
				let isTrulyPaused = isPaused;
				if (!isUndefined(value)) {
					isTrulyPaused = value;
				}
				if (!isTrulyPaused) {
					if (!pauseStartTime) {
						// @ts-ignore
						setPauseStartTime(+new moment());
					}
					recorders?.screenRecorder?.pause();
					recorders?.videoRecorder?.pause();
					if (videoRef?.current) videoRef.current.pause();
					const extensionVideo = getVideoFromExtension();
					if (extensionVideo) extensionVideo.pause();
					const end = moment();
					const diff = end.diff(startTime);
					elapsedStartTime = undefined;
					elapsedDuration = durationTime + diff;
					setDuration(elapsedDuration);
					setStartTime(undefined);

					const promises = Promise.all([
						new Promise((resolve) => {
							if (!recorders?.screenRecorder) {
								resolve(1);
								return;
							}
							recorders?.screenRecorder?.addEventListener('pause', resolve, {
								once: true,
							});
						}),
						new Promise((resolve) => {
							if (!recorders?.videoRecorder) {
								resolve(1);
								return;
							}
							recorders?.videoRecorder?.addEventListener('pause', resolve, {
								once: true,
							});
						}),
					]);
					promises.then(() => {
						console.log('pauseRecordingExtension');
						if (validExtension) {
							SendMessageToExtension(
								pauseRecordingExtension({
									duration: durationTime + diff,
									startTime: undefined,
								})
							);
						}
					});

					setIsPaused(true);
				} else if (!isOnline) {
					displayOfflineMessage();
				} else if (hasFailedAttempts) {
					displayFailedAttemptMessage();
				} else {
					resumeAudio.onended = onResumeAudioEnded;
					setHasAudioCompleted(false);
					setCanShowCountDown(true);
					setHasCountdownClosed(false);
					setCountDown(3);
					if (validExtension) {
						SendMessageToExtension(
							showCountDown({
								countDownStartTime: +new Date(),
								value: 3,
							})
						);
					}
					setCountdownType('resume');
					resumeAudio.play();
					setPlayResumeAudio(true);
				}
			},
			1000,
			{ leading: true, trailing: false }
		),
		[
			setHasCountdownClosed,
			setHasAudioCompleted,
			isOnline,
			setPlayResumeAudio,
			hasFailedAttempts,
			displayOfflineMessage,
			displayFailedAttemptMessage,
			validExtension,
			onResumeAudioEnded,
			pauseStartTime,
			setPauseStartTime,
			setTotalPauseDurationInMillis,
			setCountDown,
			setCanShowCountDown,
			resumeAudio,
			videoRef.current,
			isPaused,
			startTime,
			setDuration,
			setStartTime,
			durationTime,
			setIsPaused,
			recorders.screenRecorder,
			recorders.videoRecorder,
		]
	);

	const setResetState = useCallback(() => {
		setHasAudioCompleted(false);
		setPlayStartAudio(false);
		setPlayResumeAudio(false);
		setIsRecording(false);
		setHasCountdownClosed(false);
		setIsPrepareScreen(false);
		setTotalPauseDurationInMillis(0);
		setPauseStartTime(undefined);
		setPreviewStreams(null);
		setActive(undefined);
		setFloName('');
		setDuration(0);
		setCanShowCountDown(false);
		setStartTime(undefined);
		setActualStartTime(undefined);
		setEndTime(undefined);
		setRecorders({
			videoRecorder: null,
			screenRecorder: null,
		});
		setAudioDevice(undefined);
		setAudioDeviceMeta(undefined);
		setVideoDevice(undefined);
		setVideoDeviceMeta(undefined);
		setAvailableDevices({
			devicesAudio: [],
			devicesVideo: [],
		});
		setIsPaused(false);
		setCountDown(3);
		SendMessageToExtension(resetStateExtension(''));
		elapsedDuration = 0;
		newfloPausedDuration = 0;
		elapsedStartTime = undefined;
		trueStartTime = undefined;
		elapsedEndTime = undefined;
		if (previewStreams && previewStreams.active) {
			forEach(previewStreams.getTracks(), (track) => track.stop());
		}
		setCountdownType('');
		setStartAudioStarted(false);
		setResumeAudioStarted(false);
		setExtensionCountdownValue(3);
		setHasCountdownOpened(false);
	}, [
		setCountdownType,
		setExtensionCountdownValue,
		setStartAudioStarted,
		setResumeAudioStarted,
		setHasCountdownOpened,
		setPlayStartAudio,
		setPlayResumeAudio,
		setHasAudioCompleted,
		setIsRecording,
		setHasCountdownClosed,
		setIsPrepareScreen,
		setTotalPauseDurationInMillis,
		setPauseStartTime,
		setPreviewStreams,
		setActive,
		setFloName,
		setDuration,
		setCanShowCountDown,
		setStartTime,
		setActualStartTime,
		setEndTime,
		setRecorders,
		setAudioDevice,
		setAudioDeviceMeta,
		setVideoDevice,
		setVideoDeviceMeta,
		setAvailableDevices,
		setIsPaused,
		setCountDown,
	]);

	const onDiscardHandler = useCallback(
		throttle(
			() => {
				if (canDelete) {
					dispatch(
						showDialogAction({
							variant: 'small',
							header: NEW_FLO_DISCARD_POP_UP_TITLE_TEXT,
							body: NEW_FLO_DISCARD_POP_UP_SUB_TITLE_TEXT,
							confirmButtonType: NEW_FLO_DISCARD_POP_UP_BTN_TYPE,
							confirmButtonText: NEW_FLO_DISCARD_POP_UP_BTN_TEXT,
							dialogClassName: styles.dialogRootMessage,
							showConfirm: true,
							onConfirm: onDiscardConfirm,
						})
					);
				} else {
					dispatch(
						showDialogAction({
							variant: 'small',
							header: EDIT_FLO_DISCARD_POP_UP_TITLE_TEXT,
							body: EDIT_FLO_DISCARD_POP_UP_SUB_TITLE_TEXT,
							confirmButtonType: EDIT_FLO_DISCARD_POP_UP_BTN_TYPE,
							confirmButtonText: EDIT_FLO_DISCARD_POP_UP_BTN_TEXT,
							dialogClassName: styles.dialogRootMessage,
							showConfirm: true,
							onConfirm: onDiscardConfirm,
						})
					);
				}
			},
			1000,
			{ leading: true, trailing: false }
		),
		[floName, onDiscardConfirm, canDelete]
	);

	const onRestartHandler = useCallback(() => {}, []);

	const videoDeviceChangeHandler = useCallback(
		(deviceId?: string) => {
			setVideoDevice(deviceId);
			const device = find(availableDevices?.devicesVideo, { deviceId });
			setVideoDeviceMeta(device);
			if (active && deviceId) {
				onClickHandler(null, active, {
					audioDevice,
					videoDevice: deviceId,
				});
			}
		},
		[
			active,
			setVideoDevice,
			setVideoDeviceMeta,
			videoDevice,
			audioDevice,
			availableDevices,
		]
	);

	const audioDeviceChangeHandler = useCallback(
		(deviceId?: string) => {
			setAudioDevice(deviceId);
			const device = find(availableDevices?.devicesAudio, { deviceId });
			setAudioDeviceMeta(device);
			if (active && deviceId) {
				onClickHandler(null, active, {
					videoDevice,
					audioDevice: deviceId,
				});
			}
		},
		[
			active,
			setAudioDevice,
			setAudioDeviceMeta,
			videoDevice,
			audioDevice,
			availableDevices,
		]
	);
	const hasAlreadyTemplateSelected = useSelector(state => get(state, 'newFlo.state') !== 'stopped');

	const onClose = useCallback(() => {
		if (!canShowClose || customFlo || hasAlreadyTemplateSelected) return;
		dispatch(setCanShowNewFlo(false));
	}, [hasAlreadyTemplateSelected, canShowVideo, recordState, canShowClose, customFlo, setResetState]);

	const listener = useCallback(
		(action: { type: string; payload?: unknown }) => {
			switch (action.type) {
				case setExtensionVersionAction.type: {
					dispatch(setExtensionVersion(get(action, 'payload.version', '0.0.0')));
					dispatch(setExtensionRecording(get(action, 'payload.isRecording', false)));
					break;
				}
				case getDevicesExtension.type: {
					// @ts-ignore
					getDevices(get(action, 'payload.selection'), onPermissionChange).then(
						(devices) => {
							SendMessageToExtension(setDevicesExtension(devices));
						}
					);
					break;
				}
				case settingAsBaseTab.type: {
					SendMessageToExtension(baseReadyAction({}));
					break;
				}
				case showAppPopupAction.type: {
					dispatch(setCanShowNewFlo(true));
					break;
				}
				case 'get_latest_state': {
					SendMessageToExtension({
						type: 'set_latest_state',
						payload: {
							state: recordState,
						},
					});
					break;
				}
				case 'get-token-from-app':
				case getToken.type: {
					const authToken = Cookie.get('floik-token-v2');
					const uninstallPath = Cookie.get('floik-uninstall-path');
					SendMessageToExtension({
						type: EXTENSION_SAVE_TOKEN,
						payload: {
							token: authToken,
							uninstallPath,
						},
					});
					break;
				}
				case setSelectedTemplateApp.type: {
					dispatch(setSelectedTemplate(get(action, 'payload')));
					break;
				}
				case prepareForRecording.type: {
					SendMessageToExtension({
						type: 'take_me_to_base',
					});
					dispatch(setCanShowNewFlo(true));
					setActive(get(action, 'payload.selectedOption'));
					setAudioDevice(get(action, 'payload.devices.audioDevice'));
					setVideoDevice(get(action, 'payload.devices.videoDevice'));
					const option = get(action, 'payload.selectedOption');
					const optionSelected = find(templates, (template) => {
						return (
							(optionKeys.screen === option && template.name === 'How To videos') ||
							(optionKeys.screenCamMic === option &&
								template.name === 'Explainer videos') ||
							(optionKeys.camera === option && template.name === 'Self recorded videos')
						);
					});
					dispatch(setSelectedTemplate(optionSelected));
					onStartHandler(
						get(action, 'payload.selectedOption'),
						get(action, 'payload.devices.audioDevice'),
						get(action, 'payload.devices.videoDevice')
					);
					break;
				}
				case pauseRecordingApp.type: {
					setIsPaused(true);
					onPauseHandler(false);
					break;
				}
				case resumeRecordingApp.type: {
					if (!isOnline || hasFailedAttempts) {
						SendMessageToExtension({
							type: 'take_me_to_base',
						});
						break;
					}
					setIsPaused(false);
					onPauseHandler(true);
					break;
				}
				case stopRecordingApp.type:
					onStopHandler();

					SendMessageToExtension({
						type: 'take_me_to_base',
					});
					SendMessageToExtension({
						type: 'multiple_commands',
						payload: [resetStateExtension({})],
					});
					break;
				case discardRecordingApp.type: {
					onDiscardHandler();
					break;
				}
				case discardConfirmRecordingApp.type: {
					onDiscardConfirm();
					break;
				}
				case sendCommentAnnotationApp.type: {
					const time = getDuration({
						startTime,
						endTime,
						duration: durationTime,
					});
					const payload = (action.payload as object) || {};
					dispatch(
						sendAnnotationComment({
							...payload,
							time,
						})
					);
					break;
				}
				case multipleRecordingAppError.type:
					if (!audio.paused) {
						audio.pause();
					}
					dispatch(
						showDialogAction({
							id: 'multiple_flo_with_use_here',
							header: '',
							variant: 'small',
							dialogClassName: styles.dialogRootMessage,
							body: MULTIPLE_FLO_RECORDING_TXT,
							showConfirm: true,
							showCancel: true,
							cancelButtonText: MULTIPLE_FLO_RECORDING_CANCEL_BTN_TXT,
							confirmButtonText: MULTIPLE_FLO_RECORDING_CONFIRM_BTN_TXT,
							onConfirm(dispatch: Dispatch) {
								SendMessageToExtension(useCurrrentTabAsBase({}));
								dispatch(hideDialogAction(''));
								dispatch(setExtensionRecording(false));
							},
						})
					);
					break;
				case startRecordingApp.type: {
					startRecording();
					break;
				}
				case sendMouseEvent.type: {
					const time = getDuration({
						startTime,
						endTime,
						duration: durationTime,
					});
					let useTime = false;
					// @ts-ignore
					const startedOn = +(trueStartTime || actualStartTime || 0);
					useTime = startedOn === 0;
					// @ts-ignore
					const clickedAtInMS = get(action, 'payload.clickedAt');
					const totalElapsed = clickedAtInMS - startedOn;
					useTime = useTime && totalElapsed > 0 && startedOn > 0 && clickedAtInMS > 0;

					const actualElapsed = moment
						.duration(
							Math.abs(totalElapsed - (newfloPausedDuration || totalPauseDuration)),
							'ms'
						)
						.asSeconds();
					const clickedAt =
						!useTime && get(action, 'payload.clickedAt') && !isUndefined(actualElapsed)
							? actualElapsed
							: max([0, time]);
					const newAction = set(
						omit(action, ['payload.file']),
						'payload.time',
						clickedAt
					);
					const additionalMeta = reduce(
						get(newAction, 'payload.clickMeta.additionalMeta', {}),
						(acc, item, key) => ({
							...acc,
							[key]: {
								...item,
								attributes: map(item.attributes, ([attrKey, attrValue]) => [
									attrKey,
									attrKey === 'innerText' ||
									!(attrValue.startsWith('"') && attrValue.endsWith('"'))
										? attrValue
										: JSON.parse(attrValue),
								]),
							},
						}),
						{}
					);
					// @ts-ignore
					dispatch({
						...newAction,
						payload: {
							// @ts-ignore
							...(get(newAction, 'payload') || {}),
							clickMeta: {
								...(get(newAction, 'payload.clickMeta') || {}),
								additionalMeta,
							},
						},
					});
					if (get(action, 'payload.file')) {
						dispatch(
							sendMouseEventScreenshot({
								file: get(action, 'payload.file'),
								clickedAt,
								uiId: `screenshot_${get(action, 'payload.uiId')}`,
							})
						);
					}
					break;
				}
				case changeCountdownScreenState.type: {
					setHasCountdownClosed(get(action, 'payload') as boolean);
					break;
				}
				case changeCountdownShowingState.type: {
					setHasCountdownOpened(get(action, 'payload') as boolean);
					break;
				}
				case extensionUninstalling.type: {
					console.log('** extension port disconnected ***');
					checkExtensionUninstalled();
					break;
				}
				case changeCountdownScreenValueState.type: {
					setExtensionCountdownValue(get(action, 'payload') as number);
					break;
				}
			}
		},
		[
			recordState,
			setExtensionCountdownValue,
			validExtension,
			isOnline,
			hasFailedAttempts,
			setHasCountdownClosed,
			setHasCountdownOpened,
			onDiscardConfirm,
			startRecording,
			totalPauseDuration,
			templates,
			onDiscardHandler,
			setActive,
			onPermissionChange,
			setResetState,
			setAudioDevice,
			setVideoDevice,
			onStartHandler,
			setCanShowCountDown,
			onPauseHandler,
			onStopHandler,
			dispatch,
			recorders?.screenRecorder,
			actualStartTime,
			startTime,
			endTime,
			durationTime,
			settings,
		]
	);

	const getDevicesCallback = useCallback(
		(target: RecorderOptions) => {
			onClickHandler(null, target);
		},
		[onClickHandler]
	);

	const onResize = useCallback(() => {
		const popupDims = calculateAspectRatioFit(
			exampleFloLinkWidth,
			exampleFloLinkHeight,
			window.innerWidth * 0.9,
			window.innerHeight
		);
		const iframeDims = calculateAspectRatioFit(
			exampleFloLinkWidth,
			exampleFloLinkHeight,
			popupDims.width - 350 - 32,
			popupDims.height - 32
		);
		setPopupDims(popupDims);
		setIframeDims(iframeDims);
	}, [exampleFloLinkWidth, exampleFloLinkHeight, setIframeDims, setPopupDims]);

	useEffect(() => {
		window.onbeforeunload = function () {
			if (recordState !== 'stopped') {
				SendMessageToExtension(resetStateExtension(''));
				return 'Your flo is not saved, Are you sure you want to leave?';
			}
		};
	}, [recordState]);

	useEffect(() => {
		if (prevCanShowNewFlo != canShowNewFlo) {
			setResetState();
		}
	}, [prevCanShowNewFlo, canShowNewFlo, setResetState]);

	useEffect(() => {
		if (get(activeTemplateFilter, 'key') !== 'manual_upload' && playStartAudio && (validExtension ? hasCountdownOpened : true)) {
			setCanShowCountDown(true);
			setIsPrepareScreen(false);
			setCountdownType('start');
			setCountDown(3);
			audio.play();
			setStartAudioStarted(true);
			console.warn('using start audio');
		}
	}, [
		get(activeTemplateFilter, 'key'),
		setStartAudioStarted,
		setIsPrepareScreen,
		setCountDown,
		setCountdownType,
		audio,
		playStartAudio,
		hasCountdownOpened,
		validExtension,
	]);

	useEffect(() => {
		if (playResumeAudio && (validExtension ? hasCountdownOpened : true)) {
			console.log('play resume audio');
			setCanShowCountDown(true);
			setCountdownType('resume');
			setCountDown(3);
			resumeAudio.play();
			setResumeAudioStarted(true);
			// setHasAudioCompleted(false);
		}
	}, [
		setCanShowCountDown,
		setCountDown,
		setResumeAudioStarted,
		resumeAudio,
		setCountdownType,
		playResumeAudio,
		hasCountdownOpened,
		validExtension,
	]);

	const resumeMediaRecording = useCallback(() => {
		setPlayResumeAudio(false);
		// @ts-ignore
		const pauseDuration = !pauseStartTime ? 0 : +new moment() - (pauseStartTime || 0);
		setTotalPauseDurationInMillis((i) => {
			newfloPausedDuration = Number(i || 0) + Number(pauseDuration);
			return newfloPausedDuration;
		});
		SendMessageToExtension(
			resumeRecordingExtension({
				startTime: moment().format(),
			})
		);
		setCanShowCountDown(false);
		setCountdownType('');
		recorders?.screenRecorder?.resume();
		recorders?.videoRecorder?.resume();
		if (videoRef?.current) {
			videoRef.current.play();
		}
		const extensionVideo = getVideoFromExtension();
		if (extensionVideo) extensionVideo.play();
		elapsedStartTime = moment();
		setIsPaused(false);
		// @ts-ignore
		setStartTime(elapsedStartTime);
		setPauseStartTime(undefined);
	}, [
		setCanShowCountDown,
		setCountdownType,
		pauseStartTime,
		recorders,
		videoRef,
		setIsPaused,
		setStartTime,
		setPauseStartTime,
		setPlayResumeAudio,
	]);

	useEffect(() => {
		const hasScreen = includes(selectedTemplate?.inputPermissions, 'screenshare');
		if (
			playResumeAudio &&
			hasAudioCompleted &&
			((validExtension ? hasCountdownClosed : true) || !hasScreen) &&
			(validExtension ? !extensionCountdownValue : true)
		) {
			console.log('play resume audio 2');
			setHasAudioCompleted(false);
			setCountdownType('');
			setCountDown(3);
			setPlayResumeAudio(false);
			resumeMediaRecording();
		}
	}, [
		selectedTemplate,
		extensionCountdownValue,
		setPlayResumeAudio,
		setHasAudioCompleted,
		setCountdownType,
		setCountDown,
		resumeMediaRecording,
		playResumeAudio,
		hasAudioCompleted,
		validExtension,
		hasCountdownClosed,
	]);

	useEffect(() => {
		return ExtensionListener(listener);
	}, [listener]);

	useEffect(() => {
		window.addEventListener('resize', onResize);
		onResize();
		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, [onResize]);

	useEffect(() => {
		runRootSaga();
		dispatch(getCountDownTrackAction({}));
		dispatch(getTemplateCategories({}));
		dispatch(
			newFloGetTemplates({
				initial: true,
				filter: 'recent',
			})
		);
		return () => {
			dispatch(leaveNewFlosPageAction(''));
		};
	}, []);

	useEffect(() => {
		try {
			if (countdownTrack) {
				const downloadedTrack = new Audio(countdownTrack);
				const downloadedTrack2 = new Audio(countdownTrack);

				downloadedTrack.load();
				downloadedTrack2.load();
				// @ts-ignore
				downloadedTrack.onloadeddata = () => {
					setCountDownAudio(downloadedTrack);
				};
				downloadedTrack2.onloadeddata = () => {
					setResumeCountDownAudio(downloadedTrack2);
				};
			}
		} catch {
			console.warn('audio download error');
		}
	}, [countdownTrack, setResumeCountDownAudio, setCountDownAudio]);

	useEffect(() => {
		const hasScreen = includes(selectedTemplate?.inputPermissions, 'screenshare');
		if (
			hasAudioCompleted &&
			((validExtension ? hasCountdownClosed : true) || !hasScreen) &&
			!isRecording &&
			(validExtension ? !extensionCountdownValue : true)
		) {
			console.log('has audio complete');
			setCountdownType('');
			setCountDown(3);
			startMediaRecording();
		}
	}, [
		extensionCountdownValue,
		setCountDown,
		setCountdownType,
		validExtension,
		startMediaRecording,
		selectedTemplate,
		hasAudioCompleted,
		hasCountdownClosed,
		isRecording,
	]);

	useEffect(() => {
		if (stopSoundTrack) {
			const downloadedTrack = new Audio(stopSoundTrack);
			// @ts-ignore
			downloadedTrack.onloadeddata = () => {
				setStopSoundAudio(downloadedTrack);
			};
			downloadedTrack.onerror = () => {
				setStopSoundAudio(defaultStopAudio);
			};
			downloadedTrack.load();
		}
	}, [stopSoundTrack, setStopSoundAudio]);

	useEffect(() => {
		if (!canShowNewFlo && previewStreams) {
			forEach(previewStreams?.getTracks(), (track) => track.stop());
		} else if (canShowNewFlo && previewStreams?.active) {
			setTimeout(() => {
				const previewVideo = previewVideoRef.current as HTMLVideoElement;
				if (previewVideo) {
					previewVideo.srcObject = previewStreams;
				}
			}, 300)
		}
	}, [previewStreams?.active, canShowNewFlo, previewVideoRef.current]);

	useEffect(() => {
		if (
			recorders.videoRecorder &&
			previewStreams &&
			validExtension &&
			isExtensionRecording
		) {
			try {
				const extensionVideo = getVideoFromExtension();
				if (extensionVideo) {
					extensionVideo.srcObject = previewStreams || recorders.videoRecorder.stream;
					extensionVideo.play();
				}
			} catch (e) {
				console.error(e);
			}
		}
	}, [recorders.videoRecorder, previewStreams, validExtension, isExtensionRecording]);

	useEffect(() => {
		if (screenShareStream?.getVideoTracks) {
			const videoTracks = screenShareStream.getVideoTracks();
			if (get(videoTracks, '0')) {
				videoTracks[0].onended = onScreenShareEnded;
			}
		}
	}, [screenShareStream, onScreenShareEnded]);

	useEffect(() => {
		if (recordState === 'stopped' && prevRecordState === 'creating') {
			setTimeout(() => {
				onStopHandler();
			}, 500);
		}
	}, [onStopHandler, prevRecordState, recordState]);

	useEffect(() => {
		// @ts-ignore
		if (recordState === 'creating') {
			setIsPrepareScreen(true);
			setCountDown(3);
			if (validExtension) {
				SendMessageToExtension(
					startRecordingExtension({
						prepareScreen: true,
						formatMaxTime,
						apiCallInProgress: true,
						canPreviewVideo: includes(get(template, 'inputPermissions'), 'camera'),
						template: pick(template, ['name', 'illustrationImage', 'iconType']),
						canCaptureClicks: includes(get(template, 'inputPermissions'), 'screenshare'),
						outputType: get(template, 'defaultOutputTypeConfigurations.formatType.name'),
						currentUser: getProfile(),
						screenType: get(settings, 'screenShare.displaySurface'),
						startTime: moment().format(),
						enableAnnotation: !!recorders?.screenRecorder,
					})
				);
			}
		}
		if (recordState === 'recording') {
			const delayRecording = isExtensionInstalled && validExtension;
			if (!delayRecording) {
				setIsPrepareScreen(false);
				startRecording();
			} else if (isExtensionInstalled && extensionErrored) {
				onStartTimeout();
			} else if (!isRecording) {
				setIsPrepareScreen(true);
				if (validExtension) {
					SendMessageToExtension(
						startRecordingExtension({
							prepareScreen: true,
							formatMaxTime,
							apiCallInProgress: false,
							template: pick(template, ['name', 'illustrationImage', 'iconType']),
							canPreviewVideo: includes(get(template, 'inputPermissions'), 'camera'),
							canCaptureClicks: includes(
								get(template, 'inputPermissions'),
								'screenshare'
							),
							outputType: get(
								template,
								'defaultOutputTypeConfigurations.formatType.name'
							),
							currentUser: getProfile(),
							screenType: get(settings, 'screenShare.displaySurface'),
							startTime: moment().format(),
							enableAnnotation: !!recorders?.screenRecorder,
						})
					);
				}
			}
		} else if (recordState === 'saving') {
			setRecorders({
				videoRecorder: null,
				screenRecorder: null,
			});
		}
	}, [
		formatMaxTime,
		validExtension,
		isRecording,
		isExtensionInstalled,
		extensionErrored,
		onStartTimeout,
		recordState,
		startRecording,
		setCanShowCountDown,
		setCountDown,
		setRecorders,
		setIsPrepareScreen,
	]);

	useEffect(() => {
		if (canShowNewFlo && recordState !== 'recording') {
			let interval: NodeJS.Timeout;
			interval = setInterval(() => {
				SendMessageToExtension(getExtensionVersion({}));
			}, 3000);
			return () => {
				if (interval) {
					clearInterval(interval);
				}
			};
		}
	}, [canShowNewFlo, recordState]);

	useEffect(() => {
		if (!isExtensionRecording) {
			if (
				includes(
					['multiple_flo_with_use_here', 'multiple_flo_without_use_here'],
					dialogId
				)
			) {
				dispatch(hideDialogAction(''));
			}
		}
	}, [isExtensionRecording, dialogId]);

	useEffect(() => {
		if (isRecording && !isPaused && (!isOnline || hasFailedAttempts)) {
			onPauseHandler();
			if (!isOnline) {
				displayOfflineMessage();
			} else if (hasFailedAttempts) {
				displayFailedAttemptMessage();
			}
		}
	}, [
		displayOfflineMessage,
		displayFailedAttemptMessage,
		isRecording,
		isPaused,
		onPauseHandler,
		isOnline,
		hasFailedAttempts,
	]);

	useStartNewfloEffect({
		canShowCountDown,
		countDown,
		isPrepareScreen,
		countdownType,
		hasAudioStarted,
		hasResumeAudioStarted,
		setCountDown,
		audio,
		resumeAudio,
		setCanShowCountDown,
		setHasAudioCompleted,
		setCountdownType,
		validExtension,
		setStartAudioStarted,
		setResumeAudioStarted,
	});

	return {
		recordState,
		canShowClose,
		showTemplateDeviceSelection,
		popupDims,
		canShowNewFlo,
		canShowCountDown,
		onClose,
		NEW_FLO_COUNTDOWN_SUB_TITLE,
		NEW_FLO_COUNTDOWN_TITLE,
		NEW_FLO_RESUME_COUNTDOWN_TITLE,
		isPaused,
		countDown,
		selectedTemplate,
		recordingSingleColumn,
		cardStyle,
		audioDeviceChangeHandler,
		iframeDims,
		previewVideoRef,
		getDevicesCallback,
		videoDeviceChangeHandler,
		videoDevice,
		audioDevice,
		onBackHandler,
		onStartHandler,
		devicePermissions,
		settings,
		videoRef,
		isPrepareScreen,
		startTime,
		audioDeviceMeta,
		videoDeviceMeta,
		endTime,
		onPauseHandler,
		onStopHandler,
		durationTime,
		onDiscardHandler,
		startRecording,
		onRestartHandler,
		availableDevices,
		setAudioDevice,
	};
};

export default useNewFloEffect;
