import { Switch as _Switch } from '@mui/material';
import classNames from 'classnames';

import { get } from 'lodash';
import styles from './Switch.module.css';
import { switchType } from './Switch.types';

const Switch = (props: switchType) => {
	const {
		isChecked,
		onToggle,
		label,
		name,
		className,
		defaultChecked,
		labelClassName,
		Icon,
		disabled,
		children,
		styles: propStyles,
	} = props;
	return (
		<div
			className={classNames(styles.switchContainer, className, {
				[styles.disabled]: disabled,
			})}
		>
			<_Switch
				name={name}
				checked={isChecked}
				defaultChecked={defaultChecked}
				onChange={onToggle}
				disabled={disabled}
				classes={{
					root: styles.switchElement,
					switchBase: styles.switchBase,
					track: styles.switchTrack,
					checked: styles.switchChecked,
					thumb: styles.switchThumb,
				}}
			/>
			<p
				className={classNames(styles.switchLabel, labelClassName)}
				style={get(propStyles, 'switchLabel', {})}
			>
				{Icon && <Icon className={styles.icon} />}
				{label}
				{children}
			</p>
		</div>
	);
};

export default Switch;
