import { find, get, isEmpty, reduce } from 'lodash';
import { RootState } from '../../store';
import { FLO_ACTIVE_TAB_EDIT, FLO_ELEMENT_CROP_TYPE } from './Flo.constants';

export const floTracks = (state: RootState) => get(state, 'floPage.tracks');
export const floDuration = (state: RootState) => get(state, 'floPage.duration');
export const floComments = (state: RootState) => get(state, 'floPage.comments');
export const floCommentReplies = (state: RootState) =>
	get(state, 'floPage.commentReplies');
export const floSpaceIdSelector = (state: RootState) =>
	get(state, 'floPage.floDetails.id');
export const floDetailsSelector = (state: RootState) => get(state, 'floPage.floDetails');
export const floMetaOutputStatus = (state: RootState) =>
	get(state, 'floPage.currentMetadata.outputStatus');
export const newCommentStartId = (state: RootState) =>
	get(state, 'floPage.newCommentStartId');
export const getStageEditCommentId = (state: RootState): string =>
	get(state, 'floPage.editingCommentId');
export const floAnnotations = (state: RootState) => get(state, 'floPage.comments.data');
export const createdBySelector = (state: RootState) => ({
	name: get(state, 'floPage.floDetails.createdBy.name'),
	userId: get(state, 'floPage.floDetails.createdBy.userId'),
	profilePicUrl: get(state, 'floPage.floDetails.createdBy.userProfile.profilePicUrl'),
	avatarColor: get(
		state,
		'floPage.floDetails.createdBy.userProfile.profilePreference.avatarColor'
	),
	profilePreference: {
		avatarColor: get(
			state,
			'floPage.floDetails.createdBy.userProfile.profilePreference.avatarColor'
		),
	},
	email: get(state, 'floPage.floDetails.createdBy.email'),
});

export const appliedCropSelector = (state: RootState) => {
	const floResources = get(state, 'floPage.tracks');
	const dims = reduce(
		floResources,
		(
			acc,
			item
		): {
			[key: string]: {
				height: number;
				width: number;
			};
		} => {
			// @ts-ignore
			acc[item.type] = {
				width: item.width,
				height: item.height,
			};
			return acc;
		},
		{}
	);
	const isPublishedView = get(state, 'floPage.tabActive') !== FLO_ACTIVE_TAB_EDIT;
	const cropConfig = find(
		get(
			state,
			get(state, 'floPage.tabActive') !== FLO_ACTIVE_TAB_EDIT
				? 'floPage.publishedEditorsElementsConfig'
				: 'floPage.editorsElementsConfig'
		),
		['editorElementName', FLO_ELEMENT_CROP_TYPE]
	);
	// @ts-ignore
	const windowSize = get(dims, 'screenShare') || get(dims, 'video');
	if (!get(cropConfig, 'properties.canvasRenderConfig.canvasCoordinates')) {
		return {
			enable: false,
			values: {
				x: 0,
				y: 0,
				x2: get(windowSize, 'width', 0),
				y2: get(windowSize, 'height', 0),
				windowSize,
			},
			videoSize: windowSize,
		};
	}
	return {
		enable: isPublishedView ? !isEmpty(cropConfig) : get(cropConfig, 'enabled'),
		values: {
			x: Number(get(cropConfig, 'properties.canvasRenderConfig.canvasCoordinates.x', 0)),
			y: Number(get(cropConfig, 'properties.canvasRenderConfig.canvasCoordinates.y', 0)),
			x2:
				Number(get(cropConfig, 'properties.canvasRenderConfig.canvasCoordinates.x', 0)) +
				Number(
					get(
						cropConfig,
						'properties.canvasRenderConfig.canvasCoordinates.width',
						get(windowSize, 'width', 0)
					)
				),
			y2:
				Number(get(cropConfig, 'properties.canvasRenderConfig.canvasCoordinates.y', 0)) +
				Number(
					get(
						cropConfig,
						'properties.canvasRenderConfig.canvasCoordinates.height',
						get(windowSize, 'height', 0)
					)
				),
			// @ts-ignore
			windowSize: dims.screenShare || dims.video,
		},
		// @ts-ignore
		videoSize: dims.screenShare || dims.video,
	};
};

export const getEditorElementConfigSelector = (state: RootState) => {
	if (get(state, 'floPage.tabActive') !== FLO_ACTIVE_TAB_EDIT) {
		return get(state, 'floPage.publishedEditorsElementsConfig', []);
	}
	return get(state, 'floPage.editorsElementsConfig', []);
};

export const getCurrentFlo = (state: RootState) => get(state, 'floPage.floDetails');

// export const cropConfigSelector = (state: RootState) => {
// 	const floResources = get(state, 'floPage.tracks');
// 	const dims = reduce(
// 		floResources,
// 		(
// 			acc,
// 			item
// 		): {
// 			[key: string]: {
// 				height: number;
// 				width: number;
// 			};
// 		} => {
// 			// @ts-ignore
// 			acc[item.type] = {
// 				width: item.width,
// 				height: item.height,
// 			};
// 			return acc;
// 		},
// 		{}
// 	);
// 	const isPublishedView = get(state, 'floPage.tabActive') !== FLO_ACTIVE_TAB_EDIT;
// 	const cropConfig = find(
// 		get(
// 			state,
// 			get(state, 'floPage.tabActive') !== FLO_ACTIVE_TAB_EDIT
// 				? 'floPage.publishedEditorsElementsConfig'
// 				: 'floPage.editorsElementsConfig'
// 		),
// 		['editorElementName', FLO_ELEMENT_CROP_TYPE]
// 	);
// 	let enable = isPublishedView ? !isEmpty(cropConfig) : false;
// 	let values = {
// 			x: Number(get(cropConfig, 'properties.canvasRenderConfig.canvasCoordinates.x', 0)),
// 			y: Number(get(cropConfig, 'properties.canvasRenderConfig.canvasCoordinates.y', 0)),
// 			x2:
// 				Number(get(cropConfig, 'properties.canvasRenderConfig.canvasCoordinates.x', 0)) +
// 				Number(
// 					get(cropConfig, 'properties.canvasRenderConfig.canvasCoordinates.width', 0)
// 				),
// 			y2:
// 				Number(get(cropConfig, 'properties.canvasRenderConfig.canvasCoordinates.y', 0)) +
// 				Number(
// 					get(cropConfig, 'properties.canvasRenderConfig.canvasCoordinates.height', 0)
// 				),
// 			// @ts-ignore
// 			windowSize: dims.screenShare || dims.video,
// 	};
// 	if (get(state, 'floPage.activeEditElement.show') && get(state, 'floPage.activeEditElement.type') === FLO_ELEMENT_CROP_TYPE) {
// 		enable = true;
// 		// values = {
// 		// 	x: Number(get(state, 'floPage.activeEditElement.element.properties.values.x', 0)),
// 		// 	y: Number(get(state, 'floPage.activeEditElement.element.properties.values.y', 0)),
// 		// 	x2:
// 		// 		Number(get(state, 'floPage.activeEditElement.element.properties.values.x2', 0)) || (
// 		// 			Number(get(state, 'floPage.activeEditElement.element.properties.values.x', 0)) +
// 		// 			Number(
// 		// 				get(state, 'floPage.activeEditElement.element.properties.values.width', get(dims, 'screenShare.width') || 0)
// 		// 			)
// 		// 		),
// 		// 	y2: Number(get(state, 'floPage.activeEditElement.element.properties.values.y2', 0)) || (
// 		// 		Number(get(state, 'floPage.activeEditElement.element.properties.values.y', 0)) +
// 		// 		Number(
// 		// 			get(state, 'floPage.activeEditElement.element.properties.values.height', get(dims, 'screenShare.height') || 0)
// 		// 		)),
// 		// 	// @ts-ignore
// 		// 	windowSize: dims.screenShare || dims.video,
// 		// }
// 	}
// 	return {
// 		enable,
// 		values,
// 		// @ts-ignore
// 		videoSize: dims.screenShare || dims.video,
// 	};
// };

export const cropConfigSelector = (state: RootState) =>
	get(state, 'floPage.postProcessConfigs.crop');
