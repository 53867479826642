import { createSlice } from '@reduxjs/toolkit';
import { LoadingStates } from '../../Common/Common.types';

export interface AvatarListItem {
	avatarFileUrl: string;
	enabled: boolean;
	id: string;
	name: string;
	type: string;
}

export interface ProfileDialogReducerType {
	visible: boolean;
	cancelButtonText?: string;
	floId?: string;
	defaultAvatars: {
		data: AvatarListItem[];
		status: LoadingStates;
	};
}

const initialState = Object.freeze({
	visible: false,
	floId: '',
	cancelButtonText: 'PROFILE_POPUP_SKIP_BUTTON_TEXT',
	defaultAvatars: {
		data: [],
		status: 'loading',
	},
});

const profileDialogReducers = {
	setDefaultAvatars(
		state: ProfileDialogReducerType,
		{
			payload,
		}: {
			payload: {
				data: AvatarListItem[];
				status: LoadingStates;
			};
		}
	) {
		state.defaultAvatars.data = payload.data || [];
		state.defaultAvatars.status = payload.status || 'loaded';
	},
	showDialog(
		state: ProfileDialogReducerType,
		{ payload }: { payload: Partial<ProfileDialogReducerType> }
	) {
		return {
			...state,
			...payload,
			visible: true,
		};
	},
	hideDialog(state: ProfileDialogReducerType, { payload }: { payload: unknown }) {
		return {
			...state,
			visible: false,
		};
	},
	skipConsent(state: ProfileDialogReducerType) {},
	updateProfile(
		state: ProfileDialogReducerType,
		{
			payload,
		}: {
			payload: {
				name?: string;
				avatarId?: string;
			};
		}
	) {},
};

export const profileDialogReducer = createSlice({
	name: 'profileDialogReducer',
	initialState,
	// @ts-ignore
	reducers: profileDialogReducers,
});

export default profileDialogReducer.reducer;

export const showEditProfilePopup = profileDialogReducer.actions.showDialog;
export const hideEditProfilePopup = profileDialogReducer.actions.hideDialog;
export const skipConsentAction = profileDialogReducer.actions.skipConsent;
export const updateProfileAction = profileDialogReducer.actions.updateProfile;
export const setDefaultAvatarsAction = profileDialogReducer.actions.setDefaultAvatars;
