import { Box } from '@mui/material';
import { get, includes } from 'lodash';
import { put, select } from 'redux-saga/effects';
import { showDialogAction } from '../Components/Dialog/Dialog.reducer';
import { showEditProfilePopup } from '../Components/ProfileDialog/ProfileDialog.reducer';
import { RootState } from '../store';
import { ReactComponent as Oops } from './images/oops.svg';

export function* commonErrorPopup(e?: unknown) {
	if (!includes([402, 412], get(e, 'response.status'))) {
		yield put(
			showDialogAction({
				header: '',
				body: (
					<Box sx={{ textAlign: 'center' }}>
						<Oops />
					</Box>
				),
			})
		);
	}
}

export function* AskForConsent() {
	// @ts-ignore
	const profileSkipped = yield select((state: RootState): string =>
		get(state, 'floPage.floDetails.floUserConsent.profileSkipped')
	);
	const inFloPage = /\/flos\/.+/.test(location.pathname);
	// @ts-ignore
	const floId = yield select((state: RootState): string =>
		get(state, 'floPage.floDetails.id')
	);
	if (!profileSkipped) {
		yield put(
			showEditProfilePopup({
				floId: inFloPage ? floId : '',
			})
		);
	}
}
