import { createSlice } from '@reduxjs/toolkit';
import { concat, find, get, map } from 'lodash';
import {
	EmbedOptionType,
	FlospaceUsageDetailsType,
	setSettingsType,
} from '../Flo/Flo.types';
import {
	FloPagination,
	flosData,
	FlosPayloadType,
	ManageFlosReducerType,
	PublishedFloType,
} from './ManageFlos.types';

const initialState = Object.freeze({
	showFlos: false,
	data: [],
	EmbedOptions: [],
	loading: false,
	errored: false,
	filter: 'type:all',
	floStreams: [],
	moveFeature: {
		draggedFolder: null,
		droppedFolder: null,
		draggedFile: null,
	},
	folderFloAction: {
		floId: '',
		folderId: '',
		type: 'rename',
		isFolder: false,
	},
	folders: {
		accordion: {},
		data: [],
		pagination: {
			hasMore: false,
			pageNo: 1,
			pageSize: 100,
			totalRecords: 0,
		},
	},
	flos: {},
	fileFolderErrors: {},
	pagination: {
		hasMore: false,
		pageNo: 1,
		pageSize: 100,
		totalRecords: 0,
	},
	collaborators: {},
	publishedFloList: [],
	publishedFloMap: {},
	publishedFloListPagination: {
		hasMore: false,
		pageNo: 1,
		pageSize: 30,
		totalRecords: 0,
	},
	publishedFloListSelector: [],
	publishedFloListSelectorPagination: {
		hasMore: false,
		pageNo: 1,
		pageSize: 30,
		totalRecords: 0,
	},
});

const manageFloReducers = {
	getFlos(
		state: ManageFlosReducerType,
		{
			payload,
		}: {
			payload: {
				folderId?: string;
				floSpaceId?: string;
				filter?: string;
				pageNo: Number;
				pageSize?: Number;
			};
		}
	) {},
	setFlos(
		state: ManageFlosReducerType,
		{
			payload,
		}: {
			payload: {
				folderId: string;
				pagination: FloPagination;
				data: flosData;
			};
		}
	) {
		const { floMetaData, userData } = payload.data;
		const newFloMetaData = map(floMetaData, (item) => {
			const { createdBy } = item;
			const user = find(userData, { userId: createdBy });
			return {
				...item,
				outputTypes: map(item.floOutputMetadata, (meta) => meta.outputType),
				createdBy: user,
			};
		});

		if (payload.pagination.pageNo !== 1) {
			state.flos[payload.folderId].data =
				state.flos[payload.folderId].data.concat(newFloMetaData);
		} else {
			state.flos[payload.folderId].data = newFloMetaData;
		}
		state.flos[payload.folderId].pagination = payload.pagination;
		state.loading = false;
	},
	getFlosError(state: ManageFlosReducerType) {
		state.loading = false;
		state.errored = true;
	},
	setNewFloStreams(
		state: ManageFlosReducerType,
		{ payload }: { payload: MediaStream[] }
	) {
		state.floStreams = payload;
	},
	stopNewFloStreams(state: ManageFlosReducerType) {
		state.floStreams.forEach(
			(stream) => stream && stream.getTracks().forEach((t) => t.stop())
		);
		state.floStreams = [];
	},

	toggleFlos(state: ManageFlosReducerType) {
		state.showFlos = !state.showFlos;
	},
	leaveManageFlosPage() {
		return initialState;
	},
	updateFloStatusRequest(
		state: ManageFlosReducerType,
		{
			payload,
		}: {
			payload: {
				floId: string;
				closed: boolean;
			};
		}
	) {},
	updateFloStatus(
		state: ManageFlosReducerType,
		{
			payload,
		}: {
			payload: {
				floId: string;
				closed: boolean;
			};
		}
	) {},
	updateFloItem(state: ManageFlosReducerType, { payload }: { payload: FlosPayloadType }) {
		state.data = map(state.data, (flo) =>
			flo.id === payload.id
				? {
						...flo,
						...payload,
						duration: payload.duration || flo.duration,
				  }
				: flo
		);
	},
	addNewFlo(state: ManageFlosReducerType, { payload }: { payload: FlosPayloadType }) {
		const internalFolder = state.folders.data.find((i) => i.internalFolder);
		const folder = payload.folderId
			? state.folders.data.find((i) => i.id === payload.folderId)
			: {};
		const folderId = get(folder, 'id') || get(internalFolder, 'id');
		if (internalFolder) {
			state.flos[folderId].data = concat([payload], state.flos[folderId].data);
			state.flos[folderId].pagination.totalRecords += 1;
		}
	},
	setSettings(state: ManageFlosReducerType, { payload }: { payload: setSettingsType }) {},

	updateFloMeta(
		state: ManageFlosReducerType,
		{
			payload,
		}: {
			payload: {
				folderId?: string;
				name?: string;
				description?: string;
				actualName?: string;
				floId: string;
				outputTypeId?: string;
			};
		}
	) {},
	updateFloMetaSuccess(
		state: ManageFlosReducerType,
		{
			payload,
		}: {
			payload: {
				folderId?: string;
				name?: string;
				heading?: string;
				description?: string;
				actualName?: string;
				floId: string;
				outputTypeId?: string;
				outputStatus?: string;
			};
		}
	) {},
	setEmbedCodeOptions(
		state: ManageFlosReducerType,
		{ payload }: { payload: EmbedOptionType[] }
	) {
		state.EmbedOptions = payload;
	},
	getEmbedCodeOption(state: ManageFlosReducerType) {},
	getPublishedFloList(
		state: ManageFlosReducerType,
		{ payload }: { payload: { floSpaceId: string; pageNo?: number } }
	) {},
	getPublishedFloListSuccess(
		state: ManageFlosReducerType,
		{
			payload,
		}: {
			payload: { floMap: {}; floList: PublishedFloType[]; paginationInfo: FloPagination };
		}
	) {
		if (payload.paginationInfo.pageNo === 1) {
			state.publishedFloList = payload.floList;
			state.publishedFloMap = payload.floMap;
		} else {
			state.publishedFloList = state.publishedFloList.concat(payload.floList);
			state.publishedFloMap = { ...state.publishedFloMap, ...payload.floMap };
		}

		state.publishedFloListPagination = payload.paginationInfo;
	},
	getPublishedFloListSelector(
		state: ManageFlosReducerType,
		{ payload }: { payload: { floSpaceId: string; pageNo?: number } }
	) {},
	getPublishedFloListSelectorSuccess(
		state: ManageFlosReducerType,
		{
			payload,
		}: {
			payload: { floMap: {}; floList: PublishedFloType[]; paginationInfo: FloPagination };
		}
	) {
		if (payload.paginationInfo.pageNo === 1) {
			state.publishedFloListSelector = payload.floList;
			state.publishedFloMap = payload.floMap;
		} else {
			state.publishedFloListSelector = state.publishedFloListSelector.concat(
				payload.floList
			);
			state.publishedFloMap = { ...state.publishedFloMap, ...payload.floMap };
		}
		state.publishedFloListSelectorPagination = payload.paginationInfo;
	},
	getUsageDetails(
		state: ManageFlosReducerType,
		{ payload }: { payload: { floSpaceId: string } }
	) {},
	setUsageDetails(
		state: ManageFlosReducerType,
		{
			payload,
		}: {
			payload: { [key: string]: FlospaceUsageDetailsType };
		}
	) {
		state.usageDetails = payload;
	},
};

export const manageFloPage = createSlice({
	name: 'manageFlosPage',
	initialState,
	reducers: manageFloReducers,
});

export default manageFloPage.reducer;

export const toggleFlos = manageFloPage.actions.toggleFlos;
export const getFlos = manageFloPage.actions.getFlos;
export const setFlosAction = manageFloPage.actions.setFlos;
export const getFlosErrorAction = manageFloPage.actions.getFlosError;
export const setNewFloStreamsAction = manageFloPage.actions.setNewFloStreams;
export const stopNewFloStreamsAction = manageFloPage.actions.stopNewFloStreams;
export const leaveManageFlosPageAction = manageFloPage.actions.leaveManageFlosPage;

export const updateFloItem = manageFloPage.actions.updateFloItem;
export const updateFloStatus = manageFloPage.actions.updateFloStatus;
export const updateFloStatusAction = manageFloPage.actions.updateFloStatusRequest;

export const setSettingsManageFloAccess = manageFloPage.actions.setSettings;

export const updateFloMeta = manageFloPage.actions.updateFloMeta;
export const updateFloMetaSuccess = manageFloPage.actions.updateFloMetaSuccess;

export const setEmbedCodeOptions = manageFloPage.actions.setEmbedCodeOptions;
export const getEmbedCodeOption = manageFloPage.actions.getEmbedCodeOption;

export const getPublishedFloList = manageFloPage.actions.getPublishedFloList;
export const getPublishedFloListSuccess =
	manageFloPage.actions.getPublishedFloListSuccess;
export const getPublishedFloListSelector =
	manageFloPage.actions.getPublishedFloListSelector;
export const getPublishedFloListSelectorSuccess =
	manageFloPage.actions.getPublishedFloListSelectorSuccess;
export const getUsageDetails = manageFloPage.actions.getUsageDetails;
export const setUsageDetails = manageFloPage.actions.setUsageDetails;
