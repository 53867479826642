import { Button, Paper } from '@mui/material';
import { IconAlertTriangleFilled as WarningIcon } from '@tabler/icons-react';
import classNames from 'classnames';
import { get } from 'lodash';
import { ReactComponentLike } from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { browserName, isChrome } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { FloCreationTemplates } from '../../../../../Common/Common.types';
import { ReactComponent as CUST_DEMO } from '../../../../../Common/images/cust-demo-wo-border.svg';
import { ReactComponent as FilmStrip } from '../../../../../Common/images/FilmStrip.svg';
import { ReactComponent as GIF } from '../../../../../Common/images/gif-wo-border.svg';
import { ReactComponent as GUIDE } from '../../../../../Common/images/guide-wo-bg.svg';
import { ReactComponent as InteractiveDemoSvg } from '../../../../../Common/images/ID-Icon.svg';
import Translations from '../../../../../Common/Translate.utils';
import {
	hideDialogAction,
	showDialogAction,
} from '../../../../../Components/Dialog/Dialog.reducer';
import styles from './TemplateCard.module.css';

const TemplateCard = ({
	template,
	classes,
	hideHeader,
	onClick,
}: {
	template: FloCreationTemplates;
	classes?: {
		[key: string]: string;
	};
	hideHeader?: boolean;
	onClick(template: FloCreationTemplates, showDeviceSelection?: boolean): void;
}) => {
	const dispatch = useDispatch();
	const { TEMPLATE_COMING_SOON_TXT, VIEW_TEMPLATE_TXT } = Translations;
	const FloIcons = {
		video: FilmStrip,
		document: GUIDE,
		gif: GIF,
		interactive_demo: InteractiveDemoSvg,
		mouse_pointer: CUST_DEMO,
		default: FilmStrip,
	};
	const imageRef = React.useRef<HTMLImageElement | null>(null);
	const disable = get(template, 'status') !== 'ACTIVE';
	const onClickCallback = useCallback(
		(event?: React.MouseEvent, showDeviceSelection?: boolean) => {
			if (template.status === 'ACTIVE') {
				onClick(template, showDeviceSelection);
			}
		},
		[onClick, template]
	);
	const exampleFloLinkObj = get(template, 'exampleFlo', '');
	const exampleFloThumbnail =
		get(exampleFloLinkObj, 'thumbnailUrl') || get(template, 'illustrationImage', '');
	const exampleFloGIF =
		get(exampleFloLinkObj, 'gifUrl') || get(template, 'illustrationImage', '');

	const SVG = (get(FloIcons, template.iconType) ||
		FloIcons.default) as ReactComponentLike;
	useEffect(() => {
		if (!imageRef.current) return;
		imageRef.current.src = exampleFloThumbnail;
	}, [imageRef]);

	const onMouseEnter = useCallback(() => {
		if (!imageRef.current) return;
		imageRef.current.src = exampleFloGIF;
	}, [imageRef, template]);

	const onMouseLeave = useCallback(() => {
		if (!imageRef.current) return;
		imageRef.current.src = exampleFloThumbnail;
	}, [imageRef, template]);

	const isExtensionInstalled = useSelector((state) =>
		get(state, 'onBoarding.extensionInstalled')
	);
	return (
		<Paper
			className={classNames(styles.templateItem, get(classes, 'templateItem'), {
				[styles.disabled]: disable,
			})}
			elevation={0}
			onClick={(event) => {
				if (!isChrome && isExtensionInstalled) {
					dispatch(
						showDialogAction({
							header: (
								<span>
									<WarningIcon className={styles.warningIcon} /> {browserName} is
									unsupported
								</span>
							),
							showCancel: true,
							showConfirm: true,
							confirmButtonText: 'Cancel',
							confirmButtonType: 'confirm',
							cancelButtonText: (
								<span className={styles.cancelButton}>Continue anyway</span>
							),
							onConfirm: () => {
								dispatch(hideDialogAction(''));
							},
							onCancel: () => {
								onClickCallback(event, true);
							},
							body: `Currently ${browserName} is not fully supported and you might experience some issues during capture. Use Chrome for better experience`,
						})
					);
				} else {
					onClickCallback(event, true);
				}
			}}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<div className={styles.templatePreviewWrapper}>
				<div className={styles.templatePreviewImg}>
					<img
						ref={imageRef}
						width="100%"
						height="100%"
						// src={get(template, 'illustrationImage', '')}
						alt=""
						className={styles.templateImage}
					/>
				</div>

				{template.status !== 'COMING_SOON' && <div className={styles.templateBg}></div>}
				{template.status !== 'COMING_SOON' && (
					<div className={styles.templateHoverWrapper}>
						<div className={styles.templateHover}></div>
						<Button
							variant="contained"
							className={styles.useTemplateBtn}
							onClick={(event) => {
								event.stopPropagation();
								onClickCallback();
							}}
						>
							{VIEW_TEMPLATE_TXT}
						</Button>
					</div>
				)}
				{template.status === 'COMING_SOON' && (
					<div className={styles.comingSoon}>{TEMPLATE_COMING_SOON_TXT}</div>
				)}
			</div>
			{!hideHeader && (
				<div className={classNames(styles.templateTitle, get(classes, 'templateTitle'))}>
					<SVG />
					<span
						title={get(template, 'name')}
						className={classNames(
							styles.templateTitleText,
							get(classes, 'templateTitleText')
						)}
					>
						{get(template, 'name')}
					</span>
				</div>
			)}
		</Paper>
	);
};

export default TemplateCard;
