import { get } from 'lodash';
import qs from 'qs';
import { FLO_ELEMENT_INTERACTION_CUE_TYPE } from '../Pages/Flo/Flo.constants';
import { ActiveEditElement } from '../Pages/Flo/Flo.types';
import { RootState } from '../store';

export const currentProfileSelector = (state: RootState) => ({
	name: get(state, 'routerContainer.profile.name'),
	userId: get(state, 'routerContainer.profile.userId'),
	profilePicUrl: get(state, 'routerContainer.profile.userProfile.profilePicUrl'),
	avatarColor: get(
		state,
		'routerContainer.profile.userProfile.profilePreference.avatarColor'
	),
	profilePreference: {
		avatarColor: get(
			state,
			'routerContainer.profile.userProfile.profilePreference.avatarColor'
		),
	},
	email: get(state, 'routerContainer.profile.email'),
	is_anonymous: get(state, 'routerContainer.profile.anonymous'),
});

const a = document.createElement('a');

export const errorPageSelector = (state: RootState) => {
	try {
		const errorUrl = get(state, 'routes.errorUrl') || '';
		a.href = errorUrl;
		// @ts-ignore
		const { error, errorHeader }: { error: string; errorHeader: string } = qs.parse(
			(a || '').search.replace('?', '')
		);

		return {
			error: decodeURI(error || ''),
			errorHeader: decodeURI(errorHeader || ''),
		};
	} catch (e) {
		console.warn(e);
		return '';
	}
};

export const isExtensionInstalledSelector = (state: RootState) =>
	get(state, 'onBoarding.extensionInstalled');

export const ExtensionVersionSelector = (state: RootState) =>
	get(state, 'onBoarding.extensionVersion');

export const interactiveDialogSelector = (state: RootState) => {
	const activeEditElement = get(
		state,
		'floPage.activeEditElement',
		{}
	) as ActiveEditElement;
	return {
		show:
			get(activeEditElement, 'show', false) &&
			get(activeEditElement, 'type') === FLO_ELEMENT_INTERACTION_CUE_TYPE,
		button: get(activeEditElement, 'element.properties.values.button', []),
		positionOnScreen: get(
			activeEditElement,
			'element.properties.values.positionOnScreen',
			'center'
		),
		name: get(activeEditElement, 'element.properties.values.heading', ''),
		subheading: get(activeEditElement, 'element.properties.values.subheading', ''),
	};
};
