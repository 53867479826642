export const TemplateCategories = [
	{
		label: 'Recently used',
		key: 'recent',
	},
	// {
	// 	label: 'Handpicked for you',
	// 	key: 'user',
	// },
	{
		label: 'Create your own',
		key: 'custom',
	},
	{
		label: 'Upload video',
		key: 'manual_upload',
		// id: 'manual_upload',
	},
	// {
	// 	label: 'All templates',
	// 	key: 'all',
	// 	nonClickable: true,
	// },
];
