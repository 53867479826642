export const mockDashboardSchema = {
	name: 'insights-dashboard',
	filters: [
		{
			name: 'flo',
			label: 'Flo',
			type: 'dropdown',
			additionalProperties: {
				linkText: 'View Flo',
				showLink: true,
				urlPattern: '/flos/{flo}',
			},
			values: null,
			apiConfig: {
				method: 'GET',
				url: '/v1/analytics/dashboard/filters/flo',
				headers: [
					{
						name: 'Content-Type',
						value: 'application/json',
						paramType: 'constant',
						isRequired: null,
					},
					{
						name: 'Authorization',
						value: 'Bearer ',
						paramType: 'dyanmic',
						isRequired: null,
					},
				],
				queryParams: [
					{
						name: 'floSpaceId',
						value: 'Flo Space Id',
						paramType: 'dynamic',
						isRequired: true,
					},
					{
						name: 'page',
						value: 1,
						paramType: 'input',
						isRequired: false,
					},
					{
						name: 'pageSize',
						value: 10,
						paramType: 'input',
						isRequired: false,
					},
				],
			},
			options: [
				{
					key: '6597d09cbfa86f5bccbfe691',
					label: 'interactive demo - \n     Bar charts in Python\n',
					value: '6597d09cbfa86f5bccbfe691',
					icon: 'interactive demo',
					defaultValue: true,
				},
				{
					key: '6565f38c6346314ede0db8e8',
					label:
						'interactive demo - Create product demos and explainer videos in minutes | Floik',
					value: '6565f38c6346314ede0db8e8',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '65828777d6fa836ba988f8e9',
					label: 'video - 20 Dec',
					value: '65828777d6fa836ba988f8e9',
					icon: 'video',
					defaultValue: null,
				},
				{
					key: '654a1f313fb81514488fdbd0',
					label: 'interactive demo - 07 Nov',
					value: '654a1f313fb81514488fdbd0',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '65639a54d6b89352e1372511',
					label: 'interlacing hs and cta ',
					value: '65639a54d6b89352e1372511',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '65566394d05e2130f5c022ff',
					label:
						'interactive demo - Create product demos and explainer videos in minutes | Floik',
					value: '65566394d05e2130f5c022ff',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '6596e6ca690a6007118df113',
					label: 'video - \n     Bar charts in Python\n',
					value: '6596e6ca690a6007118df113',
					icon: 'video',
					defaultValue: null,
				},
				{
					key: '6594e8c88a91105761b9958c',
					label: 'interactive demo - Untitled document - Google Docs',
					value: '6594e8c88a91105761b9958c',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '65686d8e30cff664c29309f4',
					label:
						'interactive demo - Create product demos and explainer videos in minutes | Floik',
					value: '65686d8e30cff664c29309f4',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '657c345f37593457fd1843b5',
					label: 'video - Create product demos and explainer videos in minutes | Floik',
					value: '657c345f37593457fd1843b5',
					icon: 'video',
					defaultValue: null,
				},
				{
					key: '6564c150da8a6b6ef299e6dc',
					label:
						'interactive demo - Create product demos and explainer videos in minutes | Floik',
					value: '6564c150da8a6b6ef299e6dc',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '6560f944d6b89352e1371f13',
					label: 'video - interactive demo - 25 Nov',
					value: '6560f944d6b89352e1371f13',
					icon: 'video',
					defaultValue: null,
				},
				{
					key: '6560f2acd6b89352e1371ef7',
					label: 'interactive demo - 25 Nov',
					value: '6560f2acd6b89352e1371ef7',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '655ca9b7d05e2130f5c03280',
					label:
						'interactive demo - Create product demos and explainer videos in minutes | Floik',
					value: '655ca9b7d05e2130f5c03280',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '655061ee3fb81514488fecde',
					label: 'video - 12 Nov',
					value: '655061ee3fb81514488fecde',
					icon: 'video',
					defaultValue: null,
				},
				{
					key: '654e05443fb81514488fe6a4',
					label:
						'interactive demo - Create product demos and explainer videos in minutes | Floik',
					value: '654e05443fb81514488fe6a4',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '654e04a53fb81514488fe697',
					label: 'video - Create product demos and explainer videos in minutes | Floik',
					value: '654e04a53fb81514488fe697',
					icon: 'video',
					defaultValue: null,
				},
				{
					key: '654de6da3fb81514488fe65d',
					label: 'video - 10 Nov',
					value: '654de6da3fb81514488fe65d',
					icon: 'video',
					defaultValue: null,
				},
				{
					key: '654de7143fb81514488fe662',
					label: 'interactive demo - 10 Nov',
					value: '654de7143fb81514488fe662',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '654b8dce3fb81514488fdfac',
					label: 'video - 08 Nov',
					value: '654b8dce3fb81514488fdfac',
					icon: 'video',
					defaultValue: null,
				},
				{
					key: '654c948c3fb81514488fe314',
					label: 'video - 09 Nov',
					value: '654c948c3fb81514488fe314',
					icon: 'video',
					defaultValue: null,
				},
				{
					key: '654b8e553fb81514488fdfc5',
					label: 'interactive demo - 08 Nov',
					value: '654b8e553fb81514488fdfc5',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '654b8e283fb81514488fdfbc',
					label: 'interactive demo - 08 Nov',
					value: '654b8e283fb81514488fdfbc',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '654b8df73fb81514488fdfb1',
					label: 'interactive demo - 08 Nov',
					value: '654b8df73fb81514488fdfb1',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '654b55f13fb81514488fdf92',
					label: 'interactive demo - 08 Nov',
					value: '654b55f13fb81514488fdf92',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '654b42713fb81514488fdf71',
					label: 'interactive demo - 08 Nov',
					value: '654b42713fb81514488fdf71',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '654b1c533fb81514488fdf45',
					label: 'interactive demo - 08 Nov',
					value: '654b1c533fb81514488fdf45',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '654b1af83fb81514488fdf39',
					label: 'interactive demo - 08 Nov',
					value: '654b1af83fb81514488fdf39',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '654b1aa93fb81514488fdf2d',
					label: 'interactive demo - 08 Nov',
					value: '654b1aa93fb81514488fdf2d',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '654b17f03fb81514488fdf21',
					label: 'interactive demo - 08 Nov',
					value: '654b17f03fb81514488fdf21',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '654a34043fb81514488fdc1f',
					label: 'interactive demo - 07 Nov',
					value: '654a34043fb81514488fdc1f',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '654a350c3fb81514488fdc2d',
					label: 'interactive demo - 07 Nov',
					value: '654a350c3fb81514488fdc2d',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '654a34dd3fb81514488fdc22',
					label: 'interactive demo - 07 Nov',
					value: '654a34dd3fb81514488fdc22',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '654a2eab3fb81514488fdc09',
					label: 'interactive demo - 07 Nov',
					value: '654a2eab3fb81514488fdc09',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '654a2e5f3fb81514488fdbfd',
					label: 'interactive demo - 07 Nov',
					value: '654a2e5f3fb81514488fdbfd',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '654a144d3fb81514488fdbc2',
					label: 'interactive demo - 07 Nov',
					value: '654a144d3fb81514488fdbc2',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '654a14223fb81514488fdbb7',
					label: 'interactive demo - 07 Nov',
					value: '654a14223fb81514488fdbb7',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '654a13a23fb81514488fdba4',
					label: 'interactive demo - 07 Nov',
					value: '654a13a23fb81514488fdba4',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '654a13693fb81514488fdb9f',
					label: 'interactive demo - 07 Nov',
					value: '654a13693fb81514488fdb9f',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '654a129f3fb81514488fdb92',
					label: 'interactive demo - 07 Nov',
					value: '654a129f3fb81514488fdb92',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '654a125f3fb81514488fdb86',
					label: 'interactive demo - 07 Nov',
					value: '654a125f3fb81514488fdb86',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '654a0fd73fb81514488fdb7a',
					label: 'interactive demo - 07 Nov',
					value: '654a0fd73fb81514488fdb7a',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '654a0f9d3fb81514488fdb6e',
					label: 'interactive demo - 07 Nov',
					value: '654a0f9d3fb81514488fdb6e',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '654a0f513fb81514488fdb62',
					label: 'interactive demo - 07 Nov',
					value: '654a0f513fb81514488fdb62',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '654a0cbf3fb81514488fdb56',
					label: 'interactive demo - 07 Nov',
					value: '654a0cbf3fb81514488fdb56',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '6549f9893fb81514488fdb42',
					label: 'interactive demo - 07 Nov',
					value: '6549f9893fb81514488fdb42',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '6549f9293fb81514488fdb34',
					label: 'interactive demo - 07 Nov',
					value: '6549f9293fb81514488fdb34',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '6549f81c3fb81514488fdb24',
					label: 'interactive demo - 07 Nov',
					value: '6549f81c3fb81514488fdb24',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '6549f7a63fb81514488fdb18',
					label: 'interactive demo - 07 Nov',
					value: '6549f7a63fb81514488fdb18',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '6549ec953fb81514488fdaf5',
					label: 'interactive demo - 07 Nov',
					value: '6549ec953fb81514488fdaf5',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '6549ec443fb81514488fdae9',
					label: 'interactive demo - 07 Nov',
					value: '6549ec443fb81514488fdae9',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '6549eafe3fb81514488fdadd',
					label: 'interactive demo - 07 Nov',
					value: '6549eafe3fb81514488fdadd',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '6549dfaf3fb81514488fdacc',
					label: 'interactive demo - 07 Nov',
					value: '6549dfaf3fb81514488fdacc',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '653f967f68536f207db661a5',
					label:
						'interactive demo - Create product demos and explainer videos in minutes | Floik',
					value: '653f967f68536f207db661a5',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '653f961168536f207db66196',
					label: 'interactive demo - 30 Oct',
					value: '653f961168536f207db66196',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '65325f6b14da12466f14e840',
					label: 'interactive demo - 20 Oct',
					value: '65325f6b14da12466f14e840',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '653f4ff068536f207db6616d',
					label: 'interactive demo - 30 Oct',
					value: '653f4ff068536f207db6616d',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '653bfb58eaf6b23d2d0c24ef',
					label: 'interactive demo - 27 Oct',
					value: '653bfb58eaf6b23d2d0c24ef',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '653bbaf9eaf6b23d2d0c2478',
					label:
						'interactive demo - Create product demos and explainer videos in minutes | Floik',
					value: '653bbaf9eaf6b23d2d0c2478',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '653bb869eaf6b23d2d0c2466',
					label:
						'interactive demo - Create product demos and explainer videos in minutes | Floik',
					value: '653bb869eaf6b23d2d0c2466',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '653a2b3b9823df17ad1c5e01',
					label: 'interactive demo - 26 Oct',
					value: '653a2b3b9823df17ad1c5e01',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '652e7927b3016b6a9842daa3',
					label:
						'interactive demo - Create product demos and explainer videos in minutes | Floik',
					value: '652e7927b3016b6a9842daa3',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '651d665f7fbe78753958538f',
					label: 'interactive demo - Getting Started | Redux Toolkit',
					value: '651d665f7fbe78753958538f',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '65207a9f1b90fa75defa7de2',
					label:
						'interactive demo - Create product demos and explainer videos in minutes | Floik',
					value: '65207a9f1b90fa75defa7de2',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '6527df56c590617bd84a314e',
					label: 'video - 12 Oct',
					value: '6527df56c590617bd84a314e',
					icon: 'video',
					defaultValue: null,
				},
				{
					key: '65170b1f5a4c494b64e4f540',
					label: 'interactive demo - 29 Sep',
					value: '65170b1f5a4c494b64e4f540',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '651415349a389e3252a6cac2',
					label: 'interactive demo - 27 Sep',
					value: '651415349a389e3252a6cac2',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '651418909a389e3252a6caf6',
					label: 'interactive demo - 27 Sep',
					value: '651418909a389e3252a6caf6',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '651418f89a389e3252a6cb01',
					label: 'reposition click',
					value: '651418f89a389e3252a6cb01',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '651416889a389e3252a6cae1',
					label: 'interactive demo - 27 Sep',
					value: '651416889a389e3252a6cae1',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '651416559a389e3252a6cadc',
					label: 'interactive demo - 27 Sep',
					value: '651416559a389e3252a6cadc',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '651415e59a389e3252a6cac7',
					label: 'interactive demo - 27 Sep',
					value: '651415e59a389e3252a6cac7',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '651412cb9a389e3252a6caad',
					label: 'interactive demo - 27 Sep',
					value: '651412cb9a389e3252a6caad',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '65140f5c9a389e3252a6ca98',
					label: 'interactive demo - 27 Sep',
					value: '65140f5c9a389e3252a6ca98',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '65140f0e9a389e3252a6ca93',
					label: 'interactive demo - 27 Sep',
					value: '65140f0e9a389e3252a6ca93',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '650ab24c116a2f387f27c90d',
					label:
						'interactive demo - Create product demos and explainer videos in minutes | Floik',
					value: '650ab24c116a2f387f27c90d',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64f97da03421e9284552c2ef',
					label: 'video - 07 Sep',
					value: '64f97da03421e9284552c2ef',
					icon: 'video',
					defaultValue: null,
				},
				{
					key: '650937c9116a2f387f27c5e5',
					label:
						'interactive demo - Create product demos and explainer videos in minutes | Floik',
					value: '650937c9116a2f387f27c5e5',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '65001690116a2f387f27b296',
					label: 'interactive demo - \n      Log in to New Relic\n    ',
					value: '65001690116a2f387f27b296',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '6501cd39116a2f387f27b5a0',
					label: 'video - Create product demos and explainer videos in minutes | Floik',
					value: '6501cd39116a2f387f27b5a0',
					icon: 'video',
					defaultValue: null,
				},
				{
					key: '64fa05933421e9284552c46c',
					label: 'interactive demo - \n      Log in to New Relic\n    ',
					value: '64fa05933421e9284552c46c',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64f9cfed3421e9284552c3f2',
					label:
						'interactive demo - Create product demos and explainer videos in minutes | Floik',
					value: '64f9cfed3421e9284552c3f2',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64f9cf303421e9284552c3e8',
					label:
						'interactive demo - Create product demos and explainer videos in minutes | Floik',
					value: '64f9cf303421e9284552c3e8',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64f9ce543421e9284552c3de',
					label:
						'interactive demo - Create product demos and explainer videos in minutes | Floik',
					value: '64f9ce543421e9284552c3de',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64f9caf13421e9284552c3d4',
					label:
						'interactive demo - Create product demos and explainer videos in minutes | Floik',
					value: '64f9caf13421e9284552c3d4',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64f9c9a23421e9284552c3ca',
					label:
						'interactive demo - Create product demos and explainer videos in minutes | Floik',
					value: '64f9c9a23421e9284552c3ca',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64f9c87b3421e9284552c3bf',
					label:
						'interactive demo - Create product demos and explainer videos in minutes | Floik',
					value: '64f9c87b3421e9284552c3bf',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64f9c5a23421e9284552c3b5',
					label:
						'interactive demo - Create product demos and explainer videos in minutes | Floik',
					value: '64f9c5a23421e9284552c3b5',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64f9c4a43421e9284552c3ab',
					label:
						'interactive demo - Create product demos and explainer videos in minutes | Floik',
					value: '64f9c4a43421e9284552c3ab',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64f9c3583421e9284552c3a1',
					label: 'interactive demo - Getting Started | Redux Toolkit',
					value: '64f9c3583421e9284552c3a1',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64f9c2bc3421e9284552c397',
					label: 'interactive demo - Getting Started | Redux Toolkit',
					value: '64f9c2bc3421e9284552c397',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64f9a83b3421e9284552c354',
					label: 'interactive demo - 07 Sep',
					value: '64f9a83b3421e9284552c354',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64f97afe3421e9284552c2e1',
					label: 'interactive demo - 07 Sep',
					value: '64f97afe3421e9284552c2e1',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64f973003421e9284552c297',
					label: 'video - 07 Sep',
					value: '64f973003421e9284552c297',
					icon: 'video',
					defaultValue: null,
				},
				{
					key: '64f89cfa3421e9284552bfc2',
					label: 'interactive demo - 06 Sep',
					value: '64f89cfa3421e9284552bfc2',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64f77be53421e9284552bdbc',
					label:
						'interactive demo - Create product demos and explainer videos in minutes | Floik',
					value: '64f77be53421e9284552bdbc',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64f7775e3421e9284552bdb1',
					label:
						'interactive demo - Create product demos and explainer videos in minutes | Floik',
					value: '64f7775e3421e9284552bdb1',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64f76fe13421e9284552bb21',
					label: 'interactive demo - 05 Sep',
					value: '64f76fe13421e9284552bb21',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64f6ee663421e9284552ba4b',
					label: 'video - Getting Started | Redux Toolkit',
					value: '64f6ee663421e9284552ba4b',
					icon: 'video',
					defaultValue: null,
				},
				{
					key: '64f4d6ebcec34a497b59ba74',
					label: 'interactive demo - 04 Sep',
					value: '64f4d6ebcec34a497b59ba74',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64f4bc17cec34a497b59b7d1',
					label: 'interactive demo - 03 Sep',
					value: '64f4bc17cec34a497b59b7d1',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64f0ac1fa9e42d0274a1881f',
					label: 'interactive demo - 31 Aug',
					value: '64f0ac1fa9e42d0274a1881f',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64f07f5f9923fc77fee48099',
					label: 'interactive demo - 31 Aug',
					value: '64f07f5f9923fc77fee48099',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64efa028fee00b5a6384e919',
					label: 'interactive demo - 31 Aug',
					value: '64efa028fee00b5a6384e919',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64ee4ea47cdc9371c83bcce6',
					label: 'interactive demo - 30 Aug',
					value: '64ee4ea47cdc9371c83bcce6',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64ee4af77cdc9371c83bccda',
					label: 'interactive demo - 30 Aug',
					value: '64ee4af77cdc9371c83bccda',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64ee31131300191b0e160708',
					label: 'interactive demo - 29 Aug',
					value: '64ee31131300191b0e160708',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64ec8ba5458d1a04b65142d3',
					label: 'video - 28 Aug',
					value: '64ec8ba5458d1a04b65142d3',
					icon: 'video',
					defaultValue: null,
				},
				{
					key: '64eb7667a2526d3f56f7d9fd',
					label: 'interactive demo - 27 Aug',
					value: '64eb7667a2526d3f56f7d9fd',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64eba55ee39d710aec9770da',
					label: 'interactive demo - 28 Aug',
					value: '64eba55ee39d710aec9770da',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64eb2141a2526d3f56f7d9e7',
					label: 'video - 27 Aug',
					value: '64eb2141a2526d3f56f7d9e7',
					icon: 'video',
					defaultValue: null,
				},
				{
					key: '64eb1f7ea2526d3f56f7d9dc',
					label: 'interactive demo - 27 Aug',
					value: '64eb1f7ea2526d3f56f7d9dc',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64e73788a2526d3f56f7d315',
					label: 'video - Guide - interactive demo - 24 Aug',
					value: '64e73788a2526d3f56f7d315',
					icon: 'video',
					defaultValue: null,
				},
				{
					key: '64e86c35a2526d3f56f7d5c3',
					label: 'interactive demo - 25 Aug',
					value: '64e86c35a2526d3f56f7d5c3',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64e66780a2526d3f56f7d2f6',
					label: 'interactive demo - 24 Aug',
					value: '64e66780a2526d3f56f7d2f6',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64e5e8c1a2526d3f56f7d02e',
					label: 'interactive demo - 23 Aug',
					value: '64e5e8c1a2526d3f56f7d02e',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64e3b9eaa2526d3f56f7cc10',
					label: 'interactive demo - 22 Aug',
					value: '64e3b9eaa2526d3f56f7cc10',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64e3b26da2526d3f56f7cc00',
					label: 'interactive demo - 22 Aug',
					value: '64e3b26da2526d3f56f7cc00',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64e31e449a12c72b950e816e',
					label: 'interactive demo - 21 Aug',
					value: '64e31e449a12c72b950e816e',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64e31cf39a12c72b950e815c',
					label: 'interactive demo - 21 Aug',
					value: '64e31cf39a12c72b950e815c',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64e270aa9a12c72b950e812e',
					label: 'interactive demo - 21 Aug',
					value: '64e270aa9a12c72b950e812e',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64e263009a12c72b950e8124',
					label: 'interactive demo - 21 Aug',
					value: '64e263009a12c72b950e8124',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64df3f309a12c72b950e78d2',
					label: 'interactive demo - 18 Aug',
					value: '64df3f309a12c72b950e78d2',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64de82259a12c72b950e781f',
					label: 'video - 18 Aug',
					value: '64de82259a12c72b950e781f',
					icon: 'video',
					defaultValue: null,
				},
				{
					key: '64de72b29a12c72b950e7812',
					label: 'interactive demo - 18 Aug',
					value: '64de72b29a12c72b950e7812',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64dde78b9a12c72b950e744e',
					label: 'interactive demo - 17 Aug',
					value: '64dde78b9a12c72b950e744e',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64dd9aa39a12c72b950e73d1',
					label: 'interactive demo - 17 Aug',
					value: '64dd9aa39a12c72b950e73d1',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64dd90489a12c72b950e739d',
					label: 'interactive demo - 17 Aug',
					value: '64dd90489a12c72b950e739d',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64dd8dc59a12c72b950e7393',
					label: 'interactive demo - 17 Aug',
					value: '64dd8dc59a12c72b950e7393',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64dd530e9a12c72b950e737a',
					label: 'interactive demo - 17 Aug',
					value: '64dd530e9a12c72b950e737a',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64dc583b9a12c72b950e6f12',
					label: 'interactive demo - 16 Aug',
					value: '64dc583b9a12c72b950e6f12',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64da64bc9a12c72b950e6982',
					label: 'interactive demo - 14 Aug',
					value: '64da64bc9a12c72b950e6982',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64d9e35ff48c613dae67c882',
					label: 'interactive demo - 14 Aug',
					value: '64d9e35ff48c613dae67c882',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64d9e113f48c613dae67c878',
					label: 'interactive demo - 14 Aug',
					value: '64d9e113f48c613dae67c878',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64d630cbb79e09249bf19979',
					label: 'interactive demo - 11 Aug',
					value: '64d630cbb79e09249bf19979',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64d500ebb79e09249bf193e8',
					label: 'interactive demo - 10 Aug',
					value: '64d500ebb79e09249bf193e8',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64d50055b79e09249bf193dc',
					label: 'interactive demo - 10 Aug',
					value: '64d50055b79e09249bf193dc',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64d4fec8b79e09249bf193cb',
					label: 'interactive demo - 10 Aug',
					value: '64d4fec8b79e09249bf193cb',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64d0c625a048462b1b4f6ba3',
					label: 'video - 07 Aug',
					value: '64d0c625a048462b1b4f6ba3',
					icon: 'video',
					defaultValue: null,
				},
				{
					key: '64d0b171a048462b1b4f6b94',
					label: 'interactive demo - 07 Aug',
					value: '64d0b171a048462b1b4f6b94',
					icon: 'interactive demo',
					defaultValue: null,
				},
				{
					key: '64c8f169a048462b1b4f5c05',
					label: 'video - 01 Aug',
					value: '64c8f169a048462b1b4f5c05',
					icon: 'video',
					defaultValue: null,
				},
			],
			paginationInfo: {
				pageNo: 1,
				pageSize: -1,
				totalRecords: 141,
				hasMore: false,
			},
		},
		{
			name: 'time-filter',
			label: 'Time',
			type: 'dropdown',
			additionalProperties: {
				width: '400px',
			},
			values: [
				{
					key: 'today',
					label: 'Today',
					value: 'today',
					icon: null,
					defaultValue: null,
				},
				{
					key: 'last-7-days',
					label: 'Last 7 days',
					value: 'last-7-days',
					icon: null,
					defaultValue: true,
				},
			],
			apiConfig: null,
			options: [
				{
					key: 'today',
					label: 'Today',
					value: 'today',
					icon: null,
					defaultValue: null,
				},
				{
					key: 'last-7-days',
					label: 'Last 7 days',
					value: 'last-7-days',
					icon: null,
					defaultValue: true,
				},
			],
		},
	],
	variables: [
		{
			key: 'floSpaceId',
		},
	],
	charts: [
		{
			name: 'published_flo_card_1',
			chartConfigFile: 'charts/published_flo_card_1.json',
			filters: null,
			chartConfig: {
				name: 'published_flo_card_1',
				title: '',
				type: 'card',
				theme: 'light',
				additionalProperties: {
					mandatoryFilters: ['flo', 'time-filter'],
				},
				layout: {
					barmode: null,
					bargap: null,
					bargroupgap: null,
					xaxis: null,
					yaxis: null,
					legend: null,
					height: 400,
					width: 500,
				},
				data: [
					{
						x: null,
						y: null,
						hovertemplate: null,
						line: null,
						fill: null,
						fillcolor: null,
						text: null,
						type: 'card',
						mode: null,
						orientation: null,
						name: 'total_views',
						marker: null,
						textposition: null,
						texttemplate: null,
						width: null,
						height: null,
						values: null,
						labels: null,
						value: '',
						label: 'Total views',
						icon: 'Eye',
						tooltip: 'Users who watched this Flo',
					},
					{
						x: null,
						y: null,
						hovertemplate: null,
						line: null,
						fill: null,
						fillcolor: null,
						text: null,
						type: 'card',
						mode: null,
						orientation: null,
						name: 'interaction_rate',
						marker: null,
						textposition: null,
						texttemplate: null,
						width: null,
						height: null,
						values: null,
						labels: null,
						value: '',
						label: 'Interaction rate',
						icon: 'Eye',
						tooltip:
							'% of viewers who clicked on interactive elements (CTA, Hotspots) in this Flo',
					},
					{
						x: null,
						y: null,
						hovertemplate: null,
						line: null,
						fill: null,
						fillcolor: null,
						text: null,
						type: 'card',
						mode: null,
						orientation: null,
						name: 'completion_rate',
						marker: null,
						textposition: null,
						texttemplate: null,
						width: null,
						height: null,
						values: null,
						labels: null,
						value: '',
						label: 'Completion rate',
						icon: 'CircleCheck',
						tooltip: '% of viewers who watched Flo till the very end',
					},
					{
						x: null,
						y: null,
						hovertemplate: null,
						line: null,
						fill: null,
						fillcolor: null,
						text: null,
						type: 'card',
						mode: null,
						orientation: null,
						name: 'cta_engagement',
						marker: null,
						textposition: null,
						texttemplate: null,
						width: null,
						height: null,
						values: null,
						labels: null,
						value: '',
						label: 'CTA engagement',
						icon: 'HandClick',
						tooltip: '% of viewers who clicked on CTA in this Flo',
					},
				],
				dataAPI: null,
			},
		},
		{
			name: 'flo_view_chart',
			chartConfigFile: 'flo_view_chart.json',
			filters: [],
			chartConfig: {
				name: 'flo_view_chart',
				title: 'Flo total views vs Flo unique views',
				type: 'line',
				theme: 'light',
				additionalProperties: {
					mandatoryFilters: ['flo'],
				},
				layout: {
					barmode: null,
					bargap: null,
					bargroupgap: null,
					xaxis: {
						title: 'Time interval',
						zeroline: null,
						automargin: null,
						side: null,
						dtick: null,
						showgrid: null,
						zerolinecolor: null,
						ticktext: null,
						tickvals: null,
					},
					yaxis: {
						title: 'Number of views',
						zeroline: null,
						automargin: null,
						side: null,
						dtick: null,
						showgrid: null,
						zerolinecolor: null,
						ticktext: null,
						tickvals: null,
					},
					legend: {
						show: false,
					},
					height: 200,
					width: null,
				},
				data: [
					{
						x: '$time',
						y: '$totalViews',
						hovertemplate: '<b>%{x}</b><br>Total Views<b>: %{y}</b></br><extra></extra>',
						line: {
							shape: 'spline',
							smoothing: 0.8,
						},
						fill: 'tozeroy',
						fillcolor: '#A6A0F1',
						text: null,
						type: null,
						mode: 'lines+markers',
						orientation: null,
						name: 'Total views',
						marker: {
							color: '#A6A0F1',
							opacity: null,
						},
						textposition: null,
						texttemplate: null,
						width: null,
						height: null,
						values: null,
						labels: null,
						value: null,
						label: null,
						icon: null,
						tooltip: null,
					},
					{
						x: '$time',
						y: '$uniqueViews',
						hovertemplate: '<b>%{x}</b><br>Unique Views<b>: %{y}</b></br><extra></extra>',
						line: {
							shape: 'spline',
							smoothing: 0.8,
						},
						fill: 'tozeroy',
						fillcolor: '#655BE1',
						text: null,
						type: null,
						mode: 'lines+markers',
						orientation: null,
						name: 'Unique views',
						marker: {
							color: '#655BE1',
							opacity: null,
						},
						textposition: null,
						texttemplate: null,
						width: null,
						height: null,
						values: null,
						labels: null,
						value: null,
						label: null,
						icon: null,
						tooltip: null,
					},
				],
				dataAPI: '',
			},
		},
		{
			name: 'flo_element_chart',
			chartConfigFile: 'flo_element_chart.json',
			filters: [],
			chartConfig: {
				name: 'flo_element_chart',
				title: 'Element Clicks vs Element Unique clicks',
				type: 'horizontal-bar',
				theme: 'light',
				additionalProperties: {
					mandatoryFilters: ['flo'],
				},
				layout: {
					barmode: 'overlay',
					bargap: null,
					bargroupgap: null,
					xaxis: {
						title: 'Number of clicks',
						zeroline: true,
						automargin: null,
						side: 'top',
						dtick: null,
						showgrid: null,
						zerolinecolor: '#f7f7f7',
						ticktext: null,
						tickvals: null,
					},
					yaxis: {
						title: 'Interactive elements',
						zeroline: false,
						automargin: null,
						side: null,
						dtick: null,
						showgrid: false,
						zerolinecolor: null,
						ticktext: '$buttons',
						tickvals: '$buttongap',
					},
					legend: {
						show: false,
					},
					height: 200,
					width: null,
				},
				data: [
					{
						x: '$totalViews',
						y: '$buttongap',
						hovertemplate: 'Total clicks <b>: %{x}</b><extra></extra>',
						line: null,
						fill: null,
						fillcolor: null,
						text: '$totalViewsText',
						type: 'bar',
						mode: null,
						orientation: 'h',
						name: 'Total clicks',
						marker: {
							color: '#A6A0F1',
							opacity: null,
						},
						textposition: 'inside',
						texttemplate: null,
						width: '$width',
						height: null,
						values: null,
						labels: null,
						value: null,
						label: null,
						icon: null,
						tooltip: null,
					},
					{
						x: '$uniqueViews',
						y: '$buttongap',
						hovertemplate: 'Unique clicks <b>: %{x}</b><extra></extra>',
						line: null,
						fill: null,
						fillcolor: null,
						text: '$uniqueViewsText',
						type: 'bar',
						mode: null,
						orientation: 'h',
						name: 'Unique clicks',
						marker: {
							color: '#655BE1',
							opacity: 0.6,
						},
						textposition: 'inside',
						texttemplate: null,
						width: '$width',
						height: null,
						values: null,
						labels: null,
						value: null,
						label: null,
						icon: null,
						tooltip: null,
					},
				],
				dataAPI: '',
			},
		},
	],
};

export const mockChartData = {
	published_flo_card_1: {
		name: 'published_flo_card_1',
		title: '',
		type: 'card',
		theme: 'light',
		additionalProperties: null,
		layout: {
			barmode: null,
			bargap: null,
			bargroupgap: null,
			xaxis: null,
			yaxis: null,
			legend: null,
			height: null,
			width: 500,
		},
		data: [
			{
				value: 10,
				label: 'Total views',
				type: 'card',
				name: 'total_views',
				icon: 'Eye',
				tooltip: 'Users who watched this Flo',
			},
			{
				value: '38 %',
				label: 'Interaction rate',
				type: 'card',
				name: 'interaction_rate',
				icon: 'Eye',
				tooltip:
					'% of viewers who clicked on interactive elements (CTA, Hotspots) in this Flo',
			},
			{
				value: '48 %',
				label: 'Completion rate',
				type: 'card',
				name: 'completion_rate',
				icon: 'CircleCheck',
				tooltip: '% of viewers who watched Flo till the very end',
			},
			{
				value: '38 %',
				label: 'CTA engagement',
				type: 'card',
				name: 'cta_engagement',
				icon: 'HandClick',
				tooltip: '% of viewers who clicked on CTA in this Flo',
			},
		],
		dataAPI: null,
	},
	flo_element_chart: {
		name: 'flo_element_chart',
		title: 'Element Clicks vs Element Unique clicks',
		type: 'horizontal-bar',
		theme: 'light',
		additionalProperties: null,
		layout: {
			barmode: 'overlay',
			bargap: null,
			bargroupgap: null,
			xaxis: {
				title: 'Number of clicks',
				zeroline: true,
				automargin: null,
				side: 'top',
				dtick: null,
				showgrid: null,
				zerolinecolor: '#f7f7f7',
				ticktext: null,
				tickvals: null,
			},
			yaxis: {
				title: 'Interactive elements',
				zeroline: false,
				automargin: null,
				side: null,
				dtick: null,
				showgrid: false,
				zerolinecolor: null,
				ticktext: [
					'<b style="font-family: monospace !important" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>&nbsp;&nbsp;asasas',
					'<b style="font-family: monospace !important" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>&nbsp;&nbsp;asasasas',
					'<b style="font-family: monospace !important" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>&nbsp;&nbsp;Restart',
					'<b style="font-family: monospace !important" >00:07</b>&nbsp;&nbsp;That’s a wrap!',
					'<b style="font-family: monospace !important" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>&nbsp;&nbsp;asas',
					'<b style="font-family: monospace !important" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>&nbsp;&nbsp;asasasas',
					'<b style="font-family: monospace !important" >00:06</b>&nbsp;&nbsp;asjabsjba',
					'<b style="font-family: monospace !important" >00:06</b>&nbsp;&nbsp;Click on Bar Charts with Text',
					'<b style="font-family: monospace !important" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>&nbsp;&nbsp;asas',
					'<b style="font-family: monospace !important" >00:05</b>&nbsp;&nbsp;IC',
					'<b style="font-family: monospace !important" >00:04</b>&nbsp;&nbsp;Click on Aggregating into Single Colored Bars',
					'<b style="font-family: monospace !important" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>&nbsp;&nbsp;asas',
					'<b style="font-family: monospace !important" >00:04</b>&nbsp;&nbsp;IC',
					'<b style="font-family: monospace !important" >00:03</b>&nbsp;&nbsp;Click on Stacked vs Grouped Bars',
					'<b style="font-family: monospace !important" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>&nbsp;&nbsp;12121212',
					'<b style="font-family: monospace !important" >00:03</b>&nbsp;&nbsp;oneasa',
					'<b style="font-family: monospace !important" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>&nbsp;&nbsp;one button',
					'<b style="font-family: monospace !important" >00:02</b>&nbsp;&nbsp;cta',
					'<b style="font-family: monospace !important" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>&nbsp;&nbsp;1212',
					'<b style="font-family: monospace !important" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>&nbsp;&nbsp;as',
					'<b style="font-family: monospace !important" >00:01</b>&nbsp;&nbsp;IC',
					'<b style="font-family: monospace !important" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>&nbsp;&nbsp;Let\'s go',
					'<b style="font-family: monospace !important" >00:00</b>&nbsp;&nbsp;IC',
				],
				tickvals: [
					0.8, 1.225, 1.6500000000000001, 2.2750000000000004, 3.075, 3.5, 4.125, 5.075,
					5.875, 6.5, 7.45, 8.25, 8.875, 9.825, 10.625, 11.25, 12.05, 12.675,
					13.475000000000001, 13.900000000000002, 14.525000000000002, 15.325000000000003,
					15.950000000000003,
				],
			},
			legend: {
				show: false,
			},
			height: 1425,
			width: null,
		},
		data: [
			{
				x: [0, 0, 5, 5, 5, 0, 5, 0, 5, 5, 0, 5, 5, 0, 7, 7, 8, 8, 0, 8, 8, 8, 8],
				y: [
					0.8, 1.225, 1.6500000000000001, 2.2750000000000004, 3.075, 3.5, 4.125, 5.075,
					5.875, 6.5, 7.45, 8.25, 8.875, 9.825, 10.625, 11.25, 12.05, 12.675,
					13.475000000000001, 13.900000000000002, 14.525000000000002, 15.325000000000003,
					15.950000000000003,
				],
				hovertemplate: 'Total clicks <b>: %{x}</b><extra></extra>',
				text: [
					'&nbsp;&nbsp;',
					'&nbsp;&nbsp;',
					'5&nbsp;&nbsp;',
					'5&nbsp;&nbsp;',
					'5&nbsp;&nbsp;',
					'&nbsp;&nbsp;',
					'5&nbsp;&nbsp;',
					'&nbsp;&nbsp;',
					'5&nbsp;&nbsp;',
					'5&nbsp;&nbsp;',
					'&nbsp;&nbsp;',
					'5&nbsp;&nbsp;',
					'5&nbsp;&nbsp;',
					'&nbsp;&nbsp;',
					'7&nbsp;&nbsp;',
					'7&nbsp;&nbsp;',
					'8&nbsp;&nbsp;',
					'8&nbsp;&nbsp;',
					'&nbsp;&nbsp;',
					'8&nbsp;&nbsp;',
					'8&nbsp;&nbsp;',
					'8&nbsp;&nbsp;',
					'8&nbsp;&nbsp;',
				],
				type: 'bar',
				orientation: 'h',
				name: 'Total clicks',
				marker: {
					color: [
						'#A6A0F1',
						'#A6A0F1',
						'#A6A0F1',
						'#A6A0F1',
						'#A6A0F1',
						'#A6A0F1',
						'#A6A0F1',
						'#C0C0C0',
						'#A6A0F1',
						'#A6A0F1',
						'#C0C0C0',
						'#A6A0F1',
						'#A6A0F1',
						'#C0C0C0',
						'#A6A0F1',
						'#A6A0F1',
						'#A6A0F1',
						'#A6A0F1',
						'#A6A0F1',
						'#A6A0F1',
						'#A6A0F1',
						'#A6A0F1',
						'#A6A0F1',
					],
					opacity: null,
				},
				textposition: 'inside',
				width: [
					0.3, 0.3, 0.3, 0.6, 0.3, 0.3, 0.6, 0.6, 0.3, 0.6, 0.6, 0.3, 0.6, 0.6, 0.3, 0.6,
					0.3, 0.6, 0.3, 0.3, 0.6, 0.3, 0.6,
				],
			},
			{
				x: [0, 0, 2, 2, 2, 0, 2, 0, 2, 2, 0, 2, 2, 0, 2, 2, 2, 2, 0, 2, 2, 2, 2],
				y: [
					0.8, 1.225, 1.6500000000000001, 2.2750000000000004, 3.075, 3.5, 4.125, 5.075,
					5.875, 6.5, 7.45, 8.25, 8.875, 9.825, 10.625, 11.25, 12.05, 12.675,
					13.475000000000001, 13.900000000000002, 14.525000000000002, 15.325000000000003,
					15.950000000000003,
				],
				hovertemplate: 'Unique clicks <b>: %{x}</b><extra></extra>',
				text: [
					'&nbsp;&nbsp;',
					'&nbsp;&nbsp;',
					'2&nbsp;&nbsp;',
					'2&nbsp;&nbsp;',
					'2&nbsp;&nbsp;',
					'&nbsp;&nbsp;',
					'2&nbsp;&nbsp;',
					'&nbsp;&nbsp;',
					'2&nbsp;&nbsp;',
					'2&nbsp;&nbsp;',
					'&nbsp;&nbsp;',
					'2&nbsp;&nbsp;',
					'2&nbsp;&nbsp;',
					'&nbsp;&nbsp;',
					'2&nbsp;&nbsp;',
					'2&nbsp;&nbsp;',
					'2&nbsp;&nbsp;',
					'2&nbsp;&nbsp;',
					'&nbsp;&nbsp;',
					'2&nbsp;&nbsp;',
					'2&nbsp;&nbsp;',
					'2&nbsp;&nbsp;',
					'2&nbsp;&nbsp;',
				],
				type: 'bar',
				orientation: 'h',
				name: 'Unique clicks',
				marker: {
					color: [
						'#655BE1',
						'#655BE1',
						'#655BE1',
						'#655BE1',
						'#655BE1',
						'#655BE1',
						'#655BE1',
						'#A0A0A0',
						'#655BE1',
						'#655BE1',
						'#A0A0A0',
						'#655BE1',
						'#655BE1',
						'#A0A0A0',
						'#655BE1',
						'#655BE1',
						'#655BE1',
						'#655BE1',
						'#655BE1',
						'#655BE1',
						'#655BE1',
						'#655BE1',
						'#655BE1',
					],
					opacity: 0.6,
				},
				textposition: 'inside',
				width: [
					0.3, 0.3, 0.3, 0.6, 0.3, 0.3, 0.6, 0.6, 0.3, 0.6, 0.6, 0.3, 0.6, 0.6, 0.3, 0.6,
					0.3, 0.6, 0.3, 0.3, 0.6, 0.3, 0.6,
				],
			},
		],
		dataAPI: null,
	},
	flo_view_chart: {
		name: 'flo_view_chart',
		title: 'Flo total views vs Flo unique views',
		type: 'line',
		theme: 'light',
		additionalProperties: null,
		layout: {
			barmode: null,
			bargap: null,
			bargroupgap: null,
			xaxis: {
				title: 'Time interval',
				zeroline: null,
				automargin: null,
				side: null,
				dtick: null,
				showgrid: null,
				zerolinecolor: null,
				ticktext: null,
				tickvals: null,
			},
			yaxis: {
				title: 'Number of views',
				zeroline: null,
				automargin: null,
				side: null,
				dtick: null,
				showgrid: null,
				zerolinecolor: null,
				ticktext: null,
				tickvals: null,
			},
			legend: {
				show: false,
			},
			height: null,
			width: null,
		},
		data: [
			{
				x: [
					'2024-01-16 00:00:00',
					'2024-01-16 06:00:00',
					'2024-01-16 12:00:00',
					'2024-01-16 18:00:00',
					'2024-01-17 00:00:00',
					'2024-01-17 06:00:00',
					'2024-01-17 12:00:00',
					'2024-01-17 18:00:00',
					'2024-01-18 00:00:00',
					'2024-01-18 06:00:00',
					'2024-01-18 12:00:00',
					'2024-01-18 18:00:00',
					'2024-01-19 00:00:00',
					'2024-01-19 06:00:00',
					'2024-01-19 12:00:00',
					'2024-01-19 18:00:00',
				],
				y: [4, 6, 2, 8, 8, 10, 4, 6, 4, 2, 10, 4, 8, 10, 3],
				hovertemplate: '<b>%{x}</b><br>Total Views<b>: %{y}</b></br><extra></extra>',
				line: {
					shape: 'spline',
					smoothing: 0.8,
				},
				fill: 'tozeroy',
				fillcolor: '#A6A0F1',
				mode: 'lines+markers',
				name: 'Total views',
				marker: {
					color: '#A6A0F1',
					opacity: null,
				},
			},
			{
				x: [
					'2024-01-16 00:00:00',
					'2024-01-16 06:00:00',
					'2024-01-16 12:00:00',
					'2024-01-16 18:00:00',
					'2024-01-17 00:00:00',
					'2024-01-17 06:00:00',
					'2024-01-17 12:00:00',
					'2024-01-17 18:00:00',
					'2024-01-18 00:00:00',
					'2024-01-18 06:00:00',
					'2024-01-18 12:00:00',
					'2024-01-18 18:00:00',
					'2024-01-19 00:00:00',
					'2024-01-19 06:00:00',
					'2024-01-19 12:00:00',
					'2024-01-19 18:00:00',
				],
				y: [3, 4, 1, 6, 6, 9, 3, 4, 2, 1, 8, 3, 6, 8, 2],
				hovertemplate: '<b>%{x}</b><br>Unique Views<b>: %{y}</b></br><extra></extra>',
				line: {
					shape: 'spline',
					smoothing: 0.8,
				},
				fill: 'tozeroy',
				fillcolor: '#655BE1',
				mode: 'lines+markers',
				name: 'Unique views',
				marker: {
					color: '#655BE1',
					opacity: null,
				},
			},
		],
		dataAPI: null,
	},
};
