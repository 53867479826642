import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';

import { setToastsStateType, toastErrorType } from './Notification.types';

const initialState = Object.freeze({
	type: '',
	message: '',
	canShow: false,
});

const ToastsReducers = {
	setNotification(
		state: setToastsStateType,
		{ payload }: { payload: setToastsStateType }
	) {
		const { type, message, canShow } = payload || {};
		state.type = type;
		state.message = message;
		state.canShow = canShow;
	},
	setErrorToast(
		state: setToastsStateType,
		{ payload }: { payload: toastErrorType | unknown }
	) {
		const messageStatus = get(payload, 'response.status') || get(payload, 'status');
		const redirectOnError = get(payload, 'response.data.redirectOnError', false);
		const message = get(payload, 'response.data.data') || get(payload, 'message');
		if (
			!message ||
			(redirectOnError && [404, 403, 500].includes(messageStatus)) ||
			[412, 402].includes(messageStatus) ||
			messageStatus === 400
		)
			return;

		state.type = 'error';
		state.message = message;
		state.canShow = true;
	},
	setSuccessToast(
		state: setToastsStateType,
		{ payload }: { payload: toastErrorType | unknown }
	) {
		const message = get(payload, 'response.data.data') || get(payload, 'message');
		if (!message) return;

		state.type = 'success';
		state.message = message;
		state.canShow = true;
	},
};

export const Toasts = createSlice({
	name: 'Toast',
	initialState,
	//@ts-ignore
	reducers: ToastsReducers,
});

export const setNotification = Toasts.actions.setNotification;
export const setErrorToast = Toasts.actions.setErrorToast;
export const setSuccessToast = Toasts.actions.setSuccessToast;

export default Toasts.reducer;
