import { get } from 'lodash';
import { SagaIterator } from 'redux-saga';
import { call, ForkEffect, put, select, takeLatest } from 'redux-saga/effects';
import { API_BASE } from '../../Common/Common.env';
import { openLinkInNewTab } from '../../Common/Common.utils';
import { PAYMENT_STATUSES } from '../../Components/BillingDialog/BillingDialog';
import {
	cancelBillingDetailsAction,
	getBillingDetailsAction,
	getBillingDetailsActionSuccess,
	getSubscriptionProducts,
	getSubscriptionProductsSuccess,
	refreshPaymentStatus,
	renewBillingDetailsAction,
	retryPayment,
	upgradeBillingDetailsAction,
} from '../../Components/BillingDialog/BillingDialog.reducer';
import { setErrorToast } from '../../Components/Notification/Toasts.reducers';
import { getFloSpace } from '../../Containers/Routes/Routes.reducers';
import { getUsageDetails } from '../../Pages/ManageFlos/ManageFlos.reducers';
import API from '../../utils/API';
import { hideLoader, showLoader } from '../reducers/Loader.reducer';
// import {setBrandingDetails} from "../../Components/BrandingDialog/BrandingDialog.reducer";

function* getBillingDetailsSaga(): SagaIterator {
	try {
		yield put(showLoader('billing'));
		const floSpaceId = yield select((state) =>
			get(state, 'routerContainer.currentFloSpace.id')
		);
		if (!floSpaceId) return;
		const data = yield call(
			new API().get,
			`${API_BASE}/v1/flospace/billing/${floSpaceId}/payment`,
			{}
		);
		yield put(getBillingDetailsActionSuccess(data.data));
		yield put(getSubscriptionProducts(''));
	} catch (e) {
		console.warn(e);
		yield put(setErrorToast(e));
	} finally {
		yield put(hideLoader('billing'));
	}
}

function* getSubscriptionProductsSaga(): SagaIterator {
	try {
		yield put(showLoader('billing'));
		const floSpaceId = yield select((state) =>
			get(state, 'routerContainer.currentFloSpace.id')
		);
		if (!floSpaceId) return;
		const data = yield call(
			new API().get,
			`${API_BASE}/v1/flospace/billing/${floSpaceId}/product`,
			{}
		);
		yield put(getSubscriptionProductsSuccess(data.data));
	} catch (e) {
		console.warn(e);
		yield put(setErrorToast(e));
	} finally {
		yield put(hideLoader('billing'));
	}
}

function* renewBillingDetailsSaga() {
	try {
		// @ts-ignore
		const floSpaceId = yield select((state) =>
			get(state, 'routerContainer.currentFloSpace.id')
		);

		//@ts-ignore
		const response = yield call(
			new API().put,
			`${API_BASE}/v1/flospace/billing/${floSpaceId}/subscription/renew`,
			{}
		);
		if (response.data.newSubscriptionPaymentLink) {
			openLinkInNewTab(response.data.newSubscriptionPaymentLink);
		}
		yield put(getBillingDetailsActionSuccess(response.data));
	} catch (e) {
		console.warn(e);
		yield put(setErrorToast(e));
	}
}

function* upgradeBillingDetailsSaga({
	payload,
}: {
	payload: {
		subscriptionId: string;
	};
}) {
	try {
		// @ts-ignore
		const floSpaceId = yield select((state) =>
			get(state, 'routerContainer.currentFloSpace.id')
		);

		//@ts-ignore
		const response = yield call(
			new API().put,
			`${API_BASE}/v1/flospace/billing/${floSpaceId}/upgrade-subscription/${payload.subscriptionId}`,
			{}
		);
		if (response.data.newSubscriptionPaymentLink) {
			openLinkInNewTab(response.data.newSubscriptionPaymentLink);
		}
		yield put(getBillingDetailsActionSuccess(response.data));
	} catch (e) {
		console.warn(e);
		yield put(setErrorToast(e));
	}
}

function* cancelBillingDetailsSaga() {
	try {
		// @ts-ignore
		const floSpaceId = yield select((state) =>
			get(state, 'routerContainer.currentFloSpace.id')
		);

		//@ts-ignore
		const response = yield call(
			new API().put,
			`${API_BASE}/v1/flospace/billing/${floSpaceId}/subscription/cancel`,
			{}
		);
		yield put(getBillingDetailsActionSuccess(response.data));
		yield put(getFloSpace({}));
	} catch (e) {
		console.warn(e);
		yield put(setErrorToast(e));
	}
}

function* refreshPaymentStatusSaga({
	payload,
}: {
	payload: {
		subscriptionId: string;
		newSubscriptionSessionId: string;
	};
}) {
	const { newSubscriptionSessionId, subscriptionId } = payload;
	try {
		// @ts-ignore
		yield put(showLoader('paymentStatus'));
		// @ts-ignore
		const floSpaceId = yield select((state) =>
			get(state, 'routerContainer.currentFloSpace.id')
		);

		//@ts-ignore
		const response = yield call(
			new API().put,
			`${API_BASE}/v1/flospace/billing/${floSpaceId}/upgrade-subscription/${subscriptionId}/status${
				newSubscriptionSessionId ? `?appSessionId=${newSubscriptionSessionId}` : ''
			}`,
			{}
		);
		const billingStatus = response.data?.newSubscriptionBillingStatus;
		if (!billingStatus || billingStatus === PAYMENT_STATUSES.COMPLETED) {
			yield put(getSubscriptionProducts(''));
		}
		yield put(getBillingDetailsActionSuccess(response.data));
		yield put(getUsageDetails({ floSpaceId: floSpaceId }));
	} catch (e) {
		console.warn(e);
		yield put(setErrorToast(e));
	} finally {
		yield put(hideLoader('paymentStatus'));
	}
}

function* retryPaymentSaga({
	payload,
}: {
	payload: {
		subscriptionId: string;
	};
}) {
	try {
		// @ts-ignore
		const floSpaceId = yield select((state) =>
			get(state, 'routerContainer.currentFloSpace.id')
		);

		//@ts-ignore
		const response = yield call(
			new API().put,
			`${API_BASE}/v1/flospace/billing/${floSpaceId}/upgrade-subscription/${payload.subscriptionId}/retry`,
			{}
		);
		if (response.data.newSubscriptionPaymentLink) {
			openLinkInNewTab(response.data.newSubscriptionPaymentLink);
		}
		yield put(getBillingDetailsActionSuccess(response.data));
	} catch (e) {
		console.warn(e);
		yield put(setErrorToast(e));
	}
}

function* billingDialogSagas(): Generator<ForkEffect> {
	const tasks = [
		// @ts-ignore
		yield takeLatest(getBillingDetailsAction.type, getBillingDetailsSaga),
		// @ts-ignore
		yield takeLatest(upgradeBillingDetailsAction.type, upgradeBillingDetailsSaga),
		// @ts-ignore
		yield takeLatest(renewBillingDetailsAction.type, renewBillingDetailsSaga),
		yield takeLatest(getSubscriptionProducts.type, getSubscriptionProductsSaga),
		yield takeLatest(cancelBillingDetailsAction.type, cancelBillingDetailsSaga),
		// @ts-ignore
		yield takeLatest(refreshPaymentStatus.type, refreshPaymentStatusSaga),
		// @ts-ignore
		yield takeLatest(retryPayment.type, retryPaymentSaga),
	];
}

export default billingDialogSagas;
