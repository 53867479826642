export const FLO_ELEMENT_INTERACTION_CUE_TYPE = 'interaction_cues';
export const FLO_ELEMENT_HOTSPOTS_TYPE = 'hotspots';
export const FLO_ELEMENT_TRIM_TYPE = 'trim';
export const FLO_ELEMENT_NEW_TRIM_TYPE = 'in_trim';
export const FLO_ELEMENT_IMAGE_TYPE = 'image';
export const FLO_ELEMENT_DOCUMENT_IMAGE_TYPE = 'image';
export const FLO_ELEMENT_CROP_TYPE = 'crop';
export const FLO_ELEMENT_FREE_CROP_TYPE = 'free-crop';
export const FLO_ELEMENT_DOCUMENT_BLUR_TYPE = 'document-blur';
export const FLO_ELEMENT_DOCUMENT_SPOTLIGHT_TYPE = 'spotlight';
export const FLO_ELEMENT_BLUR_TYPE = 'blur';
export const FLO_ELEMENT_SPOTLIGHT_TYPE = 'spotlight';
export const FLO_ELEMENT_SHAPES_TYPE = 'shapes';
export const FLO_ELEMENT_CTA_TYPE = 'cta';
export const FLO_ELEMENT_VOICE_OVER_TYPE = 'voice-over';
export const FLO_ELEMENT_SUBTITLE_TYPE = 'subtitle';
export const FLO_ACTIVE_TAB_EDIT = 'edit';
export const FLO_ACTIVE_TAB_SHARE = 'share';
export const FLO_ACTIVE_TAB_COMMENTS = 'comments';
export const FLO_SHARE_TAB_TOC_KEY = 'show-toc';
export const FLO_SHARE_TAB_AUTHOR_KEY = 'show-author';
export const FLO_SHARE_TAB_SWIFT_MODE_KEY = 'skip-video';
export const FLO_SHARE_AUTO_PLAY_MODE_KEY = 'auto-play';
export const FLO_SHARE_TAB_VIDEO_SUBTITLES = 'includeSubtitles';
export const FLO_SHARE_TAB_ID_SWIFT_PLAY = 'swiftPlay';
export const FLO_ELEMENT_PRESENTER_VIRTUAL_BACKGROUND_TYPE = 'virtual-background';
export const FLO_ELEMENT_PRESENTER_PROPERTY_TYPE = 'property-presenter';
export const FLO_PRESENTER_SNIPPET_TYPE = 'VIRTUAL_BACKGROUND_SNIPPET';