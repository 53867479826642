import { get } from "lodash";
import { useSelector } from "react-redux";

const isFeatureEnabled = (flagKey: string) => {
  if (!flagKey) return {
    isDiamondEnabled: false,
    featureObject: null,
  };

	const flagDetails = useSelector((state) =>
		get(state, ['routerContainer', 'diamondFeaturesMap', flagKey])
	);

  if (!flagDetails) {
    return {
      isDiamondEnabled: false,
      featureObject: null,
    };
  }

  return {
    isDiamondEnabled: get(flagDetails, 'diamondEnabled'),
    featureObject: flagDetails,
  };
}

export default isFeatureEnabled;
