import { Box, Grid } from '@mui/material';
import classNames from 'classnames';
import { ReactComponent as ErrorSvg } from './../../Common/images/error-circle.svg';
import styles from './NoDataMessage.module.css';

interface NoDataMessageProps {
	message?: string;
	classes?: {
		[key: string]: string;
	};
}
const NoDataMessage = ({ message, classes }: NoDataMessageProps) => {
	return (
		<Grid
			container
			className={classNames(styles.noDataWrapper, classes?.wrapper)}
			flexDirection={'column'}
		>
			<Grid item>
				<Box sx={{ textAlign: 'center' }}>
					<ErrorSvg />
				</Box>
			</Grid>
			<Grid item>{message}</Grid>
		</Grid>
	);
};
export default NoDataMessage;
