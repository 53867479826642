import { elementBeanListType, FloReducerType } from '../../Flo.types';

const guideFloReducer = {
	setSelectedGuideStep(state: FloReducerType, { payload }: { payload: string }) {
		state.guide.selectedGuideStepId = payload;
	},
	setGuideGalleryImages(
		state: FloReducerType,
		{ payload }: { payload: { [key: string]: string } }
	) {
		state.guide.documentGalleryImages = payload;
	},
	setGuideActiveElement(
		state: FloReducerType,
		{ payload }: { payload: elementBeanListType }
	) {
		state.guide.activeGuideElement = payload;
	},
	setGuideImageLoadStatus(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				id: string;
				value: string;
			};
		}
	) {
		state.guide.imagesState[payload.id] = payload.value;
	},
};

export default guideFloReducer;
