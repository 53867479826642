import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { ThemeProvider } from '@mui/material/styles';
import React, { ReactElement } from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './Containers/App/App';
import store from './store';
import * as serviceWorker from './sw-register';
import theme from './theme';

let ErrorBoundary = ({ children }: { children: ReactElement }) => children;

if (
	process.env.NODE_ENV === 'production' &&
	process.env.REACT_APP_DISABLE_ERROR_MONITORING !== 'true'
) {
	Bugsnag.start({
		// @ts-ignore
		apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || 'e8b4672548e46c9068526502226b3f7a',
		plugins: [new BugsnagPluginReact()],
	});
	// @ts-ignore
	ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
}

// @ts-ignore
window['$crisp'] = window['$crisp'] || [];

const rootElement = document.getElementById('root') as Element | DocumentFragment;
const root = ReactDOMClient.createRoot(rootElement);

root.render(
	<ErrorBoundary>
		<ThemeProvider theme={theme}>
			<Provider store={store}>
				<App />
			</Provider>
		</ThemeProvider>
	</ErrorBoundary>
);

serviceWorker.register();
