import { useSelector } from 'react-redux';
import { isExtensionErrored, isExtensionInstalled } from '../../NewFloContainer.selector';
import styles from './NewFloExtensionError.module.css';

const NewFloExtensionError = () => {
	const extensionInstalled = useSelector(isExtensionInstalled);
	const extensionErrored = useSelector(isExtensionErrored);
	if (extensionInstalled && extensionErrored)
		return (
			<div className={styles.container}>
				Extension might not be able to capture clicks, try reloading the page.
			</div>
		);
	return <></>;
};

export default NewFloExtensionError;
