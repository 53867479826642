import { get } from 'lodash';
import { RootState } from '../../store';

export const onboardingStatusSelector = (state: RootState) =>
	get(
		state,
		'routerContainer.profile.userProfile.profilePreference.onboardingQuestionnaireAnswered'
	);

export const onboardingQSelector = (state: RootState) =>
	get(state, 'onboardingQuestionnaire');
