import { createSlice } from '@reduxjs/toolkit';
import { findIndex, forEach, get, keyBy, set } from 'lodash';
import { FloPagination } from '../ManageFlos/ManageFlos.types';
import {
	FloInsightsPayloadType,
	InsightReducerType,
	ReqFilterType,
} from './Insights.types';
import { mockChartData, mockDashboardSchema } from './pages/Insights.constants';

const initialState = Object.freeze({
	floInsights: [],
	pagination: {
		hasMore: false,
		pageNo: 1,
		pageSize: 100,
		totalRecords: 0,
	},
	dashboards: {},
	chartsData: {},
	chartsWithoutMandatoryFields: {},
});

const insightsReducerObject = {
	getInsightsDashboard(
		state: InsightReducerType,
		{
			payload,
		}: {
			payload: {
				dashboardName: string;
				floSpaceId: string;
			};
		}
	) {},
	setInsightsDashboard(
		state: InsightReducerType,
		{
			payload,
		}: {
			payload: {
				data: { filters: any[] };
				dashboardName: string;
			};
		}
	) {
		state.dashboards[payload.dashboardName] = payload.data;
	},
	setInsightsMockDashboard(
		state: InsightReducerType,
		{
			payload,
		}: {
			payload: {
				dashboardName: string;
			};
		}
	) {
		state.dashboards[payload.dashboardName] = {
			...mockDashboardSchema,
			userBlocked: true,
		};
		state = set(state, `chartsData.${payload.dashboardName}`, mockChartData);
	},
	getInsightsFilterData(
		state: InsightReducerType,
		{
			payload,
		}: {
			payload: {
				dashboardName: string;
				filter: { [key: string]: any };
				params: { [key: string]: any };
			};
		}
	) {},
	setDashboardError(
		state: InsightReducerType,
		{
			payload,
		}: {
			payload: {
				data: string;
				dashboardName: string;
			};
		}
	) {
		state.dashboards[payload.dashboardName].error = payload.data;
	},
	setInsightsFilterData(
		state: InsightReducerType,
		{
			payload,
		}: {
			payload: {
				dashboardName: string;
				data: { data: unknown; paginationInfo?: FloPagination };
				filter: { [key: string]: any };
			};
		}
	) {
		const dashboardFilters = get(
			state,
			`dashboards.${payload.dashboardName}.filters`,
			[]
		);
		const filterItemIndex = findIndex(dashboardFilters, ['name', payload.filter.name]);
		if (filterItemIndex > -1) {
			state = set(
				state,
				`dashboards.${payload.dashboardName}.filters[${filterItemIndex}]`,
				{
					...payload.filter,
					options: get(payload, 'data.data'),
					paginationInfo: get(payload, 'data.paginationInfo'),
				}
			);
		}
	},
	getInsightsChartData(
		state: InsightReducerType,
		{
			payload,
		}: {
			payload: {
				chartName: string;
				dashboardName: string;
				filters: ReqFilterType[];
				floSpaceId: string;
			};
		}
	) {},
	setChartsWithoutMandatoryFields(
		state: InsightReducerType,
		{
			payload,
		}: {
			payload: {
				data: { [key: string]: string[] };
				dashboardName: string;
			};
		}
	) {
		const dashboardFilters = keyBy(
			get(state, `dashboards.${payload.dashboardName}.filters`),
			'name'
		);
		let chartsMapMessages = {};

		forEach(payload.data, (filterKeys, chartName) => {
			const filters = (filterKeys || []).map((filterKey) =>
				get(dashboardFilters, `${filterKey}.label`)
			);
			let joinedFilters = filters[0];
			if (filters.length > 1) {
				joinedFilters =
					filters.slice(0, filters.length - 1).join(', ') +
					' and ' +
					filters[filters.length - 1];
			}
			chartsMapMessages = set(
				chartsMapMessages,
				chartName,
				`Select filter${
					filters.length > 1 ? 's' : ''
				} "${joinedFilters}" to view the chart`
			);
		});
		state = set(
			state,
			`chartsWithoutMandatoryFields.${payload.dashboardName}`,
			chartsMapMessages
		);
	},
	setInsightsChartData(
		state: InsightReducerType,
		{
			payload,
		}: {
			payload: {
				chartName: string;
				dashboardName: string;
				data: { [key: string]: any };
			};
		}
	) {
		state = set(
			state,
			`chartsData.${payload.dashboardName}.${payload.chartName}`,
			payload.data
		);
	},
	getDashboardChartsData(
		state: InsightReducerType,
		{
			payload,
		}: {
			payload: {
				dashboardName: string;
				charts: { name: string }[];
				filters: ReqFilterType[];
				floSpaceId: string;
			};
		}
	) {},
	getFloInsights(
		state: InsightReducerType,
		{
			payload,
		}: {
			payload: {
				floSpaceId: string;
				pageNo?: number;
			};
		}
	) {},
	setFloInsights(
		state: InsightReducerType,
		{
			payload,
		}: {
			payload: {
				data: FloInsightsPayloadType[];
				pagination: FloPagination;
			};
		}
	) {
		if (payload.pagination.pageNo === 1) {
			state.floInsights = payload.data;
		} else {
			state.floInsights = state.floInsights.concat(payload.data);
		}
		state.pagination = payload.pagination;
	},
	leaveInsightsPage() {},
};

export const insightsPage = createSlice({
	name: 'home',
	initialState,
	reducers: insightsReducerObject,
});

export default insightsPage.reducer;
export const getFloInsights = insightsPage.actions.getFloInsights;
export const setFloInsights = insightsPage.actions.setFloInsights;
export const getInsightsFilterData = insightsPage.actions.getInsightsFilterData;
export const setInsightsFilterData = insightsPage.actions.setInsightsFilterData;
export const getInsightsDashboard = insightsPage.actions.getInsightsDashboard;
export const setInsightsDashboard = insightsPage.actions.setInsightsDashboard;
export const getInsightsChartData = insightsPage.actions.getInsightsChartData;
export const setInsightsChartData = insightsPage.actions.setInsightsChartData;
export const setDashboardError = insightsPage.actions.setDashboardError;
export const getDashboardChartsData = insightsPage.actions.getDashboardChartsData;
export const setInsightsMockDashboardAction =
	insightsPage.actions.setInsightsMockDashboard;
export const setChartsWithoutMandatoryFields =
	insightsPage.actions.setChartsWithoutMandatoryFields;
export const leaveInsightsPage = insightsPage.actions.leaveInsightsPage;
