const sendGetVersion = () => {
	if (navigator?.serviceWorker?.controller) {
		navigator?.serviceWorker?.controller.postMessage({
			type: 'getVersion',
		});
	}
};

export function register() {
	const swPath = `${process.env.PUBLIC_URL}/sw-v5.js?version=05`;
	const url =
		process.env.REACT_APP_ENVIRONMENT === 'production'
			? 'https://app.floik.com'
			: 'https://us.signoff.ai';
	const allowedDomain = process.env.REACT_APP_URL || url;

	if ('serviceWorker' in navigator && window.location.origin === allowedDomain) {
		window.addEventListener('load', function () {
			navigator.serviceWorker
				.register(swPath, {
					scope: allowedDomain,
				})
				.then(
					function (registration) {
						registration.onupdatefound = () => {
							const installingWorker = registration.installing;
							if (installingWorker == null) {
								return;
							}
							installingWorker.onstatechange = () => {
								if (installingWorker.state === 'activated') {
									sendGetVersion();
									location.reload();
								}
								if (installingWorker.state === 'installing') {
								}
								if (installingWorker.state === 'installed') {
									if (navigator?.serviceWorker?.controller) {
										navigator?.serviceWorker?.controller.postMessage({
											type: 'skipWaiting',
										});
										// Unregister the existing service worker and activate the new one
										registration.unregister();
									}
								}
							};
						};
						console.log(
							'ServiceWorker registration successful with scope: ',
							registration.scope
						);
						sendGetVersion();
						// Registration was successful
					},
					function (err) {
						// registration failed :(
						console.log('ServiceWorker registration failed: ', err);
					}
				);
		});
	}
}
