import React from 'react';
import { Route } from 'react-router-dom';

const Lazy = React.lazy(() => import('./MySpace'));
const LazyWorkSpaceView = React.lazy(
	() => import('./MySpaceWorkspacePage/MySpaceWorkspacePage')
);
const LazyWorkSpaceFolderView = React.lazy(
	() => import('./MySpaceWorkspaceFolderPage/MySpaceWorkspaceFolderPage')
);

export const MY_SPACE_ROUTE_PATH = '/workspace/:mySpaceId';
export const MY_SPACE_FOLDER_VIEW_PATH = '/workspace/:mySpaceId/folders/:folderId';

const MySpaceRoute = (
	<Route path="/workspace" element={<Lazy />}>
		<Route path={MY_SPACE_ROUTE_PATH} element={<LazyWorkSpaceView />}></Route>
		<Route path={MY_SPACE_FOLDER_VIEW_PATH} element={<LazyWorkSpaceFolderView />}></Route>
	</Route>
);

export default MySpaceRoute;
