import { all, fork } from 'redux-saga/effects';
import billingDialogSagas from './BillingDialog.saga';
import brandingDialogSagas from './BrandingDialog.saga';
import invoicesDialogSaga from './InvoicesDialog.saga';
import AnalyticsLogSagas from './Log.saga';
import profileDialogSagas from './ProfileDialog.saga';
import TeamManagementDialogSagas from './TeamManagementDialog.saga';
import translationRootSagas from './Translations.saga';

export function* watchSagas() {
	//Combine sagas with
	yield all([
		fork(billingDialogSagas),
		fork(brandingDialogSagas),
		fork(profileDialogSagas),
		fork(translationRootSagas),
		fork(AnalyticsLogSagas),
		fork(TeamManagementDialogSagas),
		fork(invoicesDialogSaga),
	]);
}
