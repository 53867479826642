import classNames from 'classnames';
import proFeatureSvg from '../../Common/images/ProFeature.svg';
import styles from './DiamondIcon.module.css';

const DiamondIcon = ({
	image,
	className = '',
}: {
	image: string;
	className?: string;
}) => (
	<div className={classNames(styles.diamondIcon, className)}>
		<img alt="" src={image || proFeatureSvg} className={styles.diamondIconImg} />
	</div>
);

export default DiamondIcon;
