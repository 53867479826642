import { combineReducers } from 'redux';
import accountSettingsDialogReducer from '../../Components/AccountSettings/AccountSettings.reducer';
import billingDialogReducer from '../../Components/BillingDialog/BillingDialog.reducer';
import brandingDialogReducer from '../../Components/BrandingDialog/BrandingDialog.reducer';
import dialogReducer from '../../Components/Dialog/Dialog.reducer';
import imageGalleryReducer from '../../Components/ImageGallery/ImageGallery.reducer';
import invoicesDialogReducer from '../../Components/InvoicesDialog/InvoicesDialog.reducer';
import toastsReducer from '../../Components/Notification/Toasts.reducers';
import profileDialogReducer from '../../Components/ProfileDialog/ProfileDialog.reducer';
import teamManagementDialogReducer from '../../Components/TeamManagementDialog/TeamManagementDialog.reducer';
import HomePageReducer from '../../Pages/Home/Home.reducer';
import InsightPageReducer from '../../Pages/Insights/Insights.reducer';
import mySpaceReducer from '../../Pages/MySpace/MySpace.reducer';
import newFloReducer from '../../Pages/NewFloContainer/NewFlo.reducers';
import onboardingQuestionnaire from '../../Pages/OnboardQa/OnboardQa.reducer';
import loader from '../Loader/LoaderReducer';
import RoutesContainerReducer from './../../Containers/Routes/Routes.reducers';
import floPageReducer from './../../Pages/Flo/Flo.reducers';
import MangeflosPageReducer from './../../Pages/ManageFlos/ManageFlos.reducers';
import LoaderReducer from './Loader.reducer';
import LogsReducer from './Log.reducer';
import OnBoardingReducer from './OnBoarding.reducer';
import RoutesReducer from './Routes.reducer';
import translationReducer from './Translation.reducer';

export default combineReducers({
	loader,
	translations: translationReducer,
	routes: RoutesReducer,
	routerContainer: RoutesContainerReducer,
	manageFlos: MangeflosPageReducer,
	floPage: floPageReducer,
	newFlo: newFloReducer,
	dialog: dialogReducer,
	imageGallery: imageGalleryReducer,
	profileDialog: profileDialogReducer,
	accountSettingsDialog: accountSettingsDialogReducer,
	brandingDialog: brandingDialogReducer,
	billingDialog: billingDialogReducer,
	toasts: toastsReducer,
	loaders: LoaderReducer,
	onBoarding: OnBoardingReducer,
	homePage: HomePageReducer,
	insightPage: InsightPageReducer,
	logs: LogsReducer,
	mySpace: mySpaceReducer,
	teamManagement: teamManagementDialogReducer,
	onboardingQuestionnaire,
	invoicesDialog: invoicesDialogReducer,
});
