import { Paper } from '@mui/material';
import Cookie from 'js-cookie';
import { get } from 'lodash';
import { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Loader from '../../Components/Loader/Loader';
import styles from './Login.module.css';
import(/* webpackPreload: true */ './Login.module.css');

const Login = (): ReactElement => {
	const userName = Cookie.get('userName');
	const isLoading = useSelector((state) => get(state, 'routerContainer.isLoading'));
	useEffect(() => {
		if (
			!isLoading &&
			!sessionStorage.getItem('redirect') &&
			location.pathname !== '/auth'
		) {
			sessionStorage.setItem('redirect', location.pathname + location.search);
		}
	}, [isLoading]);

	return (
		<Paper className={styles.container}>
			<Loader fullPage />
		</Paper>
	);
};

export default Login;
