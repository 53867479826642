import axios, {
	AxiosError,
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
} from 'axios';
// import store from '../store';
import { Dispatch } from '@reduxjs/toolkit';
import Cookie from 'js-cookie';
import { get, set } from 'lodash';

import { API_BASE, WEBSITE_PRICING_PAGE } from '../Common/Common.env';
import { showDialogAction } from '../Components/Dialog/Dialog.reducer';
import { logoutAction, refreshTokenAction } from '../Containers/Routes/Routes.reducers';
import store from '../store';
import { pushErrorState } from '../store/reducers/Routes.reducer';

import { getDeviceId } from '../Common/Common.utils';
import Translations, { translate } from '../Common/Translate.utils';
import { showAccountSettingsPopup } from '../Components/AccountSettings/AccountSettings.reducer';
import DialogImageWithText from '../Components/DialogImageWithText/DialogImageWithText';

const refreshUrl = `${API_BASE}/v1/authentication/refresh-token`;
const accessTokenUrl = `${API_BASE}/v1/authentication/access-token`;
const deviceId = getDeviceId();

const responseBody = (response: AxiosResponse) => {
	return response.data;
};

const responseHeader = (response: AxiosResponse) => {
	return response;
};

class API {
	instance: AxiosInstance;
	noAuth: boolean;
	getHeader: boolean;
	redirectOnError: boolean;
	redirectOn400Error: boolean;
	preventDefaultHandlingForStatuses: number[];
	errorDialogCb: {
		[key: string]: {
			onConfirm?: Function | GeneratorFunction;
			onCancel?: Function | GeneratorFunction;
		};
	};

	constructor(
		config: AxiosRequestConfig = {
			baseURL: '/',
			timeout: 35000,
		},
		noAuth?: boolean,
		getHeader?: boolean,
		redirectOnError?: boolean,
		redirectOn400Error?: boolean,
		preventDefaultHandlingForStatuses?: number[],
		errorDialogCb?: { [key: string]: { onConfirm?: Function; onCancel?: Function } }
	) {
		this.instance = axios.create(config);
		this.noAuth = noAuth || false;
		this.getHeader = getHeader || false;
		this.redirectOnError = redirectOnError || false;
		this.redirectOn400Error = redirectOn400Error || false;
		this.errorDialogCb = errorDialogCb || {};
		this.preventDefaultHandlingForStatuses = preventDefaultHandlingForStatuses || [];
		// @ts-ignore
		this.instance.interceptors.request.use(this.requestInterceptors);
		this.instance.interceptors.response.use(
			this.responseSuccessInterceptors,
			this.responseErrorInterceptors
		);
	}

	responseSuccessInterceptors = (response: AxiosResponse): AxiosResponse => response;
	responseErrorInterceptors = (response: AxiosError): Promise<AxiosError> => {
		const { status } = response.response || {};
		const error = get(response, 'response.data.data') || {};
		let url = '';

		if (this.redirectOnError || this.redirectOn400Error) {
			if (this.redirectOn400Error && status === 400) {
				url = '/400';
			} else if (status === 404) {
				url = '/404';
			} else if (status === 403) {
				url = '/403';
			} else if (status === 500) {
				url = '/500';
			}
			if (url) {
				const { errorMessage, headerTitle } = error || {};
				if (errorMessage || headerTitle)
					store.dispatch(
						pushErrorState(
							url + `?error=${errorMessage}&errorHeader=${headerTitle}&status=${status}`
						)
					);
				else store.dispatch(pushErrorState(url + `?error=${error}&status=${status}`));
			}
		}

		const isFingerPrintSubscription = get(
			response,
			'response.data.fingerPrintSubscription'
		);
		const isUserSubscriptionOwner = get(response, 'response.data.userSubscriptionOwner');
		const errorTitleKey = get(response, 'response.data.data.headerTitle');
		// @ts-ignore
		const errorTitle =
			Translations[errorTitleKey] || Translations.POP_UP_402_LOGGED_IN_USER_TITLE_TEXT;
		const errorMsgKey = get(response, 'response.data.data.errorMessage');
		// @ts-ignore
		const errorMsg =
			Translations[errorMsgKey] ||
			Translations.POP_UP_402_FINGER_PRINT_USER_SUB_TITLE_TEXT;
		const errorImagePath = get(response, 'response.data.data.imagePath');
		if (status === 400 && !this.preventDefaultHandlingForStatuses.includes(400)) {
			store.dispatch(
				showDialogAction({
					header: '',
					variant: 'small',
					body: translate(error, error),
					showConfirm: true,
					confirmButtonText: 'Okay',
				})
			);
		}

		if (status === 402) {
			store.dispatch(
				showDialogAction({
					header: '',
					body: translate(errorMsg, errorMsg),
					showConfirm: true,
					confirmButtonType: 'confirm',
					confirmButtonText: isFingerPrintSubscription ? 'Login' : 'Pricing Plans',
					onConfirm: (dispatch: Dispatch) => {
						if (isFingerPrintSubscription) {
							const token = Cookie.get('floik-token-v2');
							// @ts-ignore
							window.location = `${API_BASE}/v1/authentication/authenticate?redirect_uri=/auth&redirect_host=${location.origin}&device_token=${deviceId}`;
						} else {
							window.open(WEBSITE_PRICING_PAGE, '_blank');
						}
					},
				})
			);
		}

		if (status === 412) {
			const diamondTitle = get(response, 'response.data.data.diamondTitle');
			const errorMessageHeader = get(response, 'response.data.data.errorMessageHeader');
			const errorMessageDescription = get(
				response,
				'response.data.data.errorMessageDescription'
			);
			const image = get(response, 'response.data.data.illustrationImage');
			store.dispatch(
				showDialogAction({
					header: isUserSubscriptionOwner ? '' : 'Subscription expired',
					body: (
						<DialogImageWithText
							diamondImage={get(response, 'response.data.data.diamondImage')}
							diamondTitle={translate(diamondTitle, diamondTitle)}
							header={translate(errorMessageHeader, errorMessageHeader)}
							message={translate(errorMessageDescription, errorMessageDescription)}
							imagePath={image}
						/>
					),
					showConfirm: isUserSubscriptionOwner,
					showCancel: isUserSubscriptionOwner,
					cancelButtonText: Translations.SUBSCRIPTION_UPGRADE_POPUP_CANCEL_BUTTON_TEXT,
					confirmButtonType: 'confirm',
					confirmButtonText: Translations.SUBSCRIPTION_UPGRADE_POPUP_CONFIRM_BUTTON_TEXT,
					blurBackdrop: true,
					actionsFullWidth: isUserSubscriptionOwner,
					onConfirm: () => {
						store.dispatch(
							showAccountSettingsPopup({
								visible: true,
								defaultActiveTab: 4,
								onClose: () => {
									window.location.reload();
								},
							})
						);
					},
					onCancel: () => {
						this.errorDialogCb?.['412']?.onCancel?.();
					},
				})
			);
		}

		if (get(response, 'response.status') === 401) {
			if (
				get(response, 'response.data.customErrorCode') &&
				get(response, 'response.data.customErrorCode') === 'LOGIN_REQUIRED'
			) {
				// @ts-ignore
				window.location = `${API_BASE}/v1/authentication/authenticate?redirect_uri=/auth&redirect_host=${location.origin}&device_token=${deviceId}`;
			} else if (
				// @ts-ignore
				![accessTokenUrl, refreshUrl].includes(get(response, 'config.url')) &&
				response.response?.status === 401
			) {
				store.dispatch(refreshTokenAction(''));
			}
		}
		if (
			refreshUrl === get(response, 'config.url') &&
			[400, 401].includes(get(response, 'response.status'))
		) {
			store.dispatch(logoutAction(''));
		}
		const newResponse = set(
			response,
			'data.redirectOnError',
			this.redirectOnError || this.redirectOn400Error
		);
		return Promise.reject(newResponse);
	};

	requestInterceptors = (params?: AxiosRequestConfig) => {
		const token = !this.noAuth && this.getToken();
		const newParams = {
			...(params || {}),
			headers: {
				...(get(params, 'headers') || {}),
				...(token ? { Authorization: `Bearer ${token}` } : {}),
			},
			defaults: {
				mode: 'cors',
				withCredentials: false,
				credentials: 'same-origin',
				headers: {
					...(get(params, 'headers') || {}),
					Authorization: `Bearer ${token}`,
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Headers':
						'Origin, X-Requested-With, Content-Type, Accept',
					'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
					// 'Content-Type': 'application/json',
				},
			},
		};
		return newParams;
	};

	getToken = () => {
		return Cookie.get('floik-token-v2');
	};

	get = <T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig) =>
		this.instance.get(url, config).then(this.getHeader ? responseHeader : responseBody);
	post = <T, B, R = AxiosResponse<T>>(
		url: string,
		body: B,
		config?: AxiosRequestConfig
	): Promise<R> =>
		this.instance
			.post(url, body, config)
			.then(this.getHeader ? responseHeader : responseBody);
	put = <T, B, R = AxiosResponse<T>>(
		url: string,
		body: B,
		config?: AxiosRequestConfig
	): Promise<R> =>
		this.instance
			.put(url, body, config)
			.then(this.getHeader ? responseHeader : responseBody);
	patch = <T, B, R = AxiosResponse<T>>(
		url: string,
		body: B,
		config?: AxiosRequestConfig
	): Promise<R> =>
		this.instance
			.patch(url, body, config)
			.then(this.getHeader ? responseHeader : responseBody);
	delete = <T, R = AxiosResponse<T>>(
		url: string,
		config?: AxiosRequestConfig
	): Promise<R> =>
		this.instance
			.delete(url, config)
			.then(this.getHeader ? responseHeader : responseBody);
}

export default API;
