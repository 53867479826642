import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { IconReload, IconUpload } from '@tabler/icons-react';
import classNames from 'classnames';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import { get, min, noop, some, throttle } from 'lodash';
import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, GridCellProps, InfiniteLoader } from 'react-virtualized';
import uniqId from 'uniqid';
import { ReactComponent as ProcessingSvg } from '../../Common/images/ProcessingLoader.svg';
import Translations, { translate } from '../../Common/Translate.utils';
import { floSpaceSelector } from '../../Pages/ManageFlos/ManageFlos.selector';
import { RootState } from '../../store';
import Button from '../Button/Button';
import ImageElement from '../Image/Image';
import Loader from '../Loader/Loader';
import { setErrorToast } from '../Notification/Toasts.reducers';
import styles from './ImageGallery.module.css';
import {
	GalleryImgType,
	getGalleryImagesAction,
	hideImageGalleryAction,
	ImageGalleryReducerType,
	uploadGalleryImageAction,
} from './ImageGallery.reducer';

export const Processing = ({ text }: { text: string }) => (
	<div className={styles.processingLoaderContainer}>
		<ProcessingSvg className={styles.processingSvg} />
		<span className={styles.processingText}>{text}</span>
	</div>
);

const ImageItem = ({
	data,
	selectedImageId,
	onClick,
	columnIndex,
	rowIndex,
	style,
	columnCount,
	minDimensions,
}: {
	rowCount: number;
	columnCount: number;
	data: GalleryImgType | undefined;
	onClick(data: GalleryImgType): void;
	selectedImageId: undefined | string;
	columnIndex: number;
	rowIndex: number;
	style: CSSProperties;
	minDimensions?: {
		width: number;
		height: number;
	};
}) => {
	const { GALLERY_UPLOAD_PROGRESS_TEXT } = Translations;
	const [isImageLoaded, setIsImageLoaded] = useState(false);
	const [minDimensionsMet, setMinDimensionsMet] = useState(false);
	const onImageLoad = useCallback(
		(event: Event) => {
			if (
				minDimensions &&
				(event.target?.naturalWidth < minDimensions?.width ||
					event.target?.naturalHeight < minDimensions?.height)
			) {
				setMinDimensionsMet(false);
			} else {
				setMinDimensionsMet(true);
			}
			setIsImageLoaded(true);
		},
		[setIsImageLoaded, setMinDimensionsMet, minDimensions]
	);

	const isUploadInProgress = get(data, 'uploadStatus') === 'IN_PROGRESS';
	const isImageReady = isImageLoaded && !isUploadInProgress && minDimensionsMet;

	return data ? (
		<div
			style={{
				...style,
				paddingLeft: columnIndex === 0 ? '6px' : 0,
				paddingRight: columnIndex === columnCount - 1 ? '6px' : 0,
				paddingTop: rowIndex === 0 ? '6px' : 0,
			}}
			key={data.id}
		>
			<div
				className={classNames(styles.imagesListItem, {
					[styles.selectedImagesListItem]: selectedImageId === get(data, 'id'),
					[styles.disabled]: !isImageReady,
				})}
				onClick={() => {
					if (isImageReady) {
						onClick(data);
					}
				}}
			>
				{isUploadInProgress ? (
					<Processing text={GALLERY_UPLOAD_PROGRESS_TEXT} />
				) : (
					<ImageElement
						onLoad={onImageLoad}
						classes={{
							imageWrapper: classNames({ [styles.disabled]: !isImageReady }),
						}}
						id={`image_${get(data, 'id')}`}
						src={get(data, 'cloudFrontUrl', '')}
					/>
				)}
			</div>
		</div>
	) : null;
};

const imageGallerySelector = (state: RootState): ImageGalleryReducerType =>
	get(state, 'imageGallery') as ImageGalleryReducerType;

const ImageGallery = () => {
	const {
		GALLERY_NO_IMAGES_FOUND,
		GALLERY_FAILED_TO_LOAD_IMAGES,
		GALLERY_CORRUPT_IMAGE_ERROR_MSG,
	} = Translations;
	const {
		config: {
			visible,
			onConfirm,
			onCancel,
			header,
			confirmButtonText,
			cancelButtonText,
			uploadButtonText,
			headerClassName,
			dialogClassName,
			classes,
			variant,
			subHeading,
			recommendedResolution,
			minDimensions,
		},
		imagesList,
		pagination,
		errors,
	} = useSelector(imageGallerySelector);

	const dispatch = useDispatch();
	const imgUploadInputRef = useRef<HTMLInputElement>(null);
	const floSpaceId = useSelector(floSpaceSelector);
	const loader =
		useSelector((state) => get(state, 'loaders.imageGallery', 0) > 0) || !floSpaceId;
	const [containerWidth, setContainerWidth] = useState(0);
	const [selectedImage, setSelectedImage] = useState<GalleryImgType | undefined | null>();
	const [isUploadInProgress, setIsUploadInProgress] = useState(false);
	const [uploadImage, setUploadImage] = useState<{
		file: File;
		height: number;
		width: number;
	} | null>(null);
	const [columnCount, setColumnCount] = useState(0);
	const prevIsUploadInProgress = useRef(false);
	const [fileSelectInputKey, setFileSelectInputKey] = useState(uniqId());

	const handleUploadClick = useCallback(() => {
		imgUploadInputRef.current?.click();
	}, [imgUploadInputRef]);

	const onImgSelect = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			if (!event.target.files) {
				return;
			}
			const file = event.target.files[0];
			const fileSizeInMb = (file?.size || 0) / 1000000;
			if (fileSizeInMb > 10) {
				dispatch(
					setErrorToast({
						type: 'error',
						message: Translations.GALLERY_UPLOAD_FILE_SIZE_ERROR,
						canShow: true,
					})
				);
				return;
			}
			let img = new Image();
			img.src = window.URL.createObjectURL(file);
			img.onload = () => {
				setUploadImage({
					file,
					width: img.naturalWidth,
					height: img.naturalHeight,
				});
				dispatch(
					uploadGalleryImageAction({
						file,
						width: img.naturalWidth,
						height: img.naturalHeight,
					})
				);
			};
			img.onerror = () => {
				dispatch(
					setErrorToast({
						type: 'error',
						message: GALLERY_CORRUPT_IMAGE_ERROR_MSG,
						canShow: true,
					})
				);
			};
		},
		[setUploadImage]
	);

	const handleClose = useCallback(() => {
		dispatch(hideImageGalleryAction(''));
		onCancel?.();
	}, [onCancel]);

	const handleConfirm = useCallback(
		throttle(
			() => {
				console.log('selected image', selectedImage);
				if (selectedImage) {
					onConfirm?.(selectedImage);
				}
				dispatch(hideImageGalleryAction(''));
			},
			1000,
			{ leading: true, trailing: false }
		),
		[dispatch, onConfirm, selectedImage]
	);

	useEffect(() => {
		if (floSpaceId && visible) {
			dispatch(
				getGalleryImagesAction({
					pageNo: 1,
				})
			);
		}
	}, [floSpaceId, visible]);

	const retryButtonClick = useCallback(() => {
		dispatch(
			getGalleryImagesAction({
				pageNo: 1,
			})
		);
	}, []);

	const onResize = useCallback(() => {
		if (visualViewport.width) {
			const availableWidth = min([visualViewport.width - 60, 1000]);
			const numberOfImagesInRow = Math.floor(
				((availableWidth || 0) - 42 - scrollbarSize() - 12) / (168 + 24)
			);
			const newContainerWidth =
				Math.floor((numberOfImagesInRow - 1) * (168 + 24) + 168) + scrollbarSize();
			setContainerWidth(newContainerWidth);
			setColumnCount(numberOfImagesInRow);
		}
	}, [visualViewport.width, setContainerWidth, setColumnCount]);

	useEffect(() => {
		onResize();
	}, []);

	useEffect(() => {
		window.addEventListener('resize', onResize);
		onResize();
		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, [onResize]);

	const rowCount = Math.ceil(imagesList.length / columnCount);

	const getRowHeight = useCallback(
		({ index }: { index: number }) => {
			if (index === 0) {
				return 168 + 24 + 6;
			} else if (index === rowCount - 1) {
				return 168 + 6;
			}
			return 168 + 24;
		},
		[rowCount]
	);

	const getColumnWidth = useCallback(
		({ index }: { index: number }) => {
			if (index === columnCount - 1) {
				return 168 + 6;
			} else if (index === 0) {
				return 168 + 24 + 6;
			}
			return 168 + 24;
		},
		[columnCount]
	);

	const onItemClick = useCallback(
		(img: GalleryImgType) => {
			const image = document.querySelector(`#image_${img.id}`);
			const meta = { width: 0, height: 0 };
			if (image) {
				meta.width = get(image, 'naturalWidth') || get(img, 'imageWidth');
				meta.height = get(image, 'naturalHeight') || get(img, 'imageHeight');
			}
			setSelectedImage({
				...img,
				meta,
			});
		},
		[setSelectedImage]
	);

	useEffect(() => {
		if (imagesList.length) {
			const isUploadInProgress = some(imagesList, ['uploadStatus', 'IN_PROGRESS']);
			setIsUploadInProgress(isUploadInProgress);
			if (prevIsUploadInProgress.current && !isUploadInProgress) {
				if (
					!minDimensions ||
					(get(uploadImage, 'width', 0) > minDimensions?.width &&
						get(uploadImage, 'height', 0) > minDimensions?.height)
				) {
					onItemClick(imagesList[0]);
				}
				setFileSelectInputKey(uniqId());
				setUploadImage(null);
			}
			prevIsUploadInProgress.current = isUploadInProgress;
		}
	}, [
		imagesList,
		onItemClick,
		setIsUploadInProgress,
		setUploadImage,
		prevIsUploadInProgress,
		setSelectedImage,
		setFileSelectInputKey,
		minDimensions,
		uploadImage,
	]);

	const loadNextPage = useCallback(
		(params: { startIndex: number; stopIndex: number }) => {
			dispatch(
				getGalleryImagesAction({
					pageNo: Math.floor((params.startIndex * columnCount) / 30) + 1,
				})
			);
			return new Promise((resolve) => resolve(false));
		},
		[floSpaceId]
	);

	useEffect(() => {
		if (!visible) {
			setSelectedImage(null);
			setIsUploadInProgress(false);
		}
	}, [visible, setContainerWidth, setSelectedImage, setIsUploadInProgress]);

	const totalRecords = get(pagination, 'totalRecords', 0);
	const addMoreRows = rowCount * columnCount < totalRecords;
	const isRowLoaded = ({ index }: { index: number }) => index < rowCount;
	const height = min([360, visualViewport.height - 300]);
	const estimatedRowSize = (168 + 24) * Math.ceil(imagesList.length / columnCount);

	if (!visible) return <span />;

	return (
		<Dialog
			open={true}
			scroll={'paper'}
			classes={{
				paper: classNames(styles.container, classes?.container || '', {
					[styles.small]: variant === 'small',
				}),
				root: classNames(styles.dialogRoot, dialogClassName),
			}}
			onClose={handleClose}
			componentsProps={{
				backdrop: {
					// @ts-ignore
					classes: {
						root: styles.positionAbsolute,
					},
				},
			}}
		>
			{header && (
				<DialogTitle
					id="responsive-dialog-title"
					className={classNames(styles.title, headerClassName)}
				>
					{header}
					{recommendedResolution && (
						<div className={styles.subTitle}>
							Recommended resolution: {recommendedResolution}
						</div>
					)}
					{minDimensions && (
						<div className={styles.subTitle}>
							Minimum resolution: {minDimensions.width}x{minDimensions.height}
						</div>
					)}
				</DialogTitle>
			)}
			<input
				type="file"
				key={fileSelectInputKey}
				accept=".png,.jpg,.jpeg, .gif"
				onChange={onImgSelect}
				ref={imgUploadInputRef}
				className={styles.imgUploadInput}
			/>
			<DialogContent className={styles.dialogContent} style={{ width: containerWidth }}>
				{loader && get(imagesList, 'length', 0) === 0 ? (
					<div className={styles.loaderWrapper}>
						<Loader nodelay />
					</div>
				) : (
					<>
						<div className={styles.subHeadingWrapper}>
							<div className={styles.subHeading}>{translate(subHeading, subHeading)}</div>
							<div className={styles.uploadButtonWrapper}>
								<div className={styles.maxFileSize}>Max file size: 10MB</div>
								<Button
									autoFocus
									disableFocusRipple
									variant="contained"
									onClick={isUploadInProgress ? noop : handleUploadClick}
									className={classNames(
										styles.uploadButton,
										get(classes, 'uploadButton'),
										{
											[styles.disabled]: isUploadInProgress,
										}
									)}
								>
									<IconUpload className={styles.uploadButtonIcon} size={16} />
									<span className={styles.uploadButtonText}>
										{translate(uploadButtonText, uploadButtonText)}
									</span>
								</Button>
							</div>
						</div>
						{get(imagesList, 'length') > 0 && (
							<div className={styles.imagesListWrapper}>
								<InfiniteLoader
									isRowLoaded={isRowLoaded}
									// @ts-ignore
									loadMoreRows={loadNextPage}
									rowCount={rowCount + (addMoreRows ? 1 : 0)}
								>
									{({ onRowsRendered, registerChild }) => {
										return (
											<Grid
												ref={(grid) => {
													registerChild(grid);
												}}
												width={containerWidth + 12}
												height={height || 0}
												estimatedColumnSize={containerWidth}
												estimatedRowSize={estimatedRowSize}
												rowCount={rowCount}
												rowHeight={getRowHeight}
												onSectionRendered={({ rowStartIndex, rowStopIndex }) => {
													onRowsRendered({
														startIndex: rowStartIndex,
														stopIndex: rowStopIndex,
													});
												}}
												columnWidth={getColumnWidth}
												columnCount={columnCount}
												overscanColumnCount={0}
												overscanRowCount={0}
												cellRenderer={(props: GridCellProps) => {
													return (
														<ImageItem
															{...props}
															minDimensions={minDimensions}
															rowCount={rowCount}
															columnCount={columnCount}
															selectedImageId={get(selectedImage, 'id')}
															onClick={onItemClick}
															// addMoreRows={addMoreRows}
															data={get(
																imagesList,
																`[${props.rowIndex * columnCount + props.columnIndex}]`
															)}
														/>
													);
												}}
											></Grid>
										);
									}}
								</InfiniteLoader>
								{loader && <Loader nodelay />}
							</div>
						)}

						{!loader &&
							get(imagesList, 'length', 0) === 0 &&
							(get(errors, 'getGalleryImages') ? (
								<div className={classNames(styles.emptyMessage, 'flex-column')}>
									{GALLERY_FAILED_TO_LOAD_IMAGES}
									<div className={styles.retryWrapper}>
										<Button className={styles.cancelButton} onClick={retryButtonClick}>
											<IconReload size={18}></IconReload>
											<div className={styles.cancelButtonText}>Retry</div>
										</Button>
									</div>
								</div>
							) : (
								<div className={styles.emptyMessage}>{GALLERY_NO_IMAGES_FOUND}</div>
							))}
					</>
				)}
			</DialogContent>
			<DialogActions
				classes={{
					root: classNames(styles.actions),
				}}
			>
				<Button
					autoFocus
					disableFocusRipple
					onClick={handleClose}
					className={classNames(styles.cancelButton, get(classes, 'cancelButton'))}
				>
					<span className={styles.cancelButtonText}>
						{translate(cancelButtonText, cancelButtonText)}
					</span>
				</Button>

				<Button
					disableFocusRipple
					variant="contained"
					className={classNames(styles.confirmButton, get(classes, 'confirmButton'), {
						[styles.disabled]: !get(selectedImage, 'id'),
					})}
					disabled={!get(selectedImage, 'id')}
					onClick={handleConfirm}
					autoFocus
				>
					{translate(confirmButtonText, confirmButtonText)}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ImageGallery;
