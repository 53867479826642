import { get } from 'lodash';
import { formattedTime } from '../../Common/Common.utils';
import { RootState } from '../../store';
import { RecordStates } from './NewFloContainer.types';

export const newFloIdSelector = (state: RootState): string => get(state, 'newFlo.floId');
export const newFloNameSelector = (state: RootState): string =>
	get(state, 'newFlo.name', '');

export const newFloTracksSelector = (state: RootState): { [key: string]: string } =>
	get(state, 'newFlo.trackIds');

export const newFloDurationSelector = (state: RootState): { [key: string]: string } =>
	get(state, 'newFlo.duration');

export const elementIdSelector = (state: RootState): { [key: string]: string } =>
	get(state, 'newFlo.elementId');

export const recordStateSelector = (state: RootState): RecordStates =>
	get(state, 'newFlo.state');

export const countdownTrackSelector = (state: RootState): string =>
	'/count-down-start.mp3' || get(state, 'newFlo.countDownTrack');

export const stopSoundTrackSelector = (state: RootState): string =>
	get(state, 'newFlo.stopSoundTrack');

export const timeRestrictionSelector = (state: RootState): number =>
	get(
		state,
		'routerContainer.currentFloSpace.flospaceConstraints.newFloMaxTimeLimitInSeconds'
	) || -1;

export const formattedTimeRestrictionSelector = (state: RootState): string => {
	const maxTime =
		get(
			state,
			'routerContainer.currentFloSpace.flospaceConstraints.newFloMaxTimeLimitInSeconds'
		) || -1;
	const formatMaxTime = maxTime > 0 ? formattedTime(maxTime * 1000) : '';
	return formatMaxTime;
};
// export const timeRestrictionSelector = (state: RootState): number => 10 * 60; // fixing to 10min
export const floTracks = (state: RootState) => get(state, 'newFlo.tracks');

export const getCapturedSize = (state: RootState) => get(state, 'newFlo.captureSize');

export const floDuration = (state: RootState) => get(state, 'newFlo.duration');
export const floComments = (state: RootState) => get(state, 'newFlo.comments');

export const getCropConfigs = (state: RootState) =>
	get(state, 'newFlo.postProcessConfigs.crop');
export const getTrimConfigs = (state: RootState) =>
	get(state, 'newFlo.postProcessConfigs.trim');

export const canDeleteEditFlo = (state: RootState) =>
	get(state, 'newFlo.canDelete', true);

export const hasFailedAttemptSelector = (state: RootState): boolean =>
	get(state, 'newFlo.hasFailedAttempt', false);

export const hasFailedAttemptMessageSelector = (state: RootState): string =>
	get(state, 'newFlo.hasFailedMessage', '');

export const isOnlineSelector = (state: RootState): boolean =>
	get(state, 'newFlo.isOnline', false);

export const isExtensionInstalled = (state: RootState) =>
	get(state, 'onBoarding.extensionInstalled');

export const isExtensionErrored = (state: RootState) =>
	get(state, 'onBoarding.extensionErrored');
