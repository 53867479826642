import { get } from 'lodash';
import { SagaIterator } from 'redux-saga';
import { call, debounce, ForkEffect, put, select, takeLatest } from 'redux-saga/effects';
import { API_BASE } from '../../Common/Common.env';
import {
	downloadInvoiceAction,
	getInvoicesAction,
	getInvoicesSuccessAction,
} from '../../Components/InvoicesDialog/InvoicesDialog.reducer';
import { setErrorToast } from '../../Components/Notification/Toasts.reducers';
import API from '../../utils/API';
import { hideLoader, showLoader } from '../reducers/Loader.reducer';
import { analyticsLog } from '../reducers/Log.reducer';

function* getInvoicesSaga({
	payload,
}: {
	payload: {
		pageNo?: number;
		hideLoader?: boolean;
	};
}): SagaIterator {
	try {
		if (!payload.hideLoader) {
			yield put(showLoader('invoicesDialog'));
		}
		const floSpaceId = yield select((state) =>
			get(state, 'routerContainer.currentFloSpace.id')
		);
		if (!floSpaceId) return;
		const response = yield call(
			new API().get,
			`${API_BASE}/v1/flospace/billing/${floSpaceId}/invoice?pageSize=30&pageNumber=${payload.pageNo}`,
			{}
		);
		yield put(getInvoicesSuccessAction(response));
	} catch (e) {
		console.error(e);
		yield put(setErrorToast(e));
		yield put(
			analyticsLog({
				level: 'error',
				message: `Get invoices failed`,
				value: get(e, 'stack'),
			})
		);
	} finally {
		if (!payload.hideLoader) {
			yield put(hideLoader('invoicesDialog'));
		}
	}
}

function* downloadInvoiceSaga({ payload }: { payload: string }): SagaIterator {
	try {
		if (payload) {
			const link = document.createElement('a');
			link.setAttribute('download', 'true');
			link.setAttribute('href', payload);
			link.click();
		}
	} catch (e) {
		console.error(e);
		yield put(setErrorToast(e));
		yield put(
			analyticsLog({
				level: 'error',
				message: `Download invoice ${payload} failed`,
				value: get(e, 'stack'),
			})
		);
	}
}

function* invoicesDialogSaga(): Generator<ForkEffect> {
	const tasks = [
		//@ts-ignore
		yield takeLatest(getInvoicesAction.type, getInvoicesSaga),
		//@ts-ignore
		yield debounce(200, downloadInvoiceAction.type, downloadInvoiceSaga),
	];
}

export default invoicesDialogSaga;
