import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Box, Button, Grid } from '@mui/material';
import classNames from 'classnames';
import React, {
	ForwardedRef,
	ReactElement,
	useCallback,
	useEffect,
	useState,
} from 'react';
import styles from '../NewFloContainer.module.css';
// import Zoom from '@mui/material/Zoom';
import moment from 'moment';
import { ReactComponent as DeleteSvg } from '../../../Common/images/Delete.svg';
import { ReactComponent as RestartSvg } from '../../../Common/images/restart.svg';
import { ReactComponent as RecordingSvg } from '../images/recording.svg';
import { RecordStates } from '../NewFloContainer.types';
import { ReactComponent as CameraSvg } from './Templates/TemplateDetails/images/CameraNoColor.svg';

import { IconMicrophone, IconMicrophoneOff } from '@tabler/icons-react';
import { get, includes, upperCase } from 'lodash';
import { useSelector } from 'react-redux';
import Translations from '../../../Common/Translate.utils';
import { SendMessageToExtension } from '../../../utils/SendMessageToExtension';
import {
	formattedTimeRestrictionSelector,
	timeRestrictionSelector,
} from '../NewFloContainer.selector';
import { setCurrentActiveTab } from '../NewFloExtension.actions';
import Zoom from '../ZoomTemp';
import NetworkSpeed from './NetworkSpeed';
import NewFloExtensionError from './NewFloExtensionError/NewFloExtensionError';

type RecordingStateProps = {
	networkSpeed: string;
	duration: number;
	activeTemplateFilter?: string;
	recordState: RecordStates;
	startTime?: moment.Moment;
	endTime?: moment.Moment;
	displaySurface?: string;
	onPauseHandler?(): void;
	onDiscard(): void;
	onRestart(): void;
	startRecording(): void;
	onStopHandler(playSound?: boolean): void;
	isPaused: boolean;
	isPrepareScreen?: boolean;
	children?: ReactElement;
	audioDeviceMeta?: MediaDeviceInfo;
	videoDeviceMeta?: MediaDeviceInfo;
	audioDevice?: string;
};

export const getDuration = ({
	endTime,
	startTime,
	duration: totalDuration,
}: {
	duration: number;
	startTime?: moment.Moment | undefined | null;
	endTime?: moment.Moment | undefined | null;
}) => {
	const end: moment.Moment = endTime || moment();
	const diff = startTime ? end.diff(startTime) : 0;
	const duration = moment.duration(totalDuration + diff);
	return duration.asSeconds();
};

const ElapsedTimer = ({
	duration: totalDuration = 0,
	startTime,
	endTime,
	onStopHandler,
	recordState,
}: Partial<{
	recordState: RecordStates;
	duration: number;
	startTime?: moment.Moment | undefined | null;
	endTime?: moment.Moment | undefined | null;
	onStopHandler(playSound?: boolean): void;
}>) => {
	const [time, setTime] = useState({ mm: '00', ss: '00' });
	const newFloMaxTimeLimitInSeconds = useSelector(timeRestrictionSelector);
	const formatMaxTime = useSelector(formattedTimeRestrictionSelector);

	useEffect(() => {
		const interval = setInterval(() => {
			const end: moment.Moment = endTime || moment();
			const diff = startTime ? end.diff(startTime) : 0;
			const duration = moment.duration(totalDuration + diff);
			const totalSeconds = duration.asSeconds();
			const minutes = duration.minutes();
			// @ts-ignore
			const mm = String(minutes).padStart(2, '0');
			// @ts-ignore
			const ss = String(duration.seconds()).padStart(2, '0');
			setTime({ mm, ss });
			if (
				newFloMaxTimeLimitInSeconds > 0 &&
				totalSeconds >= newFloMaxTimeLimitInSeconds &&
				recordState === 'recording'
			) {
				onStopHandler?.(true);
			}
		}, 1000);
		return () => {
			if (interval) {
				clearTimeout(interval);
			}
		};
	}, [totalDuration, startTime, endTime, setTime]);

	return (
		<div className={styles.timeElapsed}>
			{time.mm}:{time.ss} {formatMaxTime ? ' / ' : ''} {formatMaxTime}
		</div>
	);
};

const RecordingState = React.forwardRef(
	(
		{
			activeTemplateFilter,
			duration,
			recordState,
			startTime,
			endTime,
			onPauseHandler,
			onStopHandler,
			startRecording,
			isPaused,
			audioDeviceMeta,
			videoDeviceMeta,
			onDiscard,
			onRestart,
			displaySurface,
			isPrepareScreen,
			networkSpeed,
			audioDevice,
		}: RecordingStateProps,
		videoRef: ForwardedRef<HTMLVideoElement>
	) => {
		const {
			DISPLAY_SURFACE_DESCRIPTION_MAP,
			DISPLAY_SURFACE_NAME_MAP,
			NEW_FLO_CONTINUE_BTN_TEXT,
			NEW_FLO_CONTINUE_BTN_TOOLTIP,
			NEW_FLO_END_BTN_TEXT,
			NEW_FLO_END_BTN_TOOLTIP,
			NEW_FLO_PAUSE_BTN_TEXT,
			NEW_FLO_PAUSE_BTN_TOOLTIP,
			NEW_FLO_NO_AUDIO_DROPDOWN_LABEL,
		} = Translations;
		const onStopCallback = useCallback(() => onStopHandler?.(), [onStopHandler]);
		const onPauseCallback = useCallback(() => {
			if (isPaused && displaySurface !== 'browser') {
				SendMessageToExtension(setCurrentActiveTab(''));
			}
			onPauseHandler?.();
		}, [isPaused, onPauseHandler, displaySurface]);
		const clicksCount = useSelector((state) => get(state, 'newFlo.noOfClicks', 0));
		const inputsRequired = useSelector((state) =>
			get(state, 'newFlo.template.inputPermissions', [])
		);
		const videoRequired = includes(inputsRequired, 'camera');
		const audioRequired = includes(inputsRequired, 'microphone');

		return (
			<Zoom in={get(activeTemplateFilter, 'key') !== 'manual_upload' && includes(['recording', 'creating'], recordState)}>
				<Grid
					container
					justifyContent="left"
					alignItems="flex-start"
					gap={0}
					spacing={0}
					className={classNames(
						styles.cardRecordingBody,
						styles.cardBody,
						styles.recordingView,
						{
							[styles.shrink]: clicksCount === 0,
							// @ts-ignore
							[styles.hide]: get(activeTemplateFilter, 'key') === 'manual_upload' || !includes(['recording', 'creating'], recordState),
						}
					)}
				>
					<Grid
						item
						desktop={clicksCount > 0 ? 7.5 : 12}
						tablet={clicksCount > 0 ? 7.5 : 12}
						mobile={12}
						className={styles.recordingScreenLeftSection}
					>
						<Box className={styles.recordingScreenLeftWrapper}>
							{!isPrepareScreen && (
								<div
									className={classNames(styles.cardRecording, {
										[styles.paused]: isPaused,
									})}
								>
									<span className={styles.recordingIcon}>
										{isPaused ? <PauseIcon /> : <RecordingSvg />}
									</span>
									<span
										className={classNames(styles.recordingText, {
											[styles.paused]: isPaused,
										})}
									>
										{isPaused ? 'Recording is paused' : 'Recording in progress'}
									</span>
								</div>
							)}
							<ElapsedTimer
								onStopHandler={onStopHandler}
								duration={duration}
								startTime={startTime}
								endTime={endTime}
								recordState={recordState}
							/>
							{displaySurface && (
								<div className={styles.recordingContentWrapper}>
									<div className={styles.recordingContentType}>
										{get(DISPLAY_SURFACE_NAME_MAP, upperCase(displaySurface))} RECORDING
									</div>
									<div className={styles.recordingContentTabContainer}>
										<span className={styles.recordingContentTabTitle}>
											{get(DISPLAY_SURFACE_DESCRIPTION_MAP, upperCase(displaySurface))}
										</span>
									</div>
								</div>
							)}
							<video
								ref={videoRef}
								height={20 * 16}
								width={20 * 9}
								autoPlay
								muted
								className={classNames(styles.recordingVideoPreview, {
									[styles.hideVideo]: !videoRequired,
								})}
							/>
							{videoRequired && (
								<div className={styles.selectedDevice}>
									<CameraSvg className={styles.selectedDeviceIcon} />
									<span className={styles.deviceName}>
										{get(videoDeviceMeta, 'label')}
									</span>
								</div>
							)}
							{audioRequired && (
								<div
									className={classNames(styles.selectedDevice, {
										[styles.disabled]: audioDevice === 'no_audio',
									})}
								>
									{audioDevice === 'no_audio' ? (
										<IconMicrophoneOff className={styles.selectedDeviceIcon} />
									) : (
										<IconMicrophone className={styles.selectedDeviceIcon} />
									)}
									<span className={styles.deviceName}>
										{audioDevice === 'no_audio'
											? NEW_FLO_NO_AUDIO_DROPDOWN_LABEL
											: get(audioDeviceMeta, 'label')}
									</span>
								</div>
							)}
							<NewFloExtensionError />
							<div className={styles.buttonsWrapper}>
								{!isPrepareScreen && (
									<Button
										disableFocusRipple
										className={classNames(styles.recordButton, {
											[styles.disabled]: !startTime && !duration,
										})}
										variant="outlined"
										title={
											isPaused ? NEW_FLO_CONTINUE_BTN_TOOLTIP : NEW_FLO_PAUSE_BTN_TOOLTIP
										}
										onClick={onPauseCallback}
										disabled={!startTime && !duration}
									>
										{!isPaused ? (
											<div className={styles.buttonIconContainer}>
												<PauseIcon
													className={styles.buttonIcon}
													style={{ fontSize: 24 }}
												/>
												<span>{NEW_FLO_PAUSE_BTN_TEXT}</span>
											</div>
										) : (
											<div className={styles.buttonIconContainer}>
												<PlayArrowIcon
													className={styles.buttonIcon}
													style={{ fontSize: 24 }}
												/>
												<span>{NEW_FLO_CONTINUE_BTN_TEXT}</span>
											</div>
										)}
									</Button>
								)}
								{isPrepareScreen && (
									<Button
										disableFocusRipple
										className={classNames(styles.recordButton, {
											[styles.disabled]: recordState === 'creating',
										})}
										variant="outlined"
										title={'Next'}
										onClick={startRecording}
										disabled={recordState === 'creating'}
									>
										<div className={styles.buttonIconContainer}>
											<span>Next</span>
										</div>
									</Button>
								)}

								<Button
									disableFocusRipple
									className={classNames(styles.stopButton, {
										[styles.disabled]: !startTime && !duration,
									})}
									variant="outlined"
									disabled={!startTime && !duration}
									title={NEW_FLO_END_BTN_TOOLTIP}
									onClick={onStopCallback}
								>
									<span>{NEW_FLO_END_BTN_TEXT}</span>
								</Button>
							</div>
							<div className={styles.buttonsWrapper}>
								<Button
									variant="text"
									className={styles.discardButton}
									onClick={onDiscard}
								>
									<DeleteSvg className={styles.restartButtonIcon} />
									<span className={styles.restartButtonText}>Discard</span>
								</Button>
								<Button
									variant="text"
									className={styles.restartButton}
									onClick={onRestart}
									disabled
								>
									<RestartSvg className={styles.restartButtonIcon} />
									<span className={styles.restartButtonText}>Restart</span>
								</Button>
							</div>
							<NetworkSpeed networkSpeed={networkSpeed} />
						</Box>
					</Grid>
					<Grid
						item
						desktop={4.5}
						tablet={4.5}
						mobile={12}
						className={classNames(styles.recordingScreenRightSection, {
							[styles.hide]: clicksCount === 0,
						})}
					>
						<Box className={styles.recordingScreenRightWrapper}>
							<div className={styles.clicksCaptured}>Clicks captured</div>
							<div className={styles.clicksContainer}>{clicksCount}</div>
						</Box>
					</Grid>
				</Grid>
			</Zoom>
		);
	}
);

export default RecordingState;
