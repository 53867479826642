import { get, reduce, throttle } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatSpeed, getNetworkQuality } from '../../../utils/network';
import { SendMessageToExtension } from '../../../utils/SendMessageToExtension';
import { setIsOnline } from '../NewFlo.reducers';
import { updateSpeedAndOnline } from '../NewFloExtension.actions';

const useNetworkHook = () => {
	const dispatch = useDispatch();
	const uploadSpeedData = useSelector((state) => get(state, 'newFlo.uploadSpeedData'));
	const uploadSpeed = useSelector((state) => get(state, 'newFlo.uploadSpeed'));
	const networkStrength = useSelector((state) =>
		getNetworkQuality(get(state, 'newFlo.uploadSpeed', -1))
	);
	const [speed, setSpeed] = useState<string>('');
	useEffect(() => {
		const isOnlineCb = () => {
			dispatch(setIsOnline(true));
		};
		const isOfflineCb = () => {
			dispatch(setIsOnline(false));
		};
		window.addEventListener('online', isOnlineCb);
		window.addEventListener('offline', isOfflineCb);
		return () => {
			window.removeEventListener('online', isOnlineCb);
			window.removeEventListener('offline', isOfflineCb);
		};
	}, []);

	const checkSpeed = useCallback(
		(event: Event) => {
			const type = get(event, 'target.effectiveType');
			if (type === '2g') {
				setSpeed('weak');
			} else if (type === '3g') {
				setSpeed('fair');
			}
		},
		[setSpeed]
	);

	useEffect(() => {
		if (networkStrength.strength) {
			setSpeed(networkStrength.strength);
		} else {
			checkSpeed({ target: get(navigator, 'connection') } as unknown as Event);
		}
	}, [networkStrength.strength, checkSpeed]);

	useEffect(() => {
		try {
			// @ts-ignore
			navigator?.connection?.addEventListener('change', checkSpeed);
			return () => {
				// @ts-ignore
				navigator?.connection?.removeEventListener('change', checkSpeed);
			};
		} catch {
			console.log('navigation.connection not found');
		}
	}, [checkSpeed]);
	const sendMessage = useCallback(
		throttle(
			(data, speed) => {
				SendMessageToExtension(
					updateSpeedAndOnline({
						uploadSpeed: formatSpeed(speed, 'ps'),
						uploaded: formatSpeed(data.uploaded),
						totalSize: formatSpeed(data.totalSize),
						uploadedNum: data.uploaded,
						totalSizeNum: data.totalSize,
						networkStrengthText: data.networkStrengthText,
						networkStrengthStrength: data.networkStrengthStrength,
					})
				);
			},
			500,
			{ leading: false, trailing: true }
		),
		[]
	);
	useEffect(() => {
		const data = reduce(
			uploadSpeedData,
			(acc, data) => {
				return {
					...acc,
					uploaded: acc.uploaded + (data[0] || 0),
					totalSize: acc.totalSize + (data[1] || 0),
				};
			},
			{
				uploaded: 0,
				totalSize: 0,
				networkStrengthText: networkStrength.text,
				networkStrengthStrength: networkStrength.strength,
			}
		);

		sendMessage(data, uploadSpeed);
	}, [
		sendMessage,
		uploadSpeed,
		uploadSpeedData,
		speed,
		networkStrength.text,
		networkStrength.strength,
	]);
	return {
		speed,
	};
};

export default useNetworkHook;
