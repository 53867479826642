import { get } from 'lodash';
import { SagaIterator } from 'redux-saga';
import { call, debounce, ForkEffect, put, select, takeLatest } from 'redux-saga/effects';
import uniqId from 'uniqid';
import { API_BASE } from '../../Common/Common.env';
import { commonErrorPopup } from '../../Common/Common.saga';
import { showAccountSettingsPopup } from '../../Components/AccountSettings/AccountSettings.reducer';
import {
	getBrandingDetailsAction,
	removeBrandingLogoAction,
	removeBrandingLogoSuccessAction,
	setBrandingColorAction,
	setBrandingDetails,
	setBrandingLogoAction,
	setBrandingPositionAction,
	togglePoweredByFloikAction,
	toggleShowLogoAsWatermarkAction,
	toggleShowLogoInHeadingAction,
} from '../../Components/BrandingDialog/BrandingDialog.reducer';
import { setErrorToast } from '../../Components/Notification/Toasts.reducers';
import { setBrandImage } from '../../Containers/Routes/Routes.reducers';
import API from '../../utils/API';
import { hideLoader, showLoader } from '../reducers/Loader.reducer';

export function* getBrandImage({
	payload,
}: {
	payload: {
		retry: number;
		position: string;
		url: string;
		enabled: boolean;
	};
}): SagaIterator {
	try {
		if (!payload.enabled || !payload.url) {
			return;
		}
		// @ts-ignore
		const imageUrl: string = yield new Promise((resolve, reject) => {
			const image = new Image();
			image.src = payload.url;
			image.onload = () => {
				resolve(payload.url);
			};
			image.onerror = () => {
				reject();
			};
		});
		yield put(
			setBrandImage({
				url: imageUrl,
				position: payload.position || 'bottom-right',
			})
		);
	} catch (e) {
		console.warn(e);
		if (get(payload, 'retry') < 3) {
			yield call(getBrandImage, {
				payload: {
					...payload,
					retry: get(payload, 'retry', 0) + 1,
				},
			});
		}
	}
}

function* getBrandingDetails() {
	try {
		yield put(showLoader('upload_brand_image'));
		// @ts-ignore
		const floSpaceId = yield select((state) =>
			get(state, 'routerContainer.currentFloSpace.id')
		);
		if (!floSpaceId) return;
		//@ts-ignore
		const response = yield call(
			new API().get,
			`${API_BASE}/v1/flospace/${floSpaceId}/branding`
		);
		const data = get(response, 'data.flospaceBrandConfiguration');
		yield put(
			setBrandingDetails({
				data: {
					...data,
					uuid: uniqId(),
					hidePoweredByFloik: get(response, 'data.hidePoweredByFloik', false),
				},
				status: 'loaded',
			})
		);

		yield call(getBrandImage, {
			payload: {
				retry: 0,
				url: get(data, 'customWatermarkConfiguration.customWatermarkFilePath'),
				position: get(data, 'watermarkPosition'),
				enabled: !get(data, 'customWatermarkConfiguration.customWatermarkDisabled'),
			},
		});
		let brandStyleElem = document.getElementById('floikBrandStyle');
		if (!brandStyleElem) {
			brandStyleElem = document.createElement('style');
			brandStyleElem.id = 'floikBrandStyle';
			// @ts-ignore
			brandStyleElem.type = 'text/css';
			document.head.appendChild(brandStyleElem);
		}
		if (brandStyleElem && data?.brandColor) {
			// @ts-ignore
			const brandStyle = document.createElement('style');
			brandStyle.id = 'floikBrandStyle';
			brandStyle.type = 'text/css';
			brandStyle.innerHTML = `:root,
				:root [theme='dark'],
				:root [theme='light'] {
				 --brand-color-videoPlayer-progress-control-bar-background: ${data.brandColor};
				 --brand-color-instructions-list-step-index-background: ${data.brandColor};
				 --brand-color-instructions-list-step-index-color: ${data.brandColor};
				 --brand-color-click-animation-color: ${data.brandColor};
				 --brand-color: ${data.brandColor};
				 --brand-color-hotspot-background: ${data.brandColor}33;
				 }`;
			document.head.appendChild(brandStyle);
		}
	} catch (e) {
		console.warn(e);
		yield put(setErrorToast(e));
	} finally {
		yield put(hideLoader('upload_brand_image'));
	}
}

function* updateBrandingColor({
	payload,
}: {
	payload: {
		brandColor?: string;
	};
}) {
	try {
		// @ts-ignore
		const floSpaceId = yield select((state) =>
			get(state, 'routerContainer.currentFloSpace.id')
		);

		//@ts-ignore
		const response = yield call(
			new API().put,
			`${API_BASE}/v1/flospace/${floSpaceId}/brand-color`,
			{
				brandColor: payload.brandColor || '',
			}
		);
		// yield put(getBrandingDetailsAction({ update: true }));
	} catch (e) {
		console.warn(e);
		yield put(setErrorToast(e));
	}
}

function* togglePoweredByFloik({
	payload,
}: {
	payload: {
		hidePoweredBy: boolean;
	};
}) {
	try {
		// @ts-ignore
		const floSpaceId = yield select((state) =>
			get(state, 'routerContainer.currentFloSpace.id')
		);

		//@ts-ignore
		const response = yield call(
			new API().put,
			`${API_BASE}/v1/flospace/${floSpaceId}/poweredby?hidePoweredBy=${payload.hidePoweredBy}`,
			{}
		);
		yield put(getBrandingDetailsAction({ update: true }));
	} catch (e) {
		console.warn(e);
		yield put(setErrorToast(e));
	}
}

function* toggleShowLogoInHeading({
	payload,
}: {
	payload: {
		showLogoInHeading: boolean;
	};
}) {
	try {
		// @ts-ignore
		const floSpaceId = yield select((state) =>
			get(state, 'routerContainer.currentFloSpace.id')
		);
		//@ts-ignore
		const response = yield call(
			new API().put,
			`${API_BASE}/v1/flospace/${floSpaceId}/header-brand-logo-hide?hideBrandLogoOnHeader=${!payload.showLogoInHeading}`,
			{}
		);
		yield put(getBrandingDetailsAction({ update: true }));
	} catch (e) {
		console.warn(e);
		yield put(setErrorToast(e));
	}
}

function* toggleShowLogoAsWatermark({
	payload,
}: {
	payload: {
		showLogoAsWatermark: boolean;
	};
}) {
	try {
		// @ts-ignore
		const floSpaceId = yield select((state) =>
			get(state, 'routerContainer.currentFloSpace.id')
		);

		//@ts-ignore
		const response = yield call(
			new API().put,
			`${API_BASE}/v1/flospace/${floSpaceId}/custom-watermark-hide`,
			{
				customWatermarkDisabled: !payload.showLogoAsWatermark,
			}
		);
		yield put(getBrandingDetailsAction({ update: true }));
	} catch (e) {
		console.warn(e);
		yield put(setErrorToast(e));
	}
}

function* updateBrandingPosition({
	payload,
}: {
	payload: {
		brandPosition?: string;
		floType?: string;
	};
}) {
	try {
		// @ts-ignore
		const floSpaceId = yield select((state) =>
			get(state, 'routerContainer.currentFloSpace.id')
		);
		// @ts-ignore
		const floFormats = yield select((state) =>
			get(state, 'brandingDialog.data.floFormat')
		);

		// const floTypeDetails = floFormats.find((flo: FloFormat) => flo.name === 'video');

		//@ts-ignore
		const response = yield call(
			new API().put,
			`${API_BASE}/v1/flospace/${floSpaceId}/watermark-position`,
			{
				watermarkPosition: payload.brandPosition || 'bottom-right',
			}
		);
		yield put(getBrandingDetailsAction({ update: true }));
	} catch (e) {
		console.warn(e);
		yield put(setErrorToast(e));
	}
}

function* updateBrandingLogo({
	payload,
}: {
	payload: {
		file?: BlobPart;
	};
}): SagaIterator {
	try {
		yield put(showLoader('upload_brand_image'));
		const floSpaceId = yield select((state) =>
			get(state, 'routerContainer.currentFloSpace.id')
		);

		//@ts-ignore
		const getUploadURLResponse = yield call(
			new API().put,
			`${API_BASE}/v1/flospace/${floSpaceId}/presigned-url/upload`,
			{}
		);

		const uploadResponse: unknown = yield call(
			new API(
				{
					headers: {
						'Content-Type': 'image/png',
					},
				},
				true,
				true
			).put,
			getUploadURLResponse.data,
			payload.file
		);
		const updateEtagResponse: unknown = yield call(
			new API().put,
			`${API_BASE}/v1/flospace/${floSpaceId}/watermark`,
			{
				customWatermarkDisabled: false,
				uploadId: JSON.parse(get(uploadResponse, 'headers.etag')),
			}
		);
		yield put(getBrandingDetailsAction({ update: true }));
	} catch (e) {
		console.warn(e);
		yield put(setErrorToast(e));
	} finally {
		yield put(hideLoader('upload_brand_image'));
	}
}

function* removeBrandingLogoSaga(): SagaIterator {
	try {
		yield put(showLoader('upload_brand_image'));
		const floSpaceId = yield select((state) =>
			get(state, 'routerContainer.currentFloSpace.id')
		);

		//@ts-ignore
		yield call(new API().delete, `${API_BASE}/v1/flospace/${floSpaceId}/watermark`, {});
		yield put(removeBrandingLogoSuccessAction(''));
		yield put(getBrandingDetailsAction({ update: true }));
	} catch (e) {
		console.warn(e);
		yield put(setErrorToast(e));
	} finally {
		yield put(hideLoader('upload_brand_image'));
	}
}

function* brandingDialogSagas(): Generator<ForkEffect> {
	const tasks = [
		// @ts-ignore
		// yield takeLatest(showAccountSettingsPopup.type, getBrandingDetails),
		// @ts-ignore
		yield takeLatest(getBrandingDetailsAction.type, getBrandingDetails),
		// @ts-ignore
		yield debounce(500, setBrandingColorAction.type, updateBrandingColor),
		// @ts-ignore
		yield debounce(300, togglePoweredByFloikAction.type, togglePoweredByFloik),
		// @ts-ignore
		yield debounce(300, toggleShowLogoInHeadingAction.type, toggleShowLogoInHeading),
		// @ts-ignore
		yield debounce(300, toggleShowLogoAsWatermarkAction.type, toggleShowLogoAsWatermark),
		// @ts-ignore
		yield takeLatest(setBrandingPositionAction.type, updateBrandingPosition),
		// @ts-ignore
		yield takeLatest(setBrandingLogoAction.type, updateBrandingLogo),
		// @ts-ignore
		yield takeLatest(removeBrandingLogoAction.type, removeBrandingLogoSaga),
	];
}

export default brandingDialogSagas;
