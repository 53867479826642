import Cookie from 'js-cookie';
import { get, includes, keyBy, map } from 'lodash';
import qs from 'qs';
import { SagaIterator } from 'redux-saga';
import {
	all,
	call,
	debounce,
	ForkEffect,
	put,
	select,
	takeLatest,
} from 'redux-saga/effects';
import { EXTENSION_SAVE_TOKEN } from '../../Common/Common.constants';
import { API_BASE } from '../../Common/Common.env';
import { currentProfileSelector } from '../../Common/Common.selector';
import { WorkspaceMetaData } from '../../Common/Common.types';
import { getDeviceId } from '../../Common/Common.utils';
import Translations, { translate } from '../../Common/Translate.utils';
import { showAccountSettingsPopup } from '../../Components/AccountSettings/AccountSettings.reducer';
import { getBillingDetailsAction } from '../../Components/BillingDialog/BillingDialog.reducer';
import { getBrandingDetailsAction } from '../../Components/BrandingDialog/BrandingDialog.reducer';
import {
	DialogReducerType,
	hideDialogAction,
	showDialogAction,
} from '../../Components/Dialog/Dialog.reducer';
import DialogImageWithText from '../../Components/DialogImageWithText/DialogImageWithText';
import { getUserRolesAction } from '../../Components/TeamManagementDialog/TeamManagementDialog.reducer';
import { FloDetailsType } from '../../Pages/Flo/Flo.types';
import { getPublishedFloList } from '../../Pages/ManageFlos/ManageFlos.reducers';
import { baseReadyAction } from '../../Pages/NewFloContainer/NewFloExtensionToExtension.actions';
import store from '../../store';
import { hideLoader, showLoader } from '../../store/reducers/Loader.reducer';
import { analyticsLog } from '../../store/reducers/Log.reducer';
import { pushState } from '../../store/reducers/Routes.reducer';
import API from '../../utils/API';
import { CancelSagas } from '../../utils/saga.utils';
import { SendMessageToExtension } from '../../utils/SendMessageToExtension';
import {
	acceptInvite,
	checkIsLoggedInAction,
	createAnonymousUserAction,
	getAuthAction,
	getDiamondFeatureInfo,
	getFloSpace as getFloSpaceAction,
	getProfileDetailsAction,
	getProperFloUrlAction,
	leaveRoutesAction,
	logoutAction,
	logoutAllDevices,
	onAuthSuccessAction,
	refreshTokenAction,
	setAnonymousAction,
	setAuthAction,
	setCurrentFloSpaceAction,
	setDiamondFeatureInfo,
	setEmbededProfileDetailsAction,
	setInvitationStatus,
	setProfileDetailsAction,
	setWorkspaces,
	showDiamondFeaturePopup,
	updateThemeAction,
	updateThemeSuccessAction,
} from './Routes.reducers';
import {
	AccessTokenResponse,
	DiamondFeatureType,
	FloSpaceResponse,
	getFloSpaceType,
	Profile,
} from './Routes.types';

const currentDate = new Date();
const futureDate = new Date(currentDate.getTime() + 5 * 24 * 60 * 60 * 1000);
const deviceId = getDeviceId();

const COOKIE_PARAMS = {
	expires: futureDate,
};

const featureKeysMap = {
	floik_f_i_ai: 'flo_insights',
	floik_flo_d_mp4: 'download_mp4',
	floik_f_ts: 'teamspace',
	floik_f_b_cb: 'branding',
	floik_flo_d_pdf: 'download_pdf',
	floik_f_flo_c_l: 'flo_creation_limit',
	floik_f_ts_cb: 'teamspace_collaborators',
	floik_f_e_bl: 'blur',
	floik_f_flo_ele_sp: 'spotlight',
	floik_f_flo_e_im: 'image',
	floik_f_flo_p_l: 'floPublishLimit',
	floik_flo_d_html: 'download_html',
	floik_f_flo_e_cta: 'cta_element',
	floik_flo_d_md: 'download_markdown',
	floik_f_flo_id_hs_z: 'hotspot_zoom',
	floik_f_flo_ele_aiv_rp: 'ai_voice_rephrase',
	floik_f_flo_c_m_u_l: 'manual_upload',
	floik_f_flo_s_p: 'private_share',
};

function* onAuthSuccess(response: { data: AccessTokenResponse }): SagaIterator {
	const authToken = get(response, 'data.authToken');
	const refreshToken = get(response, 'data.refreshToken');
	const userName = get(response, 'data.userName');
	const userId = get(response, 'data.userId');
	Cookie.set('floik-id', userId || '', COOKIE_PARAMS);
	Cookie.set('floik-token-v2', authToken || '', COOKIE_PARAMS);
	Cookie.set('floik-refreshToken-v2', refreshToken || '', COOKIE_PARAMS);
	Cookie.set('floik-isRegistered', 'true', COOKIE_PARAMS);

	yield put(
		onAuthSuccessAction({
			authToken,
			refreshToken,
			userName,
			userId,
		})
	);
}

function* getWorkspaces({ payload }: { payload: string }): SagaIterator {
	try {
		const query = qs.stringify({
			flospaceId: payload,
			pageNumber: 1,
			pageSize: 20,
		});
		const response: {
			data: {
				workspaceMetaData: WorkspaceMetaData[];
			};
		} = yield call(new API().get, `${API_BASE}/v1/workspace?${query}`);
		const workspacesList = get(response, 'data.workspaceMetaData');
		yield put(setWorkspaces(workspacesList));
	} catch (e) {
		yield put(
			analyticsLog({
				level: 'error',
				message: 'failed to retrieve',
				value: {
					response: get(e, 'request.responseText'),
					stack: get(e, 'stack'),
				},
			})
		);
	}
}

function* getFloSpace({ payload }: { payload: getFloSpaceType }): SagaIterator {
	try {
		const { canRedirectToDefaultFloId } = payload || {};
		const response: FloSpaceResponse = yield call(
			new API(undefined, false, false, true).get,
			`${API_BASE}/v1/flospace`
		);
		const floSpace = get(response, 'data[0]');
		if (floSpace?.id) {
			yield put(setCurrentFloSpaceAction(floSpace));
			yield put(getPublishedFloList({ floSpaceId: floSpace.id }));
			yield put(getDiamondFeatureInfo({ floSpaceId: floSpace.id }));
		}
		const defaultFloId = get(floSpace, 'defaultFloId');
		const brandingEnabled = get(floSpace, 'flospacePreference.brandingEnabled');
		if (brandingEnabled) {
			yield put(getBrandingDetailsAction(''));
		}
		yield call(getWorkspaces, { payload: floSpace?.id });
		// yield call(getBrandImage, {
		// 	payload: {
		// 		retry: 0,
		// 		url: get(
		// 			floSpace,
		// 			'flospacePreference.customWatermarkConfiguration.customWatermarkFilePath'
		// 		),
		// 		position: get(
		// 			floSpace,
		// 			'flospacePreference.customWatermarkConfiguration.customWatermarkPosition'
		// 		),
		// 		enabled: get(
		// 			floSpace,
		// 			'flospacePreference.customWatermarkConfiguration.customWatermarkEnabled'
		// 		),
		// 	},
		// });
		yield put(getBillingDetailsAction(''));
		yield put(getUserRolesAction(''));
		//
		if (
			defaultFloId &&
			(['/', '/flos', '/404', '/500'].includes(location.pathname) ||
				canRedirectToDefaultFloId)
		) {
			// yield put(pushState(`/flos/${defaultFloId}`));
			yield put(pushState(`/flos`));
		} else if (
			!defaultFloId &&
			(includes(['/', '/404', '/500'], location.pathname) || canRedirectToDefaultFloId)
		) {
			yield put(pushState('/flos'));
		}

		return floSpace;
	} catch (e) {
		console.warn(e);
	}
}

function* getAccessToken({ payload }: { payload?: string }): SagaIterator {
	try {
		let body: {
			code?: string;
			authToken?: string;
			refresh?: string;
			deviceToken?: string;
		} = {
			code: payload,
			deviceToken: deviceId,
		};

		// @ts-ignore
		const response: { data: AccessTokenResponse } = yield call(
			new API(undefined, true).post,
			`${API_BASE}/v1/authentication/access-token`,
			body
		);
		if (get(response, 'data.authToken')) {
			SendMessageToExtension({
				type: EXTENSION_SAVE_TOKEN,
				payload: {
					token: get(response, 'data.authToken'),
				},
			});
		}
		yield call(onAuthSuccess, response);

		const redirect = sessionStorage.getItem('redirect');
		if (redirect) {
			// @ts-ignore
			let path = location.pathname + location.search;
			if (redirect !== path) {
				let search = '';

				if (includes(redirect, 'billing=true')) {
					search = '?billing=true';
				}
				sessionStorage.removeItem('redirect');
				// location.href = redirect === '/' ? ('/flos' + search) : redirect;
				yield put(pushState(redirect === '/' ? '/flos' + search : redirect));
			}
		} else {
			yield put(pushState('/flos'));
		}
		yield put(setAuthAction(true));
	} catch (e) {
		console.warn(e);
		yield put(setAuthAction(false));
		// alert('failed to login');
	}
}

function* logoutDevices({ payload }: { payload?: string }): SagaIterator {
	try {
		// @ts-ignore
		yield sessionStorage.clear();
		const token = Cookie.get('floik-token-v2');
		const userId = Cookie.get('floik-id');
		Cookie.remove('floik-token-v2');
		Cookie.set('floik-anonymous', 'true', COOKIE_PARAMS);
		Cookie.remove('floik-refreshToken-v2');
		Cookie.remove('floik-theme');
		Cookie.remove('userName');
		yield put(updateThemeAction('light'));
		// @ts-ignore
		window.location = `${API_BASE}/v1/authentication/logout-all-devices?code=${payload}&device_token=${deviceId}`;
	} catch (e) {
		console.warn('error', e);
	}
}

function* createAnonymousUser(): SagaIterator {
	try {
		// @ts-ignore
		window.location = `${API_BASE}/v1/authentication/authenticate?redirect_uri=/auth&redirect_host=${location.origin}&device_token=${deviceId}`;
	} catch (e) {
		console.warn(e);
		yield put(setAuthAction(false));
		alert('failed to login');
	}
}

function* checkIsLoggedIn({ type }: { type: string }): SagaIterator {
	try {
		// @ts-ignore
		const refreshToken = Cookie.get('floik-refreshToken-v2');
		const authToken = Cookie.get('floik-token-v2');
		const anonymous = Cookie.get('floik-anonymous');
		const theme = Cookie.get('floik-theme') || 'light';
		const isRegistered = Cookie.get('floik-isRegistered') === 'true';
		const isAuthPage = /\/auth/.test(location.pathname);

		// @ts-ignore
		const { continue_as_guest }: { continue_as_guest: string } = qs.parse(
			(location.search || '').replace('?', '')
		);

		if (isAuthPage) {
			return;
		}
		if (continue_as_guest) {
			Cookie.remove('floik-isRegistered');
			if (!authToken || isRegistered) yield call(createAnonymousUser);
		} else if (!continue_as_guest && isRegistered && anonymous === 'true') {
			// @ts-ignore
			window.location = `${API_BASE}/v1/authentication/authenticate?redirect_uri=/auth&redirect_host=${location.origin}&device_token=${deviceId}`;
		} else if (!refreshToken) {
			// yield put(setAuthAction(false));
			yield call(createAnonymousUser);
			return;
		}
		yield put(updateThemeAction(theme));
		yield put(setAuthAction(true));
		yield put(setAnonymousAction(anonymous === 'true'));
	} catch (e) {
		console.warn(e);
	}
}

function* login() {}

function* logout() {
	try {
		yield sessionStorage.clear();
		const token = Cookie.get('floik-token-v2');
		const userId = Cookie.get('floik-id');
		Cookie.remove('floik-token-v2');
		Cookie.set('floik-anonymous', 'true', COOKIE_PARAMS);
		Cookie.remove('floik-refreshToken-v2');
		Cookie.remove('floik-theme');
		Cookie.remove('userName');
		yield put(updateThemeAction('light'));
		// @ts-ignore
		window.location = `${API_BASE}/v1/authentication/logout?floik_token=${token}&redirect_host=${location.origin}&userId=${userId}&device_token=${deviceId}`;
	} catch (e) {
		console.warn('error', e);
	}
}

// export function* getBrandImage({
// 	payload,
// }: {
// 	payload: {
// 		retry: number;
// 		position: string;
// 		url: string;
// 		enabled: boolean;
// 	};
// }): SagaIterator {
// 	try {
// 		if (!payload.enabled || !payload.url) {
// 			return;
// 		}
// 		// @ts-ignore
// 		const imageUrl: string = yield new Promise((resolve, reject) => {
// 			const image = new Image();
// 			image.src = payload.url;
// 			image.onload = () => {
// 				resolve(payload.url);
// 			};
// 			image.onerror = () => {
// 				reject();
// 			};
// 		});
// 		yield put(
// 			setBrandImage({
// 				url: imageUrl,
// 				position: payload.position || 'bottom-right',
// 			})
// 		);
// 	} catch (e) {
// 		console.warn(e);
// 		if (get(payload, 'retry') < 3) {
// 			yield call(getBrandImage, {
// 				payload: {
// 					...payload,
// 					retry: get(payload, 'retry', 0) + 1,
// 				},
// 			});
// 		}
// 	}
// }

export function* getGlobalConfigs({ profile }: { profile: Profile }) {
	try {
		const response: {
			data: {
				extensionUninstallPath: string;
			};
		} = yield call(new API().get, `${API_BASE}/v1/global/configuration/client`);
		let uninstallPath = get(response, 'data.extensionUninstallPath');
		if (uninstallPath) {
			const refreshToken = Cookie.get('floik-refreshToken-v2');
			const authToken = Cookie.get('floik-token-v2');
			uninstallPath = `${uninstallPath}?name=${get(
				response.data,
				'name',
				'-'
			)}&userid=${get(response, 'data.userUUID', '-')}&t=${authToken}&rt=${refreshToken}`;
			Cookie.set('floik-uninstall-path', uninstallPath, COOKIE_PARAMS);
		}
		SendMessageToExtension({
			type: 'set_profile_data',
			payload: {
				...profile,
				uninstallPath,
			},
		});
	} catch (e) {
		console.warn(e);
	}
}

export function* getEncodedEmail({ payload }: { payload: Profile }) {
	try {
		const response = yield call(
			new API().get,
			`${API_BASE}/v1/global/user/external-integration`
		);
		yield put(setEmbededProfileDetailsAction(response.data));
	} catch (e) {
		console.warn(e);
	}
}

export function* getProfile({ payload }: { payload?: { update: true } }): SagaIterator {
	try {
		if (!payload?.update) yield put(showLoader(''));
		// @ts-ignore
		const response: { data: Profile } = yield call(
			new API(undefined, false, false, true).get,
			`${API_BASE}/v1/global/user-profile`
		);
		// @ts-ignore
		yield put(setProfileDetailsAction(response.data as Profile));
		Cookie.set('floik-anonymous', String(response.data.anonymous), COOKIE_PARAMS);
		if (!response.data.anonymous) {
			Cookie.set('floik-isRegistered', 'true', COOKIE_PARAMS);
		}
		Cookie.set('userName', get(response.data, 'name'), COOKIE_PARAMS);
		yield all([
			call(getGlobalConfigs, { profile: response.data }),
			call(getEncodedEmail, { profile: response.data }),
		]);
	} catch (e) {
		console.warn(e);
	} finally {
		if (!payload?.update) yield put(hideLoader(''));
	}
}

export function* getProperFloUrlSaga({ payload }: { payload: string }): SagaIterator {
	try {
		// @ts-ignore
		const response: { data: FloDetailsType } = yield call(
			new API(undefined, false, false, true).get,
			payload.replace(location.origin, API_BASE)
		);
		// @ts-ignore
		yield put(pushState(`/flos/${response.data?.id}`));
	} catch (e) {
		console.warn(e);
	}
}

export function* getDiamondFeatureInfoSaga({
	payload,
}: {
	payload: { floSpaceId: string };
}): SagaIterator {
	try {
		// @ts-ignore
		const response: { data: Profile } = yield call(
			new API(undefined, false, false, true).get,
			`${API_BASE}/v1/flospace/billing/${payload.floSpaceId}/diamond-feature`
		);
		// @ts-ignore
		const featuresList = map(
			get(response, 'data', []),
			(featureItem: DiamondFeatureType) => ({
				...featureItem,
				// diamondEnabled: get(featureItem, 'featureKey') === 'floik_f_flo_ele_sp',
				uiFeatureKey: get(
					featureKeysMap,
					get(featureItem, 'featureKey'),
					get(featureItem, 'featureKey')
				),
			})
		);
		const transformedMap = keyBy(featuresList, 'uiFeatureKey');
		yield put(setDiamondFeatureInfo(transformedMap));
	} catch (e) {
		console.warn(e);
		yield put(
			analyticsLog({
				level: 'error',
				message: `Get diamond feature info failed, flospaceid: ${payload.floSpaceId}`,
				value: get(e, 'stack'),
			})
		);
	}
}

export function* acceptInviteSaga({ payload }: { payload: string }): SagaIterator {
	try {
		yield put(showLoader('invitePage'));
		// @ts-ignore
		const response: { data: FloDetailsType } = yield call(
			new API(undefined, false, false, true).get,
			payload.replace(location.origin, API_BASE)
		);
		const message = (Translations.INVITE_SUCCESS_PAGE_JOINED_SUCCESSFUL || '')
			.replace('%USER_NAME%', get(response, 'data.createdBy.name', ''))
			.replace('%FLO_SPACE%', get(response, 'data.name', ''));
		if (!get(response, 'data.ignoreInviteSuccess', false)) {
			sessionStorage.setItem('invite_joined', message);
		}

		location.href = '/flos';
		// yield put(setInvitationStatus({
		// 	message,
		// 	status: 'success'
		// }));
	} catch (e) {
		console.error('error', e);
		const message = get(e, 'response.data.data') || get(e, 'message');
		yield put(
			setInvitationStatus({
				message: message || Translations.INVITE_FAILED_PAGE_DEFAULT_MESSAGE || '',
				status: 'error',
			})
		);
		yield put(
			analyticsLog({
				level: 'error',
				message: `Invite link failed, id: ${payload}`,
				value: get(e, 'stack'),
			})
		);
	} finally {
		yield put(hideLoader('invitePage'));
	}
}

export function* refreshTokenSaga() {
	try {
		const body = {
			authToken: Cookie.get('floik-token-v2'),
			refreshToken: Cookie.get('floik-refreshToken-v2'),
		};
		const response: { data: AccessTokenResponse } = yield call(
			new API(undefined, true).post,
			`${API_BASE}/v1/authentication/refresh-token`,
			body
		);
		yield call(onAuthSuccess, response);
		location.reload();
	} catch (e) {
		console.warn('error');
	}
}

export function* showDiamondFeatureSaga({
	payload,
}: {
	payload: {
		featureInfo: DiamondFeatureType;
		dialogParams?: Partial<DialogReducerType>;
		buttonClicked?: string;
	};
}) {
	try {
		const diamondTitle = get(payload, 'featureInfo.diamondTitle');
		const errorMessageHeader = get(payload, 'featureInfo.errorMessageHeader');
		const errorMessageDescription = get(payload, 'featureInfo.errorMessageDescription');
		const image = get(payload, 'featureInfo.illustrationImage');
		const learnMoreButtonLink = get(payload, 'featureInfo.learnMoreButtonLink');
		const translatedLearnMoreBtnLink = translate(
			learnMoreButtonLink,
			learnMoreButtonLink
		);
		if (get(payload, 'buttonClicked')) {
			//@ts-ignore
			const profile = yield select(currentProfileSelector);
			yield put(
				analyticsLog({
					eventType: 'floik_log_diamond_feature_click',
					message: 'Diamond feature clicked',
					value: {
						userId: profile.userId,
						email: profile.email,
						button_click: get(payload, 'buttonClicked'),
					},
				})
			);
		}
		yield put(
			showDialogAction({
				header: '',
				body: (
					<DialogImageWithText
						diamondImage={get(payload, 'featureInfo.diamondImage')}
						diamondTitle={translate(diamondTitle, diamondTitle)}
						header={translate(errorMessageHeader, errorMessageHeader)}
						message={translate(errorMessageDescription, errorMessageDescription)}
						imagePath={image}
					/>
				),
				classes: {
					contentWrapper: 'diamondDialogContentWrapper',
					content: 'diamondDialogContent',
				},
				showConfirm: true,
				showCancel: true,
				cancelButtonText:
					learnMoreButtonLink == ''
						? Translations.SUBSCRIPTION_DIAMOND_FEATURE_CANCEL_BUTTON_TEXT
						: 'Learn more',
				confirmButtonType: 'confirm',
				confirmButtonText: Translations.SUBSCRIPTION_DIAMOND_FEATURE_CONFIRM_BUTTON_TEXT,
				blurBackdrop: true,
				actionsFullWidth: !image,
				onConfirm: () => {
					store.dispatch(
						showAccountSettingsPopup({
							visible: true,
							defaultActiveTab: 4,
							onClose: () => {
								window.location.reload();
							},
						})
					);
				},
				variant: image ? 'large' : '',
				onCancel: (eventType: string) => {
					if (learnMoreButtonLink && eventType === 'cancelButton') {
						window.open(translatedLearnMoreBtnLink, '_blank');
					}
					store.dispatch(hideDialogAction(''));
				},
				...(payload.dialogParams || {}),
			})
		);
	} catch (e) {
		console.warn('error');
	}
}

export function* updateThemeSaga({ payload }: { payload: string }) {
	const theme = payload || 'light';
	document.body.setAttribute('theme', theme);
	Cookie.set('floik-theme', theme, COOKIE_PARAMS);
	yield put(updateThemeSuccessAction(theme));
}

export function* userRootSagas(): Generator<ForkEffect> {
	const tasks = [
		// @ts-ignore
		yield takeLatest(getFloSpaceAction.type, getFloSpace),
		// @ts-ignore
		yield takeLatest(getAuthAction.type, getAccessToken),
		// @ts-ignore
		yield takeLatest(logoutAllDevices.type, logoutDevices),
		// @ts-ignore
		yield takeLatest(getProfileDetailsAction.type, getProfile),
		yield takeLatest(checkIsLoggedInAction.type, checkIsLoggedIn),
		yield takeLatest(createAnonymousUserAction.type, createAnonymousUser),
		yield takeLatest(logoutAction.type, logout),
		// @ts-ignore
		yield takeLatest(updateThemeAction.type, updateThemeSaga),
		// @ts-ignore
		yield takeLatest(acceptInvite.type, acceptInviteSaga),
		// @ts-ignore
		yield takeLatest(getProperFloUrlAction.type, getProperFloUrlSaga),
		// @ts-ignore
		yield takeLatest(getDiamondFeatureInfo.type, getDiamondFeatureInfoSaga),
		// @ts-ignore
		yield takeLatest(showDiamondFeaturePopup.type, showDiamondFeatureSaga),
		// @ts-ignore
		yield debounce(1000, refreshTokenAction.type, refreshTokenSaga),
	];
	// @ts-ignore
	yield takeLatest(leaveRoutesAction.type, CancelSagas, tasks);
}

export function runRoutesSagas() {
	// @ts-ignore
	store.runSaga(userRootSagas);
	SendMessageToExtension(baseReadyAction({}));
}
