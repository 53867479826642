import { useSelector } from 'react-redux';
import proFeatureSvg from '../../Common/images/ProFeature.svg';
import { getTheme } from '../../Containers/Routes/Routes.selector';
import styles from './DialogImageWithText.module.css';

const DialogIcon = ({
	header,
	message,
	imagePath,
	diamondImage,
	diamondTitle,
}: {
	header: string;
	message: string;
	imagePath?: string;
	diamondImage?: string;
	diamondTitle?: string;
}) => {
	const theme = useSelector(getTheme);

	return (
		<div className={styles.dialogIcon}>
			<div className={styles.proFeatureIconWrapper}>
				<div className={styles.proFeatureIcon}>
					<img
						alt=""
						src={diamondImage || proFeatureSvg}
						className={styles.proFeatureIconImg}
					/>
				</div>
				<div className={styles.proFeatureText}>{diamondTitle || 'Pro Feature'}</div>
			</div>
			{imagePath && <img alt="" className={styles.image} src={imagePath}></img>}
			<div className={styles.contentTitle}>{header}</div>
			<div className={styles.contentText}>{message}</div>
		</div>
	);
};

export default DialogIcon;
