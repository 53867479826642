import { Box, Grid, LinearProgress } from '@mui/material';
import { IconPointFilled } from '@tabler/icons-react';
import classNames from 'classnames';
import { FormatTimeForUploadFile } from 'Common/FormatDate';
import Translations, { translate } from 'Common/Translate.utils';
import Button from 'Components/Button/Button';
import {
	debounce,
	filter,
	find,
	first,
	get,
	includes,
	map,
	max,
	reduce,
	toNumber,
} from 'lodash';
import React, {
	CSSProperties,
	ForwardedRef,
	MouseEventHandler,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatSpeed } from 'utils/network';
import { FloCreationTemplates } from '../../../../Common/Common.types';
import isFeatureEnabled from '../../../../Common/DiamondFeature.hook';
import DiamondIcon from '../../../../Components/DiamondIcon/DiamondIcon';
import Loader from '../../../../Components/Loader/Loader';
import NoDataMessage from '../../../../Components/NoDataMessage/NoDataMessage';
import { showDiamondFeaturePopup } from '../../../../Containers/Routes/Routes.reducers';
import TemplateCard from '../../../Home/Components/RecentFloCard/TemplateCard/TemplateCard';
import { newFloGetTemplates, saveFloAction, setSelectedTemplate, setUploadError, uploadFileForCustomFlo } from '../../NewFlo.reducers';
import { TemplateCategories } from '../../NewFloContainer.constants';
import prentStyles from '../../NewFloContainer.module.css';
import {
	AvailableDevices,
	RecorderOptions,
	RecordStates,
	TemplateCategoryType,
} from '../../NewFloContainer.types';
import Zoom from '../../ZoomTemp';
import { ReactComponent as UploadCloud } from './cloud-upload.svg';
import TemplateDetails from './TemplateDetails/TemplateDetails';
import styles from './Templates.module.css';
import { showDialogAction } from 'Components/Dialog/Dialog.reducer';

const FileUploadReady = ({
	onDrop,
	onDragOver,
	handleUploadClick,
	disable,
}: {
	onDrop(e: React.DragEvent): void;
	onDragOver(e: React.DragEvent): void;
	handleUploadClick(e: React.MouseEvent): void;
	disable?: boolean;
}) => {
	const videoFileName = useSelector((state) => get(state, 'newFlo.viedoFileName'));
	const error = useSelector((state) => get(state, 'newFlo.videoFileUploadError'));
	const wrapperRef = useRef<HTMLDivElement>(null);

	const onDragEnter = useCallback(() => {
		wrapperRef.current?.classList.add(styles.hovering);
	}, [wrapperRef]);
	const onDragLeave = useCallback(() => {
		wrapperRef.current?.classList.remove(styles.hovering);
	}, [wrapperRef]);

	if (videoFileName) {
		return <></>;
	}

	return (
		<div
			className={classNames(styles.fileUploadWrapper, {
				[styles.uploadDisabled]: disable,
			})}
			ref={wrapperRef}
			onDrop={onDrop}
			onDragOverCapture={onDragEnter}
			onDragLeaveCapture={onDragLeave}
			onDragExitCapture={onDragLeave}
			onDragOver={onDragOver}
		>
			<div className={styles.fileUploadimageWrapper}>
				<UploadCloud />
			</div>
			<div className={styles.fileUploaddragDropTextContainer}>
				{translate('FILE_UPLOAD_DRAG_AND_DROP_DESCRIPTION')}
			</div>
			<div className={styles.fileUploadsupportedFormats}>
				{translate('FILE_UPLOAD_FILES_TYPE')}
			</div>
			<div className={styles.fileUploador}>OR</div>
			<div className={styles.onHovering}>
				{translate('FILE_UPLOAD_DROP_FILE_MESSAGE')}
			</div>
			<Button
				autoFocus
				disableFocusRipple
				variant="contained"
				onClick={handleUploadClick}
				className={classNames(
					styles.uploadButton
					// {
					// 	[styles.disabled]: isUploadInProgress,
					// }
				)}
			>
				{translate('FILE_UPLOAD_BROWSE_FILES')}
			</Button>
			{error && <span className={styles.error}>{error}</span>}
		</div>
	);
};

const FileUploading = ({ onCancel }: { onCancel(): void }) => {
	const videoDuration = useSelector((state) => get(state, 'newFlo.videoFileDuration'));
	const videoFileMeta = useSelector((state) => get(state, 'newFlo.videoFileMeta'));
	const viedoFileSize = useSelector((state) => get(state, 'newFlo.viedoFileSize'));
	const videoFileName = useSelector((state) => get(state, 'newFlo.viedoFileName'));
	const disableCancelButton = useSelector((state) => get(state, 'newFlo.customFlo') && !get(state, 'newFlo.floId'));

	const chunkStats = useSelector(
		(state) =>
			get(state, 'newFlo.chunkStats') || {
				screenShareTotalSize: 0,
				screenShareUploadedSize: 0,
				videoTotalSize: 0,
				videoUploadedSize: 0,
			}
	);

	const networkStats = useSelector((state) => {
		const data = reduce(
			get(state, 'newFlo.uploadSpeedData'),
			(acc, data) => {
				return {
					uploaded: acc.uploaded + (data[0] || 0),
					totalSize: acc.totalSize + (data[1] || 0),
				};
			},
			{
				uploaded: 0,
				totalSize: 0,
			}
		);
		return {
			uploadSpeed: formatSpeed(get(state, 'newFlo.uploadSpeed'), 'ps'),
			uploaded: formatSpeed(data.uploaded),
			totalSize: formatSpeed(data.totalSize),
			uploadedNum: data.uploaded,
			totalSizeNum: data.totalSize,
		};
	});

	const buffer = 10;
	const uploadedSize = networkStats.uploadedNum;
	const totalSize =
		(chunkStats.videoTotalSize || 0) + (chunkStats.screenShareTotalSize || 0);
	const percentageUploaded = Math.round((uploadedSize / (totalSize || 1)) * 100);

	if (!videoFileName) return <></>;

	return (
		<div className={styles.fileUploadWrapper}>
			<div className={styles.fileUploadingHeader}>
				{translate('FILE_UPLOAD_UPLOADING_MESSAGE')}
			</div>
			<div className={styles.fileUploadingDescription}>
				{translate('FILE_UPLOAD_TAKES_FEW_SECOND_MESSAGE')}
			</div>
			<div className={styles.fileName}>{videoFileName || 'untitled'}</div>
			<div className={styles.fileUploadingTags}>
				<span className={styles.fileDetailsTag}>
					{get(videoFileMeta, 'width', 0)}x{get(videoFileMeta, 'height', 0)}
				</span>
				<span className={styles.fileDetailsTag}>
					{FormatTimeForUploadFile(videoDuration)}
				</span>
			</div>
			<div className={styles.fileUploadingProgress}>
				<LinearProgress
					classes={{
						dashed: styles.dashed,
						barColorPrimary: styles.progressBar,
						colorPrimary: styles.progressBarColorPrimary,
						root: styles.progressBarRoot,
					}}
					variant="buffer"
					value={percentageUploaded}
					valueBuffer={buffer}
				/>
			</div>
			<div className={styles.uploadingData}>
				{get(networkStats, 'uploaded')}/{get(networkStats, 'totalSize')}{' '}
				{translate('FILE_UPLOAD_UPLOADED_MESSAGE')}
				<IconPointFilled size={8} className={styles.dotIcon} />
				{translate('FILE_UPLOAD_UPLOAD_SPEED_MESSAGE')}:{' '}
				{get(networkStats, 'uploadSpeed')}
			</div>
			<div className="button">
				<Button
					disabled={disableCancelButton}
					variant="outlined" 
					className={styles.cancelButton} 
					onClick={onCancel}>
					Cancel
				</Button>
			</div>
		</div>
	);
};

const FileUploader = ({ disable }: { disable?: boolean }) => {
	const dispatch = useDispatch();
	const imgUploadInputRef = useRef<HTMLInputElement>(null);

	const onCancel = useCallback(() => {
		dispatch(showDialogAction({
			header: Translations.FILE_UPLOAD_CANCEL_POPUP_HEADING,
			body: Translations.FILE_UPLOAD_CANCEL_POPUP_DESCRIPTION,
			confirmButtonText:
				Translations.FILE_UPLOAD_CANCEL_POPUP_CONFIRM_TEXT,
			cancelButtonText:
				Translations.FILE_UPLOAD_CANCEL_POPUP_CANCEL_TEXT,
			confirmButtonType: 'delete',
			showConfirm: true,
			showCancel: true,
			onConfirm: () => {
				dispatch(
					saveFloAction({
						name: '',
						type: 'discard',
					})
				);
			},
		}));

	}, []);

	const onDragOver = useCallback(
		(e: React.DragEvent<HTMLDivElement>) => {
			e.preventDefault();
			if (disable) return;
			dispatch(setUploadError(''));
		},
		[disable]
	);
	const onDrop = useCallback(
		(e: React.DragEvent<HTMLDivElement>) => {
			e.preventDefault();
			if (disable) return;
			dispatch(setUploadError(''));
			if (get(e, 'dataTransfer.items')) {
				// Use DataTransferItemList interface to access the file(s)
				const item = first([...get(e, 'dataTransfer.items')]);
				if (item.kind === 'file') {
					const file = item.getAsFile();
					dispatch(
						uploadFileForCustomFlo({
							file,
							onCancel,
						})
					);
				}
			} else {
				// Use DataTransfer interface to access the file(s)
				const file = first([...get(e, 'dataTransfer.files')]);
				dispatch(
					uploadFileForCustomFlo({
						file,
						onCancel,
					})
				);
			}
		},
		[disable, onCancel]
	);
	const onInputFile = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			dispatch(setUploadError(''));
			dispatch(
				uploadFileForCustomFlo({
					file: get(e.target.files, 0),
					onCancel,
				})
			);
		},
		[onCancel]
	);

	const handleUploadClick = useCallback(() => {
		imgUploadInputRef.current?.click();
	}, [imgUploadInputRef]);

	useEffect(() => {
		dispatch(
			newFloGetTemplates({
				filter: 'manual_upload',
			})
		);
	}, []);

	return (
		<div className={styles.fileUploadContainer}>
			<FileUploadReady
				onDrop={onDrop}
				onDragOver={onDragOver}
				handleUploadClick={handleUploadClick}
				disable={disable}
			/>
			<FileUploading onCancel={onCancel} />
			<input
				ref={imgUploadInputRef}
				className={styles.uploadInputFile} 
				type="file" 
				accept="video/mp4,video/mov,video/quicktime"
				onChange={onInputFile}
				disabled={disable}
			/>
		</div>
	);
};

const EmptyFilterContainer = ({
	loading,
	isVideoUploadPage,
	disableManualUpload,
}: {
	loading?: boolean;
	isVideoUploadPage?: boolean;
	disableManualUpload?: boolean;
}) => {
	if (isVideoUploadPage) {
		return <FileUploader disable={disableManualUpload} />;
	}
	return (
		<Grid
			container
			className={classNames(styles.noData, {
				[styles.hide]: loading,
			})}
		>
			<NoDataMessage
				classes={{
					wrapper: styles,
				}}
				message={'No Data Found'}
			/>
		</Grid>
	);
};

const TemplateFilterItem = ({ 
	onFilterClick, 
	item, 
	templateFilter, 
	disableManualUpload,
	manualUploadFeatureObject,
 }: { 
	onFilterClick(item: TemplateCategoryType): void;
	item: TemplateCategoryType;
	templateFilter: string;
	disableManualUpload?: boolean;
	manualUploadFeatureObject: {
		diamondImage: string;
	}
 }) => {
	const onClick = useCallback(() => {
		onFilterClick(item);
	}, [onFilterClick, item]);

	return (
		<div
			key={item.key}
			className={classNames(styles.listItem, {
				[styles.listItemActive]: templateFilter === item.key,
				[styles.listItemNonClickable]: item.nonClickable,
			})}
			onClick={onClick}
		>
			{item.label}
			{item.key === 'manual_upload' && disableManualUpload && (
				<DiamondIcon
					className={styles.diamondIcon}
					image={get(manualUploadFeatureObject, 'diamondImage', '')}
				/>
			)}
		</div>
	)
}

const Templates = React.forwardRef(
	(
		{
			recordState,
			availableDevices,
			audioDeviceChange,
			videoDeviceChange,
			videoDevice,
			audioDevice,
			onStartHandler,
			getDevices,
			devicePermissions,
			onBack,
			iframeDims,
			networkSpeed,
			onClose,
			setAudioDevice,
		}: {
			iframeDims: CSSProperties;
			recordState: RecordStates;
			availableDevices: AvailableDevices;
			videoDeviceChange(deviceId?: string): void;
			audioDeviceChange(deviceId?: string): void;
			setAudioDevice(deviceId?: string): void;
			audioDevice?: string;
			networkSpeed: string;
			videoDevice?: string;
			devicePermissions: {
				[key: string]: string;
			};
			onBack(): void;
			onStartHandler(newSelectedTarget?: RecorderOptions): void;
			getDevices(newSelectedTarget: RecorderOptions): void;
			onClose: MouseEventHandler;
		},
		videoRef: ForwardedRef<HTMLVideoElement>
	) => {
		const templates: FloCreationTemplates[] = useSelector((state) =>
			get(state, 'newFlo.templates', [])
		);
		const videoFileID = useSelector((state) => get(state, 'newFlo.videoFileID'));
		const templateCategories: FloCreationTemplates[] = useSelector((state) =>
			get(state, 'newFlo.templateCategories', [])
		);
		const templateFilter: string = useSelector((state) =>
			get(state, 'newFlo.templateFilter')
		);
		const loading: boolean = useSelector(
			(state) => get(state, 'loaders.get_template_saga', 0) > 0
		);
		const activeTemplateFilter = useSelector((state) => {
			const template = TemplateCategories.find((i) => i.key === templateFilter);
			if (template) return template;
			return find(
				get(state, 'newFlo.templateCategories'),
				(i) => i.key === templateFilter
			);
		});

		const selectedTemplate: FloCreationTemplates | undefined = useSelector((state) =>
			get(state, 'newFlo.template')
		);
		const useTemplate: boolean = useSelector((state) =>
			get(state, 'newFlo.showTemplateDeviceSelection')
		);
		const usageDetails =
			useSelector((state) => get(state, 'manageFlos.usageDetails')) || {};
		const uploadFloUsage = get(
			usageDetails,
			'maximumNumberOfFlosCreatablePerFlospaceWithManualVideoUpload.usage',
			{}
		);
		const uploadLeft = max([
			get(uploadFloUsage, 'defaultValue', 0) - get(uploadFloUsage, 'usedValue', 0),
			0,
		]);
		const showUploadLeft =
			(get(uploadFloUsage, 'defaultValue', 0) || 0) <= toNumber(Translations.MAX_MANUAL_UPLOAD_LIMIT);
		const { featureObject: manualUploadFeatureObject } =
			isFeatureEnabled('manual_upload');
		const disableManualUpload = showUploadLeft && !uploadLeft;

		const [searchKey, setSearchKey] = useState('');
		const onStart = useCallback(() => {
			const hasScreen = includes(selectedTemplate?.inputPermissions, 'screenshare');
			const hasCamera = includes(selectedTemplate?.inputPermissions, 'camera');
			const hasMicrophone = includes(selectedTemplate?.inputPermissions, 'microphone');
			let target;
			if (hasScreen) {
				target = 'screen';
			}
			if (hasCamera) {
				target += 'camera';
			}
			if (hasMicrophone) {
				target += 'microphone';
			}
			if (target) {
				// @ts-ignore
				onStartHandler(target);
			}
		}, [onStartHandler]);
		const dispatch = useDispatch();
		const debounceOnChange = useCallback(
			debounce((event: React.ChangeEvent) => {
				setSearchKey(get(event, 'target.value', ''));
			}, 300),
			[setSearchKey]
		);
		const onClickCallback = useCallback(
			(template: FloCreationTemplates, showDetails?: boolean) => {
				dispatch(
					setSelectedTemplate({
						template,
						showDetails,
					})
				);
				const hasScreen = includes(template?.inputPermissions, 'screenshare');
				const hasCamera = includes(template?.inputPermissions, 'camera');
				const hasMicrophone = includes(template?.inputPermissions, 'microphone');
				let target = '';
				if (hasScreen) {
					target = 'screen';
				}
				if (hasCamera) {
					target += 'camera';
				}
				if (hasMicrophone) {
					target += 'microphone';
				}
				if (target && showDetails) {
					// @ts-ignore
					getDevices(target);
				}
			},
			[getDevices]
		);
		const onFilterClick = useCallback(
			(type: TemplateCategoryType) => {
				dispatch(setUploadError(''));
				if (get(type, 'key') === 'manual_upload' && disableManualUpload) {
					dispatch(
						showDiamondFeaturePopup({
							featureInfo: manualUploadFeatureObject,
							buttonClicked: get(
								manualUploadFeatureObject,
								'uiFeatureKey',
								''
							).toUpperCase(),
						})
					);
				}
				if (!type.nonClickable) {
					dispatch(
						newFloGetTemplates({
							filter: type.key,
							id: type.id,
						})
					);
				}
			},
			[disableManualUpload, manualUploadFeatureObject]
		);
		useEffect(() => {
			if (selectedTemplate?.isHomePage) {
				onClickCallback(
					{
						...selectedTemplate,
						isHomePage: false,
					},
					useTemplate
				);
			}
		}, [useTemplate, selectedTemplate, onClickCallback]);
		if (get(selectedTemplate, 'id')) {
			const isMicOptional = includes(
				selectedTemplate?.optionalInputPermissions,
				'microphone'
			);
			const micPermissionRequired = includes(
				selectedTemplate?.inputPermissions,
				'microphone'
			);
			const CameraPermissionRequired = includes(
				selectedTemplate?.inputPermissions,
				'camera'
			);
			let cameraEnabled = false;
			let micEnabled = false;
			if (micPermissionRequired && !isMicOptional) {
				micEnabled = !!audioDevice;
			} else {
				micEnabled = true;
			}
			if (CameraPermissionRequired) {
				cameraEnabled = !!videoDevice;
			} else {
				cameraEnabled = true;
			}
			let enableStartButton = micEnabled && cameraEnabled;

			return (
				<TemplateDetails
					ref={videoRef}
					networkSpeed={networkSpeed}
					iframeDims={iframeDims}
					setAudioDevice={setAudioDevice}
					template={selectedTemplate as FloCreationTemplates}
					recordState={recordState}
					enableStartButton={enableStartButton}
					showVideoOptions={CameraPermissionRequired}
					showAudioOptions={micPermissionRequired}
					availableDevices={availableDevices}
					devicePermissions={devicePermissions}
					videoDevice={videoDevice}
					onClickCallback={onClickCallback}
					onStartHandler={onStart}
					audioDevice={audioDevice}
					audioDeviceChange={audioDeviceChange}
					videoDeviceChange={videoDeviceChange}
					onClose={onClose}
				/>
			);
		}

		const SearchRegEx = RegExp(searchKey, 'ig');
		const filterTemplates = filter(templates, (t: FloCreationTemplates) =>
			searchKey ? SearchRegEx.test(get(t, 'name', '')) : true
		);

		return (
			<Zoom
				in={
					activeTemplateFilter?.key === 'manual_upload' ||
					!includes(
						[
							'recording',
							'creating',
							'saving',
							'onStopSaving',
							'onDiscardSaving',
							'stopped/save',
						],
						recordState
					)
				}
			>
				<Grid
					container
					className={classNames(prentStyles.cardRecordingBody, styles.cardBody)}
					justifyContent="center"
					alignItems="flex-start"
					flexWrap={'nowrap'}
				>
					<Grid
						item
						classes={{
							root: styles.leftSection,
						}}
						desktop={3}
						tablet={3}
						mobile={12}
					>
						<Grid
							container
							style={{ flexDirection: 'column', width: '220px', height: '100%' }}
						>
							{/*<h3 className={classNames('title3', styles.leftPaneTitle)}>Templates</h3>*/}
							<Box className={styles.listContainer}>
								{map(TemplateCategories, (item: TemplateCategoryType) => {
									return <TemplateFilterItem 
										key={item.key}
										onFilterClick={onFilterClick}
										templateFilter={templateFilter}
										item={item}
										disableManualUpload={disableManualUpload}
										manualUploadFeatureObject={manualUploadFeatureObject}
									/>
								})}
							</Box>
						</Grid>
					</Grid>
					<Grid item className={styles.rightSection} desktop={9} tablet={9} mobile={12}>
						<div className={styles.rightPaneTitleWrapper}>
							<h3 className={classNames('title3', styles.rightPaneTitle)}>
								{activeTemplateFilter?.label}
							</h3>
							{templateFilter === 'manual_upload' && showUploadLeft && (
								<div className={styles.uploadCountInfo}>
									{uploadLeft} upload{(uploadLeft || 0) > 1 ? 's' : ''} left
								</div>
							)}{' '}
						</div>
						{loading && (
							<Loader
								width={'50px'}
								height={'50px'}
								wrapperClass={styles.loaderWrapperClass}
							/>
						)}
						{templateFilter !== 'manual_upload' &&
						filterTemplates.length > 0 ? (
							<Grid
								container
								className={classNames(styles.templatesContainer)}
								gap={1}
								justifyContent="flex-start"
							>
								{map(filterTemplates, (template) => (
									<Grid item justifyContent="flex-start" key={template.id}>
										<TemplateCard
											classes={{
												templateItem: styles.templateItem,
												templateTitle: styles.templateTitle,
												templateTitleText: classNames(styles.templateTitleText),
												templateImage: styles.templateImage,
												templateDescription: styles.templateDescription,
											}}
											onClick={onClickCallback}
											template={template}
										/>
									</Grid>
								))}
							</Grid>
						) : (
							<EmptyFilterContainer
								loading={loading}
								isVideoUploadPage={templateFilter === 'manual_upload'}
								disableManualUpload={disableManualUpload}
							/>
						)}
					</Grid>
				</Grid>
			</Zoom>
		);
	}
);

export default Templates;
