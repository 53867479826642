import { ReactElement } from 'react';

const Zoom = ({
	children,
	appear,
	in: _in,
}: {
	children: ReactElement;
	appear?: boolean;
	in?: boolean;
}) => {
	if (!_in) return <></>;
	return children;
};

export default Zoom;
