import { Placement } from '@popperjs/core/lib/enums';
import { createSlice } from '@reduxjs/toolkit';
import { padStart } from 'lodash';

const initialState = Object.freeze({
	extensionInstalled: undefined,
	extensionVersion: 0,
	popup: {
		visible: false,
		message: 'Click the button to create a new flo',
		image: '/images/annotation-intro.png',
		id: 'create-flo',
		placement: 'bottom-start',
		anchorEl: undefined,
	},
	flags: {
		'inbox-drawer': false,
		'create-flo': false,
		'message-resolved': false,
		'annotation-tool': false,
		'share-flo': false,
		'flo-menu': false,
	},
});

type stateType = {
	extensionInstalled: boolean | undefined;
	extensionErrored: boolean | undefined;
	extensionVersion: number;
	popup: {
		visible: boolean;
		message: string;
		image?: string;
		id: string;
		placement?: Placement;
		anchorEl?: HTMLElement;
	};
	flags: {
		[key: string]: boolean;
	};
};

const OnboardingReducer = {
	showOnboarding(
		state: stateType,
		{
			payload,
		}: {
			payload: {
				message: string;
				image?: string;
				id: string;
				placement?: Placement;
				anchorEl: HTMLElement;
			};
		}
	) {
		state.popup.visible = true;
		state.popup.message = payload.message;
		state.popup.image = payload.image;
		state.popup.id = payload.id;
		state.popup.placement = payload.placement;
		state.popup.anchorEl = payload.anchorEl;
	},
	hideOnboarding(
		state: stateType,
		{
			payload,
		}: {
			payload: {
				id: string;
			};
		}
	) {
		state.popup.visible = false;
		state.flags[payload.id] = false;
	},
	getOnBoardingFlags() {},
	setOnBoardingFlags(
		state: stateType,
		{
			payload,
		}: {
			payload: {
				[key: string]: boolean;
			};
		}
	) {
		state.flags = payload;
	},
	setInstalledExtension(
		state: stateType,
		{
			payload,
		}: {
			payload: boolean;
		}
	) {
		state.extensionInstalled = payload;
	},
	setExtensionError(
		state: stateType,
		{
			payload,
		}: {
			payload: boolean;
		}
	) {
		state.extensionErrored = payload;
	},
	clearOnboarding(state: stateType) {
		state.flags = {};
	},
	setExtensionVersion(
		state: stateType,
		{
			payload,
		}: {
			payload: string;
		}
	) {
		const val = payload
			.split('.')
			.map((i) => padStart(i, 2, '0'))
			.join('.');
		state.extensionVersion = parseFloat((val || '0').replace(/\.(?=[^.]*$)/, ''));
		console.log('extensionVersion', state.extensionVersion);
		state.extensionInstalled = true;
	},
};

// @ts-ignore
export const onBoarding = createSlice({
	name: 'OnBoarding',
	initialState,
	//@ts-ignore
	reducers: OnboardingReducer,
});

export const showOnboarding = onBoarding.actions.showOnboarding;
export const hideOnboarding = onBoarding.actions.hideOnboarding;
export const getOnBoardingFlags = onBoarding.actions.getOnBoardingFlags;
export const setOnBoardingFlags = onBoarding.actions.setOnBoardingFlags;
export const setInstalledExtension = onBoarding.actions.setInstalledExtension;
export const setExtensionErrored = onBoarding.actions.setExtensionError;
export const clearOnboarding = onBoarding.actions.clearOnboarding;
export const setExtensionVersion = onBoarding.actions.setExtensionVersion;

export default onBoarding.reducer;
