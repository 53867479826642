import { scaleLinear } from 'd3-scale';
import { get, includes, memoize, split } from 'lodash';
import { CropStateType } from '../Pages/Flo/Flo.types';

export function gcd(x: number, y: number): number {
	let [a, b] = [x, y].sort();
	while (b !== 0) {
		const temp = b;
		b = a % b;
		a = temp;
	}
	return a;
}

export function calculateAspectRatio(width: number, height: number): number {
	const divisor = gcd(width || 0, height || 0);
	const ratioWidth = width / divisor;
	const ratioHeight = height / divisor;
	return ratioWidth / ratioHeight;
}

export const closestToN = (a: number, x: number) => {
	let n = a;
	n = n + x / 2;
	n = n - (n % x);
	if (n <= a) return n;
	return n - x;
};

export const findClosestPossibleFit = (
	srcWidth: number,
	srcHeight: number,
	maxWidth: number,
	maxHeight: number
) => {
	// let ratio = Math.min(Math.round(maxWidth) / srcWidth, Math.round(maxHeight) / srcHeight);
	// let width = srcWidth * ratio;
	// let height = srcHeight * ratio;
	// let roundedWidth = width;
	// let roundedHeight = height;
	// let update = false;
	// if (srcWidth !== Math.round(srcWidth)) {
	// 	roundedWidth = Math.round(srcWidth);
	// 	update = true;
	// }
	// if (srcHeight !== Math.round(srcHeight)) {
	// 	roundedHeight = Math.round(srcHeight);
	// 	update = true;
	// }
	//
	// if (update) {
	// 	const data = findClosestPossibleFit(roundedWidth, roundedHeight, maxWidth, maxHeight);
	// 	roundedHeight = data.height;
	// 	roundedWidth = data.width;
	// }
	//  **********
	// let width = closestToN(Math.floor(maxWidth), srcWidth);
	// let height = closestToN(Math.floor(maxHeight), srcHeight);

	// while (width > 0 && width / height !== srcWidth / srcHeight) {
	// 	width = closestToN(Math.floor(width - srcWidth), srcWidth);
	// }
	// if (width <= 0) width = closestToN(Math.floor(maxWidth), srcWidth);

	// while (height > 0 && width / height !== srcWidth / srcHeight) {
	// 	height = closestToN(Math.floor(height - srcHeight), srcHeight);
	// }
	// return { width, height };
	//  ***********

	let width = closestToN(Math.floor(maxWidth), srcWidth);
	let height = closestToN(Math.floor(maxHeight), srcHeight);

	while (width > 0 && height > 0 && width / height !== srcWidth / srcHeight) {
		const aspectRatio = width / height;

		if (aspectRatio > srcWidth / srcHeight) {
			width = closestToN(Math.floor(width - srcWidth), srcWidth);
		} else {
			height = closestToN(Math.floor(height - srcHeight), srcHeight);
		}
	}

	if (width <= 0) {
		width = closestToN(Math.floor(maxWidth), srcWidth);
		height = closestToN(Math.floor(height - srcHeight), srcHeight);
	}

	if (height <= 0) {
		height = closestToN(Math.floor(maxHeight), srcHeight);
		width = closestToN(Math.floor(width - srcWidth), srcWidth);
	}
	return { width, height };
	// return { width: closestToN(Math.round(maxWidth), srcWidth), height: closestToN(Math.round(maxHeight), srcHeight) };
};

export const calculateAspectRatioFit = (
	srcWidth: number,
	srcHeight: number,
	maxWidth: number,
	maxHeight: number
) => {
	let ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
	// return findClosestPossibleFit(srcWidth, srcHeight, maxWidth, maxHeight);
	return { width: srcWidth * ratio, height: srcHeight * ratio };
};

export const calculateVideoCropMatrix = (
	currentVideoContainerDim: { width: number; height: number },
	actualVideoConfig: { width: number; height: number } | undefined,
	croppedDimension: CropStateType | undefined,
	isEditingCrop: boolean
) => {
	const newValue = calculateAspectRatioFit(
		actualVideoConfig?.width || 0,
		actualVideoConfig?.height || 0,
		currentVideoContainerDim?.width || 0,
		currentVideoContainerDim?.height || 0
	);
	const offsetLeft =
		get(currentVideoContainerDim, 'width', 0) - get(newValue, 'width', 0);
	const offsetTop =
		get(currentVideoContainerDim, 'height', 0) - get(newValue, 'height', 0);
	if (isEditingCrop || !actualVideoConfig) {
		return {
			offsetLeft,
			width: newValue.width,
			height: newValue.height,
			offsetTop,
		};
	}

	let scaleRatio = Math.min(
		actualVideoConfig.width /
			Math.round(
				Math.abs(
					get(croppedDimension, 'values.x2', actualVideoConfig.width) -
						get(croppedDimension, 'values.x', 0)
				)
			),
		actualVideoConfig.height /
			Math.round(
				Math.abs(
					get(croppedDimension, 'values.y2', actualVideoConfig.height) -
						get(croppedDimension, 'values.y', 0)
				)
			)
	);
	// let scaleRatio =
	// 	actualVideoConfig.height /
	// Math.round(
	// 	Math.abs(
	// 		get(croppedDimension, 'values.y2', actualVideoConfig.height) -
	// 			get(croppedDimension, 'values.y', 0)
	// 	)
	// );
	// if (
	// 	aspectRatio &&
	// 	(aspectRatio > 1.9 ||
	// 		!((aspectRatio >= 1.5 && aspectRatio <= 1.6) || aspectRatio <= 1.35))
	// ) {
	// 	scaleRatio =
	// 		actualVideoConfig.width /
	// 		Math.round(
	// 			Math.abs(
	// 				get(croppedDimension, 'values.x2', actualVideoConfig.width) -
	// 					get(croppedDimension, 'values.x', 0)
	// 			)
	// 		);
	// }
	const xScaleFunction = scaleLinear([0, actualVideoConfig.width], [0, newValue.width]);
	const xScale = xScaleFunction(Math.round(get(croppedDimension, 'values.x', 0)));
	const yScaleFunction = scaleLinear([0, actualVideoConfig.height], [0, newValue.height]);
	const yScale = yScaleFunction(Math.round(get(croppedDimension, 'values.y')));
	return {
		scaleRatio,
		translateX: offsetLeft * 0.5 * scaleRatio,
		translateY: offsetTop * 0.5 * scaleRatio,
		transform: `scale(${scaleRatio}) translate(${
			-1 * (xScale - (offsetLeft * 0.5) / scaleRatio)
		}px, ${-1 * (yScale - (offsetTop * 0.5) / scaleRatio)}px)`,
		transformOrigin: '0 0',
		offsetLeft,
		width: newValue.width,
		height: newValue.height,
		offsetTop,
	};
};

export const findTooltipDefaultPosition = (
	canvasCoordinates: { x: number; y: number },
	windowsCoordinates: { width: number; height: number }
) => {
	let positionHorizontal = '';
	let positionVertical = '';

	const percentageWidth = Math.max(windowsCoordinates.width * 0.3);
	const onLeft = canvasCoordinates.x < percentageWidth;
	const onRight = canvasCoordinates.x > windowsCoordinates.width - percentageWidth;

	const percentageHeight = Math.max(windowsCoordinates.height * 0.3);
	const onTop = canvasCoordinates.y < percentageHeight;
	const onButtom = canvasCoordinates.y > windowsCoordinates.height - percentageHeight;
	if (onTop) {
		positionVertical = 'bottom';
		if (onLeft) {
			positionHorizontal = 'start';
		}
		if (onRight) {
			positionHorizontal = 'end';
		}
		return `${positionVertical}${positionHorizontal ? '-' : ''}${positionHorizontal}`;
	} else if (onButtom) {
		positionVertical = 'top';
		if (onLeft) {
			positionHorizontal = 'start';
		}
		if (onRight) {
			positionHorizontal = 'end';
		}
		return `${positionVertical}${positionHorizontal ? '-' : ''}${positionHorizontal}`;
	}

	if (onButtom) {
		return 'top';
	} else if (onRight) {
		return 'left';
	} else if (onTop) {
		return 'bottom';
	} else {
		return 'right';
	}
};

const _generateAspectRatio = (width: number, height: number) => {
	let d = gcd(width, height);
	return `${width / d}:${height / d}`;

	// const ratio = width / height;
	// const maxDenominator = 1000000;
	// const tolerance = 0.00001;
	// const maxIterations = 2000;

	// let numerator = ratio;
	// let denominator = 1;

	// for (let iterations = 0; iterations < maxIterations; iterations++) {
	// 	const roundedNumerator = Math.round(numerator);
	// 	const roundedFraction = roundedNumerator / denominator;

	// 	if (Math.abs(ratio - roundedFraction) < tolerance) {
	// 		return `${roundedNumerator}:${denominator}`;
	// 	}

	// 	if (roundedFraction < ratio) {
	// 		numerator = ratio * (denominator + 1);
	// 	} else {
	// 		denominator++;
	// 	}
	// }

	// return '';
};

const simplifyFraction = (numerator: number, denominator: number): [number, number] => {
	const commonDivisor = gcd(numerator, denominator);
	return [numerator / commonDivisor, denominator / commonDivisor];
};

const _generateAspectRatio2 = (width: number, height: number): string => {
	const ratio = width / height;
	const maxDenominator = 1000000;
	const tolerance = 0.00001;
	const maxIterations = 1000;

	let numerator = ratio;
	let denominator = 1;

	for (let iterations = 0; iterations < maxIterations; iterations++) {
		const roundedNumerator = Math.round(numerator);
		const roundedFraction = roundedNumerator / denominator;

		if (Math.abs(ratio - roundedFraction) < tolerance) {
			const [simplifiedNumerator, simplifiedDenominator] = simplifyFraction(
				roundedNumerator,
				denominator
			);
			return `${simplifiedNumerator}:${simplifiedDenominator}`;
		}

		if (roundedFraction < ratio) {
			numerator = ratio * (denominator + 1);
		} else {
			denominator++;
		}
	}

	return '';
};

export const generateAspectRatio = memoize(
	_generateAspectRatio,
	(a: number, b: number) => `${a}:${b}`
);

export const _cropAreaResize = (
	cropAspectRatio: string,
	srcWidth: number,
	srcHeight: number,
	maxWidth: number,
	maxHeight: number
) => {
	if (!cropAspectRatio || includes(['free-crop'], cropAspectRatio)) {
		return { width: Math.round(maxWidth), height: Math.round(maxHeight) };
	}
	let [aspX, aspY] = split(
		cropAspectRatio === 'original'
			? generateAspectRatio(srcWidth, srcHeight) || `${srcWidth}${srcHeight}`
			: cropAspectRatio,
		':'
	).map((i) => Number(i));

	// if (cropAspectRatio === 'original') {
	// 	const { width: newWidth, height: newHeight } = calculateAspectRatioFit(
	// 		aspX,
	// 		aspY,
	// 		maxWidth,
	// 		maxHeight
	// 	);
	// 	return {
	// 		width: Math.floor(newWidth),
	// 		height: Math.floor(newHeight),
	// 	};
	// }
	const { width: newWidth, height: newHeight } = findClosestPossibleFit(
		aspX,
		aspY,
		Math.round(Math.abs(maxWidth)),
		Math.round(Math.abs(maxHeight))
	);
	return {
		width: newWidth,
		height: newHeight,
	};
};

export const cropAreaResize = memoize(
	_cropAreaResize,
	(a, b, c, d, e) => `${a}_${b}_${c}_${d}_${e}`
);
