import { Card, Dialog } from '@mui/material';
import { find, get, includes } from 'lodash';
import { ReactElement } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { onboardingStatusSelector } from '../OnboardQa/OnboardQa.selector';
import RecordingState from './components/RecordingScreen';
import Templates from './components/Templates/Templates';
import Waiting from './components/Waiting';
import useNetworkHook from './hooks/network.hook';
import useNewFloEffect from './hooks/NewFlo.hook';
import styles from './NewFloContainer.module.css';
import { TemplateCategories } from './NewFloContainer.constants';

const Zoom = ({
	children,
	appear,
	in: _in,
}: {
	children: ReactElement;
	appear?: boolean;
	in?: boolean;
}) => {
	if (!_in) return <></>;
	return children;
};

const NewFloContainer = () => {
	const templateFilter: string = useSelector((state) =>
		get(state, 'newFlo.templateFilter')
	);
	const activeTemplateFilter = useSelector((state) => {
		const template = TemplateCategories.find((i) => i.key === templateFilter);
		if (template) return template;
		return find(
			get(state, 'newFlo.templateCategories'),
			(i) => i.key === templateFilter
		);
	});

	const {
		recordState,
		canShowClose,
		showTemplateDeviceSelection,
		popupDims,
		canShowNewFlo,
		canShowCountDown,
		onClose,
		NEW_FLO_COUNTDOWN_SUB_TITLE,
		NEW_FLO_COUNTDOWN_TITLE,
		NEW_FLO_RESUME_COUNTDOWN_TITLE,
		isPaused,
		countDown,
		selectedTemplate,
		recordingSingleColumn,
		cardStyle,
		audioDeviceChangeHandler,
		iframeDims,
		previewVideoRef,
		getDevicesCallback,
		videoDeviceChangeHandler,
		videoDevice,
		audioDevice,
		onBackHandler,
		onStartHandler,
		devicePermissions,
		settings,
		videoRef,
		isPrepareScreen,
		startTime,
		audioDeviceMeta,
		videoDeviceMeta,
		endTime,
		onPauseHandler,
		onStopHandler,
		durationTime,
		onDiscardHandler,
		startRecording,
		onRestartHandler,
		setAudioDevice,
		availableDevices,
	} = useNewFloEffect({
		activeTemplateFilter
	});
	const { speed } = useNetworkHook();
	const isOnboardingDone = useSelector(onboardingStatusSelector);


	if (!canShowNewFlo || isOnboardingDone === false) return <span />;
	return (
		<div
			className={classNames(styles.container, {
				[styles.recordStopped]: recordState === 'stopped/save',
			})}
		>
			{recordState === 'stopped/save' ? (
				<></>
			) : (
				<Dialog
					open={canShowNewFlo}
					transitionDuration={3}
					onClose={onClose}
					classes={{
						container: styles.dialogContainer,
						paper: styles.dialogWrapper,
						root: styles.dialogRoot,
					}}
				>
					{canShowCountDown && (
						<Card
							className={classNames([styles.countDownContainer, styles.card])}
							sx={{ minWidth: 260 }}
						>
							<div className={styles.cardCountDownSubTitle}>
								{includes(
									['onStopSaving', 'stopped/save', 'onDiscardSaving'],
									recordState
								)
									? ''
									: NEW_FLO_COUNTDOWN_SUB_TITLE}
							</div>
							{(recordState === 'recording' || isPaused) && (
								<Zoom appear={true} in={true}>
									<div>
										{recordState === 'recording' && (
											<>
												<div className={styles.cardCountDownTitle}>
													{!isPaused
														? NEW_FLO_COUNTDOWN_TITLE
														: NEW_FLO_RESUME_COUNTDOWN_TITLE}
												</div>
												<div className={styles.cardCountDown}>{countDown}</div>
											</>
										)}
									</div>
								</Zoom>
							)}
						</Card>
					)}
					<Card
						sx={{ minWidth: 260 }}
						className={classNames(styles.card, {
							[styles.templateDetails]: get(selectedTemplate, 'id'),
							[styles.savingContainer]: includes(['saving', 'onStopSaving'], recordState),
							[styles.recordingSingleColumn]: recordingSingleColumn,
						})}
						style={{
							...cardStyle,
							...(get(selectedTemplate, 'id') && !showTemplateDeviceSelection
								? popupDims
								: {}),
							height: 'fit-content',
						}}
					>
						<RecordingState
							activeTemplateFilter={activeTemplateFilter}
							networkSpeed={speed}
							audioDevice={audioDevice}
							displaySurface={get(settings, 'screenShare.displaySurface') as string}
							ref={videoRef}
							recordState={recordState}
							isPrepareScreen={isPrepareScreen}
							startTime={startTime}
							audioDeviceMeta={audioDeviceMeta}
							videoDeviceMeta={videoDeviceMeta}
							endTime={endTime}
							onPauseHandler={onPauseHandler}
							onStopHandler={onStopHandler}
							isPaused={isPaused}
							duration={durationTime}
							onDiscard={onDiscardHandler}
							startRecording={startRecording}
							onRestart={onRestartHandler}
						/>
						<Waiting recordState={recordState} />
						{
						(
							get(activeTemplateFilter, 'key') === 'manual_upload' ||
							!includes(
								['saving', 'recording', 'onStopSaving', 'onDiscardSaving'],
								recordState
							)
						) && (
							<Templates
								networkSpeed={speed}
								onClose={onClose}
								iframeDims={iframeDims}
								setAudioDevice={setAudioDevice}
								recordState={recordState}
								ref={previewVideoRef}
								getDevices={getDevicesCallback}
								availableDevices={availableDevices}
								audioDeviceChange={audioDeviceChangeHandler}
								videoDeviceChange={videoDeviceChangeHandler}
								videoDevice={videoDevice}
								audioDevice={audioDevice}
								onBack={onBackHandler}
								onStartHandler={onStartHandler}
								devicePermissions={devicePermissions}
							/>
						)}
					</Card>
				</Dialog>
			)}
		</div>
	);
};

export default NewFloContainer;
