import { Box, Tooltip } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MESSAGE_TO_ROLE_MAP } from '../../Common/Common.constants';
import Translations from '../../Common/Translate.utils';
import { BillingDialogState } from '../BillingDialog/BillingDialog.reducer';
import { UserRoleType } from '../TeamManagementDialog/TeamManagementDialog.types';
import styles from './AccountInfoDialog.module.css';

const AccountInfoDialog = () => {
	const flospaceInfo = useSelector((state) =>
		get(state, 'routerContainer.currentFloSpace')
	);
	const { data: billingData, subscriptions } = useSelector<unknown, BillingDialogState>(
		(state) => get(state, 'billingDialog')
	);
	const period = billingData.existingPaymentPricePeriod
		? ` (${billingData.existingPaymentPricePeriod})`
		: '';

	const userRoles =
		useSelector((state) => get(state, 'teamManagement.userRoles.data')) || [];

	const [rolesTooltip, setRolesTooltip] = useState<JSX.Element>('');

	useEffect(() => {
		const rolesComp = (
			<ul>
				{userRoles.map((role: UserRoleType) => (
					<li key={role.id} className={styles.roleInfoItem}>
						<span className={styles.roleInfoItemLabel}>{role.label}:</span>{' '}
						{get(Translations, get(MESSAGE_TO_ROLE_MAP, role.key, ''))}
					</li>
				))}
			</ul>
		);
		setRolesTooltip(rolesComp);
	}, [userRoles, setRolesTooltip]);

	const {
		ACCOUNT_NAME_TITLE_TEXT,
		ACCOUNT_CURRENT_USER_ROLE_TITLE,
		PROFILE_ACCOUNT_INFO_TITLE_TEXT,
		ACCOUNT_PLAN_NAME_TITLE_TEXT,
	} = Translations;

	return (
		<div className={styles.wrapper}>
			<Box id="responsive-dialog-title" className={styles.title}>
				{PROFILE_ACCOUNT_INFO_TITLE_TEXT}
			</Box>
			<div className={styles.content}>
				<Box className={styles.nameInputContainer}>
					<Box className={styles.nameTitle}>{ACCOUNT_NAME_TITLE_TEXT}</Box>
					<Box className={styles.nameInput}>
						{get(flospaceInfo, 'createdBy.name')} ({get(flospaceInfo, 'createdBy.email')})
					</Box>
				</Box>
				<Box className={styles.nameInputContainer}>
					<Box className={styles.nameTitle}>{ACCOUNT_PLAN_NAME_TITLE_TEXT}</Box>
					<Box className={styles.nameInput}>
						{billingData.existingSubscriptionName}
						{period}
					</Box>
				</Box>
				<Box className={styles.nameInputContainer}>
					<Box className={styles.nameTitle}>
						{ACCOUNT_CURRENT_USER_ROLE_TITLE}
						<Tooltip
							title={rolesTooltip}
							classes={{
								popper: styles.tooltip,
								tooltip: styles.tooltipContent,
							}}
							arrow
							placement="right"
						>
							<IconInfoCircle size={18} className={styles.infoIcon} />
						</Tooltip>
					</Box>
					<Box className={styles.nameInput}>{get(flospaceInfo, 'userRole.label')}</Box>
				</Box>
			</div>
		</div>
	);
};

export default AccountInfoDialog;
