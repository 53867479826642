import { createSlice } from '@reduxjs/toolkit';
import { find, get, map, reject } from 'lodash';
import {
	Pagination,
	TeamManagementDialogState,
	UserRoleType,
	UserType,
} from './TeamManagementDialog.types';

const initialState = Object.freeze({
	usersList: [],
	paginationInfo: {},
});

const teamManagementDialogReducers = {
	getTeamUsersListAction(
		state: TeamManagementDialogState,
		{ payload }: { payload: { pageNo?: number } }
	) {},
	deleteUserAction(
		state: TeamManagementDialogState,
		{ payload }: { payload: { user: UserType; isCancel: boolean } }
	) {},
	deleteUserConfirmAction(
		state: TeamManagementDialogState,
		{ payload }: { payload: { user: UserType; isCancel: boolean } }
	) {},
	deleteUserSuccessAction(
		state: TeamManagementDialogState,
		{ payload }: { payload: { user: UserType; isCancel: boolean } }
	) {
		if (payload.isCancel) {
			state.usersList = reject(state.usersList, ['id', payload.user.id]);
			state.paginationInfo.totalRecords = state.paginationInfo.totalRecords - 1;
		} else {
			state.usersList = map(state.usersList, (user: UserType) =>
				user.id === payload.user.id
					? {
							...user,
							deleted: true,
					  }
					: user
			);
		}
	},
	inviteUserAction(
		state: TeamManagementDialogState,
		{ payload }: { payload: { email: string; isNew: boolean; successCb: Function } }
	) {},
	inviteUserSuccessAction(
		state: TeamManagementDialogState,
		{ payload }: { payload: { data: UserType[]; email: string } }
	) {
		const isUserAlreadyInvited = find(state.usersList, ['userBean.email', payload.email]);
		state.usersList = reject(state.usersList, ['userBean.email', payload.email]);
		state.usersList = payload.data.concat(state.usersList);
		if (!isUserAlreadyInvited) {
			state.paginationInfo.totalRecords = state.paginationInfo.totalRecords + 1;
		}
	},
	removeDeletedUser(state: TeamManagementDialogState, { payload }: { payload: string }) {
		state.usersList = reject(state.usersList, ['userBean.email', payload]);
		state.paginationInfo.totalRecords = state.paginationInfo.totalRecords - 1;
	},
	inviteUserJoinedAction(
		state: TeamManagementDialogState,
		{ payload }: { payload: string }
	) {
		state.usersList = state.usersList.map((item) => {
			if (get(item, 'userBean.userId') === payload) {
				return {
					...item,
					invitePending: false,
					inviteAccepted: true,
				};
			}
			return item;
		});
	},
	getTeamUsersListActionSuccess(
		state: TeamManagementDialogState,
		{ payload }: { payload: { data: UserType[]; paginationInfo: Pagination } }
	) {
		if (payload.paginationInfo.pageNo === 1) {
			state.usersList = payload.data;
		} else {
			state.usersList = state.usersList.concat(payload.data);
		}
		state.paginationInfo = payload.paginationInfo;
	},
	getUserRoles() {},
	getUserRolesSuccess(
		state: TeamManagementDialogState,
		{
			payload,
		}: {
			payload: { data: UserRoleType[]; paginationInfo: Pagination };
		}
	) {
		state.userRoles = payload;
	},
	updateUserRole() {},
	updateUserRoleSuccess(
		state: TeamManagementDialogState,
		{
			payload,
		}: {
			payload: { userId: string; roleId: string };
		}
	) {
		state.usersList = map(state.usersList, (user: UserType) => {
			if (user.id === payload.userId) {
				return {
					...user,
					roleBean: {
						id: payload.roleId,
					},
				};
			}
			return user;
		});
	},
};

export const teamManagementDialogReducer = createSlice({
	name: 'teamManagementDialogReducer',
	initialState,
	// @ts-ignore
	reducers: teamManagementDialogReducers,
});

export const getTeamUsersListAction =
	teamManagementDialogReducer.actions.getTeamUsersListAction;
export const getTeamUsersListActionSuccess =
	teamManagementDialogReducer.actions.getTeamUsersListActionSuccess;
export const inviteUserAction = teamManagementDialogReducer.actions.inviteUserAction;
export const inviteUserSuccessAction =
	teamManagementDialogReducer.actions.inviteUserSuccessAction;
export const deleteUserAction = teamManagementDialogReducer.actions.deleteUserAction;
export const deleteUserConfirmAction =
	teamManagementDialogReducer.actions.deleteUserConfirmAction;
export const deleteUserSuccessAction =
	teamManagementDialogReducer.actions.deleteUserSuccessAction;
export const inviteUserJoinedAction =
	teamManagementDialogReducer.actions.inviteUserJoinedAction;
export const removeDeletedUser = teamManagementDialogReducer.actions.removeDeletedUser;
export const getUserRolesAction = teamManagementDialogReducer.actions.getUserRoles;
export const getUserRolesSuccessAction =
	teamManagementDialogReducer.actions.getUserRolesSuccess;
export const updateUserRoleAction = teamManagementDialogReducer.actions.updateUserRole;
export const updateUserRoleSuccessAction =
	teamManagementDialogReducer.actions.updateUserRoleSuccess;

export default teamManagementDialogReducer.reducer;
