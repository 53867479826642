import { Button, Grid } from '@mui/material';
import { IconDownload } from '@tabler/icons-react';
import classNames from 'classnames';
import { get, throttle } from 'lodash';
import { CSSProperties, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListRowProps } from 'react-virtualized';
import Translations from '../../Common/Translate.utils';
import InfiniteScrollList from '../InfiniteScrollList/InfiniteScrollList';
import Loader from '../Loader/Loader';
import styles from './InvoicesDialog.module.css';
import {
	downloadInvoiceAction,
	getInvoicesAction,
	InvoiceType,
} from './InvoicesDialog.reducer';

const InvoiceRow = ({
	data,
	style,
	onDownload,
}: {
	data: InvoiceType;
	onDownload: Function;
	style: CSSProperties;
}) => {
	const {
		PROFILE_INVOICES_DOWNLOAD_BUTTON_TEXT,
		PROFILE_INVOICES_RECEIPT_DOWNLOAD_BUTTON_TEXT,
	} = Translations;

	return (
		<div style={style} className={styles.tableRow}>
			<Grid
				item
				className={styles.tableCell}
				tablet={2.5}
				title={data.formattedCreatedDate}
			>
				{data.formattedCreatedDate}
			</Grid>
			<Grid item className={styles.tableCell} tablet={3.5} title={data.planName}>
				{data.planName}
			</Grid>
			<Grid
				item
				className={classNames(styles.tableCell, styles.rightAlign)}
				tablet={2}
				title={`$${data.amount}`}
			>
				${data.amount}
			</Grid>
			<Grid item className={classNames(styles.tableCell, styles.rightAlign)} tablet={4}>
				{data.invoiceUrl ? (
					<Button
						variant="outlined"
						className={styles.downloadButton}
						onClick={() => onDownload(data.invoiceUrl)}
					>
						<IconDownload size={16}></IconDownload>
						<span className={styles.downloadButtonText}>
							{PROFILE_INVOICES_DOWNLOAD_BUTTON_TEXT}
						</span>
					</Button>
				) : null}
				{data.receiptUrl ? (
					<Button
						variant="outlined"
						className={styles.downloadButton}
						onClick={() => onDownload(data.receiptUrl)}
					>
						<IconDownload size={16}></IconDownload>
						<span className={styles.downloadButtonText}>
							{PROFILE_INVOICES_RECEIPT_DOWNLOAD_BUTTON_TEXT}
						</span>
					</Button>
				) : null}
			</Grid>
		</div>
	);
};

const InvoicesDialog = () => {
	const {
		PROFILE_INVOICES_TITLE_TEXT,
		PROFILE_INVOICES_COLUMN_1_HEADING,
		PROFILE_INVOICES_COLUMN_2_HEADING,
		PROFILE_INVOICES_COLUMN_3_HEADING,
	} = Translations;

	const INVOICE_TABLE_COLUMNS = [
		{
			heading: PROFILE_INVOICES_COLUMN_1_HEADING,
			gridItemProps: { tablet: 2.5 },
		},
		{
			heading: PROFILE_INVOICES_COLUMN_2_HEADING,
			gridItemProps: { tablet: 3.5 },
		},
		{
			heading: PROFILE_INVOICES_COLUMN_3_HEADING,
			gridItemProps: { tablet: 2 },
		},
		{
			heading: '',
			gridItemProps: { tablet: 4 },
		},
	];

	const dispatch = useDispatch();

	const paginationInfo = useSelector((state) =>
		get(state, 'invoicesDialog.paginationInfo')
	);
	const invoicesList = useSelector((state) => get(state, 'invoicesDialog.data'));
	const loader = useSelector((state) => get(state, 'loaders.invoicesDialog') > 0);

	const loadMoreInvoices = useCallback(
		(params: { startIndex: number; stopIndex: number }) => {
			dispatch(
				getInvoicesAction({
					hideLoader: true,
					pageNo: Math.ceil(params.startIndex / 30) + 1,
				})
			);
			return new Promise((resolve) => resolve(false));
		},
		[]
	);

	useEffect(() => {
		dispatch(
			getInvoicesAction({
				pageNo: 1,
			})
		);
	}, []);

	const onDownloadClick = useCallback(
		throttle((val: string) => {
			dispatch(downloadInvoiceAction(val));
		}, 100),
		[]
	);

	if (loader) {
		return <Loader nodelay />;
	}

	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>{PROFILE_INVOICES_TITLE_TEXT}</div>
			<div className={styles.tableWrapper}>
				{invoicesList.length ? (
					<>
						<div className={styles.tableHead}>
							{INVOICE_TABLE_COLUMNS.map((item) => (
								<Grid
									item
									className={styles.tableCell}
									{...item.gridItemProps}
									title={item.heading}
								>
									{item.heading}
								</Grid>
							))}
						</div>
						<div className={styles.tableBody}>
							<InfiniteScrollList
								hasMoreRows={paginationInfo.hasMore}
								rowRenderer={({ index, key, style }: ListRowProps) => {
									const invoice = invoicesList[index];
									return (
										<InvoiceRow
											key={key}
											data={invoice}
											onDownload={onDownloadClick}
											style={style}
										/>
									);
								}}
								rowHeight={64}
								loadMoreRows={loadMoreInvoices}
								listData={invoicesList || []}
							/>
						</div>
					</>
				) : (
					<div className={'emptyMessage'}>No invoices found</div>
				)}
			</div>
		</div>
	);
};

export default InvoicesDialog;
