import { createSlice } from '@reduxjs/toolkit';

export interface AccountSettingsDialogReducerType {
	visible: boolean;
	floId: '';
	defaultActiveTab: number;
	onClose?(): void;
}

const initialState = Object.freeze({
	visible: false,
});

const accountSettingsDialogReducers = {
	showDialog(
		state: AccountSettingsDialogReducerType,
		{ payload }: { payload: Partial<AccountSettingsDialogReducerType> }
	) {
		return { ...initialState, ...payload, visible: true };
	},
	hideDialog(state: AccountSettingsDialogReducerType, { payload }: { payload: unknown }) {
		return initialState;
	},
};

export const accountSettingsDialogReducer = createSlice({
	name: 'accountSettingsDialogReducer',
	initialState,
	// @ts-ignore
	reducers: accountSettingsDialogReducers,
});

export default accountSettingsDialogReducer.reducer;

export const showAccountSettingsPopup = accountSettingsDialogReducer.actions.showDialog;
export const hideAccountSettingsPopup = accountSettingsDialogReducer.actions.hideDialog;
