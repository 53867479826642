import { max } from 'lodash';

export function formatSpeedStats(_speedBps: number) {
	const speedBps = max([0, _speedBps]) as number;
	if (speedBps < 1000) {
		return {
			value: speedBps.toFixed(2),
			unit: 'b',
		};
	} else if (speedBps < 1000000) {
		return {
			value: (speedBps / 1000).toFixed(2),
			unit: 'kb',
		};
	} else if (speedBps < 1000000000) {
		return {
			value: (speedBps / 1000000).toFixed(2),
			unit: 'Mb',
		};
	} else {
		return {
			value: (speedBps / 1000000000).toFixed(2),
			unit: 'Gb',
		};
	}
}

export function getNetworkQuality(speedBps: number) {
	if (speedBps === -1) {
		return {
			text: '',
			strength: '',
		};
	} else if (speedBps < 1000) {
		return {
			text: 'Very weak connection',
			strength: 'vweak',
		};
	} else if (speedBps < 1000000) {
		return {
			text: 'Weak connection',
			strength: 'weak',
		};
	} else if (speedBps < 1000000000) {
		return {
			text: 'Fair connection',
			strength: 'fair',
		};
	} else {
		return {
			text: '',
			strength: '',
		};
	}
}

export function formatSpeed(speedBps: number, postPendTerm: string = '') {
	const { value, unit } = formatSpeedStats(speedBps);
	return `${value}${unit}${postPendTerm}`;
}
