import classNames from 'classnames';
import { get } from 'lodash';
import React, { CSSProperties, useEffect, useState } from 'react';
import styles from './Avatar.module.css';

const DefaultAvatar = () => (
	<svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="28" height="28" rx="8" fill="var(--fgColor)" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.20078 8.70615C9.20078 11.3126 11.3423 13.4123 14.0008 13.4123C16.6592 13.4123 18.8008 11.3126 18.8008 8.70615C18.8008 6.09967 16.6592 4 14.0008 4C11.3423 4 9.20078 6.09967 9.20078 8.70615ZM6.18636 24H21.6909C22.55 24 23 23.0922 23 22.6564V20.2598C23 17.3911 19.0318 15.176 14 15.176C8.96818 15.176 5 17.3911 5 20.2598V22.6564C5 23.2011 5.36818 24 6.18636 24Z"
			fill="var(--bgColor)"
		/>
	</svg>
);

const Avatar = ({
	profilePicUrl,
	avatarColor,
	title,
	name,
	circular,
	small,
	onClick,
	className,
	style,
	count,
	classes,
}: {
	className?: string;
	profilePicUrl?: string;
	title?: string;
	name?: string;
	small?: boolean;
	circular?: boolean;
	onClick?(event: React.MouseEvent<HTMLElement>): void;
	style?: CSSProperties;
	avatarColor?: {
		bgColor: string;
		fgColor: string;
	};
	count?: number;
	classes?: {
		[key: string]: string;
	};
}) => {
	const [type, setType] = useState<string>(
		(profilePicUrl || '').endsWith('default.svg') ? 'svg' : 'image'
	);
	useEffect(() => {
		const isDefaultAvatar = (profilePicUrl || '').endsWith('default.svg');

		if (isDefaultAvatar) {
			setType('svg');
		} else {
			setType('image');
		}
	}, [profilePicUrl, setType]);

	const canShowCount = count && count > 1;
	return (
		<div
			id="avatar"
			onClick={onClick}
			style={{
				...style,
				// @ts-ignore
				'--bgColor': get(avatarColor, 'bgColor'),
				'--fgColor': get(avatarColor, 'fgColor'),
			}}
			className={classNames(styles.avatar, className, {
				[styles.circular]: circular,
				[styles.small]: small,
			})}
		>
			{canShowCount ? <span className={styles.count}>{count}</span> : ''}

			{!canShowCount && type === 'svg' ? (
				<DefaultAvatar />
			) : (
				<div
					className={classNames(styles.profilePicContainer, classes?.profilePicContainer)}
					style={{
						backgroundImage: canShowCount ? '' : `url(${profilePicUrl})`,
					}}
				/>
			)}
		</div>
	);
};

export default Avatar;
