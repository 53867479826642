import { get } from 'lodash';
import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes as Switch, useLocation, useNavigate } from 'react-router-dom';
import { runOnboardingSaga } from '../../Components/OnBoarding/OnBoarding.saga';
import NotFoundRoute from '../../Pages/404/404.route';
import ServerErrorRoute from '../../Pages/500/500.route';
import AuthRoute from '../../Pages/Auth/Auth.routes';
import LoginRoute from '../../Pages/Login/Login.route';
import NoAuthErrorPageRoute, {
	noAuthErrorPagePath,
} from '../../Pages/NoAuthErrorPage/NoAuthErrorPage.route';
import { updateState } from '../../store/reducers/Routes.reducer';
import AuthenticatedRoute, {
	ErrorPages,
} from '../AuthenticatedRoutes/AuthenticatedRoutes';
import { checkIsLoggedInAction, leaveRoutesAction } from './Routes.reducers';
import { runRoutesSagas } from './Routes.sagas';
import { getIsAuthenticated } from './Routes.selector';

const Routes = () => {
	const isAuthenticated = useSelector(getIsAuthenticated);
	const dispatch = useDispatch();
	const routesPath = useSelector((state) => get(state, 'routes'));
	const navigate = useNavigate();
	const location = useLocation();

	const isNoAuthRoute = location.pathname === noAuthErrorPagePath;

	useEffect(() => {
		if (routesPath.current !== routesPath.previous) {
			navigate(routesPath.current, { replace: false });
			dispatch(updateState(routesPath.current));
		} else {
			dispatch(updateState(location.pathname + location.search));
		}
	}, [
		routesPath.current,
		routesPath.previous,
		location.pathname,
		location.search,
		navigate,
		dispatch,
	]);

	useEffect(() => {
		runRoutesSagas();
		runOnboardingSaga();
		if (!isNoAuthRoute) {
			dispatch(checkIsLoggedInAction({}));
		}
		if (location.hash === '#onboarding') {
			location.hash = '';
		}
		return () => {
			dispatch(leaveRoutesAction(''));
		};
	}, [isNoAuthRoute]);
	const showErrorPage = !!routesPath.errorUrl;
	const errorUrl = routesPath.errorUrl;
	if (isAuthenticated) {
		return <AuthenticatedRoute showErrorPage={showErrorPage} errorUrl={errorUrl} />;
	}
	if (isNoAuthRoute) {
		return <Switch>{NoAuthErrorPageRoute}</Switch>;
	}

	return (
		<Fragment>
			<Switch>
				{AuthRoute}
				{NotFoundRoute}
				{ServerErrorRoute}
				{LoginRoute}
			</Switch>
			<ErrorPages showErrorPage={showErrorPage} errorUrl={errorUrl} />
		</Fragment>
	);
};

export default Routes;
