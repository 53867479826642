import { Button as MuiButton } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';
import classNames from 'classnames';
import styles from './Button.module.css';

const Button = ({ children, classes, className, ...props }: ButtonProps) => {
	return (
		<MuiButton
			{...props}
			className={classNames(styles.button, className)}
			classes={{
				root: classNames(styles.root, classes?.root),
				text: classNames(styles.text, classes?.text),
				outlined: classNames(styles.outlined, classes?.outlined),
				contained: classNames(styles.contained, classes?.contained),
			}}
			disableFocusRipple
			disableElevation
		>
			{children}
		</MuiButton>
	);
};

export default Button;
