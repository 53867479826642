import {
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Paper,
	Popper,
	Typography,
} from '@mui/material';
import classNames from 'classnames';
import { get } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideOnboarding } from '../../store/reducers/OnBoarding.reducer';
import Button from '../Button/Button';
import styles from './OnBoarding.module.css';

const OnBoardingPopup = () => {
	const {
		id,
		visible,
		placement = 'bottom-start',
		anchorEl,
		message,
		image,
	} = useSelector((state) => get(state, 'onBoarding.popup'));
	const dispatch = useDispatch();
	const cardRef = useRef<HTMLDivElement>(null);
	const [position, setPosition] = useState<{
		top?: number;
		bottom?: number;
		left?: number;
		right?: number;
	}>({
		top: 0,
		left: 0,
	});

	useEffect(() => {
		setTimeout(() => {
			if (visible && anchorEl) {
				const bounds = anchorEl.getBoundingClientRect();
				if (placement.includes('bottom')) {
					setPosition({
						top: 0,
						left: bounds.width / 2 - 4,
					});
				} else if (placement.includes('top')) {
					setPosition({
						bottom: 0,
						left: bounds.width / 2 - 4,
					});
				}
			}
		}, 100);
	}, [visible, placement, anchorEl, cardRef, setPosition]);

	return (
		<Paper
			className={classNames(styles.popupContainer, {
				[styles.popperHidden]: !visible,
			})}
		>
			{visible && (
				<Popper
					className={classNames(styles.popper, {
						[styles.bottom]: placement.includes('bottom'),
						[styles.top]: placement.includes('top'),
						[styles.left]: placement.includes('left'),
						[styles.right]: placement.includes('right'),
					})}
					disablePortal
					open={visible}
					anchorEl={anchorEl}
					placement={'bottom-start'}
				>
					<div
						className={classNames(styles.anchor, {
							[styles.anchorBottom]: placement.includes('bottom'),
							[styles.anchorTop]: placement.includes('top'),
							[styles.anchorLeft]: placement.includes('left'),
							[styles.anchorRight]: placement.includes('right'),
						})}
					>
						<div style={{ left: position.left }} className={styles.orb}></div>
						<div style={{ left: position.left }} className={styles.dashedLine}></div>
					</div>
					<Card
						ref={cardRef}
						sx={{ minWidth: 120, maxWidth: 345 }}
						className={styles.cardContainer}
					>
						{image && <CardMedia component="img" height="auto" image={image} />}
						<CardContent className={styles.messageContainer}>
							<Typography className={styles.message} gutterBottom component="div">
								{message}
							</Typography>
						</CardContent>
						<CardActions className={styles.footer}>
							<Button
								size="medium"
								variant="contained"
								className={styles.closeButton}
								onClick={() => {
									dispatch(hideOnboarding({ id }));
								}}
							>
								Close
							</Button>
						</CardActions>
					</Card>
				</Popper>
			)}
		</Paper>
	);
};

export default OnBoardingPopup;
