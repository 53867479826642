// import Zoom from '@mui/material/Zoom';
import classNames from 'classnames';

import { Zoom } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import { get, reduce } from 'lodash';
import { useSelector } from 'react-redux';
import Translations from '../../../Common/Translate.utils';
import { formatSpeed } from '../../../utils/network';
import styles from '../NewFloContainer.module.css';
import { RecordStates } from '../NewFloContainer.types';

const Waiting = ({ recordState }: { recordState: RecordStates }) => {
	const {
		NEW_FLO_PROCESS_FLO_LOADER_TITLE_TEXT,
		NEW_FLO_SAVE_FLO_LOADER_TITLE_TEXT,
		NEW_FLO_DISCARD_FLO_LOADER_TITLE_TEXT,
		NEW_FLO_PROCESS_FLO_LOADER_SUB_TITLE_TEXT,
		NEW_FLO_DISCARD_FLO_LOADER_SUB_TITLE_TEXT,
		NEW_FLO_SAVE_FLO_LOADER_SUB_TITLE_TEXT,
	} = Translations;
	const chunkStats = useSelector(
		(state) =>
			get(state, 'newFlo.chunkStats') || {
				screenShareTotalSize: 0,
				screenShareUploadedSize: 0,
				videoTotalSize: 0,
				videoUploadedSize: 0,
			}
	);

	const networkStats = useSelector((state) => {
		const data = reduce(
			get(state, 'newFlo.uploadSpeedData'),
			(acc, data) => {
				return {
					uploaded: acc.uploaded + (data[0] || 0),
					totalSize: acc.totalSize + (data[1] || 0),
				};
			},
			{
				uploaded: 0,
				totalSize: 0,
			}
		);
		return {
			uploadSpeed: formatSpeed(get(state, 'newFlo.uploadSpeed'), 'ps'),
			uploaded: formatSpeed(data.uploaded),
			totalSize: formatSpeed(data.totalSize),
			uploadedNum: data.uploaded,
			totalSizeNum: data.totalSize,
		};
	});

	const buffer = 10;
	const uploadedSize = networkStats.uploadedNum;
	const totalSize =
		(chunkStats.videoTotalSize || 0) + (chunkStats.screenShareTotalSize || 0);
	const percentageUploaded = Math.round((uploadedSize / (totalSize || 1)) * 100);
	return (
		<Zoom in={['saving', 'onStopSaving', 'onDiscardSaving'].includes(recordState)}>
			<div
				className={classNames(styles.cardRecordingBody, styles.waiting, {
					// @ts-ignore
					[styles.hide]: !['saving', 'onStopSaving', 'onDiscardSaving'].includes(
						recordState
					),
				})}
			>
				{recordState === 'onStopSaving' && (
					<div className={styles.processing}>{NEW_FLO_PROCESS_FLO_LOADER_TITLE_TEXT}</div>
				)}
				{recordState === 'onDiscardSaving' && (
					<div className={styles.processing}>{NEW_FLO_DISCARD_FLO_LOADER_TITLE_TEXT}</div>
				)}
				{recordState === 'saving' && (
					<div className={styles.processing}>{NEW_FLO_SAVE_FLO_LOADER_TITLE_TEXT}</div>
				)}
				{recordState === 'onStopSaving' && (
					<div className={styles.processingSubTitle}>
						<span>{NEW_FLO_PROCESS_FLO_LOADER_SUB_TITLE_TEXT}</span>
						<div className={styles.uploadSpeed}>
							Upload speed: {get(networkStats, 'uploadSpeed')}
						</div>
						<div className={styles.uploadProgress}>
							{get(networkStats, 'uploaded')}/{get(networkStats, 'totalSize')} uploaded
						</div>
						<div className={styles.stats}>
							<LinearProgress
								classes={{
									dashed: styles.dashed,
									barColorPrimary: styles.progressBar,
									colorPrimary: styles.progressBarColorPrimary,
									root: styles.progressBarRoot,
								}}
								variant="buffer"
								value={percentageUploaded}
								valueBuffer={buffer}
							/>
						</div>
					</div>
				)}
				{recordState === 'onDiscardSaving' && (
					<div className={styles.processingSubTitle}>
						<span>{NEW_FLO_DISCARD_FLO_LOADER_SUB_TITLE_TEXT}</span>
					</div>
				)}
				{recordState === 'saving' && (
					<div className={styles.processingSubTitle}>
						<span>{NEW_FLO_SAVE_FLO_LOADER_SUB_TITLE_TEXT}</span>
						<div className={styles.stats}>
							{/*{percentageUploaded}%*/}
							<LinearProgress
								classes={{
									dashed: styles.dashed,
									barColorPrimary: styles.progressBar,
									colorPrimary: styles.progressBarColorPrimary,
									root: styles.progressBarRoot,
								}}
								variant="buffer"
								value={percentageUploaded}
								valueBuffer={buffer}
							/>
						</div>
					</div>
				)}
			</div>
		</Zoom>
	);
};

export default Waiting;
