import { createSlice } from '@reduxjs/toolkit';

const initialState = Object.freeze({});

interface TranslationsReducerType {
	constants: {
		[key: string]: string;
	};
}

const translationReducer = {
	getTranslations(state: TranslationsReducerType) {},
	setTranslations(
		state: TranslationsReducerType,
		{
			payload,
		}: {
			payload: {
				[key: string]: string;
			};
		}
	) {
		state.constants = payload;
	},
};

export const Translations = createSlice({
	name: 'routes',
	initialState,
	//@ts-ignore
	reducers: translationReducer,
});

export const getTranslations = Translations.actions.getTranslations;
export const setTranslations = Translations.actions.setTranslations;

export default Translations.reducer;
