import {
	AutoSizer,
	InfiniteLoader,
	InfiniteLoaderProps,
	List,
	ListProps,
} from 'react-virtualized';

const InfiniteScrollList = ({
	rowHeight,
	loadMoreRows,
	rowRenderer,
	listData,
	hasMoreRows,
}: {
	rowHeight?: number;
	rowRenderer: ListProps['rowRenderer'];
	loadMoreRows: InfiniteLoaderProps['loadMoreRows'];
	listData: any[];
	hasMoreRows: boolean;
}) => {
	const isRowLoaded = ({ index }: { index: number }) => !!listData[index];

	return (
		<AutoSizer>
			{({ height, width }) => (
				<InfiniteLoader
					isRowLoaded={isRowLoaded}
					loadMoreRows={loadMoreRows}
					rowCount={listData.length + (hasMoreRows ? 4 : 0)}
				>
					{({ onRowsRendered, registerChild }) => (
						<List
							width={width || 300}
							height={height || 400}
							rowCount={listData.length}
							rowHeight={rowHeight || 30}
							rowRenderer={rowRenderer}
							onRowsRendered={onRowsRendered}
							ref={registerChild}
						/>
					)}
				</InfiniteLoader>
			)}
		</AutoSizer>
	);
};

export default InfiniteScrollList;
