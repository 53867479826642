import React from 'react';
import { Route } from 'react-router-dom';

export const noAuthErrorPagePath = '/security';

const LazyPage = React.lazy(() => import('./NoAuthErrorPage'));

const NoAuthErrorPageRoute = (
	<Route path={noAuthErrorPagePath} element={<LazyPage />}></Route>
);

export default NoAuthErrorPageRoute;
