import { useCallback, useEffect, useState } from 'react';
import { SendMessageToExtension } from '../../../utils/SendMessageToExtension';
import { hideCountDown, showCountDown } from '../NewFloExtension.actions';

const useStartNewfloEffect = ({
	canShowCountDown,
	countDown,
	isPrepareScreen,
	countdownType,
	hasAudioStarted,
	hasResumeAudioStarted,
	setCountDown,
	audio,
	resumeAudio,
	setCanShowCountDown,
	setHasAudioCompleted,
	setCountdownType,
	validExtension,
	setStartAudioStarted,
	setResumeAudioStarted,
}: {
	canShowCountDown?: boolean;
	validExtension?: boolean;
	countDown: number;
	isPrepareScreen?: boolean;
	countdownType?: string;
	hasAudioStarted?: boolean;
	hasResumeAudioStarted?: boolean;
	setCountDown(val: number): void;
	audio: HTMLAudioElement;
	resumeAudio: HTMLAudioElement;
	setCanShowCountDown(val: boolean): void;
	setHasAudioCompleted(val: boolean): void;
	setStartAudioStarted(val: boolean): void;
	setResumeAudioStarted(val: boolean): void;
	setCountdownType(val: string): void;
}) => {
	const [countDownInterval, setCountDownInterval] = useState<NodeJS.Timeout | null>(null);

	useEffect(() => {
		if (countDown !== 0) return;
		SendMessageToExtension(hideCountDown({}));
		// setCountdownType('');
		setCanShowCountDown(false);
		setCountDown(3);
		setStartAudioStarted(false);
		setResumeAudioStarted(false);
		// @ts-ignore
		setCountDownInterval((i) => i && clearInterval(i));
	}, [validExtension, countDown, setCountdownType, setCountDown, setStartAudioStarted]);

	const onInterval = useCallback(() => {
		const newValue = countDown - 1;
		if (newValue !== 0) {
			setCountDown(newValue);
		}
		if (validExtension) {
			SendMessageToExtension(
				showCountDown({
					countDownStartTime: +new Date(),
					value: newValue,
				})
			);
		}

		if (newValue === 0) {
			SendMessageToExtension(hideCountDown({}));
			setHasAudioCompleted(true);
		} else if (countdownType === 'start') {
			audio.play();
		} else if (countdownType === 'resume') {
			resumeAudio.play();
		}
	}, [countdownType, audio, resumeAudio, countDown, setCountDown]);

	useEffect(() => {
		if (!hasAudioStarted || countdownType !== 'start') return;
		const interval = setInterval(onInterval, 1000);
		setCountDownInterval(interval);
		return () => {
			if (interval) clearInterval(interval);
		};
	}, [countdownType, hasAudioStarted, setCountDownInterval, onInterval]);

	useEffect(() => {
		if (!hasResumeAudioStarted || countdownType !== 'resume') return;

		const interval = setInterval(onInterval, 1000);
		setCountDownInterval(interval);
		return () => {
			if (interval) clearInterval(interval);
		};
	}, [countdownType, hasResumeAudioStarted, setCountDownInterval, onInterval]);
};

export default useStartNewfloEffect;
