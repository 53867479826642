import { get } from 'lodash';
import { RootState } from '../../store';
import { ManageFlosReducerType } from './ManageFlos.types';

export const floSpaceSelector = (state: RootState): string =>
	get(state, 'routerContainer.currentFloSpace.id');

export const showFlosSelectors = (state: RootState) => get(state, 'manageFlos.showFlos');
export const manageFilterSelector = (state: RootState) =>
	get(state, 'manageFlos.filter', 'type:all');

export const flosSelectors = (state: RootState): ManageFlosReducerType =>
	get(state, 'manageFlos');
